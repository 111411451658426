import React from 'react';

import { IconProps } from '../../types/ui';

function MyDuoIcon({ size = 24 }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.89476 5.89235C7.08392 4.70289 7.08392 2.86744 5.89476 1.68824C4.70559 0.509037 2.87059 0.498783 1.69168 1.68824C0.502514 2.87769 0.502514 4.71315 1.69168 5.89235C2.88084 7.07155 4.70559 7.08181 5.89476 5.89235Z"
        fill="#AC3EA4"
      />
      <path
        d="M26.3157 22.1038C25.1265 20.9144 23.2915 20.9144 22.1126 22.1038C20.9234 23.2933 20.9234 25.1287 22.1126 26.3079C23.3017 27.4974 25.1367 27.4974 26.3157 26.3079C27.4946 25.1287 27.4946 23.2933 26.3157 22.1038Z"
        fill="#4F196F"
      />
      <path
        d="M8.4573 20.5862C9.04163 20.2478 9.45169 19.7044 9.62596 19.0584C9.76948 18.5047 9.76948 17.8894 9.5132 17.4178C8.92886 16.4641 8.60082 15.3054 8.60082 14.0545C8.60082 10.9168 10.8254 8.27125 13.9828 8.27125C15.4693 8.27125 16.7404 8.86598 17.6836 9.81959C17.8373 8.88649 18.4832 8.04566 19.3751 7.52271C20.1029 7.1023 20.9333 6.969 21.7021 7.08179C19.7851 5.06177 17.0377 3.81079 13.9726 3.81079C7.94473 3.81079 3.52637 8.4148 3.52637 14.5569C3.52637 16.7102 4.22346 18.597 5.37162 20.135C6.07897 20.9861 7.43216 21.1809 8.4573 20.5862Z"
        fill="#AC3EA4"
      />
      <path
        d="M24.4602 13.9725C24.4602 12.2601 24.0296 10.6605 23.271 9.25571C22.5842 8.11753 21.0669 7.7894 19.9085 8.45591C18.8731 9.05063 18.3606 10.2914 18.7604 11.368C19.1704 12.2088 19.396 13.1522 19.396 14.1468C19.396 17.305 17.2022 19.9608 14.0652 19.9608C12.7735 19.9608 11.6561 19.5404 10.7643 18.8431C10.7438 19.0072 10.713 19.1815 10.6618 19.3456C10.4157 20.2684 9.82114 21.0477 8.99077 21.5194C8.42695 21.8475 7.81186 22.0013 7.20703 22.0013C7.19677 22.0013 7.19677 22.0013 7.18652 22.0013C9.12404 23.5394 11.6356 24.411 14.2292 24.411C19.9085 24.4008 24.4602 19.848 24.4602 13.9725Z"
        fill="#4F196F"
      />
    </svg>
  );
}

export default MyDuoIcon;
