import React from 'react';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const SaveIcon = ({ size = 20, colorOverride = 'magenta' }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.45976 2.45994C2.96767 1.95203 3.65654 1.66669 4.37484 1.66669H15.6248C16.3431 1.66669 17.032 1.95203 17.5399 2.45994C18.0478 2.96785 18.3332 3.65673 18.3332 4.37502V15.625C18.3332 16.3433 18.0478 17.0322 17.5399 17.5401C17.032 18.048 16.3431 18.3334 15.6248 18.3334H4.37484C3.65654 18.3334 2.96767 18.048 2.45976 17.5401C1.95185 17.0322 1.6665 16.3433 1.6665 15.625V4.37502C1.6665 3.65673 1.95185 2.96785 2.45976 2.45994ZM12.9165 3.33335H7.08317V12.4017L9.62716 11.1297C9.86177 11.0124 10.1379 11.0124 10.3725 11.1297L12.9165 12.4017V3.33335ZM5.4165 3.33335H4.37484C4.09857 3.33335 3.83362 3.4431 3.63827 3.63845C3.44292 3.8338 3.33317 4.09875 3.33317 4.37502V15.625C3.33317 15.9013 3.44292 16.1662 3.63827 16.3616C3.83362 16.5569 4.09857 16.6667 4.37484 16.6667H15.6248C15.9011 16.6667 16.1661 16.5569 16.3614 16.3616C16.5568 16.1662 16.6665 15.9013 16.6665 15.625V4.37502C16.6665 4.09875 16.5568 3.8338 16.3614 3.63845C16.1661 3.4431 15.9011 3.33335 15.6248 3.33335H14.5832V13.75C14.5832 14.0388 14.4336 14.3071 14.1879 14.4589C13.9423 14.6107 13.6355 14.6245 13.3772 14.4954L9.99984 12.8067L6.62252 14.4954C6.36419 14.6245 6.05741 14.6107 5.81173 14.4589C5.56605 14.3071 5.4165 14.0388 5.4165 13.75V3.33335Z"
        className={fillClass}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6665 4.47919C6.6665 3.61624 7.22615 2.91669 7.9165 2.91669H12.0832C12.7735 2.91669 13.3332 3.61624 13.3332 4.47919V12.8125C13.3332 13.003 13.2499 13.1784 13.1162 13.2697C12.9824 13.361 12.8194 13.3537 12.6912 13.2506L10.2252 11.269C10.0879 11.1587 9.91177 11.1587 9.77452 11.269L7.30849 13.2506C7.18027 13.3537 7.01729 13.361 6.88351 13.2697C6.74973 13.1784 6.6665 13.003 6.6665 12.8125V4.47919Z"
        className={fillClass}
      />
    </svg>
  );
};

export default SaveIcon;
