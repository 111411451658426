import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const Headset = ({ size = 24, colorOverride }: IconProps) => {
  const colorStyle = toFillColor(colorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none" className={colorStyle}>
      <g id="headset-help">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 5.11089C9.70445 5.11089 6.22222 8.59312 6.22222 12.8887C6.22222 13.5023 5.72476 13.9998 5.11111 13.9998C4.49746 13.9998 4 13.5023 4 12.8887C4 7.36582 8.47715 2.88867 14 2.88867C19.5228 2.88867 24 7.36582 24 12.8887C24 13.5023 23.5025 13.9998 22.8889 13.9998C22.2752 13.9998 21.7778 13.5023 21.7778 12.8887C21.7778 8.59312 18.2955 5.11089 14 5.11089Z"
        />
        <path
          id="Vector (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.23603 12.0168C7.35807 11.7363 8.44499 12.585 8.44499 13.7415V19.8116C8.44499 20.9682 7.35807 21.8168 6.23603 21.5363L4.3032 21.0531C2.81931 20.6821 1.77832 19.3489 1.77832 17.8193V15.7339C1.77832 14.2043 2.81931 12.871 4.3032 12.5001L6.23603 12.0168ZM6.22276 14.3108L4.84217 14.6559C4.34754 14.7796 4.00054 15.224 4.00054 15.7339V17.8193C4.00054 18.3292 4.34754 18.7736 4.84217 18.8972L6.22276 19.2424V14.3108Z"
        />
        <path
          id="Vector (Stroke)_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7779 14.3108V19.2424L23.1585 18.8972C23.6531 18.7736 24.0001 18.3292 24.0001 17.8193V15.7339C24.0001 15.224 23.6531 14.7796 23.1585 14.6559L21.7779 14.3108ZM19.5557 13.7415C19.5557 12.585 20.6426 11.7363 21.7646 12.0168L23.6975 12.5001C25.1813 12.871 26.2223 14.2043 26.2223 15.7339V17.8193C26.2223 19.3489 25.1813 20.6821 23.6974 21.0531L21.7646 21.5363C20.6426 21.8168 19.5557 20.9682 19.5557 19.8116V13.7415Z"
        />
        <path
          id="Vector (Stroke)_4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7783 21.2216V20.666H24.0005V21.2216C24.0005 23.0625 22.5082 24.5549 20.6672 24.5549H16.7783V22.3327H20.6672C21.2809 22.3327 21.7783 21.8352 21.7783 21.2216Z"
        />
        <path
          id="Vector (Stroke)_5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3334 22.8882C12.0266 22.8882 11.7779 23.137 11.7779 23.4438C11.7779 23.7506 12.0266 23.9993 12.3334 23.9993H15.6668C15.9736 23.9993 16.2223 23.7506 16.2223 23.4438C16.2223 23.137 15.9736 22.8882 15.6668 22.8882H12.3334ZM9.55566 23.4438C9.55566 21.9097 10.7993 20.666 12.3334 20.666H15.6668C17.2009 20.666 18.4446 21.9097 18.4446 23.4438C18.4446 24.9779 17.2009 26.2216 15.6668 26.2216H12.3334C10.7993 26.2216 9.55566 24.9779 9.55566 23.4438Z"
        />
      </g>
    </svg>
  );
};

export default Headset;
