import { emailRegExp, phoneNumberRegExp } from './validators';

export const states = [
  {
    name: 'Alabama',
    code: 'AL',
  },
  {
    name: 'Alaska',
    code: 'AK',
  },
  {
    name: 'Arizona',
    code: 'AZ',
  },
  {
    name: 'Arkansas',
    code: 'AR',
  },
  {
    name: 'California',
    code: 'CA',
  },
  {
    name: 'Colorado',
    code: 'CO',
  },
  {
    name: 'Connecticut',
    code: 'CT',
  },
  {
    name: 'Delaware',
    code: 'DE',
  },
  {
    name: 'Florida',
    code: 'FL',
  },
  {
    name: 'Georgia',
    code: 'GA',
  },
  {
    name: 'Hawaii',
    code: 'HI',
  },
  {
    name: 'Idaho',
    code: 'ID',
  },
  {
    name: 'Illinois',
    code: 'IL',
  },
  {
    name: 'Indiana',
    code: 'IN',
  },
  {
    name: 'Iowa',
    code: 'IA',
  },
  {
    name: 'Kansas',
    code: 'KS',
  },
  {
    name: 'Kentucky',
    code: 'KY',
  },
  {
    name: 'Louisiana',
    code: 'LA',
  },
  {
    name: 'Maine',
    code: 'ME',
  },
  {
    name: 'Maryland',
    code: 'MD',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
  },
  {
    name: 'Michigan',
    code: 'MI',
  },
  {
    name: 'Minnesota',
    code: 'MN',
  },
  {
    name: 'Mississippi',
    code: 'MS',
  },
  {
    name: 'Missouri',
    code: 'MO',
  },
  {
    name: 'Montana',
    code: 'MT',
  },
  {
    name: 'Nebraska',
    code: 'NE',
  },
  {
    name: 'Nevada',
    code: 'NV',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
  },
  {
    name: 'New Mexico',
    code: 'NM',
  },
  {
    name: 'New York',
    code: 'NY',
  },
  {
    name: 'North Carolina',
    code: 'NC',
  },
  {
    name: 'North Dakota',
    code: 'ND',
  },
  {
    name: 'Ohio',
    code: 'OH',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
  },
  {
    name: 'Oregon',
    code: 'OR',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },
  {
    name: 'Rhode Island',
    code: 'RI',
  },
  {
    name: 'South Carolina',
    code: 'SC',
  },
  {
    name: 'South Dakota',
    code: 'SD',
  },
  {
    name: 'Tennessee',
    code: 'TN',
  },
  {
    name: 'Texas',
    code: 'TX',
  },
  {
    name: 'Utah',
    code: 'UT',
  },
  {
    name: 'Vermont',
    code: 'VT',
  },
  {
    name: 'Virginia',
    code: 'VA',
  },
  {
    name: 'Washington',
    code: 'WA',
  },
  {
    name: 'West Virginia',
    code: 'WV',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
  },
  {
    name: 'Wyoming',
    code: 'WY',
  },
];

export const stateNameByCode = (stateCode: string) => {
  return states.filter(({ name, code }) => code === stateCode)[0].name;
};

export const yesNoOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export const formatTimezoneString = (tz?: string) => {
  if (tz) {
    return tz.replace('_', ' ');
  }

  return '';
};

export const isoLangs: Record<string, { name: string }> = {
  aa: {
    name: 'Afar',
  },
  ab: {
    name: 'Abkhaz',
  },
  ae: {
    name: 'Avestan',
  },
  af: {
    name: 'Afrikaans',
  },
  ak: {
    name: 'Akan',
  },
  am: {
    name: 'Amharic',
  },
  an: {
    name: 'Aragonese',
  },
  ar: {
    name: 'Arabic',
  },
  as: {
    name: 'Assamese',
  },
  av: {
    name: 'Avaric',
  },
  ay: {
    name: 'Aymara',
  },
  az: {
    name: 'Azerbaijani',
  },
  ba: {
    name: 'Bashkir',
  },
  be: {
    name: 'Belarusian',
  },
  bg: {
    name: 'Bulgarian',
  },
  bh: {
    name: 'Bihari',
  },
  bi: {
    name: 'Bislama',
  },
  bm: {
    name: 'Bambara',
  },
  bn: {
    name: 'Bengali',
  },
  bo: {
    name: 'Tibetan Standard, Tibetan, Central',
  },
  br: {
    name: 'Breton',
  },
  bs: {
    name: 'Bosnian',
  },
  ca: {
    name: 'Catalan; Valencian',
  },
  ce: {
    name: 'Chechen',
  },
  ch: {
    name: 'Chamorro',
  },
  co: {
    name: 'Corsican',
  },
  cr: {
    name: 'Cree',
  },
  cs: {
    name: 'Czech',
  },
  cu: {
    name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
  },
  cv: {
    name: 'Chuvash',
  },
  cy: {
    name: 'Welsh',
  },
  da: {
    name: 'Danish',
  },
  de: {
    name: 'German',
  },
  dv: {
    name: 'Divehi; Dhivehi; Maldivian;',
  },
  ee: {
    name: 'Ewe',
  },
  el: {
    name: 'Greek, Modern',
  },
  en: {
    name: 'English',
  },
  eo: {
    name: 'Esperanto',
  },
  es: {
    name: 'Spanish; Castilian',
  },
  et: {
    name: 'Estonian',
  },
  eu: {
    name: 'Basque',
  },
  fa: {
    name: 'Persian',
  },
  ff: {
    name: 'Fula; Fulah; Pulaar; Pular',
  },
  fi: {
    name: 'Finnish',
  },
  fj: {
    name: 'Fijian',
  },
  fo: {
    name: 'Faroese',
  },
  fr: {
    name: 'French',
  },
  fy: {
    name: 'Western Frisian',
  },
  ga: {
    name: 'Irish',
  },
  gd: {
    name: 'Scottish Gaelic; Gaelic',
  },
  gl: {
    name: 'Galician',
  },
  gn: {
    name: 'Guaraní',
  },
  gu: {
    name: 'Gujarati',
  },
  gv: {
    name: 'Manx',
  },
  ha: {
    name: 'Hausa',
  },
  he: {
    name: 'Hebrew (modern)',
  },
  hi: {
    name: 'Hindi',
  },
  ho: {
    name: 'Hiri Motu',
  },
  hr: {
    name: 'Croatian',
  },
  ht: {
    name: 'Haitian; Haitian Creole',
  },
  hu: {
    name: 'Hungarian',
  },
  hy: {
    name: 'Armenian',
  },
  hz: {
    name: 'Herero',
  },
  ia: {
    name: 'Interlingua',
  },
  id: {
    name: 'Indonesian',
  },
  ie: {
    name: 'Interlingue',
  },
  ig: {
    name: 'Igbo',
  },
  ii: {
    name: 'Nuosu',
  },
  ik: {
    name: 'Inupiaq',
  },
  io: {
    name: 'Ido',
  },
  is: {
    name: 'Icelandic',
  },
  it: {
    name: 'Italian',
  },
  iu: {
    name: 'Inuktitut',
  },
  ja: {
    name: 'Japanese',
  },
  jv: {
    name: 'Javanese',
  },
  ka: {
    name: 'Georgian',
  },
  kg: {
    name: 'Kongo',
  },
  ki: {
    name: 'Kikuyu, Gikuyu',
  },
  kj: {
    name: 'Kwanyama, Kuanyama',
  },
  kk: {
    name: 'Kazakh',
  },
  kl: {
    name: 'Kalaallisut, Greenlandic',
  },
  km: {
    name: 'Khmer',
  },
  kn: {
    name: 'Kannada',
  },
  ko: {
    name: 'Korean',
  },
  kr: {
    name: 'Kanuri',
  },
  ks: {
    name: 'Kashmiri',
  },
  ku: {
    name: 'Kurdish',
  },
  kv: {
    name: 'Komi',
  },
  kw: {
    name: 'Cornish',
  },
  ky: {
    name: 'Kirghiz, Kyrgyz',
  },
  la: {
    name: 'Latin',
  },
  lb: {
    name: 'Luxembourgish, Letzeburgesch',
  },
  lg: {
    name: 'Luganda',
  },
  li: {
    name: 'Limburgish, Limburgan, Limburger',
  },
  ln: {
    name: 'Lingala',
  },
  lo: {
    name: 'Lao',
  },
  lt: {
    name: 'Lithuanian',
  },
  lu: {
    name: 'Luba-Katanga',
  },
  lv: {
    name: 'Latvian',
  },
  mg: {
    name: 'Malagasy',
  },
  mh: {
    name: 'Marshallese',
  },
  mi: {
    name: 'Māori',
  },
  mk: {
    name: 'Macedonian',
  },
  ml: {
    name: 'Malayalam',
  },
  mn: {
    name: 'Mongolian',
  },
  mr: {
    name: 'Marathi (Marāṭhī)',
  },
  ms: {
    name: 'Malay',
  },
  mt: {
    name: 'Maltese',
  },
  my: {
    name: 'Burmese',
  },
  na: {
    name: 'Nauru',
  },
  nb: {
    name: 'Norwegian Bokmål',
  },
  nd: {
    name: 'North Ndebele',
  },
  ne: {
    name: 'Nepali',
  },
  ng: {
    name: 'Ndonga',
  },
  nl: {
    name: 'Dutch',
  },
  nn: {
    name: 'Norwegian Nynorsk',
  },
  no: {
    name: 'Norwegian',
  },
  nr: {
    name: 'South Ndebele',
  },
  nv: {
    name: 'Navajo, Navaho',
  },
  ny: {
    name: 'Chichewa; Chewa; Nyanja',
  },
  oc: {
    name: 'Occitan',
  },
  oj: {
    name: 'Ojibwe, Ojibwa',
  },
  om: {
    name: 'Oromo',
  },
  or: {
    name: 'Oriya',
  },
  os: {
    name: 'Ossetian, Ossetic',
  },
  pa: {
    name: 'Panjabi, Punjabi',
  },
  pi: {
    name: 'Pāli',
  },
  pl: {
    name: 'Polish',
  },
  ps: {
    name: 'Pashto, Pushto',
  },
  pt: {
    name: 'Portuguese',
  },
  qu: {
    name: 'Quechua',
  },
  rm: {
    name: 'Romansh',
  },
  rn: {
    name: 'Kirundi',
  },
  ro: {
    name: 'Romanian, Moldavian, Moldovan',
  },
  ru: {
    name: 'Russian',
  },
  rw: {
    name: 'Kinyarwanda',
  },
  sa: {
    name: 'Sanskrit (Saṁskṛta)',
  },
  sc: {
    name: 'Sardinian',
  },
  sd: {
    name: 'Sindhi',
  },
  se: {
    name: 'Northern Sami',
  },
  sg: {
    name: 'Sango',
  },
  si: {
    name: 'Sinhala, Sinhalese',
  },
  sk: {
    name: 'Slovak',
  },
  sl: {
    name: 'Slovene',
  },
  sm: {
    name: 'Samoan',
  },
  sn: {
    name: 'Shona',
  },
  so: {
    name: 'Somali',
  },
  sq: {
    name: 'Albanian',
  },
  sr: {
    name: 'Serbian',
  },
  ss: {
    name: 'Swati',
  },
  st: {
    name: 'Southern Sotho',
  },
  su: {
    name: 'Sundanese',
  },
  sv: {
    name: 'Swedish',
  },
  sw: {
    name: 'Swahili',
  },
  ta: {
    name: 'Tamil',
  },
  te: {
    name: 'Telugu',
  },
  tg: {
    name: 'Tajik',
  },
  th: {
    name: 'Thai',
  },
  ti: {
    name: 'Tigrinya',
  },
  tk: {
    name: 'Turkmen',
  },
  tl: {
    name: 'Tagalog',
  },
  tn: {
    name: 'Tswana',
  },
  to: {
    name: 'Tonga (Tonga Islands)',
  },
  tr: {
    name: 'Turkish',
  },
  ts: {
    name: 'Tsonga',
  },
  tt: {
    name: 'Tatar',
  },
  tw: {
    name: 'Twi',
  },
  ty: {
    name: 'Tahitian',
  },
  ug: {
    name: 'Uighur, Uyghur',
  },
  uk: {
    name: 'Ukrainian',
  },
  ur: {
    name: 'Urdu',
  },
  uz: {
    name: 'Uzbek',
  },
  ve: {
    name: 'Venda',
  },
  vi: {
    name: 'Vietnamese',
  },
  vo: {
    name: 'Volapük',
  },
  wa: {
    name: 'Walloon',
  },
  wo: {
    name: 'Wolof',
  },
  xh: {
    name: 'Xhosa',
  },
  yi: {
    name: 'Yiddish',
  },
  yo: {
    name: 'Yoruba',
  },
  za: {
    name: 'Zhuang, Chuang',
  },
  zh: {
    name: 'Chinese',
  },
};

export const formatLanguageString = (lang?: string) => {
  if (lang) {
    const langCode = lang.slice(0, 2);
    return isoLangs[langCode]?.name ?? 'lang';
  }

  return '';
};

export const formatBinaryValue = (value?: boolean) => {
  if (typeof value === 'undefined') {
    return '';
  }

  return value ? 'Yes' : 'No';
};

export const findPhoneNumbersInString = (text: string) => {
  const result = [];
  let source = text;

  let phones = source.match(phoneNumberRegExp);

  while (phones && phones.length > 0) {
    result.push(phones[0]);
    source = source.replace(phones[0], 'PHONE');
    phones = source.match(phoneNumberRegExp);
  }

  return result;
};

export const findEmailAddressesInString = (text: string) => {
  const result = [];
  let source = text;
  let emails = source.match(emailRegExp);

  while (emails && emails.length > 0) {
    result.push(emails[0]);
    source = source.replace(emails[0], 'EMAIL');
    emails = source.match(emailRegExp);
  }

  return result;
};

export const containsPhoneNumber = (phone: string, number: string) => {
  if (!number || !/^[+\-0-9(). ]+$/g.test(number)) {
    return false;
  }

  let digits = number?.replace(/\D/g, '') ?? '';
  // Strip country code if US.
  if (digits && digits.charAt(0) === '1') {
    digits = digits.slice(1);
  }

  return phone.indexOf(digits) > -1;
};

export type ValueOf<T> = T[keyof T];
