import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor, toHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const HouseWithPlusIcon = ({
  size = 56,
  colorOverride = 'magenta',
  hoverColorOverride,
  className,
}: IconProps) => {
  const colorClass = toFillColor(colorOverride);
  const hoverFillClassName = toHoverFillColor(hoverColorOverride);
  const groupHoverClass = toGroupHoverFillColor(hoverColorOverride);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, colorClass, hoverFillClassName, groupHoverClass, 'group')}
    >
      <path
        d="M55.5952 27.0614L29.2471 0.514639C28.918 0.185016 28.4728 0 28.0088 0C27.5447 0 27.0995 0.185016 26.7704 0.514639L0.422372 27.0614C0.134605 27.3999 -0.0157656 27.8354 0.00130999 28.2808C0.0183856 28.7262 0.201649 29.1488 0.514479 29.4639C0.827309 29.7791 1.24666 29.9638 1.68874 29.981C2.13082 29.9982 2.56306 29.8467 2.89909 29.5567L28 4.26657L53.1009 29.5744C53.4369 29.8644 53.8692 30.0159 54.3113 29.9987C54.7533 29.9815 55.1727 29.7968 55.4855 29.4816C55.7984 29.1665 55.9816 28.7439 55.9987 28.2985C56.0158 27.8531 55.8654 27.4176 55.5776 27.0791L55.5952 27.0614Z"
        className={classNames(colorClass, hoverFillClassName, groupHoverClass)}
      />
      <path
        d="M44.6667 52.4286H36.3333V34.5714H19.6667V52.4286H11.3333V27.4286L8 31V52.4286C8 53.3758 8.35119 54.2842 8.97631 54.954C9.60143 55.6237 10.4493 56 11.3333 56H23V38.1429H33V56H44.6667C45.5507 56 46.3986 55.6237 47.0237 54.954C47.6488 54.2842 48 53.3758 48 52.4286V30.5714L44.6667 27V52.4286Z"
        className={classNames(colorClass, hoverFillClassName, groupHoverClass)}
      />
      <path
        d="M55.5952 27.5446L29.2471 0.523829C28.918 0.18832 28.4728 0 28.0088 0C27.5447 0 27.0995 0.18832 26.7704 0.523829L0.422372 27.5446C0.134605 27.8892 -0.0157656 28.3325 0.00130999 28.7858C0.0183856 29.2392 0.201649 29.6693 0.514479 29.9901C0.827309 30.3109 1.24666 30.4988 1.68874 30.5164C2.13082 30.5339 2.56306 30.3797 2.89909 30.0845L28 4.34276L53.1009 30.1026C53.4369 30.3977 53.8692 30.5519 54.3113 30.5344C54.7533 30.5169 55.1727 30.3289 55.4855 30.0081C55.7984 29.6873 55.9816 29.2572 55.9987 28.8039C56.0158 28.3505 55.8654 27.9072 55.5776 27.5626L55.5952 27.5446Z"
        className={classNames(colorClass, hoverFillClassName, groupHoverClass)}
      />
      <path
        d="M44.6667 53.3651H36.3333V35.1891H19.6667V53.3651H11.3333V27.9186L8 31.5539V53.3651C8 54.3292 8.35119 55.2538 8.97631 55.9356C9.60143 56.6173 10.4493 57.0003 11.3333 57.0003H23V38.8243H33V57.0003H44.6667C45.5507 57.0003 46.3986 56.6173 47.0237 55.9356C47.6488 55.2538 48 54.3292 48 53.3651V31.1176L44.6667 27.4824V53.3651Z"
        className={classNames(colorClass, hoverFillClassName, groupHoverClass)}
      />
      <path
        d="M34.0479 18.6464H31.3712V15.9566C31.3712 15.7029 31.2709 15.4596 31.0924 15.2802C30.9138 15.1008 30.6717 15 30.4192 15H25.5808C25.3283 15 25.0862 15.1008 24.9076 15.2802C24.7291 15.4596 24.6288 15.7029 24.6288 15.9566V18.6464H21.9521C21.6996 18.6464 21.4574 18.7472 21.2789 18.9266C21.1003 19.106 21 19.3493 21 19.603V24.4643C21 24.718 21.1003 24.9613 21.2789 25.1407C21.4574 25.32 21.6996 25.4208 21.9521 25.4208H24.6288V28.1102C24.6287 28.2359 24.6533 28.3603 24.7011 28.4764C24.7489 28.5925 24.819 28.6979 24.9074 28.7868C24.9959 28.8756 25.1009 28.9461 25.2164 28.9941C25.3319 29.0422 25.4558 29.0669 25.5808 29.0668H30.4192C30.5442 29.0669 30.6681 29.0422 30.7836 28.9941C30.8992 28.9461 31.0042 28.8756 31.0926 28.7868C31.181 28.698 31.2511 28.5925 31.2989 28.4764C31.3468 28.3603 31.3713 28.2359 31.3712 28.1102V25.4208H34.0479C34.3004 25.4208 34.5426 25.32 34.7211 25.1407C34.8997 24.9613 35 24.718 35 24.4643V19.603C35 19.3493 34.8997 19.106 34.7211 18.9266C34.5426 18.7472 34.3004 18.6464 34.0479 18.6464ZM33.0959 23.5077H30.4192C30.1667 23.5077 29.9245 23.6085 29.746 23.7879C29.5674 23.9673 29.4671 24.2106 29.4671 24.4643V27.1536H26.5329V24.4643C26.5329 24.2106 26.4326 23.9673 26.254 23.7879C26.0755 23.6085 25.8333 23.5077 25.5808 23.5077H22.9041V20.5596H25.5808C25.8333 20.5596 26.0755 20.4588 26.254 20.2794C26.4326 20.1 26.5329 19.8567 26.5329 19.603V16.9131H29.4671V19.603C29.4671 19.8567 29.5674 20.1 29.746 20.2794C29.9245 20.4588 30.1667 20.5596 30.4192 20.5596H33.0959V23.5077Z"
        className={classNames(colorClass, hoverFillClassName, groupHoverClass)}
      />
    </svg>
  );
};

export default HouseWithPlusIcon;
