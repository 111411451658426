import React, { ForwardedRef, PropsWithChildren, forwardRef } from 'react';

import UtilityButton from './UtilityButton';
import { ButtonProps, CommonProps, UtilityButtonProps } from './types';

const defaults: Required<CommonProps> = {
  variant: 'submit',
  fullWidth: false,
  isActive: false,
};

const buttonDefaults: UtilityButtonProps = {
  type: 'button',
};

type RefType = HTMLButtonElement;

type UtilityAction = {
  (props: UtilityButtonProps): JSX.Element;
};

const UtilityActionComponent = forwardRef<RefType, PropsWithChildren<ButtonProps>>((passedProps, ref): JSX.Element => {
  let props = { ...defaults, ...passedProps };
  props = { ...buttonDefaults, ...props };
  return <UtilityButton {...props} ref={ref as ForwardedRef<HTMLButtonElement>} />;
});

UtilityActionComponent.displayName = 'UtilityAction';

const UtilityAction = UtilityActionComponent as UtilityAction;
export default UtilityAction;
