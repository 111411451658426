import React from 'react';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const DialogIcon = ({ size = 20, colorOverride }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09987 4.10992C2.19159 3.00366 2.93451 2.15411 4.0332 1.99565C5.19966 1.82742 7.07833 1.66687 10.0004 1.66687C12.9224 1.66687 14.8011 1.82742 15.9676 1.99565C17.0663 2.15411 17.8092 3.00366 17.9009 4.10992C17.9831 5.10136 18.0559 6.58951 18.0559 8.7502C18.0559 10.9109 17.9831 12.399 17.9009 13.3905C17.8092 14.4967 17.0663 15.3463 15.9676 15.5048C14.8386 15.6676 13.0424 15.8232 10.2788 15.833L7.44792 18.0977C6.62946 18.7525 5.41705 18.1698 5.41705 17.1216V15.6619C4.87194 15.6139 4.41451 15.5597 4.0332 15.5048C2.93451 15.3463 2.19159 14.4968 2.09987 13.3905C2.01767 12.399 1.94482 10.9109 1.94482 8.7502C1.94482 6.58951 2.01767 5.10137 2.09987 4.10992ZM5.7522 6.57807C5.7522 5.97572 6.17925 5.50656 6.78122 5.48516C7.4087 5.46284 8.4103 5.43912 10.0022 5.42298C11.6797 5.40597 12.7017 5.42691 13.3211 5.45606C13.8883 5.48275 14.2522 5.91537 14.2522 6.48316C14.2522 7.09702 13.8396 7.56726 13.2262 7.59132C12.5989 7.61592 11.5966 7.63687 10.0022 7.63687C8.32524 7.63687 7.30335 7.6137 6.68381 7.58747C6.12697 7.5639 5.7522 7.13541 5.7522 6.57807ZM5.7522 11.1099C5.7522 10.5208 6.14411 10.0564 6.73254 10.0299C7.115 10.0126 7.63997 9.99988 8.35669 9.99988C9.07341 9.99988 9.59838 10.0126 9.98084 10.0299C10.5693 10.0564 10.9612 10.5208 10.9612 11.1099C10.9612 11.6989 10.5693 12.1633 9.98084 12.1899C9.59838 12.2072 9.07341 12.2199 8.35669 12.2199C7.63997 12.2199 7.115 12.2072 6.73254 12.1899C6.14411 12.1633 5.7522 11.6989 5.7522 11.1099Z"
        className={fillClass}
      />
    </svg>
  );
};

export default DialogIcon;
