import { yupResolver } from '@hookform/resolvers/yup';
import { T, useT } from '@transifex/react';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Button, Modal, Text, TextLink } from '.';
import { useUpdateMemberMutation } from '../../hooks/api';
import { Option } from '../../types/Common';
import { EngagementStatus } from '../../types/Member';
import { WelcomeCallOutcome } from '../../types/WelcomeCall';
import { FormDropdown } from '../forms/fields/FormDropdown';
import FormTextField from '../forms/fields/FormTextField';

type Props = {
  memberId: string;
  open?: boolean;
  onClose: () => void;
};

type FormPayload = {
  welcomeCallOutcome: WelcomeCallOutcome;
  cancellationReason?: string;
};

export default function WelcomeCallModal({ open, memberId, onClose }: Props): JSX.Element {
  const t = useT();

  const schema = Yup.object().shape({
    welcomeCallOutcome: Yup.mixed<WelcomeCallOutcome>()
      .required(t('Selection is required.'))
      .oneOf(['needsToReschedule', 'unreachable', 'successful', 'memberCanceled']),
    cancellationReason: Yup.string().when('welcomeCallOutcome', {
      is: 'memberCanceled',
      then: Yup.string().required(t('Cancelation reason is required.')),
    }),
  });

  const options: Array<Option<WelcomeCallOutcome>> = [
    {
      value: 'successful',
      label: t('Welcome call was successful'),
    },
    {
      value: 'needsToReschedule',
      label: t('Member needs to reschedule'),
    },
    {
      value: 'unreachable',
      label: t(`Member didn't answer call after second attempt`),
    },
    {
      value: 'memberCanceled',
      label: t(`Member answered and canceled service`),
    },
  ];

  const { handleSubmit, control, formState, watch } = useForm<FormPayload>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });
  const { mutate: updateMember } = useUpdateMemberMutation({ memberId });

  const { errors } = formState;

  const onSubmit = useCallback(
    async (data: FormPayload) => {
      switch (data.welcomeCallOutcome) {
        case 'memberCanceled':
          await updateMember({
            payload: { ...data, engagementStatus: EngagementStatus.MemberCancelDuringWelcomeCall },
          });
          break;
        case 'needsToReschedule':
          await updateMember({ payload: { ...data, engagementStatus: EngagementStatus.WelcomeCallReschedule } });
          break;
        case 'successful':
          await updateMember({ payload: { ...data, engagementStatus: EngagementStatus.ActiveMember } });
          break;
        case 'unreachable':
          await updateMember({ payload: { ...data, engagementStatus: EngagementStatus.WelcomeCallReschedule } });
          break;
      }

      onClose();
    },
    [updateMember, onClose]
  );

  const welcomeCallOutcome = watch('welcomeCallOutcome');

  return (
    <Modal
      open={open}
      setClose={onClose}
      title={t('Please select the outcome of the Welcome Call')}
      additionalInfo={t("If the member didn't answer after the first attempt, please close this window and try again.")}
    >
      <Text privacyLevel="public" variant="body2" color="darkGrayTwo" className="mb-4">
        <T _str="* indicates a required field." />
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <FormDropdown<FormPayload>
          privacyLevel="phi"
          control={control}
          name="welcomeCallOutcome"
          label={t('* Outcome Options')}
          placeholder={t('Select Outcome')}
          options={options}
          error={errors.welcomeCallOutcome?.message}
        />

        {welcomeCallOutcome === 'memberCanceled' && (
          <div className={'mt-[32px]'}>
            <FormTextField<FormPayload>
              privacyLevel="pii"
              control={control}
              name="cancellationReason"
              label={t('* Explain Reason For Member Cancelation')}
              inputType="paragraph"
              error={errors.cancellationReason?.message}
            />
          </div>
        )}
        <Button type="primary" size="large" marginClass={['mt-8']} fullWidth submit>
          <T _str="Confirm outcome" />
        </Button>
        <TextLink privacyLevel="public" onClick={onClose} className="self-center uppercase mt-8">
          <T _str="Cancel" />
        </TextLink>
      </form>
    </Modal>
  );
}
