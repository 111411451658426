import classNames from 'classnames';

import { toFillColor, toStrokeColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const Up = ({ size = 24, colorOverride, className }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('flex-shrink-0', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.11766 15.6608C5.1899 15.7332 5.27573 15.7907 5.37022 15.8299C5.46471 15.8691 5.56601 15.8893 5.66831 15.8893C5.77061 15.8893 5.87191 15.8691 5.9664 15.8299C6.06089 15.7907 6.14672 15.7332 6.21897 15.6608L11.8904 9.98779L17.5618 15.6608C17.7079 15.8068 17.906 15.8889 18.1125 15.8889C18.319 15.8889 18.5171 15.8068 18.6631 15.6608C18.8092 15.5147 18.8912 15.3167 18.8912 15.1101C18.8912 14.9036 18.8092 14.7055 18.6631 14.5595L12.4411 8.33738C12.3688 8.26495 12.283 8.20749 12.1885 8.16828C12.094 8.12907 11.9927 8.10889 11.8904 8.10889C11.7881 8.10889 11.6868 8.12907 11.5923 8.16828C11.4978 8.20749 11.412 8.26495 11.3397 8.33738L5.11766 14.5595C5.04523 14.6317 4.98776 14.7175 4.94855 14.812C4.90934 14.9065 4.88916 15.0078 4.88916 15.1101C4.88916 15.2124 4.90934 15.3137 4.94855 15.4082C4.98776 15.5027 5.04523 15.5885 5.11766 15.6608Z"
        className={classNames(toFillColor(colorOverride), toStrokeColor(colorOverride))}
      />
    </svg>
  );
};

export default Up;
