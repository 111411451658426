import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor, toGroupHoverStrokeColor, toStrokeColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const DownIcon = ({ size = 24, colorOverride, hoverColorOverride, className, active = false }: IconProps) => {
  const fillColor = toFillColor(colorOverride);
  const strokeColor = toStrokeColor(colorOverride);
  const hoverFillColor = toGroupHoverFillColor(hoverColorOverride);
  const hoverStrokeColor = toGroupHoverStrokeColor(hoverColorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" className={classNames('flex-shrink-0', className)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0313 10.9708C24.9349 10.8743 24.8205 10.7976 24.6945 10.7454C24.5685 10.6931 24.4335 10.6662 24.2971 10.6662C24.1607 10.6662 24.0256 10.6931 23.8996 10.7454C23.7736 10.7976 23.6592 10.8743 23.5629 10.9708L16.0009 18.5348L8.43904 10.9708C8.24431 10.7761 7.98021 10.6667 7.70483 10.6667C7.42945 10.6667 7.16535 10.7761 6.97062 10.9708C6.7759 11.1656 6.6665 11.4297 6.6665 11.705C6.6665 11.9804 6.7759 12.2445 6.97062 12.4393L15.2667 20.7354C15.3631 20.832 15.4775 20.9086 15.6035 20.9608C15.7295 21.0131 15.8645 21.04 16.0009 21.04C16.1374 21.04 16.2724 21.0131 16.3984 20.9608C16.5244 20.9086 16.6388 20.832 16.7352 20.7354L25.0313 12.4393C25.1278 12.3429 25.2045 12.2285 25.2567 12.1025C25.309 11.9765 25.3359 11.8415 25.3359 11.705C25.3359 11.5686 25.309 11.4336 25.2567 11.3076C25.2045 11.1816 25.1278 11.0672 25.0313 10.9708Z"
        className={classNames(fillColor, strokeColor, ...(active ? [hoverFillColor, hoverStrokeColor] : []))}
      />
    </svg>
  );
};
