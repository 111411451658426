import classNames from 'classnames';
import React, { ChangeEventHandler, FocusEventHandler, Ref } from 'react';
import { NumericFormat, PatternFormat } from 'react-number-format';

import { toTextColor } from '../../../theme/colors';
import { TextType, textVariantClassName } from '../../../theme/typography';
import { PrivacyLevel } from '../../../types/Privacy';
import { ColorType } from '../../../types/ui';
import IconBorder from '../../app/chat/IconBorder';
import { AlertIcon, MinusIcon, PlusIcon } from '../../icons';
import Text from '../Text';

export type InputType =
  | 'text'
  | 'paragraph'
  | 'email'
  | 'password'
  | 'phone'
  | 'dob'
  | 'incremental'
  | 'currency'
  | 'amountPerMonth';
export type ErrorStyle = 'alert' | 'text';

type ElemType<T extends InputType | undefined> = T extends 'paragraph' ? HTMLTextAreaElement : HTMLInputElement;

type TextInputProps<T extends InputType> = {
  id?: string;
  value?: string | ReadonlyArray<string> | number | undefined;
  placeholder?: string | undefined;
  rows?: number | undefined;
  onChange?: ChangeEventHandler<ElemType<T>>;
  onFocus?: FocusEventHandler<ElemType<T>>;
  onBlur?: FocusEventHandler<ElemType<T>>;
  error?: string;
  name: string;
  type?: T;
  fill?: boolean;
  startAdornment?: JSX.Element;
  endAdorment?: JSX.Element;
  className?: string;
  inputClassName?: string;
  privacyLevel?: PrivacyLevel;
  variant?: TextType;
  textColor?: ColorType;
  disabled?: boolean;
  autoComplete?: boolean;
  increment?: (value: number) => void;
  decrement?: (value: number) => void;
};

const TextInput = React.forwardRef(
  <T extends InputType = 'text'>(
    {
      id,
      value,
      placeholder,
      onChange,
      onFocus,
      error,
      type,
      rows,
      fill = false,
      className,
      inputClassName,
      startAdornment,
      endAdorment,
      privacyLevel = 'pii',
      variant = 'body-1-emphasis',
      textColor = 'purple',
      name,
      onBlur,
      disabled,
      autoComplete = true,
      increment,
      decrement,
    }: TextInputProps<T>,
    ref: Ref<ElemType<T>>
  ) => {
    const textVariantClass = classNames(
      {
        'fs-mask': privacyLevel === 'pii',
        'fs-unmask': privacyLevel === 'phi' || privacyLevel === 'public',
        'fs-exclude': type === 'password',
      },
      toTextColor(error ? 'errorRed' : textColor),
      textVariantClassName(variant)
    );

    const inputSharedClass = classNames(
      {
        'border-errorRed': error !== undefined,
        'border-mediumGrayOne': error === undefined && disabled === undefined,
        'bg-lightGrayTwo': disabled !== undefined,
      },
      'bg-white',
      `placeholder:font-normal`,
      `placeholder:text-darkGrayThree`,
      'border',
      'border-solid',
      'hover:border-purple',
      'active:border-purple',
      'focus:border-purple',
      'rounded-[8px]',
      'p-3',
      'focus:outline-none',
      'w-full',
      'font-sans',
      'focus:outline-offset-[-0.5px]',
      'focus:outline-2',
      'focus:outline-dashed',
      'focus:outline-linkBlue',
      inputClassName
    );

    return (
      <>
        <div className={classNames({ 'w-full': fill }, 'relative', className)}>
          {startAdornment && <div className="absolute top-[50%] left-2 translate-y-[-50%]">{startAdornment}</div>}
          {['incremental', 'currency', 'amountPerMonth'].includes(type ?? 'text') && (
            <div className="flex flex-row gap-4">
              {type === 'incremental' && (
                <button
                  type="button"
                  className="group"
                  aria-label="Minus one"
                  onClick={() => {
                    if (decrement) decrement(Number(value ?? 0));
                  }}
                >
                  <IconBorder color="white" border="purple" hoverColor="purple" activeColor="darkPurple">
                    <MinusIcon colorOverride="purple" hoverColorOverride="white" size={24} />
                  </IconBorder>
                </button>
              )}
              <NumericFormat
                isAllowed={(values) => {
                  const { floatValue } = values;
                  if (floatValue && type === 'incremental') {
                    return floatValue < 50 && floatValue > 0;
                  } else {
                    return floatValue !== 0;
                  }
                }}
                allowNegative={false}
                valueIsNumericString={true}
                thousandSeparator=","
                placeholder={placeholder}
                name={name}
                id={id}
                className={classNames(textVariantClass, inputSharedClass, {
                  'text-center': type === 'incremental',
                  'max-w-[140px]': type === 'incremental',
                  'max-w-[200px]': type === 'currency' || type === 'amountPerMonth',
                  'pl-10': !!startAdornment,
                  'pr-10': !!endAdorment,
                })}
                onChange={onChange as ChangeEventHandler<HTMLInputElement>}
                onFocus={onFocus as FocusEventHandler<HTMLInputElement>}
                onBlur={onBlur as FocusEventHandler<HTMLInputElement>}
                disabled={disabled}
                value={value?.toString()}
              />
              {type === 'incremental' && (
                <button
                  type="button"
                  className="group"
                  aria-label="Add one"
                  onClick={() => {
                    if (increment) increment(Number(value ?? 0));
                  }}
                >
                  <IconBorder color="white" border="purple" hoverColor="purple" activeColor="darkPurple">
                    <PlusIcon colorOverride="purple" hoverColorOverride="white" size={24} />
                  </IconBorder>
                </button>
              )}
            </div>
          )}
          {['text', 'email', 'password'].includes(type ?? 'text') && (
            <input
              type={type}
              placeholder={placeholder}
              ref={ref as Ref<HTMLInputElement>}
              name={name}
              id={id}
              className={classNames(textVariantClass, inputSharedClass, {
                'pl-10': !!startAdornment,
                'pr-10': !!endAdorment,
              })}
              value={value}
              onChange={onChange as ChangeEventHandler<HTMLInputElement>}
              onFocus={onFocus as FocusEventHandler<HTMLInputElement>}
              onBlur={onBlur as FocusEventHandler<HTMLInputElement>}
              disabled={disabled}
              autoComplete={autoComplete ? 'on' : 'off'}
            />
          )}
          {['dob', 'phone'].includes(type ?? 'text') && (
            <PatternFormat
              placeholder={placeholder}
              name={name}
              id={id}
              className={classNames(toTextColor(textColor), textVariantClass, inputSharedClass, {
                'pl-10': !!startAdornment,
                'pr-10': !!endAdorment,
              })}
              format={type === 'phone' ? '(###) ###-####' : '##/##/####'}
              onChange={onChange as ChangeEventHandler<HTMLInputElement>}
              onFocus={onFocus as FocusEventHandler<HTMLInputElement>}
              onBlur={onBlur as FocusEventHandler<HTMLInputElement>}
              disabled={disabled}
              autoComplete={autoComplete ? 'on' : 'off'}
              mask={type === 'dob' ? ['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y'] : ''}
              value={value?.toString()}
            />
          )}
          {type === 'paragraph' && (
            <textarea
              rows={rows ?? 3}
              placeholder={placeholder}
              name={name}
              id={id}
              className={classNames(textVariantClass, inputSharedClass, 'min-h-[96px]', inputClassName)}
              ref={ref as Ref<HTMLTextAreaElement>}
              value={value}
              onChange={onChange as ChangeEventHandler<HTMLTextAreaElement>}
              onBlur={onBlur as FocusEventHandler<HTMLTextAreaElement>}
              disabled={disabled}
              autoComplete={autoComplete ? 'on' : 'off'}
            />
          )}
          {endAdorment && <div className={'absolute top-[50%] right-4 translate-y-[-50%]'}>{endAdorment}</div>}
        </div>
        {error && (
          <div className="flex flex-row items-start gap-2 mt-1">
            <AlertIcon colorOverride="errorRed" />
            <Text privacyLevel={privacyLevel} color="darkGrayOne" variant="body-1" alignment="left">
              <span dangerouslySetInnerHTML={{ __html: error }} />
            </Text>
          </div>
        )}
      </>
    );
  }
);

TextInput.displayName = 'TextInput';
export { TextInput };
