import { Member } from '../types/Member';
import { UserSession } from '../types/UserSession';

export const helpUrl = 'https://airtable.com/appX1kqvvvOJeOlgW/pagx23Z6lCDF3UUZ0';
export const libraryUrl = 'https://sites.google.com/getduos.com/helpcenter/library-search';
export const calendarUrl = 'https://calendar.google.com/calendar';
export const hoursUrl = 'https://hours.justworks.com/';
export const oauthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
export const viewCallGuideUrl = 'https://docs.google.com/document/d/18XjrG3cro1F2GVe4IuG46da01pNWJNx47FTupiiOpTo';
export const googleFormUrl = 'https://docs.google.com/forms';
export const airtableUrl = 'https://airtable.com';
export const campaignFormUrl = 'https://airtable.com/shrhWv1YfUOsrNV0S';
export const termsUrl = 'https://www.getduos.com/terms-of-service';
export const privacyUrl = 'https://www.getduos.com/privacy-policy';

/* DEPRECATED PHONE NUMBERS/EMAILS? */
export const supportEmail = 'help@getduos.com';

// Care Gaps Campaign Phone
export const CAREGAP_SUPPORT_DISPLAY = '(855) 541-2510';
export const CAREGAP_SUPPORT_LINK = 'tel:8555412510';

// Main Line, shouldn't be used
export const DUOS_MAINLINE_PHONE_DISPLAY = '(855) 212-9242';
export const DUOS_MAINLINE_PHONE_LINK = 'tel:8552129242';

// Support number established with new activation flow, use this in future.
export const SUPPORT_PHONE_DISPLAY = '(833) 338-0858';
export const SUPPORT_PHONE_LINK = 'tel:8333389858';
export const SUPPORT_EMAIL_DISPLAY = 'support@getduos.com';
export const SUPPORT_EMAIL_LINK = 'mailto:support@getduos.com';

export const getHelpUrl = ({ member, userSession }: { member?: Member; userSession?: UserSession }) => {
  const url = new URL(helpUrl);
  if (member && member.userId) url.searchParams.append('prefill_Member External ID', member.userId);
  if (userSession && userSession.email) url.searchParams.append('prefill_Submitted By', userSession.email);
  if (member && member.clientName) url.searchParams.append('prefill_Enterprise Customer Name', member.clientName);
  if (typeof window !== 'undefined') url.searchParams.append('prefill_Member URL', window.location.href);
  if (member && member.userId) url.searchParams.append('hide_Member External ID', 'true');
  if (userSession && userSession.email) url.searchParams.append('hide_Submitted By', 'true');
  if (member && member.clientName) url.searchParams.append('hide_Enterprise Customer Name', 'true');
  return url.href;
};

export const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
] as const;
