import classNames from 'classnames';

import { IconProps } from '../../types/ui';

const DuosHugIcon = ({ size = 128, className }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className)}
    >
      <path
        d="M24.7028 24.6902C30.4684 18.9231 30.4684 10.024 24.7028 4.30666C18.9371 -1.41067 10.0402 -1.46038 4.32423 4.30666C-1.44141 10.0737 -1.44141 18.9729 4.32423 24.6902C10.0899 30.4075 18.9371 30.4572 24.7028 24.6902Z"
        fill="#AC3EA4"
      />
      <path
        d="M123.713 103.291C117.947 97.5243 109.05 97.5243 103.334 103.291C97.5688 109.058 97.5688 117.958 103.334 123.675C109.1 129.442 117.997 129.442 123.713 123.675C129.429 117.958 129.429 109.058 123.713 103.291Z"
        fill="#4F196F"
      />
      <path
        d="M37.1284 95.9335C39.9616 94.2929 41.9497 91.6579 42.7947 88.5258C43.4905 85.8412 43.4905 82.8582 42.2479 80.5713C39.4148 75.9477 37.8243 70.3298 37.8243 64.2645C37.8243 49.0514 48.61 36.2247 63.9188 36.2247C71.1258 36.2247 77.2891 39.1082 81.8618 43.7318C82.6074 39.2076 85.7387 35.1309 90.0629 32.5954C93.5919 30.5571 97.6179 29.9108 101.346 30.4576C92.0511 20.6636 78.7305 14.5983 63.8691 14.5983C34.6433 14.5983 13.2209 36.9207 13.2209 66.7005C13.2209 77.1409 16.6008 86.2886 22.1676 93.746C25.5972 97.8724 32.1581 98.817 37.1284 95.9335Z"
        fill="#AC3EA4"
      />
      <path
        d="M114.716 63.8666C114.716 55.5641 112.628 47.8084 108.95 40.9973C105.62 35.4788 98.264 33.8879 92.6474 37.1195C87.6273 40.003 85.1422 46.0186 87.0806 51.2388C89.0688 55.3155 90.1622 59.8893 90.1622 64.7118C90.1622 80.0243 79.5256 92.9007 64.3163 92.9007C58.0536 92.9007 52.6359 90.8623 48.3117 87.4817C48.2123 88.2771 48.0632 89.1223 47.8147 89.9177C46.6218 94.3922 43.739 98.1706 39.7129 100.458C36.9792 102.048 33.997 102.794 31.0645 102.794C31.0148 102.794 31.0148 102.794 30.9651 102.794C40.3591 110.252 52.5365 114.477 65.1116 114.477C92.6474 114.428 114.716 92.3538 114.716 63.8666Z"
        fill="#4F196F"
      />
    </svg>
  );
};

export default DuosHugIcon;
