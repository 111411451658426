import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { toBackgroundColor, toBorderColor } from '../../theme/colors';
import { ColorType } from '../../types/ui';

export interface CardProps {
  children: ReactNode;
  className?: string;
  direction?: 'col' | 'row' | 'row-reverse' | 'col-reverse';
  display?: 'flex' | 'block';
  rounding?: 'default' | 'slight' | 'none';
  noBorder?: boolean;
  borderColor?: ColorType;
  backgroundColor?: ColorType;
  fullWidth?: boolean;
  leftInset?: boolean;
  leftInsetColor?: 'green' | 'purple';
  onClick?: () => void;
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      className = '',
      onClick,
      display = 'flex',
      direction = 'col',
      rounding = 'default',
      noBorder = false,
      borderColor = 'mediumGrayOne',
      backgroundColor = 'white',
      fullWidth,
      leftInset,
      leftInsetColor = 'purple',
    }: CardProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        role="presentation"
        ref={ref}
        className={classNames(
          `${className} border-solid`,
          toBackgroundColor(backgroundColor),
          toBorderColor(borderColor),
          {
            'border-l-purple': leftInset && leftInsetColor === 'purple',
            'border-l-green': leftInset && leftInsetColor === 'green',
            'w-full': fullWidth,
            'rounded-2xl': rounding === 'default',
            'rounded-lg': rounding === 'slight',
            'border-[1px]': !noBorder,
            flex: display === 'flex',
            'flex-col': direction === 'col' && display === 'flex',
            'flex-row': direction === 'row' && display === 'flex',
            'flex-col-reverse': direction === 'col-reverse' && display === 'flex',
            'flex-row-reverse': direction === 'row-reverse' && display === 'flex',
          }
        )}
        onClick={onClick}
        onKeyUp={onClick}
        onKeyDown={onClick}
      >
        {leftInset && (
          <div
            className={classNames('my-[-1px] w-[4px] rounded-l-lg', {
              'rounded-l-4xl': rounding === 'default',
              'rounded-l-lg': rounding === 'slight',
              'bg-purple': leftInsetColor === 'purple',
              'bg-green': leftInsetColor === 'green',
            })}
          ></div>
        )}
        {children}
      </div>
    );
  }
);

Card.displayName = 'Card';

export default Card;
