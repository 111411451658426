import { Call, Device } from '@twilio/voice-sdk';
import { useRouter } from 'next/router';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as uuid from 'uuid';

import WelcomeCallModal from '../components/common/WelcomeCallModal';
import { useConference, useJoinConferenceCall, useRemoveFromConferenceCall, useVoiceToken } from '../hooks/api';
import useToggle from '../hooks/useToggle';
import { CallStatus, VoiceConference } from '../types/Call';
import { EngagementStatus, Member } from '../types/Member';
import {
  getActiveParticipants,
  getCallInfoFromConferenceByPhone,
  seperateParticipantsByDirection,
} from '../utils/Call';
import { cleanPhone } from '../utils/Member';

type CallContextProps = {};

export type DialpadState = 'close' | 'open' | 'minimized';

type ConnectCallRequest = {
  ToPhoneNumber: string;
  AssociatedMemberId?: string;
  MemberId?: string;
};

const CALL_STATUS_POLL_INTERVAL_MS = 1000;

export const CallContext = createContext<{
  primaryCall?: Call;
  memberName?: string;
  memberPhoneNumber?: string;
  callStatus?: CallStatus;
  conference?: VoiceConference;
  connectCall: (phoneNumber: string) => void;
  connectMemberCall: (toPhoneNumber: string, personName: string, member: Member) => void;
  addToConference: (toPhoneNumber: string) => void;
  disconnectUserFromConferenceCall: (phoneNumber: string) => void;
  disconnectAll: () => void;
  dialpadState: DialpadState;
  setDialpadState: Dispatch<SetStateAction<DialpadState>>;
  dialpadNumber: string;
  setDialpadNumber: Dispatch<SetStateAction<string>>;
  isMuted: boolean;
  toggleMicrophone: () => void;
  callError: string | null;
}>({
  primaryCall: undefined,
  memberName: undefined,
  memberPhoneNumber: undefined,
  callStatus: undefined,
  conference: undefined,
  connectCall: () => {},
  connectMemberCall: () => {},
  addToConference: () => {},
  disconnectUserFromConferenceCall: () => {},
  disconnectAll: () => {},
  dialpadState: 'close',
  setDialpadState: () => {},
  dialpadNumber: '',
  setDialpadNumber: () => {},
  isMuted: false,
  toggleMicrophone: () => {},
  callError: null,
});

function CallContextWrapper({ children }: PropsWithChildren<CallContextProps>) {
  const [primaryCall, setPrimaryCall] = useState<Call>();
  const [memberPhoneNumber, setMemberPhoneNumber] = useState<string>();
  const [conferenceId, setConferenceId] = useState<string>();
  const [memberName, setMemberName] = useState<string>();
  const [dialpadState, setDialpadState] = useState<DialpadState>('close');
  const [dialpadNumber, setDialpadNumber] = useState('');
  const [isMuted, setIsMuted] = useState(false);
  const [callSid, setCallSid] = useState<string | undefined>();
  const [callStatus, setCallStatus] = useState<CallStatus>();
  const { state: showWelcomeCallModal, setTrue: openWelcomeCallModal, setFalse: closeWelcomeCallModal } = useToggle();
  const endConferenceCallMutation = useRemoveFromConferenceCall();
  const joinConferenceCallMutation = useJoinConferenceCall();
  const [callError, setError] = useState<string | null>(null);

  const memberRef = useRef<Member>();

  const deviceRef = useRef<Device>();

  const { refetch: refetchVoiceToken } = useVoiceToken();

  const { query } = useRouter();
  const memberId = query.userId as string;

  useEffect(() => {
    if (!memberId && dialpadState !== 'close') {
      setDialpadNumber('');
      setDialpadState('close');
    }
  }, [memberId, dialpadState]);

  const { data: conference } = useConference(callSid, CALL_STATUS_POLL_INTERVAL_MS);

  useEffect(() => {
    if (conference) {
      const status = VoiceConference.getOverallCallStatus(conference);
      setCallStatus(status);
    }
  }, [conference]);

  const conferenceCleanup = useCallback(() => {
    if (!deviceRef.current) {
      return;
    }

    // If we just had a call w/ a member who is supposed to have their welcome call,
    // then open the modal
    if (memberRef.current && memberRef.current.engagementStatus === EngagementStatus.WelcomeCallScheduled) {
      openWelcomeCallModal();
    }

    setCallSid(undefined);
    setPrimaryCall(undefined);
    setMemberPhoneNumber(undefined);
    setConferenceId(undefined);
    setMemberName(undefined);
    setCallStatus(undefined);

    deviceRef.current.removeAllListeners();
    deviceRef.current.destroy();
  }, [openWelcomeCallModal]);

  const callCleanup = useCallback(
    (phoneNumberToRemove: string) => {
      if (!conference) {
        return;
      }
      const { outboundParticipants } = seperateParticipantsByDirection(conference.callParticipants);
      const activeParticipants = getActiveParticipants(outboundParticipants);
      if (activeParticipants.length <= 1) {
        conferenceCleanup();
        return;
      }

      if (cleanPhone(memberRef.current?.phone ?? '') === phoneNumberToRemove) {
        setMemberName(undefined);
        setMemberPhoneNumber(undefined);
        memberRef.current = undefined;
      }
    },
    [conferenceCleanup, conference]
  );

  useEffect(() => {
    return () => {
      if (deviceRef.current) {
        deviceRef.current.removeAllListeners();
        deviceRef.current.destroy();
      }
    };
  }, []);

  const initPrimaryCall = useCallback(
    async (params: Record<string, string>, member?: Member): Promise<void> => {
      memberRef.current = member;
      if (!primaryCall) {
        const voiceToken = await (await refetchVoiceToken()).data?.token;

        if (voiceToken) {
          const device = new Device(voiceToken!, { closeProtection: true, tokenRefreshMs: 30000 });
          deviceRef.current = device;

          device.on('tokenWillExpire', async () => {
            const { data } = await refetchVoiceToken();
            data && device.updateToken(data.token);
          });

          // This will catch ConnectionErrors from local environment.
          device.on('error', (twilioError) => {
            setError('This environment does not support calling.');
            console.error('A Device error has occurred: ', twilioError);
          });

          const newConferenceId = uuid.v4();

          const call = await device.connect({
            params: {
              AssociatedMemberId: memberId,
              MemberId: memberId,
              ConferenceId: newConferenceId,
              ...params,
            },
          });

          call.on('accept', () => {
            setCallSid(call.parameters.CallSid);
          });

          call.on('error', (twilioError, call) => {
            console.error('An Call error has occurred: ', twilioError);
          });

          call.on('disconnect', () => {
            conferenceCleanup();
          });

          setPrimaryCall(call);
          setConferenceId(newConferenceId);
        }
      }
    },
    [primaryCall, refetchVoiceToken, memberId, conferenceCleanup]
  );

  const connectCall = useCallback(
    async (phoneNumber: string) => {
      const params: ConnectCallRequest = { ToPhoneNumber: phoneNumber };
      initPrimaryCall(params);
    },
    [initPrimaryCall]
  );

  const disconnectAll = useCallback(() => {
    deviceRef.current?.disconnectAll();
    conferenceCleanup();
  }, [conferenceCleanup]);

  const toggleMicrophone = useCallback(() => {
    if (primaryCall) {
      const isMuted = primaryCall.isMuted();
      setIsMuted(!isMuted);
      primaryCall.mute(!isMuted);
      return !isMuted;
    }
  }, [primaryCall]);

  const addToConference = useCallback(
    async (phoneNumber: string) => {
      await joinConferenceCallMutation.mutate({ conferenceId, phoneNumber });
      const newCallId = joinConferenceCallMutation.data;
      if (!newCallId) {
        return;
      }
    },
    [conferenceId, joinConferenceCallMutation]
  );

  const disconnectUserFromConferenceCall = useCallback(
    async (phoneNumberToRemove: string) => {
      if (!conferenceId || !conference) {
        return;
      }
      const call = getCallInfoFromConferenceByPhone(phoneNumberToRemove, conference);
      if (!call) {
        return;
      }
      await endConferenceCallMutation.mutate(call.twilioCallId);
      callCleanup(phoneNumberToRemove);
    },
    [callCleanup, conferenceId, conference, endConferenceCallMutation]
  );

  const connectMemberCall = useCallback(
    async (toPhoneNumber: string, personName: string, member: Member) => {
      setMemberPhoneNumber(toPhoneNumber);
      setMemberName(personName);
      if (conference) {
        addToConference(toPhoneNumber);
        return;
      }
      // This will fail locally since MemberId doesn't exist in Twilio Dev environment.
      const params: ConnectCallRequest = {
        ToPhoneNumber: toPhoneNumber,
        MemberId: member.userId,
        AssociatedMemberId: member.userId, // Call should always be associated with the member, even if they aren't the primary contact.
      };
      initPrimaryCall(params, member);
    },
    [conference, addToConference, initPrimaryCall]
  );

  const value = useMemo(() => {
    return {
      primaryCall,
      memberPhoneNumber,
      callStatus,
      conference,
      memberName,
      connectCall,
      connectMemberCall,
      addToConference,
      disconnectUserFromConferenceCall,
      disconnectAll,
      dialpadState,
      setDialpadState,
      dialpadNumber,
      setDialpadNumber,
      isMuted,
      toggleMicrophone,
      callError,
    };
  }, [
    primaryCall,
    memberPhoneNumber,
    callStatus,
    conference,
    memberName,
    connectCall,
    connectMemberCall,
    addToConference,
    disconnectUserFromConferenceCall,
    disconnectAll,
    dialpadState,
    setDialpadState,
    dialpadNumber,
    setDialpadNumber,
    isMuted,
    toggleMicrophone,
    callError,
  ]);
  return (
    <>
      <CallContext.Provider value={value}>{children}</CallContext.Provider>
      {memberId && (
        <WelcomeCallModal
          key={memberId}
          memberId={memberId}
          open={showWelcomeCallModal}
          onClose={closeWelcomeCallModal}
        />
      )}
    </>
  );
}

export default CallContextWrapper;
