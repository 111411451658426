import { useT } from '@transifex/react';
import classNames from 'classnames';
import React from 'react';

import { TextType } from '../../theme/typography';
import { ColorType } from '../../types/ui';
import { getInitials } from '../../utils/Member';
import Circle from '../icons/Circle';
import Text from './Text';

type AvatarSize = 64 | 48 | 40 | 32;

const SizeToVariant: Record<AvatarSize, TextType> = {
  64: 'h2',
  48: 'h4',
  40: 'label1',
  32: 'label2',
};

const Avatar = ({
  name,
  outline = 'lightGrayTwo',
  fillColor = 'lightGrayTwo',
  textColor = 'darkGrayThree',
  size = 40,
  textType,
  className,
}: {
  name: string;
  outline?: ColorType;
  fillColor?: ColorType;
  textColor?: ColorType;
  size?: AvatarSize;
  textType?: TextType;
  className?: string;
}) => {
  const t = useT();
  const font = SizeToVariant[size];
  return (
    <Circle className={className} color={fillColor} stroke={outline} size={size}>
      <Text privacyLevel="pii" color={textColor} variant={font} className={classNames('absolute')} textCase="upper">
        {getInitials(name ?? t('Unknown'))}
      </Text>
    </Circle>
  );
};

export default Avatar;
