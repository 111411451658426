import classNames from 'classnames';

import { toFillColor, toStrokeColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const MenuRecs = ({ size = 24, colorOverride, active = true }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const strokeClass = toStrokeColor(colorOverride);
  const activeStandardClass = !active ? 'group-hover:invisible' : '';
  const standardClass = `${!active ? 'visible' : 'invisible'} ${activeStandardClass}`;
  const activeAlternateClass = !active ? 'group-hover:visible' : '';
  const alternateClass = `${!active ? 'invisible' : 'visible'} ${activeAlternateClass}`;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          className={classNames(fillClass, standardClass)}
          d="M6 3C4.34314 3 3 4.34315 3 6V30C3 31.6569 4.34315 33 6 33H30C31.6569 33 33 31.6569 33 30V6C33 4.34314 31.6569 3 30 3H6ZM6 30V6H30V30H6Z"
        />
        <path
          className={classNames(fillClass, standardClass)}
          d="M9 10.5C9 9.67157 9.67157 9 10.5 9L25.5 9C26.3284 9 27 9.67157 27 10.5C27 11.3284 26.3284 12 25.5 12L10.5 12C9.67157 12 9 11.3284 9 10.5Z"
        />
        <path
          className={classNames(fillClass, standardClass)}
          d="M9 18C9 17.1716 9.67157 16.5 10.5 16.5L25.5 16.5C26.3284 16.5 27 17.1716 27 18C27 18.8284 26.3284 19.5 25.5 19.5L10.5 19.5C9.67157 19.5 9 18.8284 9 18Z"
        />
        <path
          className={classNames(fillClass, standardClass)}
          d="M9 25.5C9 24.6716 9.67157 24 10.5 24H19.5C20.3284 24 21 24.6716 21 25.5C21 26.3284 20.3284 27 19.5 27H10.5C9.67157 27 9 26.3284 9 25.5Z"
        />
        <path
          d="M15.0625 23.5958C15.0625 24.2733 14.811 24.9166 14.3732 25.3858C13.9364 25.8538 13.3517 26.1096 12.75 26.1096C12.1483 26.1096 11.5636 25.8538 11.1268 25.3858C10.689 24.9166 10.4375 24.2733 10.4375 23.5958C10.4375 22.9184 10.689 22.2751 11.1268 21.8059C11.5636 21.3379 12.1483 21.082 12.75 21.082C13.3517 21.082 13.9364 21.3379 14.3732 21.8059C14.811 22.2751 15.0625 22.9184 15.0625 23.5958ZM26.3125 23.5958C26.3125 24.2733 26.061 24.9166 25.6232 25.3858C25.1864 25.8538 24.6017 26.1096 24 26.1096C23.3983 26.1096 22.8136 25.8538 22.3768 25.3858C21.939 24.9166 21.6875 24.2733 21.6875 23.5958C21.6875 22.9184 21.939 22.2751 22.3768 21.8059C22.8136 21.3379 23.3983 21.082 24 21.082C24.6017 21.082 25.1864 21.3379 25.6232 21.8059C26.061 22.2751 26.3125 22.9184 26.3125 23.5958ZM36.8732 25.3858C36.4364 25.8538 35.8517 26.1096 35.25 26.1096C34.6483 26.1096 34.0636 25.8538 33.6268 25.3858C33.189 24.9166 32.9375 24.2733 32.9375 23.5958C32.9375 22.9184 33.189 22.2751 33.6268 21.8059C34.0636 21.3379 34.6483 21.082 35.25 21.082C35.8517 21.082 36.4364 21.3379 36.8732 21.8059C37.311 22.2751 37.5625 22.9184 37.5625 23.5958C37.5625 24.2733 37.311 24.9166 36.8732 25.3858Z"
          className={classNames('fill-white', 'stroke-white', alternateClass)}
        />
        <path d="M30 30V6H6V30H30Z" className={classNames(fillClass, strokeClass, alternateClass)} strokeWidth="1.25" />
        <path
          d="M6 3C4.34314 3 3 4.34315 3 6V30C3 31.6569 4.34315 33 6 33H30C31.6569 33 33 31.6569 33 30V6C33 4.34314 31.6569 3 30 3H6ZM6 30V6H30V30H6Z"
          className={classNames(fillClass, alternateClass)}
        />
        <path
          d="M9 10.5C9 9.67157 9.67157 9 10.5 9L25.5 9C26.3284 9 27 9.67157 27 10.5C27 11.3284 26.3284 12 25.5 12L10.5 12C9.67157 12 9 11.3284 9 10.5Z"
          className={classNames('fill-white', alternateClass)}
        />
        <path
          d="M9 18C9 17.1716 9.67157 16.5 10.5 16.5L25.5 16.5C26.3284 16.5 27 17.1716 27 18C27 18.8284 26.3284 19.5 25.5 19.5L10.5 19.5C9.67157 19.5 9 18.8284 9 18Z"
          className={classNames('fill-white', alternateClass)}
        />
        <path
          d="M9 25.5C9 24.6716 9.67157 24 10.5 24H19.5C20.3284 24 21 24.6716 21 25.5C21 26.3284 20.3284 27 19.5 27H10.5C9.67157 27 9 26.3284 9 25.5Z"
          className={classNames('fill-white', alternateClass)}
        />
      </svg>
    </>
  );
};

export default MenuRecs;
