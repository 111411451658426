import classNames from 'classnames';
import React from 'react';

import { toFillColor, toGroupHoverFillColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const Facebook = ({ className, colorOverride = 'linkBlue', hoverColorOverride, size = 17 }: IconProps) => {
  const fillClassName = toFillColor(colorOverride);
  const hoverFillClassName = toGroupHoverFillColor(hoverColorOverride);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('group', className)}
    >
      <path
        d="M27.3333 14.0816C27.3333 6.67279 21.3638 0.666779 14 0.666779C6.63619 0.666779 0.666656 6.67279 0.666656 14.0816C0.666656 20.7772 5.54244 26.3271 11.9167 27.3334V17.9593H8.53124V14.0816H11.9167V11.1261C11.9167 7.76406 13.9073 5.90694 16.9529 5.90694C18.4112 5.90694 19.9375 6.16895 19.9375 6.16895V9.47025H18.2562C16.6 9.47025 16.0833 10.5044 16.0833 11.5663V14.0816H19.7812L19.1901 17.9593H16.0833V27.3334C22.4575 26.3271 27.3333 20.7772 27.3333 14.0816Z"
        fill="#AC3EA4"
        className={classNames(fillClassName, hoverFillClassName)}
      />
    </svg>
  );
};

export default Facebook;
