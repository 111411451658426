import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const RightArrow = ({ size = 24, colorOverride = 'purple', hoverColorOverride, className }: IconProps) => {
  const fillClassName = toFillColor(colorOverride);
  const hoverFillClassName = toGroupHoverFillColor(hoverColorOverride);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      className={classNames(className, 'group')}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7844 12.5341L14.0344 19.2841C13.891 19.4227 13.6994 19.5002 13.5 19.5002C13.3006 19.5002 13.109 19.4227 12.9656 19.2841C12.8248 19.1419 12.7458 18.9499 12.7458 18.7497C12.7458 18.5496 12.8248 18.3575 12.9656 18.2154L18.4406 12.7497H3.75C3.55109 12.7497 3.36032 12.6707 3.21967 12.5301C3.07902 12.3894 3 12.1986 3 11.9997C3 11.8008 3.07902 11.6101 3.21967 11.4694C3.36032 11.3287 3.55109 11.2497 3.75 11.2497H18.4406L12.9656 5.7841C12.846 5.63838 12.7849 5.45338 12.7942 5.26509C12.8034 5.0768 12.8824 4.89869 13.0157 4.76539C13.149 4.63208 13.3271 4.55313 13.5154 4.54388C13.7037 4.53464 13.8887 4.59575 14.0344 4.71535L20.7844 11.4654C20.9252 11.6075 21.0042 11.7996 21.0042 11.9997C21.0042 12.1999 20.9252 12.3919 20.7844 12.5341Z"
        className={classNames(fillClassName, hoverFillClassName)}
        strokeWidth="0.25"
      />
    </svg>
  );
};

export default RightArrow;
