export const routes: Record<string, any> = {
  member: {
    '[userId]': {
      annualWellness: 'Annual Wellness Visit',
      item: {
        '[itemId]': 'Detail View',
      },
      recommendations: 'All Recommendations',
      serviceRequests: {
        index: 'All Service Requests',
        '[requestId]': 'Detail View',
      },
      info: 'Member Information',
      callHistory: 'Call History',
      index: 'Member Dashboard',
      resourceLookup: {
        index: 'Resource Lookup',
      },
      savedResources: {
        index: 'Saved Resources',
      },
    },
  },
  activation: 'Create Account',
  planLookup: {
    '[memberId]': 'Plan Lookup',
  },
  login: {
    email: 'Login',
    index: 'Login',
    signin: 'Login',
  },
  caremanager: {
    resourceLookup: 'Resource Lookup',
  },
};

export const CAREMANAGER_HOME = '/caremanager/resourceLookup';
export const CAREMANAGER_LOGIN = '/login/email';
export const CAREMANAGER_PWD_LOGIN = '/login/signin';
export const CAREMANAGER_RESET_PWD = '/resetPassword';

export const MEMBER_HOME = '/app/home';
export const MEMBER_CHAT = '/app/chat';
export const MEMBER_SHARED = '/app/sharedResources';
export const MEMBER_SAVED = '/app/savedResources';
export const MEMBER_HELP = '/app/help';
export const MEMBER_LOGIN = '/login/member';
export const MEMBER_ACTIVATE = '/app/activate';
export const MEMBER_ACTIVATE_SURVEY = `${MEMBER_ACTIVATE}/welcomeSurvey`;
export const MEMBER_ACTIVATE_ELIGIBILITY = `${MEMBER_ACTIVATE}/eligibility`;
export const MEMBER_ACTIVATE_ACCOUNT = `${MEMBER_ACTIVATE}/account`;
export const MEMBER_ACTIVATE_EMAIL = `${MEMBER_ACTIVATE}/email`;
export const MEMBER_PAGE_NOT_FOUND = '/app/notFound';
export const MEMBER_PAGE_ERROR = '/app/error';
export const MEMBER_RECOMMENDATIONS = '/app/recommendations';
export const MEMBER_RESOURCE_DETAILS = '/app/resource';
export const MEMBER_BENEFIT_DETAILS = '/app/benefit';
export const MEMBER_RESOLVED_RECOMMENDATIONS = '/app/recommendations/resolved';
export const RESOLVE = '/resolve';
export const NOT_NEEDED = '/notNeeded';

export const MEMBER_FINAL_MILE_CONFIRMATION = (finalMileId: string) => `/app/finalMile/${finalMileId}/confirmation`;
export const MEMBER_SERVICES_FINAL_MILE_CONFIRMATION = (memberId: string, finalMileId: string) =>
  `/member/${memberId}/finalMile/${finalMileId}/confirmation`;

export const MEMBER_SERVICES_MEMBER_PAGE = (memberId: string) => `/member/${memberId}`;
export const MEMBER_SERVICES_RESOURCE_DETAILS = (memberId: string) => `/member/${memberId}/resource`;
export const MEMBER_SERVICES_BENEFIT_DETAILS = (memberId: string) => `/member/${memberId}/benefit`;

export const AWV_HOME = '/annualWellness';

export const DUO_HOME = '/';
export const DUO_LOGIN = '/login/duos';
export const HELP = '/help';
export const WELCOME = '/welcome';
export const GO = '/go';

export const unauthenticatedRoutes = [
  AWV_HOME,
  CAREMANAGER_LOGIN,
  CAREMANAGER_PWD_LOGIN,
  CAREMANAGER_RESET_PWD,
  DUO_LOGIN,
  MEMBER_LOGIN,
  HELP,
  WELCOME,
  GO,
  MEMBER_ACTIVATE,
  RESOLVE,
  NOT_NEEDED,
];

export function moduleDetailsUrl(moduleIds: string[]): string {
  return `/app/module/${moduleIds.join('/')}`;
}

export function memberServicesModuleDetailsUrl(userId: string, moduleIds: string[]): string {
  return `/member/${userId}/module/${moduleIds.join('/')}`;
}

export const caremanagerRoutes = [CAREMANAGER_HOME];
