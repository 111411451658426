import { T, useT } from '@transifex/react';
import { useCallback, useMemo } from 'react';

import { useCallContext } from '../hooks/contexts';
import HangupContact from './HangupContact';
import Text from './common/Text';
import { HangUpIcon } from './icons';

type HangupOptionsProps = {
  memberName?: string;
  memberPhoneNumber?: string;
  phoneNumbers: string[];
};

const HangupOptions = ({ memberName, memberPhoneNumber, phoneNumbers }: HangupOptionsProps) => {
  const t = useT();
  const { disconnectAll } = useCallContext();

  const renderSecondaryHangups = useCallback(() => {
    return phoneNumbers.map((phoneNumber: string) => (
      <HangupContact contactName={t('Outbound')} key={phoneNumber} phoneNumber={phoneNumber} />
    ));
  }, [phoneNumbers, t]);

  return (
    <div className="flex flex-col border-[1px] border-solid border-purple rounded-[4px] bg-warmWhite">
      <>
        {memberPhoneNumber && (
          <HangupContact phoneNumber={memberPhoneNumber} contactName={memberName ?? t('Outbound')} />
        )}
        {renderSecondaryHangups()}
        <button
          aria-label={t('Disconnect All')}
          className="group px-4 py-2 flex flex-row items-center gap-2 hover:bg-purple"
          onClick={() => disconnectAll()}
        >
          <HangUpIcon size={28} hoverColorOverride="white" hoverStrokeColorOverride="white" />
          <Text privacyLevel="public" variant="body1" color="purple" className="group-hover:text-white">
            <T _str={t('Disconnect All')} />
          </Text>
        </button>
      </>
    </div>
  );
};

export default HangupOptions;
