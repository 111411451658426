import React from 'react';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const RewardsIcon = ({ size = 24, colorOverride }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11.925L10.3 13.225C10.2 13.3083 10.1 13.3125 10 13.2375C9.9 13.1625 9.86667 13.0667 9.9 12.95L10.55 10.85L8.8 9.45C8.71667 9.36667 8.69167 9.27083 8.725 9.1625C8.75833 9.05417 8.83333 9 8.95 9H11.1L11.75 6.95C11.7833 6.83333 11.8667 6.775 12 6.775C12.1333 6.775 12.2167 6.83333 12.25 6.95L12.9 9H15.025C15.1417 9 15.2208 9.05417 15.2625 9.1625C15.3042 9.27083 15.2833 9.36667 15.2 9.45L13.425 10.85L14.075 12.95C14.1083 13.0667 14.075 13.1625 13.975 13.2375C13.875 13.3125 13.775 13.3083 13.675 13.225L12 11.925ZM12 21L7.325 22.55C6.99167 22.6667 6.6875 22.625 6.4125 22.425C6.1375 22.225 6 21.9583 6 21.625V15.275C5.36667 14.575 4.875 13.775 4.525 12.875C4.175 11.975 4 11.0167 4 10C4 7.76667 4.775 5.875 6.325 4.325C7.875 2.775 9.76667 2 12 2C14.2333 2 16.125 2.775 17.675 4.325C19.225 5.875 20 7.76667 20 10C20 11.0167 19.825 11.975 19.475 12.875C19.125 13.775 18.6333 14.575 18 15.275V21.625C18 21.9583 17.8625 22.225 17.5875 22.425C17.3125 22.625 17.0083 22.6667 16.675 22.55L12 21ZM12 16C13.6667 16 15.0833 15.4167 16.25 14.25C17.4167 13.0833 18 11.6667 18 10C18 8.33333 17.4167 6.91667 16.25 5.75C15.0833 4.58333 13.6667 4 12 4C10.3333 4 8.91667 4.58333 7.75 5.75C6.58333 6.91667 6 8.33333 6 10C6 11.6667 6.58333 13.0833 7.75 14.25C8.91667 15.4167 10.3333 16 12 16ZM8 20.025L12 19L16 20.025V16.925C15.4167 17.2583 14.7875 17.5208 14.1125 17.7125C13.4375 17.9042 12.7333 18 12 18C11.2667 18 10.5625 17.9042 9.8875 17.7125C9.2125 17.5208 8.58333 17.2583 8 16.925V20.025Z"
        className={fillClass}
      />
    </svg>
  );
};

export default RewardsIcon;
