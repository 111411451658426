import { useContext } from 'react';

import { ChatContext } from '../components/app/chat/ChatContent/ChatContext';
import { AWVContext } from '../contexts/AWVContext';
import { ActionItemContext } from '../contexts/ActionItemContext';
import { AlertContext } from '../contexts/AlertContext';
import { AllRecommendationsContext } from '../contexts/AllRecommendationsContext';
import { AuthenticatedContext } from '../contexts/AuthenticatedContext';
import { CallContext } from '../contexts/CallContext';
import { ServiceRequestsContext } from '../contexts/ServiceRequestsContext';
import { TopPrioritiesContext } from '../contexts/TopPrioritiesContext';

export const useAllRecommendationsContext = () => useContext(AllRecommendationsContext);

export const useTopPrioritiesContext = () => useContext(TopPrioritiesContext);

export const useActionItemContext = () => useContext(ActionItemContext);

export const useServiceRequestsContext = () => useContext(ServiceRequestsContext);

export const useCallContext = () => useContext(CallContext);

export const useAlertContext = () => useContext(AlertContext);

export const useAWVContext = () => useContext(AWVContext);

export const useChatContext = () => useContext(ChatContext);

export const useAuthenticatedContext = () => useContext(AuthenticatedContext);
