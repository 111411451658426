import { T } from '@transifex/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { useUserSession } from '../../../hooks/api';
import {
  SUPPORT_EMAIL_DISPLAY,
  SUPPORT_EMAIL_LINK,
  SUPPORT_PHONE_DISPLAY,
  SUPPORT_PHONE_LINK,
} from '../../../utils/const';
import { Button, Card, Link, Text } from '../../common/new';
import { AlertIcon } from '../../icons';
import GridWithColumns from '../../layout/new/GridWithColumns';
import NewLayout from '../../layout/new/NewLayout';
import IconBorder from '../chat/IconBorder';

const ErrorPage = () => {
  const router = useRouter();
  const { data: userSession } = useUserSession();

  const onHomepage = useCallback(() => {
    if (userSession && userSession.role === 'member') {
      router.push(`/app/home`);
    } else {
      router.push(`/`);
    }
  }, [router, userSession]);

  return (
    <NewLayout page="Error" showMenu={!!userSession} showFooter theme="light">
      <div className="bg-purple">
        <GridWithColumns className={classNames('pt-6', 'pb-10')} noMargin>
          <div className="col-span-full flex flex-col gap-6">
            <section className="flex flex-col gap-4">
              <IconBorder color="white" border="lightGrayOne" size="large">
                <AlertIcon colorOverride="errorRed" />
              </IconBorder>
              <Text variant="heading-2" color="white">
                <T _str="Oh no! We've encountered a problem." />
              </Text>
            </section>
            <Text variant="heading-4" color="white">
              <T _str="Our team has been notified and we are hoping to resolve this issue soon." />
            </Text>
          </div>
        </GridWithColumns>
      </div>
      <GridWithColumns fullPageColumn>
        <div className="col-span-full flex flex-col gap-10">
          <Card rounding="slight" borderColor="lightGrayTwo">
            <div className="p-6 flex flex-col gap-6">
              <div className="flex flex-col gap-1">
                <Text variant="heading-5">
                  <T _str="DUOS support" />
                </Text>
                <Text variant="body-1">
                  <T _str="You can reach our team Monday-Friday, 9am—9pm ET. Messages received outside of business hours will be responded to on the next business day." />
                </Text>
                <ul className="list-disc list-inside flex flex-col gap-2 mt-2">
                  <Text variant="body-1-emphasis" component="li">
                    <T _str="Email: " />
                    <Link className="!inline-block" textVariant="body-1-emphasis" href={SUPPORT_EMAIL_LINK}>
                      {SUPPORT_EMAIL_DISPLAY}
                    </Link>
                  </Text>
                  <Text variant="body-1-emphasis" component="li">
                    <T _str="Phone: " />
                    <Link className="!inline-block" textVariant="body-1-emphasis" href={SUPPORT_PHONE_LINK}>
                      {SUPPORT_PHONE_DISPLAY}
                    </Link>
                  </Text>
                </ul>
              </div>
              <div className="flex flex-col gap-1">
                <Text variant="heading-5">
                  <T _str="For a medical or mental health emergency" />
                </Text>
                <Text variant="body-1">
                  <T _str="If you are experiencing a medical or mental health emergency, please dial 911 right away." />
                </Text>
              </div>
            </div>
          </Card>
          <div className="flex-1 flex flex-col items-center">
            <Button variant="secondary" size="main" onClick={onHomepage}>
              <T _str="Return to homepage" />
            </Button>
          </div>
        </div>
      </GridWithColumns>
    </NewLayout>
  );
};

export default ErrorPage;
