import classNames from 'classnames';
import React from 'react';

import { colors } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const AddButtonWithText = ({
  className,
  size = 64,
  hoverColorOverride = 'darkPurple',
  colorOverride = 'purple',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(className, 'group')}
  >
    <circle
      cx="32"
      cy="32"
      r="31"
      fill={colors[colorOverride]}
      className="group-hover:fill-darkPurple"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5978 24.0128C20.5976 23.638 20.7464 23.2786 21.0113 23.0137C21.2762 22.7488 21.6355 22.6001 22.0104 22.6002L30.592 22.6045L30.5877 14.0228C30.5844 13.8352 30.6184 13.6488 30.6879 13.4745C30.7574 13.3002 30.8609 13.1415 30.9924 13.0077C31.1239 12.8739 31.2807 12.7677 31.4538 12.6952C31.6268 12.6227 31.8126 12.5854 32.0003 12.5855C32.188 12.5856 32.3738 12.6231 32.5469 12.6957C32.7201 12.7684 32.877 12.8748 33.0087 13.0087C33.1403 13.1427 33.2439 13.3014 33.3136 13.4758C33.3832 13.6502 33.4175 13.8366 33.4143 14.0243L33.4186 22.606L42.0003 22.6103C42.3709 22.6169 42.7242 22.7688 42.9841 23.0333C43.244 23.2978 43.3898 23.6536 43.39 24.0243C43.3901 24.3949 43.2448 24.7506 42.9851 25.0148C42.7254 25.279 42.3723 25.4306 42.0017 25.4368L33.42 25.4325L33.4244 34.0142C33.4181 34.3848 33.2666 34.7379 33.0023 34.9976C32.7381 35.2572 32.3824 35.4026 32.0118 35.4024C31.6411 35.4023 31.2853 35.2565 31.0208 34.9966C30.7563 34.7367 30.6044 34.3834 30.5978 34.0128L30.5935 25.4311L22.0118 25.4268C21.637 25.4266 21.2775 25.2775 21.0123 25.0123C20.7471 24.7471 20.598 24.3876 20.5978 24.0128Z"
      fill="white"
    />
    <path
      d="M26.1664 47.438H22.9654L22.4154 49H21.1064L23.8454 41.344H25.2974L28.0364 49H26.7164L26.1664 47.438ZM25.8144 46.415L24.5714 42.862L23.3174 46.415H25.8144ZM31.7243 41.355C32.5383 41.355 33.2497 41.5127 33.8583 41.828C34.4743 42.136 34.9473 42.5833 35.2773 43.17C35.6147 43.7493 35.7833 44.4277 35.7833 45.205C35.7833 45.9823 35.6147 46.657 35.2773 47.229C34.9473 47.801 34.4743 48.241 33.8583 48.549C33.2497 48.8497 32.5383 49 31.7243 49H29.2273V41.355H31.7243ZM31.7243 47.977C32.619 47.977 33.3047 47.735 33.7813 47.251C34.258 46.767 34.4963 46.085 34.4963 45.205C34.4963 44.3177 34.258 43.6247 33.7813 43.126C33.3047 42.6273 32.619 42.378 31.7243 42.378H30.4813V47.977H31.7243ZM39.5232 41.355C40.3372 41.355 41.0485 41.5127 41.6572 41.828C42.2732 42.136 42.7462 42.5833 43.0762 43.17C43.4135 43.7493 43.5822 44.4277 43.5822 45.205C43.5822 45.9823 43.4135 46.657 43.0762 47.229C42.7462 47.801 42.2732 48.241 41.6572 48.549C41.0485 48.8497 40.3372 49 39.5232 49H37.0262V41.355H39.5232ZM39.5232 47.977C40.4178 47.977 41.1035 47.735 41.5802 47.251C42.0568 46.767 42.2952 46.085 42.2952 45.205C42.2952 44.3177 42.0568 43.6247 41.5802 43.126C41.1035 42.6273 40.4178 42.378 39.5232 42.378H38.2802V47.977H39.5232Z"
      fill="white"
    />
  </svg>
);

export default AddButtonWithText;
