import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query';

import {
  CreateSurveyInstancePayload,
  createSurveyInstance,
  createSurveyInstanceByAdmin,
  getSurvey,
  getSurveyByAdmin,
  getSurveyInstance,
  getSurveyInstanceByAdmin,
  getSurveyInstances,
  getSurveyInstancesByAdmin,
  submitSurveyAction,
  submitSurveyActionByAdmin,
} from '../../api/rehoboam';
import { AppView } from '../../types/AppView';
import { ActionPayload } from '../../types/Survey';
import { SurveyInstance } from '../../types/SurveyInstance';
import queryClient from '../../utils/queryClient';
import { MutationResult } from './type';

export function useSubmitSurveyAction({
  appView,
  surveyInstanceId,
}: {
  appView: AppView;
  surveyInstanceId: string;
}): MutationResult<SurveyInstance | undefined, ActionPayload> {
  return useMutation(
    ['submit-instance', surveyInstanceId, appView],
    (payload: ActionPayload) => {
      if (appView === 'memberServices') return submitSurveyActionByAdmin({ surveyInstanceId, payload });
      else return submitSurveyAction({ surveyInstanceId, payload });
    },
    {
      onSuccess: async (data) => {
        if (data?.userId) queryClient.invalidateQueries({ queryKey: ['survey-instances', { userId: data.userId }] });
      },
    }
  );
}

export function useCreateSurveyInstance({
  appView,
}: {
  appView: AppView;
}): MutationResult<SurveyInstance | undefined, CreateSurveyInstancePayload> {
  return useMutation(['create-survey-instance', appView], (payload: CreateSurveyInstancePayload) => {
    if (appView === 'memberServices') return createSurveyInstanceByAdmin({ ...payload });
    else return createSurveyInstance({ ...payload });
  });
}

export function useSurveyInstances({
  appView,
  userId,
  enabled,
}: {
  appView: AppView;
  userId?: string;
  enabled?: boolean;
}): UseQueryResult<SurveyInstance[], void> {
  return useQuery(
    ['survey-instances', userId],
    () => {
      if (appView === 'memberServices' && userId) {
        return getSurveyInstancesByAdmin({ userId });
      } else if (appView === 'member') {
        return getSurveyInstances({});
      }
    },
    { enabled }
  );
}

export function useSurveyInstance({
  appView,
  surveyInstanceId,
  sectionId,
}: {
  appView: AppView;
  surveyInstanceId: string;
  sectionId?: string;
}) {
  return useQuery(['survey-instance', surveyInstanceId, sectionId], () => {
    if (appView === 'memberServices') return getSurveyInstanceByAdmin({ surveyInstanceId });
    else if (appView === 'member') return getSurveyInstance({ surveyInstanceId });
  });
}

export function useSurvey({ appView, surveyId }: { appView: AppView; surveyId?: string }) {
  return useQuery(
    ['survey', surveyId],
    () => {
      if (appView === 'memberServices') return getSurveyByAdmin({ surveyId: surveyId! });
      else return getSurvey({ surveyId: surveyId! });
    },
    { enabled: !!surveyId }
  );
}
