import { T } from '@transifex/react';

import { Text } from '..';
import { ThumbsDown as ThumbsDownIcon } from '../../../icons';

const ThumbsDown = () => {
  return (
    <>
      <ThumbsDownIcon size={24} colorOverride="linkBlue" />
      <Text variant="body-1-emphasis" color="darkGrayOne">
        <T _str="No" />
      </Text>
    </>
  );
};

export default ThumbsDown;
