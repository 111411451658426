import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const DownArrowIcon = ({ size = 24, colorOverride, hoverColorOverride, active = false }: IconProps) => {
  const fillColor = toFillColor(colorOverride);
  const hoverFillColor = toGroupHoverFillColor(hoverColorOverride);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className="flex-shrink-0"
    >
      <mask
        id="mask0_3643_6464"
        className="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3643_6464)">
        <path
          d="M10.9998 16.175V5C10.9998 4.71667 11.0956 4.47917 11.2873 4.2875C11.479 4.09583 11.7165 4 11.9998 4C12.2831 4 12.5206 4.09583 12.7123 4.2875C12.904 4.47917 12.9998 4.71667 12.9998 5V16.175L17.8998 11.275C18.0998 11.075 18.3331 10.9792 18.5998 10.9875C18.8665 10.9958 19.0998 11.1 19.2998 11.3C19.4831 11.5 19.579 11.7333 19.5873 12C19.5956 12.2667 19.4998 12.5 19.2998 12.7L12.6998 19.3C12.5998 19.4 12.4915 19.4708 12.3748 19.5125C12.2581 19.5542 12.1331 19.575 11.9998 19.575C11.8665 19.575 11.7415 19.5542 11.6248 19.5125C11.5081 19.4708 11.3998 19.4 11.2998 19.3L4.6998 12.7C4.51647 12.5167 4.4248 12.2875 4.4248 12.0125C4.4248 11.7375 4.51647 11.5 4.6998 11.3C4.8998 11.1 5.1373 11 5.4123 11C5.6873 11 5.9248 11.1 6.1248 11.3L10.9998 16.175Z"
          className={classNames(fillColor, hoverFillColor)}
        />
      </g>
    </svg>
  );
};
