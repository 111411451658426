import { useT } from '@transifex/react';
import classNames from 'classnames';
import React from 'react';

import { Text } from '..';
import { toGroupHoverText } from '../../../theme/colors';
import { ColorType, IconType } from '../../../types/ui';
import { icons } from '../../icons';

type HelpPageLinkProps = {
  icon: IconType;
  label: string;
  iconColor?: ColorType;
  iconHoverColor?: ColorType;
  className?: string;
  onClick?: () => void;
  iconActive?: boolean;
};

const HeaderIconLink = ({
  icon,
  label,
  iconColor,
  iconHoverColor,
  className,
  onClick,
  iconActive = true,
}: HelpPageLinkProps) => {
  const t = useT();
  const Icon = icons[icon];
  const hoverColorClass = toGroupHoverText(iconHoverColor);
  return (
    <button
      aria-label={t('Navigate to {label} page', { label })}
      className={classNames('group flex flex-col items-center', className)}
      onClick={onClick}
    >
      <Icon size={32} colorOverride={iconColor} hoverColorOverride={iconHoverColor} active={iconActive} />
      <Text variant="subtitle-2" color={iconColor} className={hoverColorClass}>
        {label}
      </Text>
    </button>
  );
};

export default HeaderIconLink;
