import { colors } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const MicrophoneMute = ({ size = 24, colorOverride = 'purple' }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 12C19.5 12.846 19.359 13.6605 19.101 14.4195L17.88 13.1985C17.9599 12.804 18.0001 12.4025 18 12V10.5C18 10.3011 18.079 10.1103 18.2197 9.96967C18.3603 9.82902 18.5511 9.75 18.75 9.75C18.9489 9.75 19.1397 9.82902 19.2803 9.96967C19.421 10.1103 19.5 10.3011 19.5 10.5V12ZM12 18C13.227 18 14.367 17.6325 15.318 16.9995L16.395 18.078C15.3242 18.8545 14.0663 19.3323 12.75 19.4625V22.5H17.25C17.4489 22.5 17.6397 22.579 17.7803 22.7197C17.921 22.8603 18 23.0511 18 23.25C18 23.4489 17.921 23.6397 17.7803 23.7803C17.6397 23.921 17.4489 24 17.25 24H6.75C6.55109 24 6.36032 23.921 6.21967 23.7803C6.07902 23.6397 6 23.4489 6 23.25C6 23.0511 6.07902 22.8603 6.21967 22.7197C6.36032 22.579 6.55109 22.5 6.75 22.5H11.25V19.4625C9.40003 19.2766 7.68506 18.4101 6.43782 17.0312C5.19058 15.6523 4.49998 13.8593 4.5 12V10.5C4.5 10.3011 4.57902 10.1103 4.71967 9.96967C4.86032 9.82902 5.05109 9.75 5.25 9.75C5.44891 9.75 5.63968 9.82902 5.78033 9.96967C5.92098 10.1103 6 10.3011 6 10.5V12C6 13.5913 6.63214 15.1174 7.75736 16.2426C8.88258 17.3679 10.4087 18 12 18ZM16.5 4.5V11.8185L15 10.3185V4.5C15.0035 3.71739 14.701 2.96441 14.157 2.4017C13.6131 1.839 12.8708 1.51112 12.0886 1.48803C11.3063 1.46493 10.5459 1.74845 9.96977 2.27809C9.3936 2.80773 9.04721 3.54155 9.0045 4.323L7.737 3.0555C8.08041 2.04636 8.7708 1.19184 9.68525 0.644053C10.5997 0.0962669 11.6789 -0.10923 12.7307 0.0641455C13.7824 0.237521 14.7386 0.778518 15.4288 1.59082C16.1191 2.40313 16.4986 3.43403 16.5 4.5Z"
        fill={colors[colorOverride]}
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M14.2288 15.9105L13.1068 14.7885C12.6517 14.9692 12.1593 15.0357 11.6726 14.9823C11.1859 14.9289 10.7197 14.7572 10.3146 14.4822C9.9095 14.2072 9.5779 13.8372 9.34871 13.4045C9.11952 12.9718 8.99972 12.4896 8.99975 12V10.683L7.49975 9.18301V12C7.49952 12.788 7.7062 13.5622 8.09911 14.2452C8.49203 14.9283 9.05741 15.4961 9.7387 15.8921C10.42 16.288 11.1933 16.4981 11.9813 16.5013C12.7692 16.5045 13.5442 16.3008 14.2288 15.9105ZM2.46875 2.03101L20.4688 20.031L21.5308 18.969L12.5308 9.96901L3.53075 0.969009L2.46875 2.03101Z"
        fill={colors[colorOverride]}
      />
    </svg>
  );
};

export default MicrophoneMute;
