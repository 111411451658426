import React from 'react';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const AppleIcon = ({ size = 20, colorOverride }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08276 1.62049C7.08276 1.41594 7.24858 1.25012 7.45313 1.25012C8.72627 1.25012 9.81186 2.05309 10.2311 3.18022C10.822 2.2598 11.7931 1.66679 13.0449 1.66679C13.3493 1.66679 13.6399 1.80281 13.7026 2.10067C13.7301 2.23116 13.7494 2.39576 13.7494 2.6005C13.7494 2.80525 13.7301 2.96984 13.7026 3.10034C13.6399 3.3982 13.3493 3.53422 13.0449 3.53422C11.9801 3.53422 10.7257 4.36162 10.6235 5.40856C11.4242 5.14012 12.22 5.00012 12.9165 5.00012C15.6779 5.00012 18.3332 6.76483 18.3332 10.8825C18.3332 15.0001 15.6779 18.3335 12.9165 18.3335C11.8537 18.3335 10.8723 18.0882 9.99984 17.6618C9.12738 18.0882 8.14593 18.3335 7.08317 18.3335C4.32175 18.3335 1.6665 15.0001 1.6665 10.8825C1.6665 6.76483 4.32175 5.00012 7.08317 5.00012C7.8397 5.00012 8.71335 5.16528 9.58317 5.48081C9.58712 5.16354 9.62015 4.85555 9.6806 4.56118C8.21647 4.38125 7.08276 3.13327 7.08276 1.62049ZM4.15956 10.0039C3.53453 9.49396 3.55852 8.43013 4.21313 7.62775C4.86774 6.82537 5.90509 6.58829 6.53012 7.09821C6.95145 7.44194 6.667 7.68463 6.23633 8.05209C6.02812 8.22975 5.78572 8.43657 5.57238 8.69807C5.36127 8.95683 5.20439 9.23753 5.06776 9.48201C4.78071 9.99562 4.58302 10.3494 4.15956 10.0039Z"
        className={fillClass}
      />
    </svg>
  );
};

export default AppleIcon;
