import classNames from 'classnames';

type Props = {
  direction?: 'horizontal' | 'vertical';
  margin?: 4 | 6;
  className?: string;
};

export const Divider = ({ className, margin = 6, direction = 'horizontal' }: Props) => {
  const marginClassName =
    direction === 'horizontal' ? (margin === 4 ? 'my-4' : 'my-6') : margin === 4 ? 'mx-4' : 'mx-6';

  return (
    <div
      className={classNames(
        'bg-lightGrayOne',
        marginClassName,
        direction === 'horizontal' ? 'w-full h-[1px]' : 'min-h-full w-[1px]',
        className
      )}
    />
  );
};
