import classNames from 'classnames';
import React from 'react';

import { toFillColor, toGroupHoverFillColor, toHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const CloseIcon = ({ size = 16, colorOverride, hoverColorOverride, className }: IconProps) => {
  const fillClassName = toFillColor(colorOverride);
  const hoverFillClassName = toHoverFillColor(hoverColorOverride);
  const groupHoverFillClassName = toGroupHoverFillColor(hoverColorOverride);
  const colorClasses = classNames(fillClassName, hoverFillClassName, groupHoverFillClassName);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(className, 'group flex-shrink-0')}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.29249 2.30529C2.47983 2.11782 2.73388 2.0125 2.99878 2.0125C3.26368 2.0125 3.51773 2.11782 3.70507 2.30529L7.99376 6.59829L12.2824 2.30529C12.3746 2.20978 12.4848 2.1336 12.6067 2.08119C12.7286 2.02878 12.8597 2.00119 12.9923 2.00004C13.125 1.99888 13.2565 2.02419 13.3793 2.07447C13.5021 2.12475 13.6136 2.199 13.7074 2.29289C13.8012 2.38679 13.8754 2.49844 13.9256 2.62133C13.9758 2.74423 14.0011 2.87591 14 3.00869C13.9988 3.14147 13.9713 3.27269 13.9189 3.39469C13.8665 3.5167 13.7904 3.62704 13.695 3.71929L9.40633 8.01229L13.695 12.3053C13.877 12.4939 13.9777 12.7465 13.9754 13.0087C13.9731 13.2709 13.8681 13.5217 13.6828 13.7071C13.4976 13.8925 13.2471 13.9977 12.9851 14C12.7232 14.0022 12.4709 13.9014 12.2824 13.7193L7.99376 9.42629L3.70507 13.7193C3.51666 13.9014 3.26431 14.0022 3.00238 14C2.74044 13.9977 2.48988 13.8925 2.30466 13.7071C2.11944 13.5217 2.01438 13.2709 2.0121 13.0087C2.00982 12.7465 2.11052 12.4939 2.29249 12.3053L6.58118 8.01229L2.29249 3.71929C2.10521 3.53176 2 3.27745 2 3.01229C2 2.74712 2.10521 2.49282 2.29249 2.30529Z"
        className={colorClasses}
      />
    </svg>
  );
};
