import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { Call } from '@twilio/voice-sdk';

import {
  CreateConversationPayload,
  GetMemberNeedsOpts,
  addMemberTags,
  adminGetMemberIncentiveFaqsById,
  confirmEligibilityDOB,
  confirmEligibilityHealthId,
  createAWVFeedback,
  createConversation,
  createExtMemberAccount,
  createMemberAccount,
  createMemberAttestation,
  createReferralByTrackingInfo,
  createResourceFeedback,
  createResourceFeedbackForCurrentMember,
  deleteMemberTag,
  emailCurrentMemberResourceInfo,
  emailResourceInfo,
  fetchPrompts,
  generateVoiceToken,
  getAWVResources,
  getAccountClientConfig,
  getCallRecording,
  getCallsForMember,
  getCaregiverById,
  getCaregiversById,
  getClientConfig,
  getClients,
  getConference,
  getConversation,
  getConversations,
  getCurrentMemberModules,
  getCurrentModulesAdmin,
  getCurrentUserAssignedMembers,
  getDefaultTags,
  getEligibilityRecordSummary,
  getEligibilityRecordSummaryByShortCode,
  getHasAccountForRecord,
  getLegacyCallsForMember,
  getMemberAccount,
  getMemberByAccount,
  getMemberById,
  getMemberCards,
  getMemberIncentiveFaqsById,
  getMemberNeed,
  getMemberNeedAdmin,
  getMemberNeeds,
  getMemberNeedsAdmin,
  getMembersById,
  getPlansByZipcode,
  getResourceReferrals,
  getSharedResources,
  getUserSession,
  getWelcomeCall,
  impersonateAccount,
  joinConferenceCall,
  logPrompt,
  logout,
  magicLinkLogin,
  passwordLogin,
  registerMemberExternal,
  removeFromConferenceCall,
  resetPasswordLogin,
  searchAccounts,
  searchResources,
  searchResourcesCurrentUser,
  sendMessage,
  setPasswordLogin,
  submitActivateAccount,
  submitAuthChallenge,
  unimpersonateAccount,
  updateDuoAssigned,
  updateMember,
  updateMemberAttestation,
  updateMemberNeed,
  updateMemberNeedAdmin,
} from '../api/member';
import {
  addTodoListItemByUserId,
  createComment,
  editComment,
  getActivationSurveyQuestion,
  getAllSurveyInstances,
  getCommentsByObjectIdAndType,
  getCurrentUserSurveysByStatus,
  getDashboardPrompts,
  getInteractionsByTodoItemId,
  getQuestion,
  getServiceRequestForMember,
  getTodoItemById,
  getTodoListByUserId,
  getTodoListItem,
  setItemAsDiscussedById,
  submitActivationSurveyAction,
  submitActivationSurveyActionMember,
  updateTodoListItemByItemId,
} from '../api/rehoboam';
import {
  Account,
  AccountSearchPayload,
  CreateMemberAccountRequest,
  DuosRole,
  SearchAccountResponse,
  UpdateAssignmentPayload,
  UpdateAssignmentResponse,
} from '../types/Account';
import { ActionItem, ActionItemInteraction, ActionItemPayload } from '../types/ActionItem';
import {
  AWVFeedbackRequestBody,
  AWVResourcesSearchParams,
  MemberAttestationCreate,
  MemberAttestationUpdate,
} from '../types/AnnualWellness';
import { AppView } from '../types/AppView';
import { AuthChallenge, PasswordLoginParams, ResetPasswordLoginParams, SetPasswordLoginParams } from '../types/Auth';
import { CalendarEvent } from '../types/CalendarEvent';
import { CallLogResponse, CallRecording, LegacyCallResponse, VoiceConference } from '../types/Call';
import { Caregiver } from '../types/Caregiver';
import { GetClientsResponse } from '../types/Client';
import { ClientConfig, ClientConfigV2 } from '../types/ClientConfig';
import {
  Comment,
  EditCommentPayload,
  GetCommentPayload,
  NewCommentPayload,
  NewCommentResponse,
} from '../types/Comments';
import { PaginatedResults } from '../types/Common';
import { Conversation, Message, MessageRequest } from '../types/Conversation';
import { DashboardPrompt } from '../types/DashboardPrompt';
import {
  ConfirmEligibilityDOBParams,
  ConfirmEligibilityHIDParams,
  EligibilityRecordSummary,
} from '../types/EligibilityRecord';
import { CreateAccountParams } from '../types/ExternalMemberAccount';
import { FetchPromptsParams, FetchPromptsResponse, LogPromptParams, UserPrompt } from '../types/Ftue';
import { IncentiveFaq } from '../types/Incentive';
import { MagicLinkLoginParams } from '../types/MagicLink';
import { IMemberTag, ManagedMember, Member, MemberCardProps, MemberJourneyStatus } from '../types/Member';
import { ModuleDisplay } from '../types/Module';
import { MemberNeedView, UpdateMemberNeed } from '../types/Need';
import { PlanSearchParams } from '../types/Plan';
import { ResourcesReferralResponse, TrackingRequest } from '../types/Referral';
import { ExternalMemberRegistrationRequest } from '../types/Register';
import { Relationship } from '../types/Relationship';
import {
  DuoCaremanagerResourceFeedbackRequest,
  EmailMemberResourceInfoRequestBase,
  EmailResourceInfoRequest,
  MemberResources,
  ResourceFeedbackRequest,
  ResourceLookupForm,
} from '../types/Resources';
import { ServiceRequest } from '../types/ServiceRequest';
import { ActionPayload } from '../types/Survey';
import { SurveyInstance, SurveyStatus } from '../types/SurveyInstance';
import { UserSessionResponse } from '../types/UserSession';
import queryClient from '../utils/queryClient';
import { MutationResult } from './splitApi/type';

export function useEligibilityRecordSummary({
  eligibilityRecordId,
}: {
  eligibilityRecordId?: string;
}): UseQueryResult<EligibilityRecordSummary, void> {
  return useQuery(
    ['eligibilitySummary', eligibilityRecordId],
    () => getEligibilityRecordSummary(eligibilityRecordId!),
    {
      enabled: !!eligibilityRecordId,
    }
  );
}

export function useEligibilityRecordSummaryByShortCode({
  shortCode,
}: {
  shortCode?: string;
}): UseQueryResult<EligibilityRecordSummary, void> {
  return useQuery(['eligibilitySummary', shortCode], () => getEligibilityRecordSummaryByShortCode(shortCode!), {
    enabled: !!shortCode,
  });
}

export function useExtCreateMemberAccountMutation(): MutationResult<void, CreateAccountParams> {
  return useMutation((params: CreateAccountParams) => createExtMemberAccount(params));
}

export function useConfirmEligibilityDOBMutation(): MutationResult<void, ConfirmEligibilityDOBParams> {
  return useMutation((params: ConfirmEligibilityDOBParams) => confirmEligibilityDOB(params));
}

export function useConfirmEligibilityHIDMutation(): MutationResult<void, ConfirmEligibilityHIDParams> {
  return useMutation((params: ConfirmEligibilityHIDParams) => confirmEligibilityHealthId(params));
}

export function useMagicLinkMutation(): MutationResult<void, MagicLinkLoginParams> {
  return useMutation((params: MagicLinkLoginParams) => magicLinkLogin(params));
}

export function usePasswordLoginMutation(): MutationResult<void, PasswordLoginParams> {
  return useMutation((params: PasswordLoginParams) => passwordLogin(params));
}

export function useSetPasswordLoginMutation(): MutationResult<void, SetPasswordLoginParams> {
  return useMutation((params: SetPasswordLoginParams) => setPasswordLogin(params));
}

export function useResetPasswordLoginMutation(): MutationResult<void, ResetPasswordLoginParams> {
  return useMutation((params: ResetPasswordLoginParams) => resetPasswordLogin(params));
}

export function useLogout(): MutationResult<void, void> {
  return useMutation(() => logout());
}

export function useAuthChallenge(): MutationResult<void, AuthChallenge> {
  return useMutation((params: AuthChallenge) => submitAuthChallenge({ value: params.token, type: 'code' }));
}

export function useActivateAccount(): MutationResult<void, string> {
  return useMutation((code: string) => submitActivateAccount(code));
}

export function useAddNewItem({
  memberId,
  onSuccess,
}: {
  memberId: string;
  onSuccess: (newItem: ActionItem) => void;
}): MutationResult<ActionItem | undefined, { memberId: string; payload: ActionItemPayload }> {
  return useMutation(({ memberId, payload }) => addTodoListItemByUserId(memberId, payload), {
    onSuccess: (data: ActionItem | undefined) => {
      if (data) {
        queryClient.setQueryData<ActionItem[]>(['todoList', { memberId }], (currentTodoList) =>
          currentTodoList ? [...currentTodoList, data] : [data]
        );
        onSuccess(data);
      }
    },
  });
}

export function useUpdateTodoItem({
  memberId,
  onSuccess,
}: {
  memberId?: string;
  onSuccess: (updatedItem: ActionItem) => void;
}): MutationResult<ActionItem | undefined, { itemId?: string; payload: ActionItemPayload }> {
  return useMutation(({ itemId, payload }) => updateTodoListItemByItemId(itemId!, payload), {
    onSuccess: (data: ActionItem | undefined) => {
      if (data) {
        queryClient.setQueryData<ActionItem>(['action-item', { itemId: data.id }], {
          ...data,
        });
        queryClient.setQueryData<ActionItem[]>(['todoList', { memberId }], (currentTodoList) =>
          currentTodoList
            ? currentTodoList.map((currentItem: ActionItem) => {
                if (currentItem.id === data.id) {
                  return {
                    ...data,
                  };
                } else {
                  return currentItem;
                }
              })
            : []
        );
        onSuccess(data);
      }
    },
  });
}

export function useUserSession(): UseQueryResult<UserSessionResponse, void> {
  return useQuery(['user-session'], () => getUserSession());
}

export function useMember({ memberId }: { memberId?: string }): UseQueryResult<Member, { memberId?: string }> {
  return useQuery(['member', { memberId }], () => getMemberById(memberId!), {
    enabled: !!memberId,
  });
}

export function useGetCurrentMember(opts: { enabled?: boolean }): UseQueryResult<Member> {
  return useQuery(
    ['memberByAccount', { memberId: undefined }],
    () => {
      return getMemberByAccount();
    },
    opts
  );
}

export function useMembers({ memberId }: { memberId?: string }): UseQueryResult<Relationship[], { memberId?: string }> {
  return useQuery(['memberMembers', { memberId }], () => getMembersById(memberId!), {
    enabled: !!memberId,
  });
}

export function useCaregivers({
  memberId,
}: {
  memberId?: string;
}): UseQueryResult<Relationship[], { memberId?: string }> {
  return useQuery(['memberCaregivers', { memberId }], () => getCaregiversById(memberId!), {
    enabled: !!memberId,
  });
}

export function useCaregiver({
  caregiverId,
}: {
  caregiverId?: string;
}): UseQueryResult<Caregiver, { caregiverId?: string }> {
  return useQuery(['caregiver', { caregiverId }], () => getCaregiverById(caregiverId!), {
    enabled: !!caregiverId,
  });
}

export function useClients(): UseQueryResult<GetClientsResponse, void> {
  return useQuery(['clients'], getClients);
}

export function useTodoList({ memberId }: { memberId?: string }): UseQueryResult<ActionItem[], { memberId?: string }> {
  return useQuery(['todoList', { memberId }], () => getTodoListByUserId(memberId!), {
    enabled: !!memberId,
  });
}

export function useTodoItem({
  itemId,
  skip = false,
}: {
  itemId?: string;
  skip?: boolean;
}): UseQueryResult<ActionItem, { itemId?: string }> {
  return useQuery(['todoItem', { itemId }], () => getTodoItemById(itemId!), {
    enabled: !!itemId && !skip,
  });
}

export function useComments({ objectId, objectType }: GetCommentPayload): UseQueryResult<Comment[], GetCommentPayload> {
  return useQuery<Comment[], GetCommentPayload>(
    ['useComment', { objectId, objectType }],
    () => getCommentsByObjectIdAndType({ objectId, objectType }),
    { enabled: !!objectId }
  );
}

export function useCreateComment({
  onSuccess,
}: {
  onSuccess: () => void;
}): MutationResult<NewCommentResponse | undefined, { payload: NewCommentPayload }> {
  return useMutation(({ payload }) => createComment(payload), {
    onSuccess(data) {
      if (data) {
        const { id: objectId, type: objectType } = data.object;
        queryClient.setQueryData<NewCommentResponse[]>(['useComment', { objectId, objectType }], (currentComments) => {
          if (currentComments) {
            return [...currentComments, data];
          } else {
            return [];
          }
        });
      }
      onSuccess();
    },
  });
}

export function useEditComment(): MutationResult<NewCommentResponse | undefined, { payload: EditCommentPayload }> {
  return useMutation(({ payload }) => editComment(payload), {
    onSuccess(data) {
      if (data) {
        const { id: objectId, type: objectType } = data.object;
        queryClient.setQueryData<NewCommentResponse[]>(['useComment', { objectId, objectType }], (currentComments) =>
          currentComments ? currentComments.map((comment) => (comment.id === data.id ? { ...data } : comment)) : []
        );
      }
    },
  });
}

export function useInteractions({
  itemId,
}: {
  itemId?: string;
}): UseQueryResult<ActionItemInteraction[], { itemId?: string }> {
  return useQuery(['interactions', { itemId }], () => getInteractionsByTodoItemId(itemId!), {
    enabled: !!itemId,
  });
}

export function useSetTodoItemDiscussed({
  memberId,
}: {
  memberId: string;
}): MutationResult<void, { itemId: string; memberId: string }> {
  return useMutation(({ itemId }) => setItemAsDiscussedById(itemId), {
    onSuccess: (data, parameters) => {
      queryClient.setQueryData<ActionItem[]>(['todoList', { memberId }], (currentTodoList) =>
        currentTodoList
          ? currentTodoList.map((currentItem: ActionItem) => {
              if (currentItem.id === parameters.itemId) {
                return {
                  ...currentItem,
                  discussed: true,
                };
              } else {
                return currentItem;
              }
            })
          : []
      );
    },
  });
}

export function useGetActionItemQuery(itemId: string) {
  return useQuery<ActionItem>(['action-item', { itemId }], () => getTodoListItem(itemId), {
    enabled: !!itemId,
  });
}

export function useVoiceToken() {
  return useQuery<{ token: string }>(['voiceToken'], generateVoiceToken, {
    refetchOnWindowFocus: false,
    enabled: false,
  });
}

export function useConference(callSid?: string, intervalInMs?: number) {
  const options: Omit<
    UseQueryOptions<VoiceConference | undefined, unknown, VoiceConference | undefined, QueryKey>,
    'queryKey' | 'queryFn'
  > = {
    enabled: !!callSid,
  };
  if (!!intervalInMs) {
    options.refetchInterval = callSid ? intervalInMs : 0;
  }
  return useQuery<VoiceConference | undefined>(
    ['conference', callSid],
    async (): Promise<VoiceConference | undefined> => {
      if (!callSid) {
        return;
      }
      return getConference(callSid);
    },
    options
  );
}

export function useJoinConferenceCall(): MutationResult<string | undefined, any> {
  return useMutation(async ({ conferenceId, phoneNumber }: { conferenceId: string; phoneNumber: string }) =>
    joinConferenceCall(conferenceId, phoneNumber)
  );
}

export function useRemoveFromConferenceCall(): MutationResult<Call | undefined, any> {
  return useMutation(async (twilioCallId: string) => removeFromConferenceCall(twilioCallId));
}

/**
 * Custom react-query for fetching members
 *
 * @param isFetchingAll Need to fetch all members or only assigned members to current user?
 * @param options React query options
 * @returns Members react-query states { data: Member[], isLoading: boolean, ... }
 */
export function useGetMemberCardList(
  isFetchingAll: boolean,
  options: Omit<UseQueryOptions<MemberCardProps[], unknown, MemberCardProps[], QueryKey>, 'queryKey' | 'queryFn'>
) {
  const fetchAllMemberCards = async (): Promise<{ data: MemberCardProps[] }> => {
    const limit = 1000;
    let offset = 0;
    let hasMore = true;
    let result: Member[] = [];

    while (hasMore) {
      const { data } = await getMemberCards({ limit, offset });
      result = result.concat(data);
      hasMore = data.length === limit;
      offset += limit;
    }

    return { data: result.map((member) => Member.toMemberCardProps(member)) };
  };

  const fetchAssignedMemberCards = async (): Promise<{ data: MemberCardProps[] }> => {
    const { data } = await getCurrentUserAssignedMembers([
      MemberJourneyStatus.ActiveEngaged,
      MemberJourneyStatus.ActiveUnengaged,
      MemberJourneyStatus.Inactive,
    ]);
    return { data: data.map((managedMember) => ManagedMember.toMemberCardProps(managedMember)) };
  };

  return useQuery<MemberCardProps[]>(
    ['memberCards'],
    () => (isFetchingAll ? fetchAllMemberCards() : fetchAssignedMemberCards()).then(({ data }) => data),
    {
      ...options,
      staleTime: 10 * 60 * 1000, // 10 mins
    }
  );
}

export function useGetDefaultTags() {
  return useQuery<IMemberTag[]>(['default-tags'], () => getDefaultTags());
}

export function useAddMemberTags({
  memberId,
}: {
  memberId: string;
}): MutationResult<IMemberTag[] | undefined, { memberId: string; tagNames: string[] }> {
  return useMutation(
    ({ memberId, tagNames }: { memberId: string; tagNames: string[] }) => addMemberTags(memberId, tagNames),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<Member | undefined>(['member', { memberId }], (currentMember) => {
          if (currentMember !== undefined && data) {
            return {
              ...(currentMember as Member),
              tags: data,
            };
          } else {
            return undefined;
          }
        });
      },
    }
  );
}

export function useDeleteTagName({
  memberId,
}: {
  memberId: string;
}): MutationResult<void, { memberId: string; tagName: string }> {
  return useMutation(
    ({ memberId, tagName }: { memberId: string; tagName: string }) => deleteMemberTag(memberId, tagName),
    {
      onSuccess: (data, parameters) => {
        queryClient.setQueryData<Member | undefined>(['member', { memberId }], (currentMember) => {
          if (currentMember !== undefined) {
            return {
              ...(currentMember as Member),
              tags: currentMember.tags.filter((currentTag) => currentTag.name !== parameters.tagName),
            };
          } else {
            return undefined;
          }
        });
      },
    }
  );
}

export function useServiceRequests(memberId: string): UseQueryResult<ServiceRequest[], void> {
  return useQuery(['serviceRequests', memberId], () => getServiceRequestForMember(memberId));
}

export function useUpdateMemberMutation({
  memberId,
}: {
  memberId: string;
}): MutationResult<Member | undefined, { payload: Partial<Member> }> {
  return useMutation(({ payload }: { payload: Partial<Member> }) => updateMember(memberId, payload), {
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['member', { memberId }] });

      // Any updates to welcome call outcome or engagement status means we should
      // refetch dashboard prompts
      if (
        variables.payload.welcomeCallOutcome ||
        variables.payload.engagementStatus ||
        variables.payload.disengagedPromptDismissedAt
      ) {
        await queryClient.invalidateQueries({ queryKey: ['dashboardPrompts'] });
      }

      // We should also refetch our list of members on any updates, since
      // we may have changed any of the displayed properties of them
      await queryClient.invalidateQueries({ queryKey: ['members'] });
    },
  });
}

export function useGetWelcomeCall({
  userId,
}: {
  userId?: string;
}): UseQueryResult<CalendarEvent | null, { userId: string }> {
  return useQuery(['user-welcome-call', { userId }], () => getWelcomeCall(userId!), {
    enabled: !!userId,
  });
}

export function useLegacyCallLog({
  memberId,
  limit = 10,
}: {
  memberId?: string;
  limit?: number;
}): UseQueryResult<LegacyCallResponse, { memberId?: string; limit?: number }> {
  return useQuery(
    ['legacy-call-log', memberId, limit],
    () => getLegacyCallsForMember({ memberId: memberId!, limit, offset: 0 }),
    {
      enabled: !!memberId,
      keepPreviousData: true,
    }
  );
}

export function useCallLog({
  memberId,
  limit = 10,
}: {
  memberId?: string;
  limit?: number;
}): UseQueryResult<CallLogResponse, { memberId?: string; limit?: number }> {
  return useQuery(['call-log', memberId, limit], () => getCallsForMember({ memberId: memberId!, limit, offset: 0 }), {
    enabled: !!memberId,
    keepPreviousData: true,
  });
}

export function useDashboardPrompts(memberId: string): UseQueryResult<DashboardPrompt[]> {
  return useQuery(['dashboardPrompts', memberId], () => getDashboardPrompts(memberId));
}

export function useCallRecording({ recordingId }: { recordingId?: string }): UseQueryResult<CallRecording> {
  return useQuery(['dashboardPrompts', recordingId], () => getCallRecording({ recordingId: recordingId! }), {
    enabled: !!recordingId,
  });
}

export function useSearchAccount(
  payload: AccountSearchPayload
): UseQueryResult<SearchAccountResponse, AccountSearchPayload> {
  return useQuery(['searchAccounts'], () => searchAccounts(payload), { retry: false });
}

export function useUpdateDuoAssigned(): MutationResult<UpdateAssignmentResponse | undefined, UpdateAssignmentPayload> {
  return useMutation((payload: UpdateAssignmentPayload) => updateDuoAssigned(payload), {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['member', { memberId: data?.memberId }] });
    },
  });
}

export function useSearchResources(
  searchParams: ResourceLookupForm,
  enabledForNeeds: boolean = false
): UseQueryResult<MemberResources> {
  const finalParams = searchParams;
  if (searchParams.resourceType === 'All Resource Types') {
    finalParams.resourceType = undefined;
  }

  const {
    plan: { memberId, zipcode, planId },
    needs,
    guidedSearchPath,
    queryString,
    resourceType,
    resourceReferralStatuses,
    tags,
    benefitNames,
    needName,
  } = searchParams;

  // We need an identifier or this request will fail
  const hasPlanIdentified = memberId !== undefined || planId !== undefined;

  const zipcodeInput = zipcode && zipcode !== '' ? zipcode : undefined;
  const planIdInput = planId && planId !== '' ? planId : undefined;
  const defaultCriteria =
    Object.values(finalParams).filter((value) => {
      return Array.isArray(value) ? value.length > 0 : true;
    }).length > 0 && hasPlanIdentified;
  const needsCriteria = hasPlanIdentified && needName !== undefined;
  return useQuery(
    [
      'searchResources',
      memberId,
      zipcode,
      planId,
      needs,
      guidedSearchPath,
      queryString,
      resourceType,
      resourceReferralStatuses,
      tags,
      benefitNames,
      needName,
    ],
    () =>
      searchResources({
        plan: { memberId, zipcode: zipcodeInput, planId: planIdInput },
        needs: needs && needs.length > 0 ? needs : undefined,
        guidedSearchPath,
        queryString: queryString && queryString !== '' ? queryString : undefined,
        resourceType,
        resourceReferralStatuses,
        tags: tags && tags.length > 0 ? tags : undefined,
        benefitNames: benefitNames && benefitNames.length > 0 ? benefitNames : undefined,
        needName: needName,
      }),
    {
      enabled: !enabledForNeeds ? defaultCriteria : needsCriteria,
    }
  );
}

export function useSearchResourcesForCurrentMember(
  searchParams: ResourceLookupForm,
  getAllResources: boolean = false,
  role: DuosRole = DuosRole.Member
): UseQueryResult<MemberResources> {
  const { benefitNames, needName } = searchParams;
  const memberId = searchParams.plan.memberId;
  const enabledForMember = getAllResources || needName !== undefined;
  const enabledForAdmin = memberId !== undefined && needName !== undefined && benefitNames !== undefined;
  return useQuery(
    ['searchResourcesCurrentUser', benefitNames, needName, memberId],
    () => {
      if (role !== DuosRole.Member) {
        return searchResources({ plan: { memberId: memberId }, benefitNames: benefitNames, needName: needName });
      } else {
        return searchResourcesCurrentUser({
          benefitNames: benefitNames,
          needName: needName,
        });
      }
    },
    { enabled: role === DuosRole.Member ? enabledForMember : enabledForAdmin }
  );
}

export function useGetClientConfig() {
  return useQuery<ClientConfig>(['clientConfig'], () => getClientConfig());
}

export function useCreateReferralByTrackingInfo(): MutationResult<
  ResourcesReferralResponse | undefined,
  TrackingRequest
> {
  return useMutation(['create-referral'], (payload: TrackingRequest) => createReferralByTrackingInfo({ payload }));
}

export function useSubmitActivationSurveyAction({
  surveyInstanceId,
  isAuthed,
}: {
  surveyInstanceId: string;
  isAuthed?: boolean;
}): MutationResult<SurveyInstance | undefined, ActionPayload> {
  return useMutation(['submit-activation-survey-action', surveyInstanceId], (payload: ActionPayload) => {
    if (isAuthed) {
      return submitActivationSurveyActionMember({ surveyInstanceId, payload });
    }
    return submitActivationSurveyAction({ surveyInstanceId, payload });
  });
}

export function useCreateMemberAttestation(): MutationResult<string | undefined, MemberAttestationCreate> {
  return useMutation(['create-attestation'], (payload: MemberAttestationCreate) => createMemberAttestation(payload));
}

export function useUpdateMemberAttestation(
  attestationId: string
): MutationResult<string | undefined, MemberAttestationUpdate> {
  return useMutation(['update-attestation', attestationId], (payload: MemberAttestationUpdate) =>
    updateMemberAttestation(attestationId, payload)
  );
}

export function useQuestion({ questionId }: { questionId?: string }) {
  return useQuery(['question', questionId], () => getQuestion({ questionId: questionId! }), { enabled: !!questionId });
}

export function useActivationSurveyQuestion({ questionId }: { questionId?: string }) {
  return useQuery(
    ['activation-survey-question', questionId],
    () => getActivationSurveyQuestion({ questionId: questionId! }),
    {
      enabled: !!questionId,
    }
  );
}

export function useGetUserSurveys(memberId?: string) {
  return useQuery(['user-surveys', memberId], () => {
    if (memberId) {
      return getAllSurveyInstances(memberId);
    }
    return getCurrentUserSurveysByStatus();
  });
}

export function useGetUserSurveysByStatus(status?: SurveyStatus) {
  return useQuery(['user-surveys', status], () => {
    return getCurrentUserSurveysByStatus(status);
  });
}

export function useGetAWVResources(params: AWVResourcesSearchParams) {
  return useQuery(['awvResourcesSearch', params.memberId], () => getAWVResources(params));
}

export function useCreateAWVFeedback(): MutationResult<any | undefined, AWVFeedbackRequestBody> {
  return useMutation(['awvFeedback'], (payload: AWVFeedbackRequestBody) => createAWVFeedback(payload));
}

export function useEmailResourceInfo(
  benefitName: string
): MutationResult<string | undefined, { payload: EmailResourceInfoRequest }> {
  return useMutation(['emailResourceInfo', benefitName], ({ payload }: { payload: EmailResourceInfoRequest }) =>
    emailResourceInfo({ payload })
  );
}

export function useEmailCurrentMemberResourceInfo(): MutationResult<
  string | undefined,
  EmailMemberResourceInfoRequestBase
> {
  return useMutation(['emailCurrentMemberResourceInfo'], (payload: EmailMemberResourceInfoRequestBase) =>
    emailCurrentMemberResourceInfo(payload)
  );
}

export function useGetMemberAccount(memberId: string): UseQueryResult<Account | undefined> {
  return useQuery(['getMemberAccount', memberId], () => getMemberAccount(memberId));
}

export function useCreateMemberAccount(
  memberId: string
): MutationResult<Account | undefined, CreateMemberAccountRequest> {
  return useMutation(['createMemberAccount', memberId], (payload: CreateMemberAccountRequest) =>
    createMemberAccount(payload)
  );
}

export function useGetSearchPlansByZipcode(params: PlanSearchParams) {
  return useQuery(['plansByZipcode', params.zipcode], () => getPlansByZipcode(params), {
    enabled: params.zipcode !== undefined,
  });
}

export function useCreateConversation(): MutationResult<Conversation | undefined, CreateConversationPayload> {
  return useMutation(['createdConversation'], (payload: CreateConversationPayload) => createConversation(payload));
}

export function useSendMessage(): MutationResult<Conversation | undefined, MessageRequest> {
  return useMutation(
    ['sendMessage'],
    ({ conversationId, message, displayFtue }: MessageRequest) =>
      sendMessage({ conversationId, payload: { message }, displayFtue }),
    {
      onMutate: (variables) => {
        queryClient.setQueryData(['conversation', variables.conversationId], (previous: Conversation | undefined) => {
          if (previous && previous.messages) {
            const newMessage: Message = {
              id: 'fake',
              conversationId: variables.conversationId,
              isChatbot: false,
              message: { type: 'text', text: variables.message.text || variables.message.selectedOption?.label },
              creationTime: new Date(),
            };
            const messages = [...previous.messages, newMessage];
            if (previous) {
              return { ...previous, messages };
            }
          }
          return previous;
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ['conversation', data?.id] });
      },
    }
  );
}

export function useConversations({ accountId }: { accountId?: string }): UseQueryResult<Conversation[] | undefined> {
  return useQuery(['conversations', accountId], () => getConversations({ accountId }), { enabled: !!accountId });
}

export function useConversation({
  conversationId,
}: {
  conversationId?: string;
}): UseQueryResult<Conversation | undefined> {
  return useQuery(['conversation', conversationId], () => getConversation({ conversationId }), {
    enabled: !!conversationId,
  });
}

export function useGetCurrentUserSharedResources(): UseQueryResult<MemberResources | undefined> {
  return useQuery(['current-user-shared-resources'], () => getSharedResources());
}

export function useCreateResourceFeedbackForCurrentMember(): MutationResult<void, ResourceFeedbackRequest> {
  return useMutation(['create-current-member-resource-feedback'], (payload: ResourceFeedbackRequest) =>
    createResourceFeedbackForCurrentMember(payload)
  );
}

export function useCreateResourceFeedback(): MutationResult<void, DuoCaremanagerResourceFeedbackRequest> {
  return useMutation(['create-resource-feedback'], (payload: DuoCaremanagerResourceFeedbackRequest) =>
    createResourceFeedback(payload)
  );
}

export function useResourceReferrals(): UseQueryResult<any> {
  return useQuery(['resourceReferrals'], () => getResourceReferrals());
}

export type RegistrationResponse = {
  userId: string;
  signupToken: string;
};

export function useRegisterMemberExternal(): MutationResult<
  RegistrationResponse | undefined,
  ExternalMemberRegistrationRequest
> {
  return useMutation(['external-member-registration'], (payload: ExternalMemberRegistrationRequest) =>
    registerMemberExternal(payload)
  );
}

export function useHasAccount({ eligibilityRecordId }: { eligibilityRecordId?: string }): UseQueryResult<boolean> {
  return useQuery(['eligibility-record-has-account'], () => getHasAccountForRecord(eligibilityRecordId!), {
    enabled: !!eligibilityRecordId,
  });
}

export function useGetMemberNeeds({
  memberId,
  limit,
  offset,
  state,
  resolutionStatus,
}: GetMemberNeedsOpts): UseQueryResult<PaginatedResults<MemberNeedView>> {
  return useQuery(['member-needs', limit, offset, state, resolutionStatus], () => {
    if (memberId) return getMemberNeedsAdmin({ memberId, limit, offset, state, resolutionStatus });
    else return getMemberNeeds({ limit, offset, state, resolutionStatus });
  });
}

export function useGetMemberNeed({
  memberNeedId,
  memberId,
}: {
  memberNeedId: string;
  memberId?: string;
}): UseQueryResult<MemberNeedView | undefined> {
  return useQuery(['member-need', memberNeedId], () => {
    if (memberId) return getMemberNeedAdmin({ memberId: memberId, memberNeedId: memberNeedId });
    else return getMemberNeed({ memberNeedId });
  });
}

export function useUpdateMemberNeed({
  memberNeedId,
}: {
  memberNeedId?: string;
}): MutationResult<MemberNeedView | undefined, UpdateMemberNeed> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['update-member-need'],
    mutationFn: (payload: UpdateMemberNeed) => {
      if (memberNeedId) return updateMemberNeedAdmin({ payload });
      else return updateMemberNeed({ payload });
    },
    onSuccess: (_data, vars) => {
      queryClient.invalidateQueries(['member-need', vars.id]);
      queryClient.invalidateQueries(['member-needs']);
      queryClient.invalidateQueries(['member-need-admin', vars.id]);
      queryClient.invalidateQueries(['member-needs-admin']);
      queryClient.invalidateQueries(['current-member-modules']);
      queryClient.invalidateQueries(['member-modules', _data?.memberId]);
    },
  });
}

export function useGetCurrentMemberModules(): UseQueryResult<ModuleDisplay[]> {
  return useQuery(['current-member-modules'], () => getCurrentMemberModules());
}

export function useAccountClientConfig(): UseQueryResult<ClientConfigV2 | undefined> {
  return useQuery(['account-client-config'], () => getAccountClientConfig());
}

type ImpersonateAccountRequest = { accountId: string };

export function useImpersonateAccount(): MutationResult<Account | undefined, ImpersonateAccountRequest> {
  return useMutation(
    ['impersonate-account'],
    (payload: ImpersonateAccountRequest) => impersonateAccount(payload.accountId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['user-session']);
      },
    }
  );
}

export function useUnimpersonateAccount(): MutationResult<void, void> {
  return useMutation(['unimpersonate-account'], () => unimpersonateAccount());
}

export function useGetMemberIncentiveFaqsById(
  incentiveId: string,
  appView: AppView
): UseQueryResult<IncentiveFaq[] | undefined> {
  return useQuery(['incentive-faqs', incentiveId], () => {
    if (appView === 'memberServices') return adminGetMemberIncentiveFaqsById(incentiveId);
    else return getMemberIncentiveFaqsById(incentiveId);
  });
}

export function useGetCurrentModulesAdmin({ memberId }: { memberId: string }): UseQueryResult<ModuleDisplay[]> {
  return useQuery(['member-modules', memberId], () => getCurrentModulesAdmin({ memberId: memberId }));
}

export function usePrompts({
  userId,
  promptType,
  includeExpired,
}: FetchPromptsParams): UseQueryResult<FetchPromptsResponse> {
  return useQuery(
    ['prompts', userId, promptType, includeExpired],
    () => fetchPrompts({ userId, promptType, includeExpired }),
    {
      enabled: !!userId,
    }
  );
}

export function useLogPrompt() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ userId, promptType }: LogPromptParams) => {
      return logPrompt({ userId, promptType });
    },
    {
      onSuccess: (_, { userId }) => {
        if (userId) {
          queryClient.invalidateQueries(['prompts', userId]);
        }
      },
    }
  );
}
