import React from 'react';

import { IconProps, IconType } from '../../../types/ui';
import { icons } from '../../icons';

const DynamicIcon = ({
  icon,
  active,
  className,
  size,
  hoverColorOverride,
  colorOverride,
}: { icon: IconType } & IconProps) => {
  const Icon = icons[icon];

  return (
    <div>
      <Icon
        active={active}
        className={className}
        size={size}
        hoverFillColorOverride={hoverColorOverride}
        colorOverride={colorOverride}
      />
    </div>
  );
};

export default DynamicIcon;
