import classNames from 'classnames';

import { toFillColor, toStrokeColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const MenuHelp = ({ size = 24, colorOverride, active = true }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const strokeClass = toStrokeColor(colorOverride);
  const activeStandardClass = active ? 'group-hover:invisible' : '';
  const standardClass = `${active ? 'visible' : 'invisible'} ${activeStandardClass}`;
  const activeAlternateClass = active ? 'group-hover:visible' : '';
  const alternateClass = `${active ? 'invisible' : 'visible'} ${activeAlternateClass}`;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          className={classNames(fillClass, strokeClass, standardClass)}
          d="M15.0625 22.5958C15.0625 23.2733 14.811 23.9166 14.3732 24.3858C13.9364 24.8538 13.3517 25.1096 12.75 25.1096C12.1483 25.1096 11.5636 24.8538 11.1268 24.3858C10.689 23.9166 10.4375 23.2733 10.4375 22.5958C10.4375 21.9184 10.689 21.2751 11.1268 20.8059C11.5636 20.3379 12.1483 20.082 12.75 20.082C13.3517 20.082 13.9364 20.3379 14.3732 20.8059C14.811 21.2751 15.0625 21.9184 15.0625 22.5958ZM26.3125 22.5958C26.3125 23.2733 26.061 23.9166 25.6232 24.3858C25.1864 24.8538 24.6017 25.1096 24 25.1096C23.3983 25.1096 22.8136 24.8538 22.3768 24.3858C21.939 23.9166 21.6875 23.2733 21.6875 22.5958C21.6875 21.9184 21.939 21.2751 22.3768 20.8059C22.8136 20.3379 23.3983 20.082 24 20.082C24.6017 20.082 25.1864 20.3379 25.6232 20.8059C26.061 21.2751 26.3125 21.9184 26.3125 22.5958ZM36.8732 24.3858C36.4364 24.8538 35.8517 25.1096 35.25 25.1096C34.6483 25.1096 34.0636 24.8538 33.6268 24.3858C33.189 23.9166 32.9375 23.2733 32.9375 22.5958C32.9375 21.9184 33.189 21.2751 33.6268 20.8059C34.0636 20.3379 34.6483 20.082 35.25 20.082C35.8517 20.082 36.4364 20.3379 36.8732 20.8059C37.311 21.2751 37.5625 21.9184 37.5625 22.5958C37.5625 23.2733 37.311 23.9166 36.8732 24.3858Z"
        />
        <path
          className={classNames(fillClass, strokeClass, standardClass)}
          d="M7.58906 46.1134L7.64531 46.1013C12.7922 45.0073 15.9366 43.5637 17.3991 42.7711C19.5523 43.3865 21.7715 43.6965 24 43.6933C36.4256 43.6933 46.5 34.248 46.5 22.5966C46.5 10.9453 36.4256 1.5 24 1.5C11.5744 1.5 1.5 10.9453 1.5 22.5966C1.5 27.9009 3.58969 32.7532 7.04063 36.4602C6.79919 38.8414 6.30474 41.1852 5.56687 43.4462L5.55844 43.4793C5.34875 44.1269 5.11988 44.7671 4.87219 45.3991C4.65 45.9597 5.08031 46.5865 5.64 46.4901C6.29155 46.3759 6.94132 46.2503 7.58906 46.1134ZM9.83906 36.7465C9.87846 36.3052 9.82655 35.8601 9.68702 35.4429C9.5475 35.0256 9.3238 34.6464 9.03188 34.3324C6.05062 31.1257 4.3125 27.0209 4.3125 22.5966C4.3125 12.9766 12.7612 4.51381 24 4.51381C35.2388 4.51381 43.6875 12.9766 43.6875 22.5966C43.6875 32.2197 35.2388 40.6795 24 40.6795C22.0175 40.6826 20.0431 40.407 18.1275 39.8597C17.4581 39.6675 16.746 39.7469 16.1278 40.0828C15.0394 40.6735 12.6403 41.8006 8.71969 42.7741C9.26665 40.8065 9.6414 38.7886 9.83906 36.7465Z"
          strokeWidth="1.25"
        />
        <path
          d="M15.0625 23.5958C15.0625 24.2733 14.811 24.9166 14.3732 25.3858C13.9364 25.8538 13.3517 26.1096 12.75 26.1096C12.1483 26.1096 11.5636 25.8538 11.1268 25.3858C10.689 24.9166 10.4375 24.2733 10.4375 23.5958C10.4375 22.9184 10.689 22.2751 11.1268 21.8059C11.5636 21.3379 12.1483 21.082 12.75 21.082C13.3517 21.082 13.9364 21.3379 14.3732 21.8059C14.811 22.2751 15.0625 22.9184 15.0625 23.5958ZM26.3125 23.5958C26.3125 24.2733 26.061 24.9166 25.6232 25.3858C25.1864 25.8538 24.6017 26.1096 24 26.1096C23.3983 26.1096 22.8136 25.8538 22.3768 25.3858C21.939 24.9166 21.6875 24.2733 21.6875 23.5958C21.6875 22.9184 21.939 22.2751 22.3768 21.8059C22.8136 21.3379 23.3983 21.082 24 21.082C24.6017 21.082 25.1864 21.3379 25.6232 21.8059C26.061 22.2751 26.3125 22.9184 26.3125 23.5958ZM36.8732 25.3858C36.4364 25.8538 35.8517 26.1096 35.25 26.1096C34.6483 26.1096 34.0636 25.8538 33.6268 25.3858C33.189 24.9166 32.9375 24.2733 32.9375 23.5958C32.9375 22.9184 33.189 22.2751 33.6268 21.8059C34.0636 21.3379 34.6483 21.082 35.25 21.082C35.8517 21.082 36.4364 21.3379 36.8732 21.8059C37.311 22.2751 37.5625 22.9184 37.5625 23.5958C37.5625 24.2733 37.311 24.9166 36.8732 25.3858Z"
          className={classNames('fill-white', 'stroke-white', alternateClass)}
        />
        <path
          d="M7.58906 47.1134L7.64531 47.1013C12.7922 46.0073 15.9366 44.5637 17.3991 43.7711C19.5523 44.3865 21.7715 44.6965 24 44.6933C36.4256 44.6933 46.5 35.248 46.5 23.5966C46.5 11.9453 36.4256 2.5 24 2.5C11.5744 2.5 1.5 11.9453 1.5 23.5966C1.5 28.9009 3.58969 33.7532 7.04063 37.4602C6.79919 39.8414 6.30474 42.1852 5.56687 44.4462L5.55844 44.4793C5.34875 45.1269 5.11988 45.7671 4.87219 46.3991C4.65 46.9597 5.08031 47.5866 5.64 47.4901C6.29155 47.3759 6.94132 47.2503 7.58906 47.1134ZM9.83906 37.7465C9.87846 37.3052 9.82655 36.8601 9.68702 36.4429C9.5475 36.0256 9.3238 35.6464 9.03188 35.3324C6.05063 32.1257 4.3125 28.0209 4.3125 23.5966C4.3125 13.9766 12.7612 5.51381 24 5.51381C35.2388 5.51381 43.6875 13.9766 43.6875 23.5966C43.6875 33.2197 35.2388 41.6795 24 41.6795C22.0175 41.6826 20.0431 41.407 18.1275 40.8597C17.4581 40.6675 16.746 40.7469 16.1278 41.0828C15.0394 41.6735 12.6403 42.8006 8.71969 43.7741C9.26665 41.8065 9.6414 39.7886 9.83906 37.7465Z"
          className={classNames(fillClass, strokeClass, alternateClass)}
          strokeWidth="1.25"
        />
        <path
          d="M9.5 42.0006L10.5 35.0006L5.5 28.5006L5 16.0006C12.6 7.60055 15.8333 6.16722 16.5 6.50055L27 5L35.5 7.5H34.5L41.5 14L43.5 18V23.0006L43.3127 23.5L39 35.0006L27 40.0006L16.5 39.0006L9.5 42.0006Z"
          className={classNames(fillClass, strokeClass, alternateClass)}
        />
        <path
          d="M15.0625 22.5958C15.0625 23.2733 14.811 23.9166 14.3732 24.3858C13.9364 24.8538 13.3517 25.1096 12.75 25.1096C12.1483 25.1096 11.5636 24.8538 11.1268 24.3858C10.689 23.9166 10.4375 23.2733 10.4375 22.5958C10.4375 21.9184 10.689 21.2751 11.1268 20.8059C11.5636 20.3379 12.1483 20.082 12.75 20.082C13.3517 20.082 13.9364 20.3379 14.3732 20.8059C14.811 21.2751 15.0625 21.9184 15.0625 22.5958ZM26.3125 22.5958C26.3125 23.2733 26.061 23.9166 25.6232 24.3858C25.1864 24.8538 24.6017 25.1096 24 25.1096C23.3983 25.1096 22.8136 24.8538 22.3768 24.3858C21.939 23.9166 21.6875 23.2733 21.6875 22.5958C21.6875 21.9184 21.939 21.2751 22.3768 20.8059C22.8136 20.3379 23.3983 20.082 24 20.082C24.6017 20.082 25.1864 20.3379 25.6232 20.8059C26.061 21.2751 26.3125 21.9184 26.3125 22.5958ZM36.8732 24.3858C36.4364 24.8538 35.8517 25.1096 35.25 25.1096C34.6483 25.1096 34.0636 24.8538 33.6268 24.3858C33.189 23.9166 32.9375 23.2733 32.9375 22.5958C32.9375 21.9184 33.189 21.2751 33.6268 20.8059C34.0636 20.3379 34.6483 20.082 35.25 20.082C35.8517 20.082 36.4364 20.3379 36.8732 20.8059C37.311 21.2751 37.5625 21.9184 37.5625 22.5958C37.5625 23.2733 37.311 23.9166 36.8732 24.3858Z"
          className={classNames('fill-white', 'stroke-white', alternateClass)}
        />
        <path
          d="M7.58906 46.1134L7.64531 46.1013C12.7922 45.0073 15.9366 43.5637 17.3991 42.7711C19.5523 43.3865 21.7715 43.6965 24 43.6933C36.4256 43.6933 46.5 34.248 46.5 22.5966C46.5 10.9453 36.4256 1.5 24 1.5C11.5744 1.5 1.5 10.9453 1.5 22.5966C1.5 27.9009 3.58969 32.7532 7.04063 36.4602C6.79919 38.8414 6.30474 41.1852 5.56687 43.4462L5.55844 43.4793C5.34875 44.1269 5.11988 44.7671 4.87219 45.3991C4.65 45.9597 5.08031 46.5865 5.64 46.4901C6.29155 46.3759 6.94132 46.2503 7.58906 46.1134ZM9.83906 36.7465C9.87846 36.3052 9.82655 35.8601 9.68702 35.4429C9.5475 35.0256 9.3238 34.6464 9.03188 34.3324C6.05062 31.1257 4.3125 27.0209 4.3125 22.5966C4.3125 12.9766 12.7612 4.51381 24 4.51381C35.2388 4.51381 43.6875 12.9766 43.6875 22.5966C43.6875 32.2197 35.2388 40.6795 24 40.6795C22.0175 40.6826 20.0431 40.407 18.1275 39.8597C17.4581 39.6675 16.746 39.7469 16.1278 40.0828C15.0394 40.6735 12.6403 41.8006 8.71969 42.7741C9.26665 40.8065 9.6414 38.7886 9.83906 36.7465Z"
          className={classNames(fillClass, strokeClass, alternateClass)}
          strokeWidth="1.25"
        />
      </svg>
    </>
  );
};

export default MenuHelp;
