import classNames from 'classnames';

import { toFillColor, toStrokeColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const MenuFaq = ({ size = 24, colorOverride, active = true }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const strokeClass = toStrokeColor(colorOverride);
  const activeStandardClass = active ? 'group-hover:invisible' : '';
  const standardClass = `${active ? 'visible' : 'invisible'} ${activeStandardClass}`;
  const activeAlternateClass = active ? 'group-hover:visible' : '';
  const alternateClass = `${active ? 'invisible' : 'visible'} ${activeAlternateClass}`;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.2109 26C11.2109 24.8954 12.1064 24 13.2109 24L33.2109 24C34.3155 24 35.2109 24.8954 35.2109 26C35.2109 27.1046 34.3155 28 33.2109 28H13.2109C12.1064 28 11.2109 27.1046 11.2109 26Z"
          className={classNames(fillClass, standardClass)}
        />
        <path
          d="M11.2109 18C11.2109 16.8954 12.1064 16 13.2109 16L25.2109 16C26.3155 16 27.2109 16.8954 27.2109 18C27.2109 19.1046 26.3155 20 25.2109 20H13.2109C12.1064 20 11.2109 19.1046 11.2109 18Z"
          className={classNames(fillClass, standardClass)}
        />
        <path
          d="M9.21094 7C8.10637 7 7.21094 7.89543 7.21094 9V40.2984L10.4482 36.2518C11.5869 34.8285 13.3107 34 15.1334 34H39.2109C40.3155 34 41.2109 33.1046 41.2109 32V9C41.2109 7.89543 40.3155 7 39.2109 7H9.21094ZM3.21094 9C3.21094 5.68629 5.89723 3 9.21094 3H39.2109C42.5246 3 45.2109 5.68629 45.2109 9V32C45.2109 35.3137 42.5246 38 39.2109 38H15.1334C14.5259 38 13.9512 38.2762 13.5717 38.7506L8.90972 44.5781C7.02009 46.9401 3.21094 45.604 3.21094 42.5791V9Z"
          className={classNames(fillClass, standardClass)}
        />
        <path
          d="M7 9V40.5L12.5 34.5L14 34H39.5H40L41 32.5V8.5L39.5 7H8.5L7 9Z"
          className={classNames(fillClass, strokeClass, alternateClass)}
        />
        <path
          d="M11 26C11 24.8954 11.8954 24 13 24L33 24C34.1046 24 35 24.8954 35 26C35 27.1046 34.1046 28 33 28H13C11.8954 28 11 27.1046 11 26Z"
          className={classNames('fill-white', alternateClass)}
        />
        <path
          d="M11 18C11 16.8954 11.8954 16 13 16L25 16C26.1046 16 27 16.8954 27 18C27 19.1046 26.1046 20 25 20H13C11.8954 20 11 19.1046 11 18Z"
          className={classNames('fill-white', alternateClass)}
        />
        <path
          d="M9 7C7.89543 7 7 7.89543 7 9V40.2984L10.2373 36.2518C11.3759 34.8285 13.0998 34 14.9225 34H39C40.1046 34 41 33.1046 41 32V9C41 7.89543 40.1046 7 39 7H9ZM3 9C3 5.68629 5.68629 3 9 3H39C42.3137 3 45 5.68629 45 9V32C45 35.3137 42.3137 38 39 38H14.9225C14.3149 38 13.7403 38.2762 13.3608 38.7506L8.69878 44.5781C6.80915 46.9401 3 45.604 3 42.5791V9Z"
          className={classNames(fillClass, alternateClass)}
        />
      </svg>
    </>
  );
};

export default MenuFaq;
