import classNames from 'classnames';

import DecorativeCirclesLarge from './DecorativeCirclesLarge';
import DecorativeCirclesSmall from './DecorativeCirclesSmall';

export type DecorationsType = 'activate' | 'home' | 'notFoundPage' | 'recommendations' | 'needs' | 'reverseHome';

type DecorationsProps = {
  decorationsType?: DecorationsType;
  className?: string;
};

const Decorations = ({ decorationsType, className }: DecorationsProps) => (
  <div className={classNames('relative -top-[90px] tablet:-top-[120px]', className)}>
    <DecorativeCirclesSmall decorationsType={decorationsType} className="tablet:hidden block" />
    <DecorativeCirclesLarge decorationsType={decorationsType} className="hidden tablet:block" />
  </div>
);

export default Decorations;
