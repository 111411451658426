import classNames from 'classnames';

import { colors, toFillColor, toStrokeColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const CirclePhoneIcon = ({ size = 64, colorOverride = 'darkPurple' }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 64 64" fill="none" className="group">
      <circle
        cx="32"
        cy="32"
        r="31"
        strokeWidth="2"
        className={classNames(
          toFillColor(colorOverride),
          toStrokeColor(colorOverride),
          'group-hover:fill-lightGrayOne group-hover:stroke-purple'
        )}
      />
      <path
        d="M33.8984 21.0938C38.8984 21.0938 42.8984 25.0938 42.8984 30.0938M33.8984 26.0938C35.8984 26.0938 37.8984 28.0938 37.8984 30.0938M17.8984 21.0938C17.8984 38.0938 25.8984 46.0938 42.8984 46.0938V38.0938L35.8984 36.0938L33.8984 39.0938C29.8984 39.0938 24.8984 34.0938 24.8984 30.0938L27.8984 28.0938L25.8984 21.0938H17.8984Z"
        className="stroke-white group-hover:stroke-purple"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CirclePhoneIcon;
