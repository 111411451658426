import moment from 'moment';
import React, { PropsWithChildren, createContext, useCallback, useMemo } from 'react';

import { useCaregivers, useMember, useMembers, useSetTodoItemDiscussed, useUpdateMemberMutation } from '../hooks/api';
import { useSurveyInstances } from '../hooks/splitApi';
import { EngagementStatus, IMemberTag, Member } from '../types/Member';
import { Relationship } from '../types/Relationship';
import { SurveyInstance } from '../types/SurveyInstance';

type TopPrioritiesContextProps = {
  userId: string;
  scrollRef?: React.RefObject<HTMLDivElement> | null;
};

export type TopPrioritiesContextType = {
  member?: Member;
  relationships?: Relationship[];
  loading: boolean;
  setDiscussed: (itemId: string) => void;
  scrollRef: React.RefObject<HTMLDivElement> | null;
  tags: IMemberTag[];
  surveyInstances?: SurveyInstance[];
  setMemberIsEngaged: () => Promise<Member | undefined>;
  setMemberIsDisengaged: () => Promise<Member | undefined>;
  engagingMember: boolean;
  disengagingMember: boolean;
};

export const TopPrioritiesContext = createContext<TopPrioritiesContextType>({
  member: undefined,
  relationships: [],
  loading: true,
  setDiscussed: () => {},
  scrollRef: null,
  tags: [],
  surveyInstances: [],
  setMemberIsEngaged: () => new Promise(() => () => undefined),
  setMemberIsDisengaged: () => new Promise(() => () => undefined),
  engagingMember: false,
  disengagingMember: false,
});

export function TopPrioritiesContextWrapper({
  userId,
  scrollRef = null,
  children,
}: PropsWithChildren<TopPrioritiesContextProps>) {
  const memberQuery = useMember({ memberId: userId });
  const membersQuery = useMembers({ memberId: userId ? userId.toString() : undefined });
  const caregiversQuery = useCaregivers({ memberId: userId ? userId.toString() : undefined });
  const discussedMutation = useSetTodoItemDiscussed({ memberId: userId });
  const { data: surveyInstances } = useSurveyInstances({ appView: 'memberServices', userId });

  const { mutateAsync: mutateMemberIsEngaged, isLoading: engagingMember } = useUpdateMemberMutation({
    memberId: userId,
  });
  const { mutateAsync: mutateMemberIsDisengaged, isLoading: disengagingMember } = useUpdateMemberMutation({
    memberId: userId,
  });

  const setMemberIsEngaged = useCallback(
    async () => mutateMemberIsEngaged({ payload: { disengagedPromptDismissedAt: moment().utc().toISOString() } }),
    [mutateMemberIsEngaged]
  );

  const setMemberIsDisengaged = useCallback(
    async () =>
      mutateMemberIsDisengaged({
        payload: { engagementStatus: EngagementStatus.ActiveMemberMissedWeeklyCall },
      }),
    [mutateMemberIsDisengaged]
  );

  const setDiscussed = useCallback(
    (itemId: string) => {
      discussedMutation.mutate({ memberId: userId, itemId });
    },
    [discussedMutation, userId]
  );

  const member = useMemo(() => memberQuery.data, [memberQuery.data]);

  const tags = useMemo(() => memberQuery.data?.tags ?? [], [memberQuery.data]);

  const caregivers = useMemo(() => caregiversQuery.data ?? [], [caregiversQuery.data]);

  const members = useMemo(() => membersQuery.data ?? [], [membersQuery.data]);

  const relationships = useMemo(() => caregivers.concat(members), [caregivers, members]);

  const loading = useMemo(
    () => memberQuery.isLoading || membersQuery.isLoading || caregiversQuery.isLoading,
    [memberQuery.isLoading, membersQuery.isLoading, caregiversQuery.isLoading]
  );

  const value = useMemo(() => {
    return {
      member,
      relationships,
      loading,
      setDiscussed,
      scrollRef,
      tags,
      surveyInstances,
      setMemberIsEngaged,
      setMemberIsDisengaged,
      engagingMember,
      disengagingMember,
    };
  }, [
    member,
    relationships,
    loading,
    setDiscussed,
    scrollRef,
    tags,
    surveyInstances,
    setMemberIsEngaged,
    setMemberIsDisengaged,
    engagingMember,
    disengagingMember,
  ]);

  return <TopPrioritiesContext.Provider value={value}>{children}</TopPrioritiesContext.Provider>;
}

export default TopPrioritiesContextWrapper;
