import React from 'react';

import { IconProps } from '../../../types/ui';

const DigitalDuo = ({ size = 24 }: IconProps) => {
  return (
    <div>
      <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
        <g id="Group 9963">
          <circle id="Ellipse 9" cx="16" cy="16" r="15" fill="white" stroke="#E0DDE8" strokeWidth="2" />
          <g id="Group">
            <path
              id="Vector"
              d="M10.5968 10.5941C11.3896 9.80116 11.3896 8.57752 10.5968 7.79139C9.80406 7.00525 8.58072 6.99842 7.79478 7.79139C7.002 8.58436 7.002 9.80799 7.79478 10.5941C8.58755 11.3803 9.80406 11.3871 10.5968 10.5941Z"
              fill="#AC3EA4"
            />
            <path
              id="Vector_2"
              d="M24.2111 21.3994C23.4183 20.6064 22.195 20.6064 21.409 21.3994C20.6163 22.1924 20.6163 23.416 21.409 24.2022C22.2018 24.9951 23.4251 24.9951 24.2111 24.2022C24.997 23.416 24.997 22.1924 24.2111 21.3994Z"
              fill="#4F196F"
            />
            <path
              id="Vector_3"
              d="M12.3049 20.3906C12.6944 20.1651 12.9678 19.8028 13.084 19.3721C13.1797 19.003 13.1797 18.5928 13.0088 18.2783C12.6192 17.6426 12.4005 16.8701 12.4005 16.0361C12.4005 13.9443 13.8836 12.1807 15.9885 12.1807C16.9795 12.1807 17.827 12.5772 18.4557 13.2129C18.5582 12.5908 18.9888 12.0303 19.5834 11.6816C20.0686 11.4014 20.6222 11.3125 21.1348 11.3877C19.8567 10.041 18.0252 9.20703 15.9817 9.20703C11.9632 9.20703 9.01758 12.2764 9.01758 16.3711C9.01758 17.8067 9.48231 19.0645 10.2477 20.0899C10.7193 20.6573 11.6214 20.7871 12.3049 20.3906Z"
              fill="#AC3EA4"
            />
            <path
              id="Vector_4"
              d="M22.9738 15.9801C22.9738 14.8385 22.6867 13.7721 22.181 12.8356C21.7231 12.0768 20.7116 11.8581 19.9393 12.3024C19.2491 12.6989 18.9074 13.526 19.1739 14.2438C19.4473 14.8043 19.5976 15.4333 19.5976 16.0963C19.5976 18.2018 18.1351 19.9723 16.0438 19.9723C15.1827 19.9723 14.4378 19.6921 13.8432 19.2272C13.8295 19.3366 13.809 19.4528 13.7748 19.5622C13.6108 20.1774 13.2144 20.6969 12.6608 21.0114C12.285 21.2301 11.8749 21.3327 11.4717 21.3327C11.4648 21.3327 11.4648 21.3327 11.458 21.3327C12.7497 22.3581 14.4241 22.9391 16.1532 22.9391C19.9393 22.9323 22.9738 19.8971 22.9738 15.9801Z"
              fill="#4F196F"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default DigitalDuo;
