import { useT } from '@transifex/react';
import classNames from 'classnames';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import Modal from 'react-modal';

import { Text } from '..';
import { TextType } from '../../../theme/typography';
import { PrivacyLevel } from '../../../types/Privacy';
import { ButtonSize, ColorType, IconType } from '../../../types/ui';
import IconBorder from '../../app/chat/IconBorder';
import { icons } from '../../icons';
import UtilityAction from './UtilityAction';

type IconStyle = {
  color?: ColorType;
  size?: number;
  hoverColor?: ColorType;
  disabled?: boolean;
};

type ModalProps<T> = {
  open: T | null;
  setClose: () => void;
  title?: string;
  titleTextVariant?: TextType;
  titleTextColor?: ColorType;
  titleIcon?: IconType;
  titleIconStyle?: IconStyle;
  titleClassName?: string;
  titleIconActive?: boolean;
  privacyLevel?: PrivacyLevel;
  titlePrivacyLevel?: PrivacyLevel;
  className?: string;
  contentClassName?: string;
  position?: 'default' | 'bottom';
  closeButtonSize?: ButtonSize;
  floatingCloseButton?: boolean;
};

function NewModal<T>({
  open,
  setClose,
  children,
  title,
  titleTextVariant = 'title-1',
  titleTextColor = 'darkGrayOne',
  titleIcon,
  titleIconStyle,
  titleIconActive,
  titlePrivacyLevel = 'public',
  className,
  titleClassName,
  contentClassName,
  position = 'default',
  floatingCloseButton = false,
}: PropsWithChildren<ModalProps<T>>) {
  const t = useT();
  const POSITION_CLASSES_BASE = 'absolute left-[50%] translate-x-[-50%]';
  const POSITION_CENTER = 'top-[50%] translate-y-[-50%] translate-y-[calc(-50%+65px)]';
  const POSITION_CENTER_TABLET = 'top-[50%] translate-y-[-50%] translate-y-[calc(-50%+65px)]';
  const POSITION_CLASS = classNames(
    POSITION_CLASSES_BASE,
    position === 'default' ? POSITION_CENTER : POSITION_CENTER_TABLET,
    position === 'bottom' ? 'maxMobile:bottom-0' : ''
  );

  useEffect(() => {
    if (open) {
      document.body.className = 'overflow-hidden h-screen';
    } else {
      document.body.className = '';
    }
  }, [open]);

  const titleIconComponent = useMemo(() => {
    if (!titleIcon) return;
    const Icon = icons[titleIcon];

    return titleIcon === 'community' ? (
      <div>
        <Icon
          colorOverride={titleIconStyle?.color}
          {...{ hoverColorOverride: !titleIconStyle?.disabled ? titleIconStyle?.hoverColor : undefined }}
          size={titleIconStyle?.size}
        />
      </div>
    ) : (
      <IconBorder color="lightGrayTwo">
        <Icon
          colorOverride={titleIconStyle?.color}
          {...{ hoverColorOverride: !titleIconStyle?.disabled ? titleIconStyle?.hoverColor : undefined }}
          size={titleIconStyle?.size}
          active={titleIconActive}
        />
      </IconBorder>
    );
  }, [
    titleIcon,
    titleIconActive,
    titleIconStyle?.color,
    titleIconStyle?.disabled,
    titleIconStyle?.hoverColor,
    titleIconStyle?.size,
  ]);

  return (
    <>
      {open && (
        <Modal
          appElement={document.getElementById('__next') as HTMLElement}
          isOpen={open !== null}
          onRequestClose={setClose}
          style={{
            /* Need to use style to overwrite react-modal defaults. */
            overlay: {
              zIndex: 100,
              backgroundColor: 'rgba(43, 42, 46, 0.6)',
              overflow: 'none',
            },
          }}
          className={classNames(
            'opacity-100 desktop:max-w-[1084px] max-w-[calc(100vw-40px)] desktop:w-[75%] w-[calc(100vw-40px)] bg-white flex flex-col justify-start items-center rounded-[8px] tablet:max-h-[calc(100svh-144px)] max-h-[calc(100svh-72px)] overflow-y-auto',
            POSITION_CLASS,
            className
          )}
        >
          <div className={classNames(contentClassName, 'self-stretch flex-1 flex flex-row justify-between gap-2')}>
            <div className="flex-1 flex flex-col">
              {title && (
                <div
                  className={classNames('flex flex-row w-full justify-between pb-[14px] items-center', titleClassName)}
                >
                  <div className="self-start flex flex-row gap-2 my-auto items-center">
                    {titleIconComponent}
                    <Text variant={titleTextVariant} color={titleTextColor} privacyLevel={titlePrivacyLevel}>
                      {title}
                    </Text>
                  </div>
                </div>
              )}
              <div className={classNames('flex flex-1 flex-col w-full items-start mb-5 tablet:mb-0')}>{children}</div>
            </div>
            <div className={classNames(floatingCloseButton ? 'absolute top-2 right-5' : '')}>
              <UtilityAction variant="close" aria-label={t('Close')} onClick={setClose} />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default NewModal;
