import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const PhoneIcon = ({ size = 24, colorOverride, hoverColorOverride, className }: IconProps) => {
  const fillClassName = toFillColor(colorOverride);
  const hoverFillClassName = toGroupHoverFillColor(hoverColorOverride);

  const pathClasses = colorOverride || hoverColorOverride ? classNames(fillClassName, hoverFillClassName) : '';

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('flex-shrik-0', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.8866 0.833435L6.01236 0.833435C6.35944 0.833435 6.67018 1.04856 6.79236 1.37342L8.00522 4.59833C8.05875 4.74066 8.07231 4.89492 8.04445 5.0444L7.44832 8.24211C8.16603 9.8934 9.33652 11.0216 11.2908 12.0439L14.4403 11.4337C14.5927 11.4041 14.7504 11.4178 14.8955 11.4731L18.1303 12.7057C18.4534 12.8289 18.6669 13.1387 18.6669 13.4845L18.6669 16.4713C18.6669 17.9463 17.3626 19.1596 15.8248 18.825C13.2697 18.269 8.50623 16.8512 5.16101 13.506C1.95071 10.2957 0.88221 5.87235 0.523395 3.49679C0.298802 2.00986 1.49305 0.833435 2.8866 0.833435ZM5.43545 2.5001L2.8866 2.5001C2.40089 2.5001 2.1153 2.87664 2.17137 3.24787C2.51323 5.51119 3.5107 9.49869 6.33952 12.3275C9.3363 15.3243 13.707 16.6585 16.1791 17.1964C16.5826 17.2842 17.0003 16.983 17.0003 16.4713L17.0003 14.0587L14.5241 13.1151L11.3255 13.7349C11.1448 13.7699 10.9576 13.7441 10.7931 13.6616C8.34998 12.4353 6.73957 10.9672 5.80987 8.64293C5.75121 8.49628 5.73544 8.33598 5.76438 8.18071L6.36341 4.96747L5.43545 2.5001Z"
        className={pathClasses}
      />
    </svg>
  );
};

export default PhoneIcon;
