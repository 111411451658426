import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const LocalLibraryIcon = ({ size = 24, colorOverride }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 17.15V10C3 9.46667 3.19583 9.01667 3.5875 8.65C3.97917 8.28333 4.44167 8.11667 4.975 8.15C6.29167 8.35 7.54583 8.7375 8.7375 9.3125C9.92917 9.8875 11.0167 10.6333 12 11.55C12.9833 10.6333 14.0708 9.8875 15.2625 9.3125C16.4542 8.7375 17.7083 8.35 19.025 8.15C19.5583 8.11667 20.0208 8.28333 20.4125 8.65C20.8042 9.01667 21 9.46667 21 10V17.15C21 17.6833 20.825 18.1375 20.475 18.5125C20.125 18.8875 19.6833 19.0917 19.15 19.125C18.0833 19.2917 17.05 19.5667 16.05 19.95C15.05 20.3333 14.1167 20.8417 13.25 21.475C13.0667 21.625 12.8708 21.7375 12.6625 21.8125C12.4542 21.8875 12.2333 21.925 12 21.925C11.7667 21.925 11.5458 21.8875 11.3375 21.8125C11.1292 21.7375 10.9333 21.625 10.75 21.475C9.88333 20.8417 8.95 20.3333 7.95 19.95C6.95 19.5667 5.91667 19.2917 4.85 19.125C4.31667 19.0917 3.875 18.8875 3.525 18.5125C3.175 18.1375 3 17.6833 3 17.15ZM12 9C10.9 9 9.95833 8.60833 9.175 7.825C8.39167 7.04167 8 6.1 8 5C8 3.9 8.39167 2.95833 9.175 2.175C9.95833 1.39167 10.9 1 12 1C13.1 1 14.0417 1.39167 14.825 2.175C15.6083 2.95833 16 3.9 16 5C16 6.1 15.6083 7.04167 14.825 7.825C14.0417 8.60833 13.1 9 12 9Z"
        className={fillClass}
      />
    </svg>
  );
};

export default LocalLibraryIcon;
