import { useT } from '@transifex/react';
import React, { PropsWithChildren } from 'react';

import { Card, HelpCenterLink, Text } from '.';

const ErrorCard = ({ children }: PropsWithChildren<{}>) => {
  const t = useT();
  return (
    <Card className="px-6 py-8 gap-2">
      <Text privacyLevel="public" variant="h3" color="darkGrayOne">
        {children}
      </Text>
      <HelpCenterLink label={t('Reach out to support')} />
    </Card>
  );
};

export default ErrorCard;
