import { colors } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const RightCircle = ({ size = 26, colorOverride = 'magenta' }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12" stroke={colors[colorOverride]} strokeWidth="2" />
    <path
      d="M11.171 7.92137C11.1167 7.97556 11.0736 8.03993 11.0442 8.1108C11.0148 8.18166 10.9997 8.25764 10.9997 8.33436C10.9997 8.41109 11.0148 8.48706 11.0442 8.55793C11.0736 8.6288 11.1167 8.69317 11.171 8.74735L15.4258 13.0009L11.171 17.2545C11.0615 17.364 11 17.5126 11 17.6675C11 17.8224 11.0615 17.971 11.171 18.0805C11.2806 18.19 11.4291 18.2516 11.584 18.2516C11.7389 18.2516 11.8875 18.19 11.997 18.0805L16.6636 13.4139C16.7179 13.3597 16.761 13.2954 16.7904 13.2245C16.8198 13.1536 16.835 13.0777 16.835 13.0009C16.835 12.9242 16.8198 12.8482 16.7904 12.7774C16.761 12.7065 16.7179 12.6421 16.6636 12.5879L11.997 7.92137C11.9428 7.86705 11.8785 7.82395 11.8076 7.79454C11.7367 7.76514 11.6608 7.75 11.584 7.75C11.5073 7.75 11.4313 7.76514 11.3605 7.79454C11.2896 7.82395 11.2252 7.86705 11.171 7.92137Z"
      fill={colors[colorOverride]}
      stroke={colors[colorOverride]}
    />
  </svg>
);

export default RightCircle;
