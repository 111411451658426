import { T } from '@transifex/react';
import classNames from 'classnames';

import { Text } from '..';
import { BookmarkFilledIcon, BookmarkOutlineIcon } from '../../../icons';

type SaveOnCardProps = {
  saved?: boolean;
};

const SaveOnCard = ({ saved = false }: SaveOnCardProps) => {
  return (
    <>
      <BookmarkFilledIcon
        size={24}
        colorOverride="linkBlue"
        className={classNames(saved ? 'block' : 'hidden group-hover:block')}
      />
      <BookmarkOutlineIcon
        size={24}
        colorOverride="linkBlue"
        className={classNames(saved ? 'hidden' : 'block group-hover:hidden')}
      />
      <Text variant="body-1-emphasis" color="darkGrayOne">
        {saved ? <T _str="Saved" /> : <T _str="Save" />}
      </Text>
    </>
  );
};

export default SaveOnCard;
