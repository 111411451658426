import classNames from 'classnames';
import React from 'react';

import { toFillColor, toGroupHoverFillColor, toHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

function Check({ size = 24, colorOverride, hoverColorOverride, className }: IconProps) {
  const fillClassName = toFillColor(colorOverride);
  const hoverFillClassName = toHoverFillColor(hoverColorOverride);
  const groupHoverFillClassName = toGroupHoverFillColor(hoverColorOverride);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, 'group flex-shrink-0')}
    >
      <path
        d="M19.5299 6.41002C19.8189 6.68324 19.9876 7.06005 19.9989 7.4576C20.0101 7.85515 19.863 8.24089 19.5899 8.53002L11.0899 17.53C10.9522 17.6756 10.7866 17.7921 10.6031 17.8727C10.4195 17.9532 10.2217 17.9962 10.0213 17.999C9.82085 18.0018 9.62189 17.9644 9.43617 17.889C9.25044 17.8136 9.08171 17.7017 8.93994 17.56L4.43994 13.06C4.17498 12.7757 4.03073 12.3996 4.03759 12.011C4.04444 11.6224 4.20187 11.2516 4.47669 10.9768C4.75152 10.7019 5.12229 10.5445 5.51089 10.5377C5.89949 10.5308 6.27559 10.6751 6.55994 10.94L9.96994 14.348L17.4099 6.47002C17.6832 6.18101 18.06 6.01234 18.4575 6.00109C18.8551 5.98984 19.2408 6.13693 19.5299 6.41002Z"
        className={classNames(fillClassName, hoverFillClassName, groupHoverFillClassName)}
      />
    </svg>
  );
}

export default Check;
