import { T, useT } from '@transifex/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';

import { Text, TextLink } from '..';
import { useAuthenticatedContext } from '../../../hooks/contexts';
import { DUO_HOME, MEMBER_ACTIVATE_SURVEY, MEMBER_CHAT, MEMBER_LOGIN } from '../../../routes';
import { MEMBER_HOME } from '../../../routes';
import { AppView } from '../../../types/AppView';
import { DigitalDuo, GlobeIcon } from '../../icons';
import Logo from '../../icons/Logo';
import Container from '../../layout/new/Container';
import RecommendationsMobileQuickLinkButton from '../../layout/new/RecommendationsMobileQuickLinkButton';
import ResourcesMobileQuickLinkButton from '../../layout/new/ResourcesMobileQuickLinkButton';
import ImpersonationBanner from '../ImpersonationBanner';
import { Button } from '../new';
import HeaderIconLink from './HeaderIconLink';

export type Theme = 'light' | 'dark';

export type Alignment = 'center' | 'left';

export type ShowQuickLink = 'welcome' | 'loggedIn';

export type ShowMenu = 'mobileOnly' | 'always';

export type HeaderProps = {
  theme?: Theme;
  alignment?: Alignment;
  showMenu?: ShowMenu;
  showQuickLink?: ShowQuickLink;
  className?: string;
  openMenu?: () => void;
  chatLayout?: boolean;
  currentLocale?: string;
  switchLocale?: () => void;
  mobileQuickLinksType?: 'resources' | 'recommendations';
  appView?: AppView;
};

const Header = ({
  theme = 'light',
  alignment = 'left',
  showMenu,
  showQuickLink,
  className = '',
  openMenu,
  chatLayout = false,
  currentLocale,
  switchLocale,
  mobileQuickLinksType,
  appView = 'member',
}: HeaderProps) => {
  const t = useT();
  const router = useRouter();
  const [quickLinkOpen, setQuickLinkOpen] = useState(false);

  const quickLinksPadding = `pb-[52px]`;

  const iconColor = theme === 'light' ? 'purple' : 'white';
  const iconHoverColor = theme === 'light' ? 'magenta' : 'lightGrayOne';

  const includesApp = router.pathname.includes('/app');

  const goHome = useCallback(
    () => (appView === 'member' ? router.push(MEMBER_HOME) : router.push(DUO_HOME)),
    [appView, router]
  );
  const goChat = useCallback(() => router.push(MEMBER_CHAT), [router]);

  const { userSession } = useAuthenticatedContext();

  const isImpersonating = userSession?.impersonator !== undefined;

  return (
    <div className={classNames(mobileQuickLinksType ? quickLinksPadding : '', 'w-full')}>
      <div className="w-screen z-30">
        {isImpersonating && <ImpersonationBanner userSession={userSession} />}
        <div className={classNames(theme === 'light' ? 'bg-white' : 'bg-purple', 'shadow-md', 'w-full')}>
          <Container
            className={classNames(
              'h-[72px]',
              showMenu ? 'justify-between' : alignment === 'center' ? 'justify-center' : 'justify-start',
              'flex flex-row items-center py-[10px]',
              className
            )}
          >
            <div
              className={classNames(
                'desktop:w-[360px] justify-start',
                showMenu === 'mobileOnly' ? 'block tablet:hidden' : ''
              )}
            >
              {showMenu && (
                <HeaderIconLink
                  icon="menuHamburger"
                  label="Menu"
                  iconColor={iconColor}
                  iconHoverColor={iconHoverColor}
                  onClick={openMenu}
                />
              )}
            </div>
            <Logo onClick={includesApp ? goHome : undefined} />
            <div
              className={classNames(
                'desktop:w-[360px] flex flex-row gap-6 justify-end',
                showMenu === 'mobileOnly' ? 'invisible tablet:visible' : ''
              )}
            >
              {showQuickLink === 'loggedIn' && (
                <button
                  aria-label={t('Navigate to Digital Duo')}
                  className={classNames('group flex flex-col items-center', className)}
                  onClick={goChat}
                >
                  <DigitalDuo size={32} />
                  <Text variant="subtitle-2" color={iconColor} className={'group-hover:text-magenta'}>
                    <T _str="Digital Duo" />
                  </Text>
                </button>
              )}
              {showQuickLink === 'welcome' && (
                <>
                  <Button variant="secondary" size="main" href={MEMBER_LOGIN}>
                    <T _str="Log In" />
                  </Button>
                  <Button variant="primary" size="main" href={`${MEMBER_ACTIVATE_SURVEY}`}>
                    <T _str="Sign Up" />
                  </Button>
                  {currentLocale && (
                    <div className="group">
                      <TextLink
                        color="darkGrayTwo"
                        variant="link-1"
                        className="mt-[12px] gap-2"
                        invertUnderline
                        sameTab
                        onClick={() => {
                          if (switchLocale) {
                            switchLocale();
                          }
                        }}
                      >
                        {currentLocale === 'en' ? 'ESP' : 'EN'}
                        <GlobeIcon colorOverride="darkGrayTwo" hoverColorOverride="linkHoverBlue" />
                      </TextLink>
                    </div>
                  )}
                </>
              )}
            </div>
          </Container>
        </div>

        {mobileQuickLinksType === 'resources' && (
          <>
            <ResourcesMobileQuickLinkButton quickLinkeIsOpen={quickLinkOpen} setQuickLinkIsOpen={setQuickLinkOpen} />
          </>
        )}
        {mobileQuickLinksType === 'recommendations' && (
          <>
            <RecommendationsMobileQuickLinkButton
              setQuickLinkIsOpen={setQuickLinkOpen}
              quickLinkeIsOpen={quickLinkOpen}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
