import classNames from 'classnames';

import { toFillColor, toStrokeColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const ShieldWithHeart = ({ size = 24, colorOverride = 'purple', className }: IconProps) => {
  const fillClassName = toFillColor(colorOverride);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      className={classNames('group', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_6517_1937"
        className="maskType:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_6517_1937)">
        <path
          d="M8 10.8999C8 11.8332 8.45833 12.7249 9.375 13.5749C10.2917 14.4249 11.0583 15.1332 11.675 15.6999C11.775 15.7832 11.8875 15.8249 12.0125 15.8249C12.1375 15.8249 12.25 15.7832 12.35 15.6999C12.95 15.1499 13.7083 14.4374 14.625 13.5624C15.5417 12.6874 16 11.7999 16 10.8999C16 10.2999 15.7833 9.78324 15.35 9.3499C14.9167 8.91657 14.4 8.6999 13.8 8.6999C13.45 8.6999 13.1125 8.77074 12.7875 8.9124C12.4625 9.05407 12.2 9.2499 12 9.4999C11.8 9.2499 11.5417 9.05407 11.225 8.9124C10.9083 8.77074 10.5667 8.6999 10.2 8.6999C9.6 8.6999 9.08333 8.91657 8.65 9.3499C8.21667 9.78324 8 10.2999 8 10.8999ZM12 21.8999C11.8833 21.8999 11.775 21.8916 11.675 21.8749C11.575 21.8582 11.475 21.8332 11.375 21.7999C9.125 21.0499 7.33333 19.6624 6 17.6374C4.66667 15.6124 4 13.4332 4 11.0999V6.3749C4 5.95824 4.12083 5.58324 4.3625 5.2499C4.60417 4.91657 4.91667 4.6749 5.3 4.5249L11.3 2.2749C11.5333 2.19157 11.7667 2.1499 12 2.1499C12.2333 2.1499 12.4667 2.19157 12.7 2.2749L18.7 4.5249C19.0833 4.6749 19.3958 4.91657 19.6375 5.2499C19.8792 5.58324 20 5.95824 20 6.3749V11.0999C20 13.4332 19.3333 15.6124 18 17.6374C16.6667 19.6624 14.875 21.0499 12.625 21.7999C12.525 21.8332 12.425 21.8582 12.325 21.8749C12.225 21.8916 12.1167 21.8999 12 21.8999ZM12 19.8999C13.7333 19.3499 15.1667 18.2499 16.3 16.5999C17.4333 14.9499 18 13.1166 18 11.0999V6.3749L12 4.1249L6 6.3749V11.0999C6 13.1166 6.56667 14.9499 7.7 16.5999C8.83333 18.2499 10.2667 19.3499 12 19.8999Z"
          className={fillClassName}
        />
      </g>
    </svg>
  );
};

export default ShieldWithHeart;
