import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor, toGroupHoverStrokeColor, toStrokeColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const HelpPageIcon = ({ size = 32, colorOverride = 'white', hoverColorOverride = 'lightGrayOne' }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const strokeClass = toStrokeColor(colorOverride);
  const hoverFill = toGroupHoverFillColor(hoverColorOverride);
  const hoverStroke = toGroupHoverStrokeColor(hoverColorOverride);
  const combinedClass = classNames(fillClass, strokeClass, hoverFill, hoverStroke);
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0625 22.5958C15.0625 23.2733 14.811 23.9166 14.3732 24.3858C13.9364 24.8538 13.3517 25.1096 12.75 25.1096C12.1483 25.1096 11.5636 24.8538 11.1268 24.3858C10.689 23.9166 10.4375 23.2733 10.4375 22.5958C10.4375 21.9184 10.689 21.2751 11.1268 20.8059C11.5636 20.3379 12.1483 20.082 12.75 20.082C13.3517 20.082 13.9364 20.3379 14.3732 20.8059C14.811 21.2751 15.0625 21.9184 15.0625 22.5958ZM26.3125 22.5958C26.3125 23.2733 26.061 23.9166 25.6232 24.3858C25.1864 24.8538 24.6017 25.1096 24 25.1096C23.3983 25.1096 22.8136 24.8538 22.3768 24.3858C21.939 23.9166 21.6875 23.2733 21.6875 22.5958C21.6875 21.9184 21.939 21.2751 22.3768 20.8059C22.8136 20.3379 23.3983 20.082 24 20.082C24.6017 20.082 25.1864 20.3379 25.6232 20.8059C26.061 21.2751 26.3125 21.9184 26.3125 22.5958ZM36.8732 24.3858C36.4364 24.8538 35.8517 25.1096 35.25 25.1096C34.6483 25.1096 34.0636 24.8538 33.6268 24.3858C33.189 23.9166 32.9375 23.2733 32.9375 22.5958C32.9375 21.9184 33.189 21.2751 33.6268 20.8059C34.0636 20.3379 34.6483 20.082 35.25 20.082C35.8517 20.082 36.4364 20.3379 36.8732 20.8059C37.311 21.2751 37.5625 21.9184 37.5625 22.5958C37.5625 23.2733 37.311 23.9166 36.8732 24.3858Z"
        className={combinedClass}
      />
      <path
        d="M7.58906 46.1134L7.64531 46.1013C12.7922 45.0073 15.9366 43.5637 17.3991 42.7711C19.5523 43.3865 21.7715 43.6965 24 43.6933C36.4256 43.6933 46.5 34.248 46.5 22.5966C46.5 10.9453 36.4256 1.5 24 1.5C11.5744 1.5 1.5 10.9453 1.5 22.5966C1.5 27.9009 3.58969 32.7532 7.04063 36.4602C6.79919 38.8414 6.30474 41.1852 5.56687 43.4462L5.55844 43.4793C5.34875 44.1269 5.11988 44.7671 4.87219 45.3991C4.65 45.9597 5.08031 46.5865 5.64 46.4901C6.29155 46.3759 6.94132 46.2503 7.58906 46.1134ZM9.83906 36.7465C9.87846 36.3052 9.82655 35.8601 9.68702 35.4429C9.5475 35.0256 9.3238 34.6464 9.03188 34.3324C6.05062 31.1257 4.3125 27.0209 4.3125 22.5966C4.3125 12.9766 12.7612 4.51381 24 4.51381C35.2388 4.51381 43.6875 12.9766 43.6875 22.5966C43.6875 32.2197 35.2388 40.6795 24 40.6795C22.0175 40.6826 20.0431 40.407 18.1275 39.8597C17.4581 39.6675 16.746 39.7469 16.1278 40.0828C15.0394 40.6735 12.6403 41.8006 8.71969 42.7741C9.26665 40.8065 9.6414 38.7886 9.83906 36.7465Z"
        className={combinedClass}
        strokeWidth="1.25"
      />
    </svg>
  );
};

export default HelpPageIcon;
