import { useT } from '@transifex/react';
import classNames from 'classnames';
import { memo, useMemo } from 'react';

import { IconButton, Text, TextLink } from '..';
import { findEmailAddressesInString, findPhoneNumbersInString } from '../../../utils/misc';
import { replaceStringsWithComponents } from '../../../utils/ui';
import { PhoneNumberLink } from './PhoneNumberLink';

type Props = {
  className?: string;
  label: string;
  emptyMessage?: string;
  content?: React.ReactNode;
  direction?: 'horizontal' | 'vertical';
  minHeight?: number;
  labelClassName?: string;
  editable?: boolean;
  onEdit?: () => void;
  editLabel?: string;
  truncate?: boolean;
};

export const Info = memo(
  ({
    className,
    label,
    content,
    emptyMessage,
    direction = 'vertical',
    editable = false,
    onEdit,
    editLabel,
    labelClassName,
    truncate = false,
  }: Props) => {
    const t = useT();
    const missingContent = useMemo(() => !content, [content]);
    const memberInformation = useMemo(() => {
      if (typeof content !== 'string') return content;

      const phones = content
        .split('\n')
        .map(findPhoneNumbersInString)
        .reduce((previousPhone, currentPhone) => [...previousPhone, ...currentPhone], []);
      const emails = content
        .split('\n')
        .map(findEmailAddressesInString)
        .reduce((previousEmail, currentEmail) => [...previousEmail, ...currentEmail], []);

      const phoneNumberLinks = [...new Set(phones)].reduce(
        (previousPhoneLink, currentPhoneLink) => ({
          ...previousPhoneLink,
          [currentPhoneLink]: <PhoneNumberLink number={currentPhoneLink} />,
        }),
        {}
      );
      const emailLinks = [...new Set(emails)].reduce(
        (previousEmailLink, currentEmailLink) => ({
          ...previousEmailLink,
          [currentEmailLink]: (
            <TextLink
              privacyLevel="pii"
              variant="body2"
              link={`https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(currentEmailLink)}`}
            >
              {currentEmailLink}
            </TextLink>
          ),
        }),
        {}
      );

      return replaceStringsWithComponents(content, Object.assign(phoneNumberLinks, emailLinks));
    }, [content]);

    return (
      <div
        className={classNames(
          className,
          'flex',
          direction === 'vertical' ? 'flex-col' : 'flex-row items-start gap-[16px]'
        )}
      >
        <div
          className={classNames(
            'flex items-center gap-2 min-w-[fit-content]',
            direction === 'vertical' && 'mb-1',
            labelClassName
          )}
        >
          <Text privacyLevel="public" variant="body1" color="darkGrayOne">
            {label}
          </Text>
          {editable && <IconButton icon="edit" className="p-[1px]" onClick={onEdit} />}
        </div>
        <Text privacyLevel="pii" variant="body2" color="darkGrayTwo" preWrap truncate={truncate}>
          {memberInformation}
        </Text>
        {missingContent && emptyMessage && (
          <Text privacyLevel="public" variant="body3" color="darkGrayTwo">
            {emptyMessage}
          </Text>
        )}
        {missingContent && onEdit && (
          <TextLink privacyLevel="public" variant="body3" onClick={onEdit}>
            {editLabel ? editLabel : t('Add {label}.', { label })}
          </TextLink>
        )}
      </div>
    );
  }
);

Info.displayName = 'Info';
