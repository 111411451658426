import classNames from 'classnames';

import { toStrokeColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const WarningCircleOutLine = ({ size = 24, colorOverride, className }: IconProps) => {
  const strokeColor = toStrokeColor(colorOverride);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, 'flex-shrink-0')}
    >
      <path d="M12 11.5V16.5" className={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12 7.51L12.01 7.49889"
        className={strokeColor}
        stroke="#0A0A0B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        className={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WarningCircleOutLine;
