import classNames from 'classnames';
import React from 'react';

import { toFillColor, toGroupHoverFillColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const LinkedIn = ({ className, colorOverride = 'linkBlue', hoverColorOverride, size = 44 }: IconProps) => {
  const fillClassName = toFillColor(colorOverride);
  const hoverFillClassName = toGroupHoverFillColor(hoverColorOverride);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('group', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9539 0.333466H2.17207C1.18199 0.333466 0.333344 1.17791 0.333344 2.1631V23.8372C0.333344 24.8224 1.18199 25.6668 2.17207 25.6668H23.8125C24.8026 25.6668 25.6512 24.8224 25.6512 23.8372V2.1631C25.7926 1.17791 24.944 0.333466 23.9539 0.333466ZM7.82969 21.8668H4.15224V9.76309H7.97113V21.8668H7.82969ZM5.99096 8.21495C4.718 8.21495 3.86936 7.22976 3.86936 5.96309C3.86936 4.69643 4.85944 3.85198 5.99096 3.85198C7.26393 3.85198 8.11257 4.83717 8.11257 5.96309C8.25401 7.22976 7.26393 8.21495 5.99096 8.21495ZM21.9737 21.8668H18.1549V15.9557C18.1549 14.5483 18.1549 12.7186 16.1747 12.7186C14.1945 12.7186 13.9116 14.2668 13.9116 15.8149V21.7261H10.2342V9.76309H13.9116V11.452C14.4774 10.4668 15.6089 9.48161 17.4476 9.48161C21.2665 9.48161 21.9737 12.0149 21.9737 15.252V21.8668Z"
        className={classNames(fillClassName, hoverFillClassName)}
      />
    </svg>
  );
};

export default LinkedIn;
