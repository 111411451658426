import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor, toGroupHoverStrokeColor, toStrokeColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const NewDown = ({ size = 24, colorOverride, hoverColorOverride, className, active = false }: IconProps) => {
  const fillColor = toFillColor(colorOverride);
  const strokeColor = toStrokeColor(colorOverride);
  const hoverFillColor = toGroupHoverFillColor(hoverColorOverride);
  const hoverStrokeColor = toGroupHoverStrokeColor(hoverColorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 8L12 16L20 8H4Z"
        fill="magenta"
        className={classNames(className, fillColor, strokeColor, ...(active ? [hoverFillColor, hoverStrokeColor] : []))}
      />
    </svg>
  );
};

export default NewDown;
