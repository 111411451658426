import { T, UT, useT } from '@transifex/react';
import classNames from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { Popover } from 'react-tiny-popover';

import { useCallContext } from '../../../../hooks/contexts';
import { toBorderColor } from '../../../../theme/colors';
import { CallStatus } from '../../../../types/Call';
import { ColorType } from '../../../../types/ui';
import { callStatusIconComponentMap, callStatusTextMap, getNonMemberNumbers } from '../../../../utils/Call';
import { formatPhone } from '../../../../utils/Member';
import HangupOptions from '../../../HangupOptions';
import { Avatar, IconButton, Text } from '../../../common';
import { CallIcon } from '../../../icons';
import Circle from '../../../icons/Circle';

export const CallBanner = ({ className }: { className?: string }) => {
  const {
    memberName,
    primaryCall,
    memberPhoneNumber,
    conference,
    toggleMicrophone,
    dialpadState,
    setDialpadState,
    callStatus = 'initiated',
  } = useCallContext();
  const t = useT();
  const [open, setOpen] = useState(false);
  const popoverContainer = useRef<HTMLDivElement>(null);

  const getFormattedMemberPhone = useCallback(() => {
    return memberPhoneNumber && formatPhone(memberPhoneNumber);
  }, [memberPhoneNumber]);

  const getColorByCallStatus = (callStatus: CallStatus, defaultColor: ColorType = 'darkGrayThree'): ColorType => {
    if (callStatus === 'in-progress') {
      return 'mediumGreen';
    }
    if (callStatus === 'failed') {
      return 'errorRedTwo';
    }
    return defaultColor;
  };

  const renderInitialOuboundText = useCallback(() => {
    let initialText = memberName || getFormattedMemberPhone() || '';
    if (!initialText) {
      const nonMemberNumbers = getNonMemberNumbers(conference, memberPhoneNumber);
      initialText = nonMemberNumbers[0];
    }
    return (
      <UT
        _str="Outbound call with <span className='text-magenta ml-[4px]'>{initialText}</span>"
        initialText={initialText}
      />
    );
  }, [conference, getFormattedMemberPhone, memberName, memberPhoneNumber]);

  const renderOutboundCallText = useCallback(() => {
    const nonMemberNumbers = getNonMemberNumbers(conference, memberPhoneNumber);
    if (nonMemberNumbers?.length <= 0) {
      return;
    }
    if (!memberPhoneNumber) {
      if (nonMemberNumbers?.length <= 1) {
        return;
      }
      nonMemberNumbers.splice(0, 1);
    }

    return nonMemberNumbers
      .map((phoneNumber) => (
        <UT
          key={phoneNumber}
          _str=" and <span className='text-magenta ml-[4px]''>{phoneNumber}</span>"
          phoneNumber={phoneNumber}
        />
      ))
      .reduce((prev, current) => (
        <>
          {prev}
          {current}
        </>
      ));
  }, [conference, memberPhoneNumber]);

  const renderPopover = useCallback(
    () => (
      <Popover
        containerStyle={{ zIndex: '30' }}
        ref={popoverContainer}
        isOpen={open}
        onClickOutside={() => setOpen(false)}
        positions={['top', 'left']}
        padding={2}
        align="start"
        content={() => (
          <HangupOptions
            memberName={memberName}
            memberPhoneNumber={memberPhoneNumber}
            phoneNumbers={getNonMemberNumbers(conference, memberPhoneNumber)}
          />
        )}
      >
        <div className="mr-[24px]">
          <IconButton
            label={t('Open hangup options')}
            icon="hangUp"
            size="xLarge"
            color="purple"
            iconSize={28}
            className="border-2 border-purple"
            onClick={() => {
              setOpen((open) => !open);
            }}
          />
        </div>
      </Popover>
    ),
    [open, t, memberName, memberPhoneNumber, conference]
  );

  return (
    <div
      className={classNames(
        `flex justify-between px-[32px] py-[24px] border-mediumTeal border-2 rounded-t-lg`,
        toBorderColor(getColorByCallStatus(callStatus, 'mediumTeal')),
        className
      )}
    >
      <div className="flex items-center">
        {memberName && (
          <Avatar name={memberName} outline="purple" textColor="magenta" size={48} className="mr-[16px]" />
        )}
        {!memberName && (
          <Circle stroke="mediumTeal" color="mediumTeal" size={48} className="mr-[16px]">
            <CallIcon size={24} colorOverride="white" className="absolute" />
          </Circle>
        )}
        <div>
          <Text privacyLevel="pii" variant="h4">
            {renderInitialOuboundText()}
            {renderOutboundCallText()}
          </Text>
          <span className="inline-flex items-center">
            {memberName && (
              <>
                <Text privacyLevel="pii" variant="label1">
                  {getFormattedMemberPhone()}
                </Text>
                <Circle className="mx-[8px]" color="darkGrayTwo" stroke="darkGrayTwo" />
              </>
            )}
            <>
              <Text
                privacyLevel="public"
                className="pl-[8px] mr-[8px]"
                variant="label2"
                color={getColorByCallStatus(callStatus)}
              >
                {callStatusTextMap[callStatus]}
              </Text>
              {callStatusIconComponentMap[callStatus]}
            </>
          </span>
        </div>
      </div>
      <div className="flex items-center gap-[24px]">
        <IconButton
          label={t('Open Dialpad')}
          icon={'dialPad'}
          size="xLarge"
          color="purple"
          iconSize={24}
          className="border-2 border-purple"
          onClick={() => dialpadState === 'close' && setDialpadState('open')}
        />
        <IconButton
          label={t('Mute Call')}
          icon={!primaryCall?.isMuted() ? 'microphone' : 'microphoneMute'}
          size="xLarge"
          color="purple"
          iconSize={24}
          className="border-2 border-purple"
          onClick={() => toggleMicrophone()}
        />
        {renderPopover()}
      </div>
    </div>
  );
};
