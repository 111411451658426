import moment from 'moment';
import React, { PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';

import { useTodoList } from '../hooks/api';
import { ActionItem, ReadinessPriority } from '../types/ActionItem';

export type ActionFormType = 'edit' | 'new';

type AllRecommendationsContextProps = {
  userId: string;
  scrollRef?: React.RefObject<HTMLDivElement> | null;
};

export const AllRecommendationsContext = createContext<{
  loading: boolean;
  error: boolean;
  sortedRecommendations: ActionItem[];
  completedRecommendations: ActionItem[];
  scrollRef: React.RefObject<HTMLDivElement> | null;
}>({
  loading: true,
  error: false,
  sortedRecommendations: [],
  completedRecommendations: [],
  scrollRef: null,
});

function AllRecommendationsContextWrapper({
  userId,
  scrollRef = null,
  children,
}: PropsWithChildren<AllRecommendationsContextProps>) {
  const [sortedRecommendations, setSortedRecommendations] = useState<ActionItem[]>([]);

  const todoListQuery = useTodoList({ memberId: userId ? userId.toString() : undefined });
  const todoList = useMemo(() => todoListQuery.data ?? [], [todoListQuery.data]);

  useEffect(() => {
    const recommendations = todoList.filter((item) => item.type === 'recommended' && item.source === 'campaignTool');
    const openRecommendations = recommendations.filter((item) => item?.status ?? 'open' === 'open');

    // We only want to sort on initial load and keeping this in state because design doesn't want to refresh the order based on the readiness.
    const sortedRecommendations = openRecommendations.sort(
      (itemA, itemB) =>
        (itemA.readinessStatus ? ReadinessPriority[itemA.readinessStatus] : 4) -
        (itemB.readinessStatus ? ReadinessPriority[itemB.readinessStatus] : 4)
    );

    setSortedRecommendations((currentSortedRecommendations) => {
      return openRecommendations.length > currentSortedRecommendations.length
        ? sortedRecommendations
        : currentSortedRecommendations.map((currentRecommendation) => ({
            ...recommendations.filter(
              (recommendation: ActionItem) => recommendation.id === currentRecommendation.id
            )[0],
          }));
    });
  }, [todoList]);

  const completedRecommendations = useMemo(
    () =>
      todoList
        .filter((item) => item.status === 'completed')
        .sort((itemA, itemB) => (moment(itemA.closedAt).isBefore(itemB.closedAt) ? -1 : 1)),
    [todoList]
  );

  const loading = useMemo(() => todoListQuery.isLoading, [todoListQuery.isLoading]);

  const error = useMemo(() => todoListQuery.isError, [todoListQuery.isError]);

  const value = useMemo(() => {
    return {
      loading,
      error,
      sortedRecommendations,
      completedRecommendations,
      scrollRef,
    };
  }, [loading, error, sortedRecommendations, completedRecommendations, scrollRef]);

  return <AllRecommendationsContext.Provider value={value}>{children}</AllRecommendationsContext.Provider>;
}

export default AllRecommendationsContextWrapper;
