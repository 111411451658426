import React from 'react';

import { colors } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const Right = ({ size = 16, colorOverride = 'white' }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.48542 3.48436C5.43713 3.53253 5.39882 3.58974 5.37268 3.65274C5.34654 3.71573 5.33309 3.78326 5.33309 3.85147C5.33309 3.91967 5.34654 3.9872 5.37268 4.05019C5.39882 4.11319 5.43713 4.1704 5.48542 4.21857L9.26742 7.99953L5.48542 11.7805C5.38806 11.8778 5.33336 12.0099 5.33336 12.1476C5.33336 12.2853 5.38806 12.4173 5.48542 12.5147C5.58278 12.6121 5.71483 12.6667 5.85252 12.6667C5.99021 12.6667 6.12227 12.6121 6.21963 12.5147L10.3677 8.36663C10.416 8.31846 10.4543 8.26125 10.4804 8.19825C10.5066 8.13526 10.52 8.06773 10.52 7.99953C10.52 7.93132 10.5066 7.86379 10.4804 7.8008C10.4543 7.73781 10.416 7.68059 10.3677 7.63242L6.21963 3.48436C6.17146 3.43608 6.11424 3.39777 6.05125 3.37163C5.98826 3.34549 5.92073 3.33203 5.85252 3.33203C5.78432 3.33203 5.71679 3.34549 5.6538 3.37163C5.5908 3.39777 5.53359 3.43608 5.48542 3.48436Z"
        stroke={colors[colorOverride]}
        fill={colors[colorOverride]}
      />
    </svg>
  );
};

export default Right;
