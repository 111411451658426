import classNames from 'classnames';
import React from 'react';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const AlertIcon = ({ size = 24, colorOverride, className }: IconProps) => (
  <svg
    className={classNames(className, 'flex-shrink-0')}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.33301C4.31999 1.33301 1.33333 4.31967 1.33333 7.99967C1.33333 11.6797 4.31999 14.6663 8 14.6663C11.68 14.6663 14.6667 11.6797 14.6667 7.99967C14.6667 4.31967 11.68 1.33301 8 1.33301ZM8 8.66634C7.63333 8.66634 7.33333 8.36634 7.33333 7.99967V5.33301C7.33333 4.96634 7.63333 4.66634 8 4.66634C8.36666 4.66634 8.66666 4.96634 8.66666 5.33301V7.99967C8.66666 8.36634 8.36666 8.66634 8 8.66634ZM7.33333 9.99967V11.333H8.66666V9.99967H7.33333Z"
      className={toFillColor(colorOverride)}
    />
  </svg>
);
