import { toFillColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const ChatFinished = ({ size = 24, colorOverride }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2613_56597)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.07754 9.41074C6.40297 9.08531 6.93061 9.08531 7.25605 9.41074L9.16679 11.3215L12.7442 7.74408C13.0696 7.41864 13.5973 7.41864 13.9227 7.74408C14.2482 8.06951 14.2482 8.59715 13.9227 8.92259L9.75605 13.0893C9.43061 13.4147 8.90297 13.4147 8.57754 13.0893L6.07754 10.5893C5.7521 10.2638 5.7521 9.73618 6.07754 9.41074Z"
          className={fillClass}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0007 2.49992C5.85851 2.49992 2.50065 5.85778 2.50065 9.99992C2.50065 11.3674 2.86586 12.647 3.50351 13.7493C3.6031 13.9215 3.63783 14.1236 3.60142 14.3191L3.12556 16.875L5.68145 16.3991C5.87699 16.3627 6.07909 16.3975 6.25126 16.4971C7.35356 17.1347 8.63319 17.4999 10.0007 17.4999C14.1428 17.4999 17.5006 14.1421 17.5006 9.99992C17.5006 5.85778 14.1428 2.49992 10.0007 2.49992ZM0.833984 9.99992C0.833984 4.93731 4.93804 0.833252 10.0007 0.833252C15.0633 0.833252 19.1673 4.93731 19.1673 9.99992C19.1673 15.0625 15.0633 19.1666 10.0007 19.1666C8.44452 19.1666 6.97691 18.7782 5.69167 18.0925L2.23652 18.7358C1.96646 18.7861 1.68897 18.7001 1.49473 18.5058C1.30049 18.3116 1.21445 18.0341 1.26473 17.7641L1.90802 14.3089C1.2224 13.0237 0.833984 11.556 0.833984 9.99992Z"
          className={fillClass}
        />
      </g>
      <defs>
        <clipPath id="clip0_2613_56597">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChatFinished;
