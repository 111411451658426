import { toStrokeColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const CheckedCircleOutLine = ({ size = 24, colorOverride = 'darkGrayOne' }: IconProps) => {
  const strokeColor = toStrokeColor(colorOverride);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="flex-shrink-0"
    >
      <path
        className={strokeColor}
        d="M7 12.5L10 15.5L17 8.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        className={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckedCircleOutLine;
