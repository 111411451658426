import classNames from 'classnames';
import React from 'react';

import { toFillColor, toStrokeColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const MenuBookmarkIcon = ({ size = 20, colorOverride, active }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const strokeClass = toStrokeColor(colorOverride);
  const activeStandardClass = !active ? 'group-hover:invisible' : '';
  const standardClass = `${!active ? 'visible' : 'invisible'} ${activeStandardClass}`;
  const activeAlternateClass = !active ? 'group-hover:visible' : '';
  const alternateClass = `${!active ? 'invisible' : 'visible'} ${activeAlternateClass}`;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('group')}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        strokeWidth={1.5}
        d="M3.33301 4.16669C3.33301 2.78598 4.4523 1.66669 5.83301 1.66669H14.1663C15.5471 1.66669 16.6663 2.78598 16.6663 4.16669V17.5C16.6663 17.8049 16.4999 18.0854 16.2323 18.2314C15.9648 18.3775 15.6388 18.3658 15.3824 18.201L10.4503 15.0304C10.1758 14.8539 9.82355 14.8539 9.54904 15.0304L4.61697 18.201C4.36055 18.3658 4.03458 18.3775 3.76702 18.2314C3.49946 18.0854 3.33301 17.8049 3.33301 17.5V4.16669Z"
        className={classNames(strokeClass, standardClass)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33301 4.16669C3.33301 2.78598 4.4523 1.66669 5.83301 1.66669H14.1663C15.5471 1.66669 16.6663 2.78598 16.6663 4.16669V17.5C16.6663 17.8049 16.4999 18.0854 16.2323 18.2314C15.9648 18.3775 15.6388 18.3658 15.3824 18.201L10.4503 15.0304C10.1758 14.8539 9.82355 14.8539 9.54904 15.0304L4.61697 18.201C4.36055 18.3658 4.03458 18.3775 3.76702 18.2314C3.49946 18.0854 3.33301 17.8049 3.33301 17.5V4.16669Z"
        className={classNames(strokeClass, fillClass, alternateClass)}
      />
    </svg>
  );
};

export default MenuBookmarkIcon;
