import classNames from 'classnames';
import React, { PropsWithChildren, useMemo } from 'react';

import { toTextColor } from '../../theme/colors';
import { TextType, textVariantClassName } from '../../theme/typography';
import { PrivacyLevel } from '../../types/Privacy';
import { ColorType } from '../../types/ui';

export type TextCase = 'upper' | 'lower' | 'normal' | 'capitalize';

type FontWeight = 'regular' | 'semibold' | 'bold' | 'medium' | 'thin';

export type Alignment = 'left' | 'right' | 'center';

export interface TextProps<C extends React.ElementType> {
  className?: string;
  variant?: TextType;
  color?: ColorType;
  onClick?: () => void;
  onKeyDown?: (event: any) => void;
  role?: string;
  display?: 'flex' | 'inline';
  tabIndex?: number;
  preWrap?: boolean;
  truncate?: boolean;
  noWrap?: boolean;
  textCase?: TextCase;
  component?: C;
  privacyLevel?: PrivacyLevel;
  italic?: boolean;
  fontWeight?: FontWeight;
  alignment?: Alignment;
}

function Text<C extends React.ElementType>({
  children,
  className = '',
  variant = 'body-1',
  color = 'darkGrayOne',
  display = 'flex',
  onClick,
  onKeyDown,
  role = 'text',
  tabIndex = -1,
  preWrap = false,
  truncate = false,
  noWrap = false,
  textCase = ['label1', 'label2', 'label3', 'overline'].includes(variant) ? 'upper' : 'normal',
  component,
  privacyLevel = 'phi',
  italic,
  fontWeight,
  alignment,
}: PropsWithChildren<TextProps<C>>): JSX.Element {
  const textClassName = useMemo(() => textVariantClassName(variant), [variant]);

  const colorClassName = useMemo(() => toTextColor(color), [color]);

  const defaultVariantComponent = useMemo(() => {
    switch (variant) {
      case 'h1':
        return 'h1';
      case 't1':
        return 'h1';
      case 'h2':
        return 'h2';
      case 't2':
        return 'h2';
      case 'h3':
        return 'h3';
      case 't3':
        return 'h3';
      case 'h4':
        return 'h4';
      case 't4':
        return 'h4';
      case 't5':
        return 'h5';
      case 't6':
        return 'h6';
      case 'label1':
        return 'h5';
      case 'label2':
        return 'h6';
      case 'heading-1':
        return 'h1';
      case 'heading-2':
        return 'h2';
      case 'heading-3':
        return 'h3';
      case 'heading-4':
        return 'h4';
      case 'heading-5':
        return 'h5';
      default:
        return 'p';
    }
  }, [variant]);

  const Component = component ?? defaultVariantComponent;

  return (
    <Component
      className={classNames(colorClassName, textClassName, className, `font-sans`, {
        'whitespace-pre-wrap': preWrap,
        '!block': truncate,
        truncate: truncate,
        'whitespace-no-wrap': noWrap,
        '!font-bold': fontWeight === 'bold',
        '!font-semibold': fontWeight === 'semibold',
        '!font-medium': fontWeight === 'medium',
        '!font-normal': fontWeight === 'regular',
        '!font-thin': fontWeight === 'thin',
        capitalize: textCase === 'capitalize',
        'normal-case': textCase === 'normal',
        uppercase: textCase === 'upper',
        lowercase: textCase === 'lower',
        'break-words': !truncate,
        'fs-mask': privacyLevel === 'pii',
        'fs-unmask': privacyLevel === 'phi' || privacyLevel === 'public',
        inline: display === 'inline',
        italic: italic,
        'text-left': alignment === 'left',
        'text-center': alignment === 'center',
        'text-right': alignment === 'right',
      })}
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...(onClick ? { tabIndex } : {})}
      role={role}
    >
      {children}
    </Component>
  );
}

export default Text;
