import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const HelpIcon = ({ size = 24, colorOverride, hoverColorOverride }: IconProps) => {
  const hoverClass = toGroupHoverFillColor(hoverColorOverride);
  const hoverFillColor = toGroupHoverFillColor(colorOverride);
  const fillColor = toFillColor(colorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" className="group">
      <circle cx="16" cy="16" r="14" className={classNames('fill-white', hoverClass)} />
      <path
        d="M16 2C8.26875 2 2 8.26875 2 16C2 23.7313 8.26875 30 16 30C23.7313 30 30 23.7313 30 16C30 8.26875 23.7313 2 16 2ZM16 27.625C9.58125 27.625 4.375 22.4188 4.375 16C4.375 9.58125 9.58125 4.375 16 4.375C22.4188 4.375 27.625 9.58125 27.625 16C27.625 22.4188 22.4188 27.625 16 27.625Z"
        className={classNames(fillColor, hoverFillColor)}
      />
      <path
        d="M19.4875 9.89688C18.55 9.075 17.3125 8.625 16 8.625C14.6875 8.625 13.45 9.07813 12.5125 9.89688C11.5375 10.75 11 11.8969 11 13.125V13.3625C11 13.5 11.1125 13.6125 11.25 13.6125H12.75C12.8875 13.6125 13 13.5 13 13.3625V13.125C13 11.7469 14.3469 10.625 16 10.625C17.6531 10.625 19 11.7469 19 13.125C19 14.0969 18.3125 14.9875 17.2469 15.3969C16.5844 15.65 16.0219 16.0937 15.6187 16.675C15.2094 17.2687 14.9969 17.9812 14.9969 18.7031V19.375C14.9969 19.5125 15.1094 19.625 15.2469 19.625H16.7469C16.8844 19.625 16.9969 19.5125 16.9969 19.375V18.6656C16.9985 18.3623 17.0915 18.0664 17.2637 17.8167C17.436 17.5669 17.6795 17.3749 17.9625 17.2656C19.8062 16.5563 20.9969 14.9313 20.9969 13.125C21 11.8969 20.4625 10.75 19.4875 9.89688ZM14.75 22.875C14.75 23.2065 14.8817 23.5245 15.1161 23.7589C15.3505 23.9933 15.6685 24.125 16 24.125C16.3315 24.125 16.6495 23.9933 16.8839 23.7589C17.1183 23.5245 17.25 23.2065 17.25 22.875C17.25 22.5435 17.1183 22.2255 16.8839 21.9911C16.6495 21.7567 16.3315 21.625 16 21.625C15.6685 21.625 15.3505 21.7567 15.1161 21.9911C14.8817 22.2255 14.75 22.5435 14.75 22.875Z"
        className={classNames(fillColor, hoverFillColor)}
      />
    </svg>
  );
};

export default HelpIcon;
