import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { getMemberByAccount, getMemberById } from '../../api/member';
import { AppView } from '../../types/AppView';
import { Member } from '../../types/Member';

export function useMemberSplit({
  appView,
  memberId,
}: {
  appView: AppView;
  memberId?: string;
}): UseQueryResult<Member, { memberId?: string }> {
  return useQuery(['memberByAccount', { memberId }], () => {
    if (appView === 'memberServices') return getMemberById(memberId!);
    else if (appView === 'member') return getMemberByAccount();
  });
}
