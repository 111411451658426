import React, { PropsWithChildren, createContext, useCallback, useMemo } from 'react';

import { useComments, useEditComment, useServiceRequests, useUserSession } from '../hooks/api';
import { Comment, EditCommentPayload } from '../types/Comments';
import { ServiceRequest } from '../types/ServiceRequest';
import { UserSession } from '../types/UserSession';

export type ActionFormType = 'edit' | 'new';

type ServiceRequestsContextProps = {
  userId: string;
  requestId?: string;
  scrollRef?: React.RefObject<HTMLDivElement> | null;
};

export const ServiceRequestsContext = createContext<{
  serviceRequests?: ServiceRequest[];
  loading: boolean;
  currentUser: UserSession | null;
  comments: Comment[];
  editComment: (currentComment: EditCommentPayload) => void;
}>({
  serviceRequests: [],
  loading: false,
  currentUser: null,
  comments: [],
  editComment: () => {},
});

function ServiceRequestsContextWrapper({
  userId,
  requestId,
  children,
}: PropsWithChildren<ServiceRequestsContextProps>) {
  const serviceRequestsQuery = useServiceRequests(userId);

  const { data: userData } = useUserSession();
  const { data: commentsData, isLoading: commentsIsLoading } = useComments({
    objectId: requestId,
    objectType: 'serviceRequest',
  });

  const editCommentMutation = useEditComment();

  const editComment = useCallback(
    (currentComment: EditCommentPayload) => {
      editCommentMutation.mutate({ payload: { ...currentComment } });
    },
    [editCommentMutation]
  );

  const currentUser = useMemo(() => userData ?? null, [userData]);

  const comments = useMemo(() => commentsData ?? [], [commentsData]);

  const serviceRequests = useMemo(() => serviceRequestsQuery.data, [serviceRequestsQuery.data]);

  const loading = useMemo(() => serviceRequestsQuery.isLoading, [serviceRequestsQuery.isLoading]);

  const value = useMemo(() => {
    return {
      serviceRequests,
      loading,
      comments,
      currentUser,
      editComment,
    };
  }, [serviceRequests, loading, comments, currentUser, editComment]);

  return <ServiceRequestsContext.Provider value={value}>{children}</ServiceRequestsContext.Provider>;
}

export default ServiceRequestsContextWrapper;
