import classNames from 'classnames';
import React from 'react';
import { PropsWithChildren } from 'react';

import { Elevation, ElevationClasses } from './Elevation';

type BorderColor = 'purple' | 'mediumGrayOne' | 'magenta' | 'successGreen' | 'lightGrayTwo' | 'darkGrayTwo';

export type CardBackgroundColor =
  | 'white'
  | 'magenta'
  | 'yellowTint'
  | 'warmWhite'
  | 'lightGrayTwo'
  | 'lightMagenta'
  | 'lightGreen'
  | 'errorRedBg'
  | 'lightTeal'
  | 'darkBlueBg'
  | 'lightLinkBlue'
  | 'teal'
  | 'teal';

type CardRounding = 'slight' | 'default';

type CardType = {
  elevation?: Elevation;
  rounding?: CardRounding;
  borderColor?: BorderColor;
  backgroundColor?: CardBackgroundColor;
  leftInset?: boolean;
  opaque?: boolean;
  className?: string;
};

const classes = ['overflow-hidden'];

const Card = React.forwardRef<HTMLDivElement, PropsWithChildren<CardType>>(
  (
    {
      children,
      rounding = 'slight',
      borderColor,
      backgroundColor = 'white',
      leftInset = false,
      elevation,
      opaque = false,
      className,
    },
    ref
  ): JSX.Element => {
    const elevationClass: string = elevation ? ElevationClasses[elevation] : '';
    return (
      <div
        ref={ref}
        className={classNames(
          classes,
          elevationClass,
          {
            'rounded-xl': rounding === 'default',
            'rounded-lg': rounding === 'slight',
            'border-mediumGrayOne': borderColor === 'mediumGrayOne',
            'border-magenta': borderColor === 'magenta',
            'border-successGreen': borderColor === 'successGreen',
            'border-lightGrayTwo': borderColor === 'lightGrayTwo',
            'border-darkGrayTwo': borderColor === 'darkGrayTwo',
            'border-purple': borderColor === 'purple',
            'bg-white': backgroundColor === 'white',
            'bg-magenta': backgroundColor === 'magenta',
            'bg-yellowTint': backgroundColor === 'yellowTint',
            'bg-lightGrayTwo': backgroundColor === 'lightGrayTwo',
            'bg-lightMagenta': backgroundColor === 'lightMagenta',
            'bg-lightGreen': backgroundColor === 'lightGreen',
            'bg-errorRedBg': backgroundColor === 'errorRedBg',
            'bg-lightTeal': backgroundColor === 'lightTeal',
            'bg-teal': backgroundColor === 'teal',
            'bg-darkBlueBg': backgroundColor === 'darkBlueBg',
            'bg-lightLinkBlue': backgroundColor === 'lightLinkBlue',
            'border-none': borderColor === undefined,
            'border-[1px]': !leftInset && borderColor !== undefined,
            'border-l-[3px]': leftInset,
            'border-r-[1px]': leftInset,
            'border-t-[1px]': leftInset,
            'border-b-[1px]': leftInset,
            'bg-warmWhite': backgroundColor === 'warmWhite',
            'opaque-95 tablet:opacity-90': opaque,
          },
          className
        )}
      >
        {children}
      </div>
    );
  }
);

Card.displayName = 'Card';

export default Card;
