import { T } from '@transifex/react';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { LoadingSpinner, Text } from '.';
import { useUnimpersonateAccount } from '../../hooks/api';
import { useMemberSplit } from '../../hooks/splitApi/member';
import { UserSession } from '../../types/UserSession';
import { Button } from './new';

const ImpersonationBanner = ({ userSession }: { userSession: UserSession }) => {
  const router = useRouter();
  const { mutateAsync, isLoading } = useUnimpersonateAccount();
  const { data: member } = useMemberSplit({ appView: 'member' });
  const onLogout = useCallback(async () => {
    try {
      await mutateAsync();
      router.push(`/member/${member?.userId}`);
    } catch {}
  }, [mutateAsync, router, member]);
  return (
    <div className="bg-magenta p-4 flex flex-row justify-center items-center gap-4">
      <Text variant="body-1-deprecated" color="white">
        <T _str="You are currently viewing {name}'s account." name={userSession.name} />
      </Text>
      <Button variant="secondary" size="small" onClick={onLogout}>
        {isLoading ? <LoadingSpinner size={24} colorOverride="purple" /> : <T _str="Logout" />}
      </Button>
    </div>
  );
};

export default ImpersonationBanner;
