import classNames from 'classnames';
import React from 'react';
import { PropsWithChildren } from 'react';

const classes = ['bg-white', 'p-4'];

const CardContent = React.forwardRef<HTMLDivElement, PropsWithChildren<{}>>((passedProps, ref): JSX.Element => {
  return (
    <div ref={ref} className={classNames(classes)}>
      {passedProps.children}
    </div>
  );
});

CardContent.displayName = 'CardContent';

export default CardContent;
