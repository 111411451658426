import React from 'react';

import { IconProps } from '../../types/ui';

const DoubleCircle = ({ size }: IconProps) => {
  return (
    <svg width={size ?? 15} height={size ?? 15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.50057" cy="7.50081" r="3.90476" className="fill-purple stroke-purple" strokeWidth="0.857143" />
      <circle cx="7.5" cy="7.5" r="6.5" className="stroke-purple" strokeWidth="2" />
    </svg>
  );
};

export default DoubleCircle;
