import { Gender, Pronouns } from './Common';
import { User } from './User';
import { WelcomeCallOutcome } from './WelcomeCall';

export enum EnrollmentType {
  Member = 'member',
  Caregiver = 'caregiver',
  Caremanager = 'caremanager',
  Ingest = 'ingest',
  EligibilityRecord = 'eligibilityRecord',
}

export type Member = User & {
  clientId?: string;
  clientName?: string;
  enrolledAt?: string;
  enrollmentType?: EnrollmentType;
  journeyStatus?: string;
  primaryContactInfo: User;
  tags: IMemberTag[];
  insuranceMemberId?: string;
  insurancePlanName?: string;
  coverageMethod?: string;
  coveredAt?: string;
  termsAndPrivacyAccepted?: boolean;
  termsAndPrivacyAcceptedTime?: string;
  defaultLang?: string;
  // location?: Location;
  timeZone?: string;
  emergencyContact?: string;
  pcpInfo?: string;
  pronouns?: Pronouns;
  income?: string;
  hasInternetAccess?: boolean;
  isVeteran?: boolean;
  isMedicaidBeneficiary?: boolean;
  receivesSnap?: boolean;
  engagementStatus?: EngagementStatus;
  enrollmentNotes?: string;
  memberNotes?: string;
  gender?: Gender;
  zipcode: string;
  assignedDuo?: string;
  welcomeCallOutcome?: WelcomeCallOutcome;
  disengagedPromptDismissedAt?: string;
  flexTransition?: FlexTransition;
  memberStatus?: MemberStatus;
  insurancePlanDisplayName?: string;
  isDemoRecord?: boolean;
  pcpData: PcpData;
  planType?: string;
};

export type PcpData = {
  firstName?: string;
  lastName?: string;
  npi?: string;
  addressLine1?: string;
  city?: string;
  state?: string;
  zip?: string;
};

export type FlexTransition = {
  anticipatedFlexTransitionDate?: string;
  actualFlexSupportTransitionDate?: string;
  transitionSource?: TransitionSource;
};

export type TransitionSource = 'welcomeBanner';

export namespace Member {
  export const toMemberCardProps = (member: Member): MemberCardProps => {
    return { ...member };
  };
}

export type ManagedMember = {
  memberId: string;
  memberFirstName: string;
  memberLastName: string;
  clientName?: string;
  engagementStatus?: EngagementStatus;
  memberStatus?: MemberStatus;
};

export namespace ManagedMember {
  export const toMemberCardProps = (managedMember: ManagedMember): MemberCardProps => {
    const { memberId: userId, memberFirstName: firstName, memberLastName: lastName } = managedMember;
    return { ...managedMember, userId, firstName, lastName };
  };
}

export type MemberStatus = {
  memberJourneyStatus: MemberJourneyStatus;
};

export type MemberCardProps = {
  userId: string;
  firstName: string;
  lastName: string;
  phone?: string;
  dob?: string;
  email?: string;
  clientName?: string;
  need?: any;
  numberOfActiveServiceRequests?: any;
  engagementStatus?: EngagementStatus;
  memberStatus?: MemberStatus;
};

export namespace MemberCardProps {
  export const lowerCaseName = (cardProps: MemberCardProps): string =>
    `${cardProps.firstName.toLowerCase()} ${cardProps.lastName.toLowerCase()}`;
}

export type MemberActionType = 'recommended' | 'custom' | 'admin';

export type MemberActionItem = {
  type: MemberActionType;
  id: string;
  todoListId: string;
  text: string;
  memberNeeds: string[];
  readinessStatus?: Readiness;
  status: MemberActionItemState;
  priority: number;
  linkedServiceRequests: string[]; // @todo: use proper type
  comments: string[]; // @todo: use proper type
};

export enum Readiness {
  NotReadyForChange = 'notReadyForChange',
  ThinkingAboutChange = 'thinkingAboutChange',
  ReadyForChange = 'readyForChange',
}

export type MemberActionItemState = 'open' | 'completed';

export type CareMemberInfo = {
  id: string;
  firstName: string;
  lastName: string;
  relationship: string;
};

export interface IMemberTag {
  suggested?: boolean;
  displayText: string;
  id: string;
  name: string;
}

export enum EngagementStatus {
  DoNotContact = 'do_not_contact',
  NewLead = 'new_lead',
  AttemptingToContact = 'attempting_to_contact',
  WorkingLead = 'working_lead',
  MonthlyFollowup = 'monthly_follow_up',
  WelcomeCallScheduled = 'welcome_call_scheduled',

  ColdLeadDeceased = 'cold_lead.deceased',
  ColdLeadICanGoogleMyself = 'cold_lead.i_can_google_myself',
  ColdLeadWantedInPersonService = 'cold_lead.wanted_in_person_service',

  ColdLeadIncompleteLead = 'cold_lead.incomplete_lead',
  ColdLeadServiceNotAsExpected = 'cold_lead.service_not_as_expected',
  ColdLeadRefusal = 'cold_lead.refusal',
  ColdLeadCostConcerns = 'cold_lead.cost_concerns',
  ColdLeadNoAnswerAfter3Attempts = 'cold_lead.no_answer_after_3_attempts',

  ColdLeadNotATrueLead = 'cold_lead.not_a_true_lead',
  ColdLeadOther = 'cold_lead.other',

  NotEligible = 'not_eligible',
  FreeTrialMember = 'free_trial_member',
  ActiveMember = 'active_member',
  InactiveMember = 'inactive_member',
  PreEnrolled = 'pre_enrolled',
  DeactivatedMemberUnengaged = 'deactivated_member.unengaged',
  DeactivatedMemberServicesNotNeeded = 'deactivated_member.services_not_needed',

  DeactivatedMemberDeceased = 'deactivated_member.deceased',
  DeactivatedMemberGraduated = 'deactivated_member.graduated',
  DeactivatedMemberLostCoverage = 'deactivated_member.lost_coverage',
  DeactivatedMemberCancelAfterActivation = 'deactivated_member.cancel_after_activation',

  DeactivatedMemberCancelDuringFreeTrial = 'deactivated_member.cancel_during_free_trial',

  BadTelephoneNumber = 'bad_telephone_number',
  WelcomeCallReschedule = 'reschedule_welcome_call',
  MemberCancelDuringWelcomeCall = 'member_cancel_during_welcome_call',

  ActiveMemberMissedWeeklyCall = 'active_member.missed_weekly_call',
}

export const EngagementStatusLabels: Record<EngagementStatus, string> = {
  'cold_lead.cost_concerns': 'Cold Lead Cost Concerns',
  'cold_lead.deceased': 'Cold Lead Deceased',
  'cold_lead.i_can_google_myself': 'Cold Lead I Can Google Myself',
  'cold_lead.incomplete_lead': 'Cold Lead Incomplete Lead',
  'cold_lead.no_answer_after_3_attempts': 'Cold Lead No Answer After 3 Attempts',
  'cold_lead.not_a_true_lead': 'Cold Lead Not a True Lead',
  'cold_lead.other': 'Cold Lead Other',
  'cold_lead.refusal': 'Cold Lead Refusal',
  'cold_lead.service_not_as_expected': 'Cold Lead Service Not as Expected',
  'cold_lead.wanted_in_person_service': 'Cold Lead Wanted in Person Service',
  'deactivated_member.cancel_after_activation': 'Deactivated Member Cancel After Activation',
  'deactivated_member.cancel_during_free_trial': 'Deactivated Member Cancel During Free Trial',
  'deactivated_member.deceased': 'Deactivated Member Deceased',
  'deactivated_member.graduated': 'Deactivated Member Graduated',
  'deactivated_member.lost_coverage': 'Deactivated Member Lost Coverage',
  'deactivated_member.services_not_needed': 'Deactivated Member Service not Needed',
  'deactivated_member.unengaged': 'Deactivated Member Unengaged',
  active_member: 'Active Member',
  attempting_to_contact: 'Attempting to Contact',
  bad_telephone_number: 'Bad Telephone Number',
  do_not_contact: 'Do Not Contact',
  free_trial_member: 'Free Trial Member',
  inactive_member: 'Inactive Member',
  monthly_follow_up: 'Monthly Follow-Up',
  new_lead: 'New Lead',
  not_eligible: 'Not Eligible',
  pre_enrolled: 'Pre-Enrolled',
  reschedule_welcome_call: 'Reschedule Welcome Call',
  welcome_call_scheduled: 'Welcome Call Scheduled',
  working_lead: 'Working Lead',
  member_cancel_during_welcome_call: 'Member Canceled During Welcome Call',
  'active_member.missed_weekly_call': 'Unengaged',
};

export type MemberFilterOption = {
  query: string;
  status: string[];
  client: string[];
};

export enum MemberJourneyStatus {
  ActiveEngaged = 'Active_Engaged',
  ActiveUnengaged = 'Active_Unengaged',
  Inactive = 'Inactive',
}
