import { useT } from '@transifex/react';
import classNames from 'classnames';
import React, { createRef, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Text } from '..';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { Option } from '../../../types/Common';
import { PrivacyLevel } from '../../../types/Privacy';
import { AlertIcon, DownIcon, UpIcon } from '../../icons';
import DropdownOption from './DropdownOption';

const StyledDiv = styled('div')<{ parentWidth: number }>`
  width: ${(props) => `${props.parentWidth - 4}px`};
`;

type DropdownProps<T extends string> = {
  onChange: (value: T) => void;
  value?: T | string;
  options: Option<T>[];
  placeholder: string;
  error?: string;
  className?: string;
  btnClassName?: string;
  dropdownClassName?: string;
  disabled?: boolean;
  privacyLevel?: PrivacyLevel;
};

function Dropdown<T extends string>({
  onChange,
  value = '',
  options,
  placeholder,
  error,
  className,
  btnClassName,
  dropdownClassName,
  disabled,
  privacyLevel = 'pii',
}: DropdownProps<T>) {
  const t = useT();
  const containingRef = createRef<HTMLDivElement>();
  const labelRef = createRef<HTMLButtonElement>();
  const [width, setWidth] = useState(0);
  const [open, setOpen] = useState<boolean>(false);

  const label = useMemo(() => {
    if (!value) {
      return placeholder;
    }
    const optionsFiltered = options.filter((option) => option.value === value);
    return optionsFiltered.length > 0 && optionsFiltered[0].label;
  }, [options, placeholder, value]);

  useOutsideClick(containingRef, () => setOpen(false));

  useLayoutEffect(() => {
    if (!!labelRef.current) setWidth(labelRef.current.clientWidth);
  }, [labelRef]);

  useEffect(() => {
    if (open && labelRef.current) {
      labelRef.current.focus();
    }
  }, [labelRef, open]);

  const optionsRef = createRef<HTMLDivElement>();

  return (
    <div ref={containingRef} className={classNames('flex flex-col items-stretch relative', className)}>
      <button
        ref={labelRef}
        className={classNames(
          {
            'border-errorRed': error !== undefined,
            'border-mediumGrayOne': error === undefined && !disabled && !value,
            'border-darkGrayThree': disabled,
            'border-purple': value,
            'bg-lightGrayTwo': disabled,
            'cursor-not-allowed': disabled,
            'bg-white': !disabled,
            'cursor-pointer': !disabled,
          },
          'hover:border-purple',
          'active:border-purple',
          'border',
          'rounded-[8px]',
          'h-12',
          'p-3',
          'flex',
          'flex-row',
          'items-center',
          'justify-between',
          btnClassName
        )}
        onClick={() => {
          !disabled && setOpen(!open);
        }}
        type="button"
        onKeyDown={(event) => {
          if (/^[a-zA-Z]+$/i.test(event.key) && optionsRef.current) {
            const position = options.findIndex((option) => option.value[0].toUpperCase() === event.key.toUpperCase());
            optionsRef.current?.scrollTo(0, 48 * position);
          }
        }}
      >
        <Text
          privacyLevel={privacyLevel}
          tabIndex={-1}
          variant={!value ? 'body-1' : 'body-1-emphasis'}
          color={error ? 'errorRed' : !disabled ? (!value ? 'darkGrayThree' : 'purple') : 'darkGrayThree'}
        >
          {value && <>{label}</>}
          {!value && <>{placeholder ? placeholder : t('Select')}</>}
        </Text>
        {open ? <UpIcon colorOverride="linkBlue" /> : <DownIcon colorOverride="linkBlue" />}
      </button>
      {open && width && (
        <div className="mt-12 z-20 absolute max-h-[240px]">
          <StyledDiv
            ref={optionsRef}
            className={classNames(
              'max-h-[240px]',
              'overflow-y-scroll',
              'rounded-[8px]',
              'border',
              `border-purple`,
              'border-darkGrayTwo',
              'bg-white',
              'flex flex-col items-stretch',
              { width: `${width}px` },
              dropdownClassName,
              disabled && 'bg-lightGrayTwo'
            )}
            parentWidth={width + 6}
          >
            {options.map((option) => (
              <DropdownOption
                privacyLevel={privacyLevel}
                key={option.value}
                {...option}
                onChange={(value: T) => {
                  onChange(value);
                  setOpen(false);
                }}
              />
            ))}
          </StyledDiv>
        </div>
      )}
      {error && (
        <div className="flex flex-row items-start gap-2 mt-2">
          <AlertIcon colorOverride="errorRed" />
          <Text privacyLevel={privacyLevel} color="darkGrayOne" variant="body-1">
            <span dangerouslySetInnerHTML={{ __html: error }} />
          </Text>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
