import { toStrokeColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const HandsIcon = ({ size = 16, colorOverride = 'magenta' }: IconProps) => {
  const strokeClass = toStrokeColor(colorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 152 152" fill="none" className={strokeClass}>
      <path
        d="M114 126.667L138.219 102.448C138.932 101.736 139.332 100.77 139.333 99.7627V66.5C139.333 63.9804 138.332 61.5641 136.551 59.7825C134.769 58.0009 132.353 57 129.833 57V57C127.314 57 124.897 58.0009 123.116 59.7825C121.334 61.5641 120.333 63.9804 120.333 66.5V95"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114 101.335L119.434 95.9006C119.72 95.6155 119.946 95.2769 120.1 94.9041C120.255 94.5313 120.334 94.1318 120.334 93.7283C120.333 93.1588 120.173 92.6007 119.874 92.1164C119.574 91.6322 119.146 91.2408 118.636 90.986L115.83 89.5863C113.454 88.3978 110.763 87.987 108.14 88.4121C105.516 88.8372 103.093 90.0767 101.213 91.955L95.5448 97.6233C93.1692 99.9982 91.8342 103.219 91.8335 106.579V126.668"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.0001 126.667L13.7814 102.448C13.0686 101.736 12.6676 100.77 12.6667 99.7627V66.5C12.6667 63.9804 13.6676 61.5641 15.4492 59.7825C17.2308 58.0009 19.6472 57 22.1667 57V57C24.6863 57 27.1027 58.0009 28.8843 59.7825C30.6659 61.5641 31.6667 63.9804 31.6667 66.5V95"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.0001 101.335L32.5661 95.9006C32.2809 95.6153 32.0547 95.2766 31.9003 94.9039C31.746 94.5312 31.6667 94.1317 31.6667 93.7283V93.7283C31.6667 92.5693 32.3254 91.5116 33.3641 90.986L36.1697 89.5863C38.5467 88.3978 41.2373 87.987 43.8606 88.4121C46.4839 88.8372 48.9071 90.0767 50.7871 91.955L56.4554 97.6233C58.831 99.9982 60.166 103.219 60.1668 106.579V126.668"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.5578 76.0002H65.4424V61.2245H50.6667V40.1092H65.4424V25.3335H86.5578V40.1092H101.333V61.2245H86.5578V76.0002Z"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HandsIcon;
