import classNames from 'classnames';

import { CropCircleIcon, RadioCircle } from '../../../icons';

type AnimatedRadioIconProps = {
  selected?: boolean;
  className?: string;
};

const AnimatedRadioIcon = ({ className, selected }: AnimatedRadioIconProps) => {
  return (
    <div className="w-6 h-6 relative flex-shrink-0">
      <div
        className={classNames([
          'z-20',
          'absolute',
          'top-0',
          'left-0',
          'transition-all',
          'duration-200',
          { 'opacity-100': selected, 'scale-100': selected, 'opacity-0': !selected, 'scale-0': !selected },
        ])}
      >
        <RadioCircle colorOverride="linkBlue" />
      </div>
      <div
        className={classNames([
          className,
          'absolute',
          'top-0',
          'left-0',
          'transition-all',
          'duration-200',
          { 'opacity-100': !selected, 'opacity-0': selected },
        ])}
      >
        <CropCircleIcon colorOverride="mediumGrayOne" />
      </div>
    </div>
  );
};

export default AnimatedRadioIcon;
