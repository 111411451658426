import classNames from 'classnames';
import React, { PropsWithChildren, useMemo } from 'react';
import Modal from 'react-modal';

import { IconButton, Text } from '.';
import { PrivacyLevel } from '../../types/Privacy';

type ModalProps<T> = {
  open: T | null;
  setClose: () => void;
  title?: string;
  titleClassName?: string;
  additionalInfo?: string;
  privacyLevel?: PrivacyLevel;
  titlePrivacyLevel?: PrivacyLevel;
  newModal?: boolean;
};

function DuosModal<T>({
  open,
  setClose,
  children,
  title,
  additionalInfo,
  titleClassName,
  privacyLevel = 'pii',
  titlePrivacyLevel = 'public',
  newModal = false,
}: PropsWithChildren<ModalProps<T>>) {
  const [startPurpleTitle, middlePinkTitle, endPurpleTitle] = useMemo(() => {
    const titleSections = title?.split('**');
    if (!titleSections) return [];
    if (titleSections.length < 3) return [titleSections[0], '', ''];
    else return titleSections.slice(0, 3);
  }, [title]);
  return (
    <>
      {open && (
        <Modal
          appElement={document.getElementById('__next') as HTMLElement}
          isOpen={open !== null}
          onRequestClose={setClose}
          style={{
            /* Need to use style to overwrite react-modal defaults. */
            overlay: {
              zIndex: 100,
              overflowY: 'scroll',
            },
          }}
          className="absolute opacity-100 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] max-w-[1084px] w-[75%] max-h-[calc(100svh-80px)] overflow-hidden"
        >
          <div className="overflow-scroll relative max-h-[calc(100svh-80px)] bg-white flex flex-col justify-start items-center p-[24px] rounded-[8px] border-darkGrayOne border-[1px]">
            <IconButton
              label="Close"
              icon="close"
              color="purple"
              hoverColor="darkPurple"
              className="self-end"
              onClick={setClose}
            />
            <div className="flex flex-1 flex-col max-w-[840px] w-full">
              <Text
                privacyLevel={titlePrivacyLevel}
                variant="h2"
                color="purple"
                className={classNames('text-center', titleClassName)}
              >
                {startPurpleTitle}
                <Text privacyLevel={privacyLevel} variant="h2" color="magenta" component={'span'}>
                  {middlePinkTitle}
                </Text>
                {endPurpleTitle}
              </Text>
              {additionalInfo && (
                <Text privacyLevel={titlePrivacyLevel} variant="body1" color="purple" className="text-center mt-4">
                  {additionalInfo}
                </Text>
              )}
              <div
                className={classNames(
                  newModal ? '' : 'mx-auto max-w-[600px]',
                  'w-full mt-8 flex flex-col items-stretch mb-4'
                )}
              >
                {children}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default DuosModal;
