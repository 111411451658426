import { T } from '@transifex/react';
import React from 'react';

import { Button } from '.';
import { EmailIcon } from '../../icons';

const ShareButton = ({ onClick, disabled }: { onClick?: () => void; disabled?: boolean }) => {
  return (
    <Button variant="darkOpaque" size="medium" onClick={onClick} disabled={disabled}>
      <EmailIcon />
      <T _str="Email to me" />
    </Button>
  );
};

export default ShareButton;
