import { T } from '@transifex/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { Ref } from 'react';

import { MEMBER_RECOMMENDATIONS, MEMBER_RESOLVED_RECOMMENDATIONS } from '../../../routes';
import { Text } from '../../common';
import { DownIcon, MainNav, UpIcon } from '../../icons';
import CheckedCircleOutLine from '../../icons/CheckedCircleOutline';

type RecommendationsMobileQuickLinkButtonProps = {
  quickLinkeIsOpen?: boolean;
  setQuickLinkIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ResourcesMobileQuickLinkButton = ({
  quickLinkeIsOpen = false,
  setQuickLinkIsOpen,
}: RecommendationsMobileQuickLinkButtonProps) => {
  const router = useRouter();

  const isResolvedPage = router.pathname.includes(MEMBER_RESOLVED_RECOMMENDATIONS);

  const desktopOverlayClass = quickLinkeIsOpen
    ? 'fixed top-0 left-0 right-0 buttom-0 bg-[rgba(21,24,31,0.5)] h-screen w-screen transform transition-all duration-200 ease-in-out -z-10'
    : '';
  const desktopOverlayAnimationClass = quickLinkeIsOpen ? 'opacity-100 z-30' : 'opacity-0';
  const mobileOverlayAnimationClass = quickLinkeIsOpen ? 'translate-y-0' : '-translate-y-10';

  return (
    <div>
      <div className="tablet:hidden flex flex-col items-stretch bg-white z-10">
        <button
          className="flex flex-row gap-2 items-center p-4 shadow-[0px_2px_8px_0px_rgba(16,_26,_97,_0.08)]"
          onClick={() => setQuickLinkIsOpen((menuShown: boolean) => !menuShown)}
        >
          <div>
            {isResolvedPage ? (
              <CheckedCircleOutLine size={24} colorOverride="magenta" active={true} />
            ) : (
              <MainNav size={24} colorOverride="magenta" active={true} />
            )}
          </div>
          <Text variant="nav-2" color="darkGrayOne">
            {isResolvedPage ? <T _str="Resolved" /> : <T _str="All Recommendations" />}
          </Text>
          {quickLinkeIsOpen ? (
            <UpIcon size={24} colorOverride="darkBlue" />
          ) : (
            <DownIcon size={24} colorOverride="darkBlue" />
          )}
        </button>
      </div>
      <div
        className={classNames('tablet:hidden flex flex-col items-stretch bg-white z-50', mobileOverlayAnimationClass)}
      >
        {quickLinkeIsOpen && (
          <button
            className={classNames('flex flex-row gap-2 items-center p-4 mx-4')}
            onClick={() =>
              !isResolvedPage ? router.push(MEMBER_RESOLVED_RECOMMENDATIONS) : router.push(MEMBER_RECOMMENDATIONS)
            }
          >
            <div>
              {!isResolvedPage ? (
                <CheckedCircleOutLine size={24} colorOverride="darkBlue" />
              ) : (
                <MainNav size={24} colorOverride="darkBlue" />
              )}
            </div>
            <Text variant="nav-2" color="darkGrayOne">
              {!isResolvedPage ? <T _str="Resolved" /> : <T _str="All Recommendations" />}
            </Text>
          </button>
        )}
      </div>
      <div className={classNames(desktopOverlayClass, desktopOverlayAnimationClass)} />
    </div>
  );
};
export default ResourcesMobileQuickLinkButton;
