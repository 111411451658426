import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { TextType } from '../../../theme/typography';
import { ColorType } from '../../../types/ui';
import { Text } from '../../common';

export interface FormLabelProps {
  variant?: TextType;
  color?: ColorType;
  className?: string;
}

const FormLabel = ({
  children,
  variant = 'body1',
  color = 'purple',
  className,
}: PropsWithChildren<FormLabelProps>): JSX.Element => {
  const component = typeof children === 'string' ? 'label' : 'span';

  return (
    <Text
      privacyLevel="public"
      variant={variant}
      color={color}
      className={classNames(className)}
      component={component}
      alignment="left"
    >
      {children}
    </Text>
  );
};

export default FormLabel;
