import React from 'react';

import { IconProps } from '../../types/ui';

const Clock = ({ size = 20, className = '' }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" className={className}>
      <path
        d="M9.991 0.833496C4.931 0.833496 0.833496 4.94016 0.833496 10.0002C0.833496 15.0602 4.931 19.1668 9.991 19.1668C15.0602 19.1668 19.1668 15.0602 19.1668 10.0002C19.1668 4.94016 15.0602 0.833496 9.991 0.833496ZM10.0002 17.3335C5.9485 17.3335 2.66683 14.0518 2.66683 10.0002C2.66683 5.9485 5.9485 2.66683 10.0002 2.66683C14.0518 2.66683 17.3335 5.9485 17.3335 10.0002C17.3335 14.0518 14.0518 17.3335 10.0002 17.3335ZM9.7985 5.41683H9.7435C9.37683 5.41683 9.0835 5.71016 9.0835 6.07683V10.4035C9.0835 10.7243 9.2485 11.0268 9.53266 11.1918L13.3368 13.4743C13.6485 13.6577 14.0518 13.566 14.2352 13.2543C14.4277 12.9427 14.3268 12.5302 14.006 12.3468L10.4585 10.2385V6.07683C10.4585 5.71016 10.1652 5.41683 9.7985 5.41683Z"
        className="fill-purple"
      />
    </svg>
  );
};

export default Clock;
