import { T } from '@transifex/react';
import classNames from 'classnames';
import React from 'react';

import { Text } from '../..';
import Logo from '../../../icons/Logo';
import Container from '../../../layout/new/Container';
import FooterLinks from './FooterLinks';
import FooterSocialLinks from './FooterSocialLinks';
import FooterTerms from './FooterTerms';

type FooterProps = {};

const Footer = ({}: FooterProps) => {
  return (
    <div className="relative z-5 bg-white border-t-[3px] border-solid border-lightGrayTwo">
      <Container className={classNames('flex flex-col py-[64px] tablet:py-[84px] gap-6')}>
        <div className="flex items-start">
          <Logo />
        </div>
        <Text variant="overline" color="darkGrayOne">
          <T _str="QUICK LINKS" />
        </Text>
        <FooterLinks />
        <div className="flex flex-wrap flex-col tablet:flex-row justify-between">
          <FooterTerms />
          <FooterSocialLinks />
        </div>
      </Container>
    </div>
  );
};

export default Footer;
