import { T } from '@transifex/react';

import { Text } from '..';
import { ThumbsUp as ThumbsUpIcon } from '../../../icons';

const ThumbsUp = () => {
  return (
    <>
      <ThumbsUpIcon size={24} colorOverride="linkBlue" />
      <Text variant="body-1-emphasis" color="darkGrayOne">
        <T _str="Yes" />
      </Text>
    </>
  );
};

export default ThumbsUp;
