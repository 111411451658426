import classNames from 'classnames';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { PropsWithChildren, useCallback, useState } from 'react';

import { AppView } from '../../../types/AppView';
import Decorations, { DecorationsType } from '../../common/Decorations';
import Header, { HeaderProps, Theme } from '../../common/Header';
import Footer from '../../common/new/Footer';
import MainMenu from '../../common/new/MainMenu';
import { PAGE_CLASSES } from './common';

type MobileQuickLinksType = 'recommendations' | 'resources';

type Props = PropsWithChildren<{
  page?: string;
  showHeader?: boolean;
  showMenu?: boolean;
  showFooter?: boolean;
  theme?: Theme;
  mobileQuickLinksType?: MobileQuickLinksType;
  decorations?: DecorationsType;
  currentLocale?: string;
  switchLocale?: () => void;
  appView?: AppView;
}>;

const NewLayout = ({
  children,
  page,
  showHeader = true,
  showMenu = false,
  showFooter = true,
  theme = 'dark',
  mobileQuickLinksType,
  decorations,
  currentLocale,
  switchLocale,
  appView = 'member',
}: Props) => {
  const router = useRouter();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const openMenu = useCallback(() => setMenuIsOpen(true), []);
  const closeMenu = useCallback(() => setMenuIsOpen(false), []);

  const headerProps: HeaderProps = {
    theme,
    alignment: 'center',
    showMenu: showMenu ? 'always' : undefined,
    showQuickLink: showMenu ? 'loggedIn' : undefined,
    openMenu,
    mobileQuickLinksType,
    appView,
  };

  return (
    <div className={classNames(PAGE_CLASSES)}>
      <Head>
        <title>{page ?? 'Duos Inc.'}</title>
        <meta name="description" content={page ?? 'DUOS Inc.'} />
      </Head>
      {showHeader && <Header {...headerProps} />}
      {decorations && <Decorations decorationsType={decorations} className="z-20" />}
      {children}
      {showFooter && <Footer />}
      <MainMenu open={menuIsOpen} setClose={closeMenu} currentLocale={currentLocale} switchLocale={switchLocale} />
    </div>
  );
};

export default NewLayout;
