import classNames from 'classnames';

import { RadioButton, Text } from '../..';
import { Option } from '../../../../types/Common';
import { PrivacyLevel } from '../../../../types/Privacy';
import { AlertIcon } from '../../../icons';

type RadioButtonGroupProps<T extends string> = {
  options: Option<T>[];
  value?: T;
  direction?: 'horizontal' | 'vertical';
  onChange: (value: T) => void;
  privacyLevel?: PrivacyLevel;
  error?: string;
};

export function RadioButtonGroup<T extends string>({
  options,
  value,
  direction = 'horizontal',
  onChange,
  privacyLevel = 'pii',
  error,
}: RadioButtonGroupProps<T>) {
  const handleSelectOption = (option: T) => () => {
    onChange(option);
  };

  return (
    <div
      className={classNames(
        'flex',
        'justify-between',
        direction === 'horizontal' ? 'flex-row' : 'flex-col',
        'gap-1',
        'items-stretch'
      )}
    >
      {options.map((option) => (
        <RadioButton
          privacyLevel={privacyLevel}
          key={option.value}
          checked={value === option.value}
          label={option.label}
          onClick={handleSelectOption(option.value)}
        />
      ))}
      {error && (
        <div className="flex flex-row items-start gap-2 mt-1">
          <AlertIcon colorOverride="errorRed" />
          <Text privacyLevel={privacyLevel} color="darkGrayOne" variant="body-1" alignment="left">
            <span dangerouslySetInnerHTML={{ __html: error }} />
          </Text>
        </div>
      )}
    </div>
  );
}

export default RadioButtonGroup;
