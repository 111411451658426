import classNames from 'classnames';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const DropDownIcon = ({ colorOverride, className }: IconProps) => {
  const fillColor = toFillColor(colorOverride);

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.0001 12.5L15 6.66667H10H5L10.0001 12.5Z"
        className={classNames('fill-mediumTeal group-hover:fill-white', fillColor)}
      />
    </svg>
  );
};
