import classNames from 'classnames';
import React, { ReactNode, forwardRef } from 'react';

import { toGroupHoverText, toHoverText, toTextColor } from '../../theme/colors';
import { TextType, textVariantClassName } from '../../theme/typography';
import { PrivacyLevel } from '../../types/Privacy';
import { ColorType } from '../../types/ui';

type TextLinkProps = {
  children: string | ReactNode;
  color?: ColorType;
  hoverColor?: ColorType;
  link?: string;
  onClick?: () => void;
  className?: string;
  variant?: TextType;
  label?: string;
  flex?: boolean;
  privacyLevel?: PrivacyLevel;
  sameTab?: boolean;
  invertUnderline?: boolean;
};

const getFlexClass = (enable: boolean) => {
  if (!enable) {
    return;
  }
  return 'flex flex-row';
};
const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>(
  (
    {
      children,
      color = 'linkBlue',
      hoverColor = 'linkHoverBlue',
      link,
      onClick,
      className,
      variant = 'body1',
      label = 'Text Link',
      flex = true,
      privacyLevel = 'phi',
      sameTab = false,
      invertUnderline = false,
    },
    ref
  ) => {
    return (
      <a
        ref={ref}
        className={classNames(
          className,
          'font-sans cursor-pointer h-[24px] items-center',
          textVariantClassName(variant),
          invertUnderline
            ? 'no-underline hover:underline group-hover:underline'
            : 'underline hover:no-underline group-hover:no-underline',
          toTextColor(color),
          toHoverText(hoverColor),
          toGroupHoverText(hoverColor),
          getFlexClass(flex),
          {
            'fs-mask': privacyLevel === 'pii',
            'fs-unmask': privacyLevel === 'phi' || privacyLevel === 'public',
          }
        )}
        color={color}
        href={link}
        {...(sameTab ? { target: '_self' } : { target: '_blank', rel: 'noreferrer' })}
        onKeyDown={(event) => {
          if (event.code === 'Space' || event.code === 'Enter') {
            onClick?.();
            event.preventDefault();
          }
        }}
        onClick={onClick}
        title={typeof children === 'string' ? children : label}
        tabIndex={0}
      >
        {children}
      </a>
    );
  }
);

TextLink.displayName = 'TextLink';

export default TextLink;
