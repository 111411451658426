export enum Elevation {
  Elevation1 = 'elevation-1',
  Elevation2 = 'elevation-2',
  Elevation3 = 'elevation-3',
  Base = 'base',
}

export const ElevationClasses: Record<Elevation, string> = {
  'elevation-1': 'shadow-elevation-1',
  'elevation-2': 'shadow-elevation-2',
  'elevation-3': 'shadow-elevation-3',
  base: 'shadow-base',
};
