import { colors, toFillColor, toStrokeColor } from '../../theme/colors';
import { ColorType, IconProps } from '../../types/ui';

type Props = {
  fillColorOverride?: ColorType;
  outlineColorOverride?: ColorType;
  size?: number;
};

const DuosLogoCircle = ({ size = 20, fillColorOverride, outlineColorOverride }: Props) => {
  const fill = fillColorOverride ? toFillColor(fillColorOverride) : 'fill-white';
  const outline = outlineColorOverride ? toStrokeColor(outlineColorOverride) : 'stroke-lightGrayTwo';

  return (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.5" y="1.5" width="97" height="97" rx="48.5" className={fill} />
      <rect x="1.5" y="1.5" width="97" height="97" rx="48.5" strokeWidth="3" className={outline} />
      <path
        d="M37.2082 37.2047C39.085 35.3275 39.085 32.4308 37.2082 30.5698C35.3314 28.7088 32.4353 28.6926 30.5746 30.5698C28.6978 32.447 28.6978 35.3437 30.5746 37.2047C32.4514 39.0658 35.3314 39.0819 37.2082 37.2047Z"
        fill="#AC3EA4"
      />
      <path
        d="M69.4387 62.7888C67.5619 60.9116 64.6657 60.9116 62.8051 62.7888C60.9283 64.666 60.9283 67.5627 62.8051 69.4237C64.6819 71.3009 67.578 71.3009 69.4387 69.4237C71.2993 67.5627 71.2993 64.666 69.4387 62.7888Z"
        fill="#4F196F"
      />
      <path
        d="M41.2511 60.3969C42.1733 59.8628 42.8205 59.0052 43.0956 57.9856C43.3221 57.1118 43.3221 56.1408 42.9176 55.3964C41.9953 53.8914 41.4776 52.0628 41.4776 50.0885C41.4776 45.1365 44.9886 40.9614 49.9718 40.9614C52.3179 40.9614 54.3241 41.9 55.8126 43.405C56.0553 41.9323 57.0746 40.6054 58.4823 39.78C59.631 39.1165 60.9415 38.9062 62.155 39.0842C59.1294 35.8962 54.7933 33.9219 49.9557 33.9219C40.4421 33.9219 33.4688 41.1879 33.4688 50.8814C33.4688 54.2798 34.569 57.2574 36.3811 59.6848C37.4974 61.028 39.6331 61.3355 41.2511 60.3969Z"
        fill="#AC3EA4"
      />
      <path
        d="M66.5086 49.9574C66.5086 47.2549 65.829 44.7304 64.6317 42.5133C63.5477 40.7171 61.1531 40.1992 59.3249 41.2511C57.6907 42.1897 56.8818 44.1478 57.5128 45.847C58.1599 47.174 58.5159 48.6628 58.5159 50.2325C58.5159 55.2168 55.0535 59.4082 50.1025 59.4082C48.0639 59.4082 46.3004 58.7447 44.8927 57.6442C44.8604 57.9032 44.8118 58.1783 44.7309 58.4372C44.3426 59.8936 43.4042 61.1235 42.0937 61.8679C41.2038 62.3858 40.233 62.6285 39.2785 62.6285C39.2623 62.6285 39.2623 62.6285 39.2461 62.6285C42.304 65.0559 46.268 66.4315 50.3614 66.4315C59.3249 66.4153 66.5086 59.2301 66.5086 49.9574Z"
        fill="#4F196F"
      />
    </svg>
  );
};

export default DuosLogoCircle;
