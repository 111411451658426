import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const MagnifyingGlass = ({ size = 24, colorOverride, hoverColorOverride }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const hoverClass = toGroupHoverFillColor(hoverColorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16634 3.33325C5.94468 3.33325 3.33301 5.94492 3.33301 9.16658C3.33301 12.3882 5.94468 14.9999 9.16634 14.9999C10.4763 14.9999 11.6862 14.5674 12.6598 13.8385L15.2438 16.4225C15.5692 16.7479 16.0968 16.7479 16.4223 16.4225C16.7477 16.0971 16.7477 15.5694 16.4223 15.244L13.8383 12.66C14.5672 11.6865 14.9997 10.4765 14.9997 9.16658C14.9997 5.94492 12.388 3.33325 9.16634 3.33325ZM4.99967 9.16658C4.99967 6.8654 6.86515 4.99992 9.16634 4.99992C11.4675 4.99992 13.333 6.8654 13.333 9.16658C13.333 10.3154 12.8692 11.3543 12.1165 12.1089C11.3613 12.8662 10.3192 13.3333 9.16634 13.3333C6.86515 13.3333 4.99967 11.4678 4.99967 9.16658Z"
        className={classNames(fillClass, hoverClass)}
      />
    </svg>
  );
};

export default MagnifyingGlass;
