import { useCallback, useState } from 'react';

type SharedToggleState = {
  toggle: () => void;
  setTrue: () => void;
  setFalse: () => void;
};

export type ToggleState = {
  state: boolean;
} & SharedToggleState;

export type NullableToggleState = {
  state: boolean | null;
} & SharedToggleState;

export default function useToggle(defaultValue?: boolean): ToggleState;
export default function useToggle(defaultValue: null): NullableToggleState;
export default function useToggle(defaultValue?: boolean | null): NullableToggleState {
  const [state, setState] = useState<boolean | null>(defaultValue === undefined ? false : defaultValue);
  const toggle = useCallback(() => setState((existing) => !existing), []);
  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);

  return {
    state,
    toggle,
    setTrue,
    setFalse,
  };
}
