export enum DuosRole {
  Duo = 'duo',
  Product = 'product',
  Dev = 'dev',
  Ops = 'ops',
  Support = 'support',
  Caremanager = 'caremanager',
  Member = 'member',
}

export type Account = {
  id: string;
  cognitoId: string;
  firstName: string;
  lastName: string;
  email: string;
  hoursAvailable: number;
  role: DuosRole;
  engLang?: boolean;
  esLang?: boolean;
  isActive: boolean;
  creationTime: string;
  lastLoginTime?: string;
};

export type AccountSearchPayload = {
  searchQuery: string;
  availableFrom?: number;
  availableTo?: number;
  engLang?: boolean;
  esLang?: boolean;
  isActive?: boolean;
  roles?: DuosRole[];
  limit?: number;
  offset?: number;
};

export type SearchAccountResponse = {
  meta: {
    limit: number;
    offset: number;
    total: number;
  };
  data: Account[];
  errors: string[];
};

export type UpdateAssignmentPayload = {
  accountId: string;
  memberId: string;
  startDate: string;
  endDate?: string;
};

export type UpdateAssignmentResponse = {
  accountId: string;
  memberId: string;
  startDate: string;
  endDate?: string;
};

export type CreateMemberAccountRequest = {
  memberId: string;
  email: string;
  isActive?: boolean;
};
