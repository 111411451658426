import { ReactNode } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import type { Control, Path } from 'react-hook-form';

import { TextType } from '../../../theme/typography';
import { Option } from '../../../types/Common';
import { PrivacyLevel } from '../../../types/Privacy';
import { ColorType } from '../../../types/ui';
import { Dropdown } from '../../common';
import FormLabel from './FormLabel';

type FormDropdownProps<T extends FieldValues> = {
  name: Path<T>;
  label: string | JSX.Element;
  labelVariant?: TextType;
  labelColor?: ColorType;
  subLabel?: string | ReactNode;
  subLabelVariant?: TextType;
  subLabelColor?: ColorType;
  placeholder?: string;
  control?: Control<T, any>;
  error?: string;
  options: Option<string>[];
  privacyLevel?: PrivacyLevel;
  dropdownClassName?: string;
  labelClassName?: string;
};

export const FormDropdown = <T extends FieldValues>({
  name,
  control,
  label,
  labelVariant = 'body-1-emphasis',
  labelColor = 'darkGrayOne',
  subLabel = '',
  subLabelVariant = 'body-1',
  subLabelColor = 'darkGrayOne',
  placeholder,
  options,
  error,
  privacyLevel = 'pii',
  dropdownClassName,
  labelClassName,
}: FormDropdownProps<T>) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => {
      return (
        <div className="flex flex-col gap-2">
          {typeof label === 'string' ? (
            <FormLabel variant={labelVariant} color={labelColor}>
              {label}{' '}
              {typeof subLabel === 'string' && subLabel !== '' && (
                <FormLabel variant={subLabelVariant} color={subLabelColor}>
                  ({subLabel})
                </FormLabel>
              )}
            </FormLabel>
          ) : (
            label
          )}
          <Dropdown
            privacyLevel={privacyLevel}
            onChange={field.onChange}
            value={field.value}
            options={options}
            placeholder={placeholder ?? ''}
            error={error}
            dropdownClassName={dropdownClassName}
            disabled={options.length === 0}
          />
        </div>
      );
    }}
  />
);
