import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const Community = ({ size = 24, colorOverride, hoverColorOverride }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const hoverClass = toGroupHoverFillColor(hoverColorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 15.75C27 13.6789 28.6789 12 30.75 12C32.8211 12 34.5 13.6789 34.5 15.75V23.6272C34.5 24.2637 34.2471 24.8742 33.7971 25.3243L28.0607 31.0607C27.4749 31.6464 26.5251 31.6464 25.9393 31.0607C25.3536 30.4749 25.3536 29.5251 25.9393 28.9393L31.5 23.3787V15.75C31.5 15.3358 31.1642 15 30.75 15C30.3358 15 30 15.3358 30 15.75V22.5C30 23.3284 29.3284 24 28.5 24C27.6716 24 27 23.3284 27 22.5V15.75Z"
        className={classNames(fillClass, hoverClass)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9104 20.7183C24.28 19.3487 26.3724 19.0091 28.1048 19.8753L28.7692 20.2075C29.5235 20.5847 30 21.3557 30 22.199C30 22.7896 29.7654 23.3559 29.3479 23.7735L28.0607 25.0606C27.4749 25.6464 26.5251 25.6464 25.9393 25.0606C25.3536 24.4749 25.3536 23.5251 25.9393 22.9393L26.4381 22.4406C25.9398 22.3225 25.4051 22.4662 25.0317 22.8396L23.6893 24.182C23.408 24.4633 23.25 24.8448 23.25 25.2426V30C23.25 30.8284 22.5784 31.5 21.75 31.5C20.9216 31.5 20.25 30.8284 20.25 30V25.2426C20.25 24.0492 20.7241 22.9046 21.568 22.0607L22.9104 20.7183Z"
        className={classNames(fillClass, hoverClass)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 15C4.83579 15 4.5 15.3358 4.5 15.75V23.3787L10.0607 28.9393C10.6464 29.5251 10.6464 30.4749 10.0607 31.0607C9.47487 31.6464 8.52513 31.6464 7.93934 31.0607L2.20294 25.3243C1.75286 24.8742 1.5 24.2637 1.5 23.6272V15.75C1.5 13.6789 3.17893 12 5.25 12C7.32107 12 9 13.6789 9 15.75V22.5C9 23.3284 8.32843 24 7.5 24C6.67157 24 6 23.3284 6 22.5V15.75C6 15.3358 5.66421 15 5.25 15Z"
        className={classNames(fillClass, hoverClass)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.56189 22.4406C10.0602 22.3225 10.5949 22.4662 10.9683 22.8396L12.3107 24.182C12.592 24.4633 12.75 24.8448 12.75 25.2426V30C12.75 30.8284 13.4216 31.5 14.25 31.5C15.0784 31.5 15.75 30.8284 15.75 30V25.2426C15.75 24.0492 15.2759 22.9046 14.432 22.0607L13.0896 20.7183C11.72 19.3487 9.62763 19.0091 7.89518 19.8753L7.23082 20.2075C6.47649 20.5847 6 21.3557 6 22.199C6 22.7896 6.23458 23.3559 6.65215 23.7735L7.93934 25.0606C8.52513 25.6464 9.47487 25.6464 10.0607 25.0606C10.6464 24.4749 10.6464 23.5251 10.0607 22.9393L9.56189 22.4406Z"
        className={classNames(fillClass, hoverClass)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 6C14 5.17157 14.6716 4.5 15.5 4.5H20.5C21.3284 4.5 22 5.17157 22 6V8H24C24.8284 8 25.5 8.67157 25.5 9.5V14.5C25.5 15.3284 24.8284 16 24 16H22V18C22 18.8284 21.3284 19.5 20.5 19.5H15.5C14.6716 19.5 14 18.8284 14 18V16H12C11.1716 16 10.5 15.3284 10.5 14.5V9.5C10.5 8.67157 11.1716 8 12 8H14V6ZM17 7.5V9.5C17 10.3284 16.3284 11 15.5 11H13.5V13H15.5C16.3284 13 17 13.6716 17 14.5V16.5H19V14.5C19 13.6716 19.6716 13 20.5 13H22.5V11H20.5C19.6716 11 19 10.3284 19 9.5V7.5H17Z"
        className={classNames(fillClass, hoverClass)}
      />
    </svg>
  );
};

export default Community;
