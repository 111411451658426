import React, { PropsWithChildren } from 'react';

import { NewTextType } from '../../../theme/typography';
import Text, { TextProps } from '../Text';

type newTexType<C extends React.ElementType> = TextProps<C> & {
  variant: NewTextType;
};

function NewText<C extends React.ElementType>(props: PropsWithChildren<newTexType<C>>): JSX.Element {
  return <Text {...props}>{props.children}</Text>;
}

export default NewText;
