import AddButton from './AddButton';
import AddButtonWithText from './AddButtonWithText';
import { AlertIcon } from './Alert';
import AppleIcon from './AppleIcon';
import { BackArrow } from './BackArrow';
import BellIcon from './Bell';
import BookmarkFilledIcon from './BookmarkFilled';
import BookmarkOutlineIcon from './BookmarkOutline';
import BullseyeArrow from './BullseyeArrow';
import { CalendarIcon } from './Calendar';
import CallIcon from './Call';
import CancelCircleOutLine from './CancelCircleOutline';
import CheckIcon from './Check';
import CheckBoxIcon from './CheckBox';
import CheckCircleIcon from './CheckCircle';
import CheckedCircleOutline from './CheckedCircleOutline';
import CheckmarkWithRibbon from './CheckmarkWithRibbon';
import CircleIcon from './Circle';
import CirclePhoneIcon from './CirclePhoneIcon';
import ClockIcon from './Clock';
import { CloseIcon } from './Close';
import CloseWithCircle from './CloseWithCircle';
import CompleteIcon from './Complete';
import { CopyIcon } from './Copy';
import CreditCardIcon from './CreditCard';
import CropCircleIcon from './CropCircle';
import CropSquareIcon from './CropSquare';
import DialIcon from './DialIcon';
import DialPadIcon from './DialPad';
import DialogIcon from './DialogIcon';
import DiamondIcon from './Diamond';
import DiscussIcon from './Discuss';
import DiscussFullIcon from './DiscussFull';
import { DiscussedIcon } from './Discussed';
import DiscussedBubbleIcon from './DiscussedBubble';
import DoubleCircleIcon from './DoubleCircle';
import { DownIcon } from './Down';
import { DownArrowIcon } from './DownArrow';
import DownCircleIcon from './DownCircle';
import { DropDownIcon } from './DropDown';
import DuosHugIcon from './DuosHug';
import { DuosIcon } from './DuosIcon';
import DuosLogoCircle from './DuosLogoCircle';
import { EditIcon } from './Edit';
import EmailIcon from './Email';
import EnvelopWithCheckIcon from './EnvelopWithCheck';
import FinancialIcon from './FinancialIcon';
import GiftCardIcon from './GiftCard';
import GlobeIcon from './Globe';
import GoogleLogoIcon from './GoogleLogo';
import HandsHeart from './HandsHeart';
import HandsIcon from './HandsIcon';
import Handshake from './HandshakeIcon';
import HangUpIcon from './HangUp';
import Headset from './Headset';
import HealthIcon from './Health';
import HealthShield from './HealthShield';
import HeartIcon from './HeartIcon';
import HeartOnHand from './HeartOnHand';
import HelpIcon from './Help';
import HelpPageIcon from './HelpPage';
import HistoryIcon from './History';
import { HomeIcon } from './Home';
import HouseWithPlusIcon from './HouseWithPlus';
import InfoCircleIcon from './InfoCircle';
import InfoCircleOutline from './InfoCircleOutline';
import { InsuranceIcon } from './Insurance';
import { LeftIcon } from './Left';
import { LibraryIcon } from './Library';
import { ListIcon } from './List';
import LocalLibraryIcon from './LocalLibraryIcon';
import LogoIcon from './Logo';
import { MagnifyPlusIcon } from './MagnifyPlus';
import MainNav from './MainNav';
import MemberRightArrow from './MemberRightArrow';
import MenuPageIcon from './MenuPage';
import MessageQuestion from './MessageQuestion';
import MicrophoneIcon from './Microphone';
import MicrophoneMuteIcon from './MicrophoneMute';
import MinusIcon from './Minus';
import MoneyIcon from './Money';
import MyDuoIcon from './MyDuoIcon';
import NavigationIcon from './Navigation';
import NewDownIcon from './NewDown';
import NewUpIcon from './NewUp';
import { NotesIcon } from './Notes';
import PencilIcon from './PencilIcon';
import { PersonCheckCircle } from './PersonCheckCircle';
import PhoneIcon from './Phone';
import PhoneFilledIcon from './PhoneFilled';
import PhoneWithRingIcon from './PhoneWithRing';
import { PinIcon } from './Pin';
import PlusIcon from './Plus';
import RadioCircle from './RadioCircle';
import RatingStarIcon from './RatingStar';
import RewardsIcon from './Rewards';
import RightIcon from './Right';
import RightArrowIcon from './RightArrow';
import RightChevronIcon from './RightChevron';
import RightCircleIcon from './RightCircle';
import SaveIcon from './SaveIcon';
import SearchIcon from './Search';
import { SettingsIcon } from './Settings';
import ShareIcon from './Share';
import SharedIcon from './Shared';
import ShieldWithHeart from './ShieldWithHeart';
import Social from './Social';
import SparkleIcon from './Sparkle';
import { StarIcon } from './Star';
import TransportationIcon from './Transportation';
import UpIcon from './Up';
import { UserIcon } from './User';
import WarningCircleOutLine from './WarningCircleOutline';
import WaveIcon from './Wave';
import WavingHandIcon from './WavingHand';
import WelcomeIcon from './Welcome';
import ChatFinished from './chat/ChatFinished';
import Community from './chat/Community';
import MagnifyingGlass from './chat/MagnifyingGlass';
import ThumbsDown from './chat/ThumbsDown';
import ThumbsUp from './chat/ThumbsUp';
import { CashIcon, PiggyBankIcon } from './incentive';
import MenuBookmarkIcon from './menu/BookmarkIcon';
import DigitalDuo from './menu/DigitalDuo';
import MenuFaq from './menu/Faq';
import MenuHamburger from './menu/Hamburger';
import MenuHelp from './menu/Help';
import MenuHome from './menu/Home';
import MenuLogin from './menu/Login';
import MenuLogout from './menu/Logout';
import MenuNewHelp from './menu/NewHelp';
import MenuRecs from './menu/Recommendations';
import MenuShare from './menu/Share';
import FacebookIcon from './socials/Facebook';
import InstagramIcon from './socials/Instagram';
import LinkedInIcon from './socials/LinkedIn';

export { CelebrateImg } from './Celebrate';

export const icons = {
  addButton: AddButton,
  addButtonWithText: AddButtonWithText,
  alert: AlertIcon,
  backArrow: BackArrow,
  bell: BellIcon,
  clock: ClockIcon,
  creditCard: CreditCardIcon,
  copy: CopyIcon,
  dialPad: DialPadIcon,
  diamond: DiamondIcon,
  'double-circle': DoubleCircleIcon,
  duosHug: DuosHugIcon,
  envelopWithCheck: EnvelopWithCheckIcon,
  hangUp: HangUpIcon,
  logo: LogoIcon,
  magnifyPlus: MagnifyPlusIcon,
  microphone: MicrophoneIcon,
  microphoneMute: MicrophoneMuteIcon,
  wave: WaveIcon,
  welcome: WelcomeIcon,
  ratingStar: RatingStarIcon,
  right: RightIcon,
  'right-circle': RightCircleIcon,
  'right-arrow': RightArrowIcon,
  rightChevron: RightChevronIcon,
  call: CallIcon,
  infoCircle: InfoCircleIcon,
  home: HomeIcon,
  calendar: CalendarIcon,
  library: LibraryIcon,
  settings: SettingsIcon,
  pin: PinIcon,
  close: CloseIcon,
  closeWithCircle: CloseWithCircle,
  notes: NotesIcon,
  newUp: NewUpIcon,
  newDown: NewDownIcon,
  down: DownIcon,
  'down-circle': DownCircleIcon,
  left: LeftIcon,
  star: StarIcon,
  user: UserIcon,
  edit: EditIcon,
  insurance: InsuranceIcon,
  infoCircleOutline: InfoCircleOutline,
  list: ListIcon,
  complete: CompleteIcon,
  up: UpIcon,
  checkCircle: CheckCircleIcon,
  discussedBubble: DiscussedBubbleIcon,
  check: CheckIcon,
  discuss: DiscussIcon,
  discussFull: DiscussFullIcon,
  googleLogo: GoogleLogoIcon,
  discussed: DiscussedIcon,
  dropDown: DropDownIcon,
  help: HelpIcon,
  dialIcon: DialIcon,
  phoneCall: CirclePhoneIcon,
  search: SearchIcon,
  sparkle: SparkleIcon,
  health: HealthIcon,
  facebook: FacebookIcon,
  linkedIn: LinkedInIcon,
  instagram: InstagramIcon,
  wavingHand: WavingHandIcon,
  hands: HandsIcon,
  giftcard: GiftCardIcon,
  houseWithPlus: HouseWithPlusIcon,
  phoneWithRing: PhoneWithRingIcon,
  phone: PhoneIcon,
  history: HistoryIcon,
  share: ShareIcon,
  shared: SharedIcon,
  email: EmailIcon,
  checkedCircleOutline: CheckedCircleOutline,
  warningCircleOutline: WarningCircleOutLine,
  cancelCircleOutline: CancelCircleOutLine,
  helpPage: HelpPageIcon,
  menuPage: MenuPageIcon,
  healthShield: HealthShield,
  saveIcon: SaveIcon,
  financialIcon: FinancialIcon,
  heartIcon: HeartIcon,
  transportationIcon: TransportationIcon,
  appleIcon: AppleIcon,
  rewardsIcon: RewardsIcon,
  dialogIcon: DialogIcon,
  memberRightArrow: MemberRightArrow,
  bookmarkOutlineIcon: BookmarkOutlineIcon,
  bookmarkFilledIcon: BookmarkFilledIcon,
  myDuoIcon: MyDuoIcon,
  menuBookmark: MenuBookmarkIcon,
  menuHome: MenuHome,
  menuShare: MenuShare,
  menuRecs: MenuRecs,
  menuHelp: MenuHelp,
  menuNewHelp: MenuNewHelp,
  menuLogin: MenuLogin,
  menuLogout: MenuLogout,
  menuFaq: MenuFaq,
  menuHamburger: MenuHamburger,
  thumbsUp: ThumbsUp,
  thumbsDown: ThumbsDown,
  magnifyGlass: MagnifyingGlass,
  chatFinished: ChatFinished,
  community: Community,
  headset: Headset,
  social: Social,
  mainNav: MainNav,
  digitalDuo: DigitalDuo,
  messageQuestion: MessageQuestion,
  bullseyeArrow: BullseyeArrow,
  handsHeart: HandsHeart,
  globe: GlobeIcon,
  pencil: PencilIcon,
  cropSquare: CropSquareIcon,
  checkbox: CheckBoxIcon,
  navigation: NavigationIcon,
  money: MoneyIcon,
  cropCircle: CropCircleIcon,
  radioCircle: RadioCircle,
  minus: MinusIcon,
  plus: PlusIcon,
  piggyBank: PiggyBankIcon,
  cash: CashIcon,
  downArrow: DownArrowIcon,
  phoneFilled: PhoneFilledIcon,
  duosIcon: DuosIcon,
  localLibrary: LocalLibraryIcon,
  checkmarkWithRibbon: CheckmarkWithRibbon,
  handshake: Handshake,
  shieldWithHeart: ShieldWithHeart,
  personCheckCircle: PersonCheckCircle,
  duosLogoCircle: DuosLogoCircle,
};

export type Icon = keyof typeof icons;

export {
  AddButton,
  AddButtonWithText,
  AlertIcon,
  BackArrow,
  BellIcon,
  CalendarIcon,
  CallIcon,
  CircleIcon,
  ClockIcon,
  CloseIcon,
  CloseWithCircle,
  CopyIcon,
  CreditCardIcon,
  DialPadIcon,
  DiamondIcon,
  DoubleCircleIcon,
  DownIcon,
  DownCircleIcon,
  DuosHugIcon,
  EnvelopWithCheckIcon,
  HangUpIcon,
  HomeIcon,
  HealthIcon,
  InfoCircleIcon,
  LibraryIcon,
  LogoIcon,
  MagnifyPlusIcon,
  MicrophoneIcon,
  MicrophoneMuteIcon,
  NotesIcon,
  NewUpIcon,
  NewDownIcon,
  PinIcon,
  RatingStarIcon,
  RightIcon,
  RightChevronIcon,
  RightCircleIcon,
  RightArrowIcon,
  SettingsIcon,
  WaveIcon,
  WelcomeIcon,
  LeftIcon,
  StarIcon,
  UserIcon,
  EditIcon,
  InsuranceIcon,
  ListIcon,
  CompleteIcon,
  UpIcon,
  CheckCircleIcon,
  DiscussedBubbleIcon,
  CheckIcon,
  DiscussIcon,
  DiscussFullIcon,
  GoogleLogoIcon,
  DiscussedIcon,
  DropDownIcon,
  HelpIcon,
  DialIcon,
  CirclePhoneIcon,
  SparkleIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  WavingHandIcon,
  HandsIcon,
  HeartOnHand,
  GiftCardIcon,
  HouseWithPlusIcon,
  PhoneWithRingIcon,
  PhoneIcon,
  HistoryIcon,
  ShareIcon,
  SharedIcon,
  EmailIcon,
  SearchIcon,
  WarningCircleOutLine,
  CheckedCircleOutline,
  CancelCircleOutLine,
  HelpPageIcon,
  MenuPageIcon,
  HealthShield,
  SaveIcon,
  FinancialIcon,
  HeartIcon,
  TransportationIcon,
  AppleIcon,
  DialogIcon,
  MemberRightArrow,
  BookmarkOutlineIcon,
  BookmarkFilledIcon,
  MyDuoIcon,
  MenuBookmarkIcon,
  MenuHome,
  MenuShare,
  MenuRecs,
  MenuHelp,
  MenuNewHelp,
  MenuLogout,
  MenuFaq,
  MenuHamburger,
  ThumbsUp,
  ThumbsDown,
  MagnifyingGlass,
  ChatFinished,
  Community,
  Headset,
  Social,
  MainNav,
  DigitalDuo,
  MessageQuestion,
  BullseyeArrow,
  HandsHeart,
  MenuLogin,
  GlobeIcon,
  PencilIcon,
  CropSquareIcon,
  CheckBoxIcon,
  NavigationIcon,
  MoneyIcon,
  CropCircleIcon,
  RadioCircle,
  MinusIcon,
  PlusIcon,
  PiggyBankIcon,
  CashIcon,
  DownArrowIcon,
  PhoneFilledIcon,
  DuosIcon,
  LocalLibraryIcon,
  CheckmarkWithRibbon,
  Handshake,
  ShieldWithHeart,
  PersonCheckCircle,
  DuosLogoCircle,
};
