import classNames from 'classnames';
import React from 'react';

import { toFillColor, toGroupHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const AddButton = ({ className, size, hoverColorOverride = 'darkPurple', colorOverride = 'purple' }: IconProps) => {
  const fillClassName = toFillColor(colorOverride);
  const hoverFillClassName = toGroupHoverFillColor(hoverColorOverride);

  return (
    <svg
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, 'group')}
    >
      <circle cx="24" cy="24" r="24" className={classNames(fillClassName, hoverFillClassName)} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9867 12.158C24.3755 12.1579 24.7483 12.3086 25.0231 12.5769C25.2978 12.8453 25.4521 13.2094 25.452 13.5891L25.4475 22.2832L34.3493 22.2789C34.5439 22.2755 34.7373 22.3099 34.9181 22.3803C35.0989 22.4507 35.2635 22.5556 35.4023 22.6888C35.541 22.822 35.6513 22.9809 35.7264 23.1562C35.8016 23.3315 35.8403 23.5198 35.8402 23.7099C35.8401 23.9001 35.8013 24.0883 35.7259 24.2637C35.6505 24.4391 35.5401 24.5981 35.4012 24.7315C35.2623 24.8648 35.0976 24.9699 34.9167 25.0404C34.7358 25.111 34.5424 25.1457 34.3478 25.1425L25.446 25.1468L25.4416 33.841C25.4347 34.2164 25.2771 34.5743 25.0028 34.8376C24.7284 35.101 24.3593 35.2486 23.9748 35.2488C23.5904 35.249 23.2214 35.1017 22.9473 34.8387C22.6733 34.5756 22.5161 34.2178 22.5096 33.8424L22.514 25.1483L13.6123 25.1526C13.2279 25.1463 12.8616 24.9928 12.5923 24.7251C12.3229 24.4574 12.1721 24.097 12.1723 23.7215C12.1725 23.3461 12.3237 22.9855 12.5933 22.7176C12.8629 22.4496 13.2294 22.2957 13.6138 22.289L22.5155 22.2847L22.52 13.5905C22.5202 13.2108 22.6749 12.8466 22.95 12.578C23.225 12.3093 23.5979 12.1583 23.9867 12.158Z"
        fill="#F7FAFC"
      />
    </svg>
  );
};

export default AddButton;
