import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import { IconProps } from '../../types/ui';
import { Icon, icons } from '../icons';

type BannerProps = {
  type: BannerType;
  icon: Icon;
  iconProps?: IconProps;
  className?: string;
};

type BannerType = 'info-yellow' | 'info-mediumTeal' | 'success' | 'error';

const Banner = ({ type, icon, iconProps, children, className }: PropsWithChildren<BannerProps>) => {
  const BannerIcon = icons[icon];
  let colorClasses;
  switch (type) {
    case 'info-yellow':
      colorClasses = 'bg-yellowTint border-mediumGrayOne';
      break;
    case 'info-mediumTeal':
      colorClasses = 'bg-lightTeal border-mediumGrayOne';
      break;
    case 'success':
      colorClasses = 'bg-lightGreen border-green';
      break;
    case 'error':
      colorClasses = 'bg-redTint border-errorRedTwo';
      break;
  }
  return (
    <div
      className={classNames(
        'w-full flex items-start p-[8px] mt-[32px] border-2 rounded-[8px] py-[16px] pr-[16px]',
        colorClasses,
        className
      )}
    >
      <BannerIcon {...iconProps} />
      <div className="flex flex-wrap items-center ml-[8px]">{children}</div>
    </div>
  );
};

export default Banner;
