import React, { Ref } from 'react';

import { Text } from '.';
import { TextType } from '../../theme/typography';
import WarningCircleOutLine from '../icons/WarningCircleOutline';
import Checkbox from './Checkbox';
import FormControlLabel from './FormControlLabel';

type Props = {
  label: string | JSX.Element;
  name: string;
  labelType?: TextType;
  labelStyle?: string;
  controlRef?: Ref<HTMLInputElement>;
  labelRef?: Ref<HTMLLabelElement>;
  error?: string;
  errorStyle?: string;
};

const CheckboxWithLabel = ({
  name,
  label,
  labelType,
  labelStyle,
  controlRef,
  labelRef,
  error,
  errorStyle = 'text',
}: Props) => {
  const labelText = (
    <Text component="span" privacyLevel="pii" color="darkGrayOne" variant={labelType} className={labelStyle}>
      {label}
    </Text>
  );

  return (
    <>
      <FormControlLabel
        label={labelText}
        control={<Checkbox name={name} ref={controlRef} error={error} errorStyle={errorStyle} />}
        ref={labelRef}
      />
      <div>
        {error && (
          <>
            {errorStyle === 'text' && (
              <span className="flex items-center flex-wrap gap-1 mt-[8px]">
                <WarningCircleOutLine size={20} colorOverride="errorRed" />
                <Text color="errorRed" variant="subtitle-2" className="mt-[4px]">
                  {error}
                </Text>
              </span>
            )}
          </>
        )}
      </div>
    </>
  );
};

CheckboxWithLabel.displayName = 'Checkbox';

export default CheckboxWithLabel;
