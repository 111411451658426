import classNames from 'classnames';
import React, { Ref } from 'react';
import { Controller } from 'react-hook-form';

import { mergeForwardedRef } from '../../utils/ref';
import { CheckIcon } from '../icons';

type Props<T extends string> = {
  name: T;
  error?: string;
  errorStyle?: string;
};

const Checkbox = React.forwardRef(
  <T extends string>({ name, error, errorStyle }: Props<T>, forwardedRef: Ref<HTMLInputElement>) => {
    const isErrorText = errorStyle === 'text';
    const hasError = error && isErrorText;
    const commonClasses = classNames(
      'cursor-pointer border-[2px] rounded-[4px] min-w-[24px] w-[24px] h-[24px] flex items-center justify-center group-focus-within:border-darkGrayOne',
      hasError ? 'border-errorRed' : 'border-darkGrayOne'
    );

    const checkboxClasses = classNames('relative inline-flex items-center cursor-pointer group', 'border-darkGrayOne');

    return (
      <Controller
        name={name}
        render={({ field }) => {
          return (
            <span className={checkboxClasses}>
              <span className={commonClasses}>
                {field.value ? (
                  <span className="bg-linkBlue border-solid rounded-[4px]">
                    <CheckIcon size={24} colorOverride="white" />
                  </span>
                ) : null}
              </span>
              <input
                type="checkbox"
                className="absolute top-0 left-0 w-full h-full m-0 p-0 opacity-0 cursor-pointer focus-visible:bg-"
                checked={field.value}
                {...mergeForwardedRef(field, forwardedRef)}
              />
            </span>
          );
        }}
      />
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
