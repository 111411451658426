import React, { Dispatch, PropsWithChildren, SetStateAction, createContext, useMemo, useState } from 'react';

import { useGetAWVResources } from '../hooks/api';
import { AWVResource } from '../types/AnnualWellness';

export type ActionFormType = 'edit' | 'new';

type AWVContextProps = {
  memberId: string;
  requestId?: string;
  hasPcp?: boolean;
  scrollRef?: React.RefObject<HTMLDivElement> | null;
  duoId?: string;
};

export const AWVContext = createContext<{
  awvResources?: AWVResource[];
  loading: boolean;
  memberId: string;
  hasPcp?: boolean;
  attestationId: string;
  setAttestationId: Dispatch<SetStateAction<string>>;
  duoId?: string;
}>({
  awvResources: [],
  loading: false,
  memberId: '',
  hasPcp: false,
  attestationId: '',
  setAttestationId: () => {},
  duoId: '',
});

function AWVContextWrapper({ memberId, children, hasPcp, duoId }: PropsWithChildren<AWVContextProps>) {
  const awvResourcesQuery = useGetAWVResources({ memberId });

  const [attestationId, setAttestationId] = useState<string>('');

  const awvResources = useMemo(() => awvResourcesQuery.data ?? [], [awvResourcesQuery]);

  const loading = useMemo(() => awvResourcesQuery.isLoading, [awvResourcesQuery.isLoading]);

  const value = useMemo(() => {
    return {
      awvResources,
      loading,
      memberId,
      hasPcp,
      attestationId,
      setAttestationId,
      duoId,
    };
  }, [awvResources, loading, memberId, hasPcp, attestationId, duoId]);

  return <AWVContext.Provider value={value}>{children}</AWVContext.Provider>;
}

export default AWVContextWrapper;
