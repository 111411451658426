import moment from 'moment';

import { Readiness } from '../types/Member';

interface WithCreationTime {
  creationTime: string | Date;
}

interface WithLastModifiedTime {
  lastModifiedTime?: string;
}

interface WithReadinessStatus {
  readinessStatus?: Readiness;
}

function readinessPriority(readiness?: Readiness): number {
  if (readiness === 'readyForChange') {
    return 0;
  } else if (readiness === 'thinkingAboutChange') {
    return 1;
  } else if (readiness === undefined) {
    return 2;
  } else {
    return 3;
  }
}

export function compareByReadinessStatus(first: WithReadinessStatus, second: WithReadinessStatus): number {
  return readinessPriority(first.readinessStatus) - readinessPriority(second.readinessStatus);
}

export function compareByCreationTime<T extends WithCreationTime>(a: T, b: T): number {
  return moment(b.creationTime).diff(a.creationTime);
}

export function sortByCreationTime<T extends WithCreationTime>(arrayWithCreationTime: T[]): T[] {
  return arrayWithCreationTime.sort(compareByCreationTime);
}

function compareByLastModifiedTime<T extends WithLastModifiedTime>(a: T, b: T): number {
  return moment(b.lastModifiedTime).diff(a.lastModifiedTime);
}

export function sortByLastModifiedTime<T extends WithLastModifiedTime>(arrayWithLastModifiedTime: T[]): T[] {
  return arrayWithLastModifiedTime.sort(compareByLastModifiedTime);
}
