import React from 'react';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const HeartIcon = ({ size = 20, colorOverride }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1615 6.49221C19.5595 8.20686 19.2618 10.051 18.3466 11.5395C16.7298 14.1734 14.1133 16.2188 11.5715 18.0739C10.6371 18.7558 9.36454 18.7558 8.43016 18.0739C5.88835 16.2188 3.27179 14.1734 1.65499 11.5395C0.73983 10.051 0.442106 8.20686 0.840145 6.49221C1.69059 2.58639 6.39989 0.990385 9.3333 3.60783C9.49806 3.75387 9.6499 3.91575 9.80583 4.08199C9.86975 4.15013 9.93435 4.21901 10.0008 4.28783C10.0673 4.21901 10.1319 4.15013 10.1958 4.08198C10.3517 3.91574 10.5036 3.75387 10.6683 3.60783C13.6017 0.990385 18.3111 2.58639 19.1615 6.49221ZM8.91931 12.664C8.93554 13.1323 9.23414 13.5199 9.702 13.5461C9.79247 13.5511 9.89207 13.5541 10.0012 13.5541C10.1104 13.5541 10.21 13.5511 10.3005 13.5461C10.7684 13.5199 11.067 13.1323 11.0832 12.664C11.092 12.4101 11.1003 12.0807 11.1057 11.6598C11.5273 11.6543 11.8572 11.6461 12.1114 11.6373C12.5797 11.621 12.9673 11.3224 12.9935 10.8546C12.9985 10.7641 13.0015 10.6645 13.0015 10.5553C13.0015 10.4461 12.9985 10.3465 12.9935 10.2561C12.9673 9.78821 12.5797 9.4896 12.1114 9.47338C11.8572 9.46457 11.5274 9.45628 11.1058 9.45081C11.1003 9.02872 11.092 8.69858 11.0832 8.44411C11.067 7.97581 10.7684 7.5882 10.3005 7.56208C10.21 7.55703 10.1104 7.55406 10.0012 7.55406C9.89207 7.55406 9.79247 7.55703 9.702 7.56208C9.23414 7.5882 8.93554 7.97581 8.91931 8.44411C8.91049 8.69858 8.9022 9.02872 8.89673 9.45082C8.47535 9.45629 8.14568 9.46457 7.89151 9.47338C7.42321 9.4896 7.0356 9.78821 7.00948 10.2561C7.00443 10.3465 7.00146 10.4461 7.00146 10.5553C7.00146 10.6645 7.00443 10.7641 7.00948 10.8546C7.0356 11.3224 7.42321 11.621 7.89151 11.6373C8.14569 11.6461 8.47536 11.6543 8.89676 11.6598C8.90223 12.0807 8.91051 12.4101 8.91931 12.664Z"
        className={fillClass}
      />
    </svg>
  );
};

export default HeartIcon;
