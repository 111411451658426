import { T } from '@transifex/react';
import { useRouter } from 'next/router';
import React from 'react';

import FooterLink from './FooterLink';

const FooterLinks = () => {
  const router = useRouter();
  const isAuthenticated = router.asPath.startsWith('/app');
  const helpPath = isAuthenticated ? '/app/help' : '/help';

  return (
    <div className="flex tablet:flex-row flex-col gap-6">
      <FooterLink href={helpPath} underline={false}>
        <T _str="Help" />
      </FooterLink>
      <FooterLink href="https://www.getduos.com/about-us" underline={false} sameTab={false}>
        <T _str="About DUOS" />
      </FooterLink>
      <FooterLink href="https://www.getduos.com/team" underline={false} sameTab={false}>
        <T _str="Meet the Team" />
      </FooterLink>
    </div>
  );
};

export default FooterLinks;
