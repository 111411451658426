import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { PrivacyLevel } from '../../types/Privacy';
import { AlertIcon, CompleteIcon } from '../icons';
import { IconButton } from './IconButton';
import Text from './Text';

type AlertProps = {
  width?: number;
  onClose?: () => void;
  className?: string;
  inline?: boolean;
  error?: boolean;
  errorTextPadding?: string;
  privacyLevel?: PrivacyLevel;
};

const StyledCard = styled('div')<{ parentWidth?: number }>`
  ${(props) => (props.parentWidth ? `width: ${`${props.parentWidth + 4}px`};` : '')}
`;

export function Alert({
  width,
  onClose,
  children,
  className = '',
  inline = false,
  error = false,
  errorTextPadding,
  privacyLevel = 'pii',
}: PropsWithChildren<AlertProps>) {
  const Icon = () => {
    return error ? <AlertIcon colorOverride="errorRedTwo" /> : <CompleteIcon />;
  };

  const CardColors = error ? 'bg-lightRed border-errorRedTwo border-[2px]' : 'bg-warmWhite border-green border-[3px]';
  const Padding = error ? (errorTextPadding ? errorTextPadding : 'px-[16px] py-[8px]') : 'p-[21px]';
  const TextColor = error ? 'errorRedTwo' : 'green';

  return (
    <>
      {!inline && (
        <StyledCard
          parentWidth={width}
          className={classNames(
            'absolute w-full rounded-[8px] flex flex-row items-center justify-start gap-[8px] z-30',
            CardColors,
            Padding,
            className
          )}
        >
          <Icon />
          <Text privacyLevel={privacyLevel} className="flex-1" variant="body2" color={TextColor}>
            {children}
          </Text>
          <IconButton icon={'close'} color={error ? 'errorRedTwo' : 'green'} onClick={onClose} />
        </StyledCard>
      )}
      {inline && (
        <div className={classNames(CardColors, Padding, 'rounded-[8px] flex items-start gap-1', className)}>
          <Icon />
          <Text privacyLevel={privacyLevel} variant="body2" color={TextColor}>
            {children}
          </Text>
        </div>
      )}
    </>
  );
}

export default Alert;
