import uniqueId from 'lodash/uniqueId';
import moment from 'moment';

import { CallEntry } from '../components/member/callHistory/CallLogEntry';
import { getCallStatusFromParticipants, seperateParticipantsByDirection } from '../utils/Call';

export type CallStatus =
  | 'initiated'
  | 'answered'
  | 'queued'
  | 'in-progress'
  | 'completed'
  | 'busy'
  | 'no-answer'
  | 'ringing'
  | 'canceled'
  | 'failed';

export namespace CallStatus {
  const PendingCallStatuses: CallStatus[] = ['initiated', 'queued', 'ringing'];
  const InactiveCallStatuses: CallStatus[] = ['completed', 'busy', 'no-answer', 'canceled', 'failed'];

  export function isPending(callStatus: CallStatus): boolean {
    return PendingCallStatuses.includes(callStatus);
  }

  export function isInactive(callStatus: CallStatus): boolean {
    return InactiveCallStatuses.includes(callStatus);
  }

  export function isActive(callStatus: CallStatus): boolean {
    return !isInactive(callStatus);
  }
}

export type CallDirection = 'inbound' | 'outbound-api' | 'outbound-dial';

export type LegacyCallResponse = {
  meta: {
    limit: number;
    offset: number;
  };
  data: CallLog[];
  error: any[];
};

export type CallLogResponse = LegacyCallResponse;

export type CallLog = {
  participants: CallParticipant[];
  initiator: string;
  createdAt: string;
  twilioRecordingId: string;
  duration: number;
};

export namespace CallLog {
  /**
   * Inbound call is the client browser connecting to Twilio.
   * Outbound calls are Twilio reaching out to phone numbers.
   *
   * 1. call status -> first creation time outbound.
   * 2. call numbers -> filter out the inbound ones.
   * 3. callId use first creation time outbound.
   * 4. back up duration is the duration of the inbound.
   * @param callLog CallLog we want to draw UI of.
   * @return Call Entry Component that matches this Call Log object.
   */
  export function convertCallLogToEntry(callLog: CallLog) {
    const { inboundParticipant, outboundParticipants } = seperateParticipantsByDirection(callLog.participants);
    const outboundNumbers = [...new Set(outboundParticipants.map((participant) => participant.toPhoneNumber))];
    const inboundNumber = inboundParticipant?.toPhoneNumber;
    return {
      callId: inboundParticipant?.twilioCallId ?? outboundParticipants[0]?.twilioCallId ?? uniqueId(),
      callNumbers: outboundNumbers.length > 0 ? outboundNumbers : inboundNumber ? [inboundNumber] : [],
      user: callLog.initiator.slice(0, 7) === 'client:' ? callLog.initiator.slice(7) : callLog.initiator,
      creationTime: moment.utc(callLog.createdAt).toString(),
      durationInSeconds: callLog.duration ? callLog.duration : inboundParticipant?.duration ?? 0,
      status: getCallStatusFromParticipants(outboundParticipants, inboundParticipant),
      recordingId: callLog.twilioRecordingId,
    } as CallEntry;
  }
}

export type CallParticipant = {
  id: string;
  twilioCallId: string;
  parentCallId?: string;
  initiator: string;
  direction: CallDirection;
  toPhoneNumber: string;
  associatedMemberId?: string;
  sipResponseCode?: number;
  duration?: number;
  twilioRecordingId?: string;
  recordingUrl?: string;
  recordingDuration?: number;
  callStatus: CallStatus;
  lastUpdate: string;
  creationTime: string;
};

export type VoiceConference = {
  id: string;
  twilioConferenceId?: string;
  durationSeconds?: number;
  associatedMemberId?: string;
  twilioRecordingId?: string;
  recordingUrl?: string;
  recordingDurationSeconds?: number;
  callParticipants: CallParticipant[];
  creationTime: string;
};

export namespace VoiceConference {
  export function getOverallCallStatus(conference: VoiceConference): CallStatus {
    const { inboundParticipant, outboundParticipants } = seperateParticipantsByDirection(conference.callParticipants);
    const pendingParticipantStatus = outboundParticipants.find((participant) =>
      CallStatus.isPending(participant.callStatus)
    )?.callStatus;
    const activeParticipantStatus = outboundParticipants.find((participant) =>
      CallStatus.isActive(participant.callStatus)
    )?.callStatus;
    return pendingParticipantStatus ?? activeParticipantStatus ?? inboundParticipant?.callStatus ?? 'initiated';
  }
}

export type AddConferenceParticipantRequest = {
  toPhoneNumber: string;
};

export type CallRecording = {
  mediaUrl: string;
};
