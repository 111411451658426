import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import {
  toBackgroundColor,
  toBorderColor,
  toGroupActiveBackgroundColor,
  toGroupHoverBackgroundColor,
} from '../../../theme/colors';
import { ColorType } from '../../../types/ui';

type IconBorderProps = {
  size?: 'normal' | 'large' | 'small' | 'xlarge' | 'xxxlarge' | 'tiny';
  color?: ColorType;
  hoverColor?: ColorType;
  activeColor?: ColorType;
  border?: ColorType;
  borderWidth?: '1px' | '2px'; // Add a new prop for border width
  className?: string;
};

const IconBorder = ({
  color = 'lightGrayTwo',
  hoverColor,
  activeColor,
  size = 'normal',
  border,
  borderWidth = '2px',
  children,
  className,
}: PropsWithChildren<IconBorderProps>) => {
  const colorClass = toBackgroundColor(color);
  const hoverColorClass = toGroupHoverBackgroundColor(hoverColor);
  const activeColorClass = toGroupActiveBackgroundColor(activeColor);
  const borderClass = toBorderColor(border);

  return (
    <div
      className={classNames(
        colorClass,
        hoverColorClass,
        activeColorClass,
        'rounded-full flex flex-col items-center justify-center',
        {
          'min-w-[40px] min-h-[40px] w-[40px] h-[40px]': size === 'normal',
          'min-w-[48px] min-h-[48px] w-[48px] h-[48px]': size === 'large',
          'min-w-[250px] min-h-[250px] w-[250px] h-[250px]': size === 'xxxlarge',
          'min-w-[80px] min-h-[80px] w-[80px] h-[80px]': size === 'xlarge',
          'min-w-[36px] min-h-[36px] w-[36px] h-[36px]': size === 'small',
          'min-w-[18px] min-h-[18px] w-[18px] h-[18px]': size === 'tiny',
          border: border && borderWidth === '1px',
          'border-2': border && borderWidth === '2px',
        },
        border ? borderClass : '',
        className
      )}
    >
      {children}
    </div>
  );
};

export default IconBorder;
