import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import Container from './Container';
import { COMMON_CLASSES, GRID_CHILDREN_SECTION_CLASSES, TYPE_CLASSES, TYPE_CLASSES_WITHOUT_MARGIN } from './common';

const GridWithColumns = ({
  noMargin = false,
  fullPageColumn = false,
  children,
  className,
}: PropsWithChildren<{ fullPageColumn?: boolean; noMargin?: boolean; className?: string }>) => {
  const classes = noMargin ? TYPE_CLASSES_WITHOUT_MARGIN : TYPE_CLASSES;
  const layoutClasses = classNames(...classes['new']);
  const commonClasses = classNames(COMMON_CLASSES);
  return (
    <div className={classNames(fullPageColumn ? GRID_CHILDREN_SECTION_CLASSES : '', className)}>
      <Container>
        <div className={classNames(commonClasses, layoutClasses)}>{children}</div>
      </Container>
    </div>
  );
};

export default GridWithColumns;
