import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor, toHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const MinusIcon = ({ size, colorOverride, hoverColorOverride }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const hoverClass = toHoverFillColor(hoverColorOverride);
  const groupHoverClass = toGroupHoverFillColor(hoverColorOverride);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className="flex-shrink-0"
    >
      <mask
        id="mask0_181_3461"
        className="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_181_3461)">
        <path
          d="M6 13C5.71667 13 5.47917 12.9042 5.2875 12.7125C5.09583 12.5208 5 12.2833 5 12C5 11.7167 5.09583 11.4792 5.2875 11.2875C5.47917 11.0958 5.71667 11 6 11H18C18.2833 11 18.5208 11.0958 18.7125 11.2875C18.9042 11.4792 19 11.7167 19 12C19 12.2833 18.9042 12.5208 18.7125 12.7125C18.5208 12.9042 18.2833 13 18 13H6Z"
          className={classNames(fillClass, hoverClass, groupHoverClass)}
        />
      </g>
    </svg>
  );
};

export default MinusIcon;
