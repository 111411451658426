import classNames from 'classnames';
import React from 'react';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const CropSquareIcon = ({ size = 24, colorOverride }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className="flex-shrink-0"
    >
      <mask
        id="mask0_3295_1809"
        className="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3295_1809)">
        <rect x="3" y="3" width="18" height="18" rx="2" fill="white" />
        <path
          d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19Z"
          fill="#B4B4B9"
          className={classNames(fillClass)}
        />
      </g>
    </svg>
  );
};

export default CropSquareIcon;
