import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor, toHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const HistoryIcon = ({ size = 24, colorOverride = 'purple', className }: IconProps) => {
  const colorClass = toFillColor(colorOverride);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, 'group')}
    >
      <path
        d="M13.2595 2.99958C8.16954 2.85958 3.99954 6.94958 3.99954 11.9996H2.20954C1.75954 11.9996 1.53954 12.5396 1.85954 12.8496L4.64954 15.6496C4.84954 15.8496 5.15954 15.8496 5.35954 15.6496L8.14954 12.8496C8.21864 12.7791 8.26533 12.6897 8.28373 12.5927C8.30212 12.4957 8.29139 12.3955 8.25289 12.3046C8.21439 12.2137 8.14985 12.1362 8.06739 12.0819C7.98493 12.0277 7.88826 11.999 7.78954 11.9996H5.99954C5.99954 8.09958 9.17954 4.94958 13.0995 4.99958C16.8195 5.04958 19.9495 8.17958 19.9995 11.8996C20.0495 15.8096 16.8995 18.9996 12.9995 18.9996C11.3895 18.9996 9.89954 18.4496 8.71954 17.5196C8.52801 17.3687 8.28769 17.2935 8.04431 17.3082C7.80093 17.323 7.57146 17.4267 7.39954 17.5996C6.97954 18.0196 7.00954 18.7296 7.47954 19.0896C9.05067 20.3321 10.9965 21.0053 12.9995 20.9996C18.0495 20.9996 22.1395 16.8296 21.9995 11.7396C21.8695 7.04958 17.9495 3.12958 13.2595 2.99958ZM12.7495 7.99958C12.3395 7.99958 11.9995 8.33958 11.9995 8.74958V12.4296C11.9995 12.7796 12.1895 13.1096 12.4895 13.2896L15.6095 15.1396C15.9695 15.3496 16.4295 15.2296 16.6395 14.8796C16.8495 14.5196 16.7295 14.0596 16.3795 13.8496L13.4995 12.1396V8.73958C13.4995 8.33958 13.1595 7.99958 12.7495 7.99958Z"
        className={colorClass}
      />
    </svg>
  );
};

export default HistoryIcon;
