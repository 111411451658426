import { ActionItem, ActionItemInteraction, ActionItemPayload } from '../types/ActionItem';
import { Comment, CommentObjectType, NewCommentPayload, NewCommentResponse } from '../types/Comments';
import { DashboardPrompt } from '../types/DashboardPrompt';
import { ServiceRequest } from '../types/ServiceRequest';
import { ActionPayload, QuestionObject, Survey, SurveyInstanceResponse } from '../types/Survey';
import { SurveyInstance, SurveyStatus } from '../types/SurveyInstance';
import { getJson, postJson, putJson } from './common';

export function getTodoListByUserId(userId: string): Promise<ActionItem[]> {
  return getJson(`/v1/admin/users/${userId}/todoList`, 'rehoboam');
}

export function getTodoItemById(itemId: string): Promise<ActionItem> {
  return getJson(`/v1/admin/todoItems/${itemId}`, 'rehoboam');
}

export function addTodoListItemByUserId(userId: string, payload: ActionItemPayload): Promise<ActionItem | undefined> {
  return postJson<ActionItem, ActionItemPayload>(
    `/v1/admin/users/${userId}/todoList/todoItem`,
    payload,
    false,
    'rehoboam'
  );
}

export function updateTodoListItemByItemId(
  itemId: string,
  payload: ActionItemPayload
): Promise<ActionItem | undefined> {
  return putJson<ActionItem, ActionItemPayload>(`/v1/admin/todoItems/${itemId}`, payload, false, 'rehoboam');
}

export function getCommentsByObjectIdAndType({
  objectId,
  objectType,
}: {
  objectId?: string;
  objectType: CommentObjectType;
}): Promise<Comment[]> {
  return getJson(`/v1/admin/comments?objectId=${objectId}&objectType=${objectType}`, 'rehoboam');
}

export function createComment(payload: NewCommentPayload): Promise<NewCommentResponse | undefined> {
  return postJson<NewCommentResponse, NewCommentPayload>(`/v1/admin/comments`, payload, false, 'rehoboam');
}

export function editComment(payload: { commentId: string; text: string }): Promise<NewCommentResponse | undefined> {
  return putJson<NewCommentResponse, { text: string }>(
    `/v1/admin/comments/${payload.commentId}`,
    { text: payload.text },
    false,
    'rehoboam'
  );
}

export function getInteractionsByTodoItemId(itemId: string): Promise<ActionItemInteraction[]> {
  return getJson(`/v1/admin/todoItems/${itemId}/interaction`, 'rehoboam');
}

export function setItemAsDiscussedById(itemId: string): Promise<void> {
  return postJson<void, {}>(`/v1/admin/todoItems/${itemId}/discussed`, {}, true, 'rehoboam');
}

export function getTodoListItem(itemId: string) {
  return getJson<ActionItem>(`/v1/admin/todoItems/${itemId}`, 'rehoboam');
}

export function getServiceRequestForMember(memberId: string): Promise<ServiceRequest[]> {
  return getJson<ServiceRequest[]>(`/v1/admin/member/${memberId}/service_requests`, 'rehoboam');
}

export function getDashboardPrompts(memberId: string): Promise<DashboardPrompt[]> {
  return getJson<DashboardPrompt[]>(`/v1/admin/members/${memberId}/dashboard_prompts`, 'rehoboam');
}

export function getSurveyForMember({ surveyId }: { surveyId: string }): Promise<Survey> {
  return getJson<Survey>(`/v1/admin/member/rehoboam/surveys/${surveyId}?expand=true`, 'rehoboam');
}

export function getQuestion({ questionId }: { questionId: string }): Promise<QuestionObject> {
  return getJson<QuestionObject>(`/v1/admin/rehoboam/questions/${questionId}`, 'rehoboam');
}

export type CreateSurveyInstancePayload = {
  userId: string;
  surveyName: string;
};

export function createSurveyInstance({
  userId,
  surveyName,
}: CreateSurveyInstancePayload): Promise<SurveyInstance | undefined> {
  return postJson<SurveyInstance, CreateSurveyInstancePayload>(
    `/v1/admin/member/surveyInstances`,
    { userId, surveyName },
    undefined,
    'rehoboam'
  );
}

export function createSurveyInstanceByAdmin({
  userId,
  surveyName,
}: CreateSurveyInstancePayload): Promise<SurveyInstance | undefined> {
  return postJson<SurveyInstance, CreateSurveyInstancePayload>(
    `/v1/admin/rehoboam/surveys_instances`,
    { userId, surveyName },
    undefined,
    'rehoboam'
  );
}

export function getSurveyInstances({ status }: { status?: string }): Promise<SurveyInstance[]> {
  const params = new URLSearchParams();
  if (status) params.append('status', status);
  return getJson<SurveyInstance[]>(`/v1/rest/surveyInstances?${params.toString()}`, 'rehoboam');
}

export function getSurveyInstancesByAdmin({ userId }: { userId: string }): Promise<SurveyInstance[]> {
  const params = new URLSearchParams();
  params.append('userId', userId);
  return getJson<SurveyInstance[]>(`/v1/admin/rehoboam/survey_instances?${params.toString()}`, 'rehoboam');
}

export type GetSurveyInstancePayload = {
  surveyInstanceId: string;
};

export function getSurveyInstance({ surveyInstanceId }: GetSurveyInstancePayload): Promise<SurveyInstanceResponse> {
  return getJson<SurveyInstanceResponse>(`/v1/admin/member/surveyInstances/${surveyInstanceId}`, 'rehoboam');
}

export function getSurveyInstanceByAdmin({
  surveyInstanceId,
}: GetSurveyInstancePayload): Promise<SurveyInstanceResponse> {
  return getJson<SurveyInstanceResponse>(`/v1/admin/rehoboam/survey_instances/${surveyInstanceId}`, 'rehoboam');
}

export function getSurvey({ surveyId }: { surveyId: string }): Promise<Survey> {
  return getJson<Survey>(`/v1/rest/surveys/${surveyId}?expand=true`, 'rehoboam');
}

export function getSurveyByAdmin({ surveyId }: { surveyId: string }): Promise<Survey> {
  return getJson<Survey>(`/v1/admin/rehoboam/surveys/${surveyId}?expand=true`, 'rehoboam');
}

export function submitSurveyAction({
  surveyInstanceId,
  payload,
}: {
  surveyInstanceId: string;
  payload: ActionPayload;
}): Promise<SurveyInstance | undefined> {
  return postJson<SurveyInstance, ActionPayload>(
    `/v1/admin/member/surveyInstances/${surveyInstanceId}/action`,
    payload,
    undefined,
    'rehoboam'
  );
}

export function submitSurveyActionByAdmin({
  surveyInstanceId,
  payload,
}: {
  surveyInstanceId: string;
  payload: ActionPayload;
}): Promise<SurveyInstance | undefined> {
  return postJson<SurveyInstance, ActionPayload>(
    `/v1/admin/rehoboam/survey_instances/${surveyInstanceId}/action`,
    payload,
    undefined,
    'rehoboam'
  );
}

export function submitActivationSurveyAction({
  surveyInstanceId,
  payload,
}: {
  surveyInstanceId: string;
  payload: ActionPayload;
}): Promise<SurveyInstance | undefined> {
  return postJson<SurveyInstance, ActionPayload>(
    `/v1/rest/surveyInstances/${surveyInstanceId}/action`,
    payload,
    undefined,
    'rehoboam'
  );
}

export function submitActivationSurveyActionMember({
  surveyInstanceId,
  payload,
}: {
  surveyInstanceId: string;
  payload: ActionPayload;
  isAuthed?: boolean;
}): Promise<SurveyInstance | undefined> {
  return postJson<SurveyInstance, ActionPayload>(
    `/v1/admin/member/surveyInstances/${surveyInstanceId}/action`,
    payload,
    undefined,
    'rehoboam'
  );
}

export function getActivationSurveyQuestion({ questionId }: { questionId: string }): Promise<QuestionObject> {
  return getJson<QuestionObject>(`/v1/rest/questions/${questionId}`, 'rehoboam');
}

export function getCurrentUserSurveysByStatus(status?: SurveyStatus): Promise<SurveyInstanceResponse[]> {
  const params = new URLSearchParams();
  status && params.append('status', status);
  return getJson<SurveyInstanceResponse[]>(`/v1/rest/surveyInstances?${params.toString()}`, 'rehoboam');
}

export function getAllSurveyInstances(userId?: string): Promise<SurveyInstanceResponse[]> {
  const params = new URLSearchParams();
  userId && params.append('userId', userId);
  return getJson<SurveyInstanceResponse[]>(`/v1/rest/surveyInstances?${params.toString()}`, 'rehoboam');
}
