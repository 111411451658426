import { toFillColor, toStrokeColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const CancelCircleOutLine = ({ size = 24, colorOverride = 'darkGrayOne' }: IconProps) => {
  const fillColor = toFillColor(colorOverride);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="flex-shrink-0"
    >
      <path
        className={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46409 8.46447C8.85462 8.07394 9.48778 8.07394 9.87831 8.46447L11.9996 10.5858L14.1209 8.46447C14.5115 8.07394 15.1446 8.07394 15.5352 8.46447C15.9257 8.85499 15.9257 9.48815 15.5352 9.87868L13.4138 12L15.5352 14.1213C15.9257 14.5118 15.9257 15.145 15.5352 15.5355C15.1446 15.9261 14.5115 15.9261 14.1209 15.5355L11.9996 13.4142L9.87831 15.5355C9.48778 15.9261 8.85462 15.9261 8.46409 15.5355C8.07357 15.145 8.07357 14.5118 8.46409 14.1213L10.5854 12L8.46409 9.87868C8.07357 9.48816 8.07357 8.85499 8.46409 8.46447Z"
      />
      <path
        className={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
      />
    </svg>
  );
};

export default CancelCircleOutLine;
