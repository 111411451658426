export abstract class ApiError extends Error {
  constructor(public status: number, public message: string) {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
    this.name = this.constructor.name;
  }
}

export class BadRequest extends ApiError {
  constructor(message?: string) {
    super(400, message ?? 'Bad request');
  }
}

export class Unauthorized extends ApiError {
  constructor() {
    super(401, 'Unauthorized request');
  }
}

export class NotFound extends ApiError {
  constructor() {
    super(404, 'Resource was not found error');
  }
}

export class ConflictRequest extends ApiError {
  constructor() {
    super(409, 'Conflict request');
  }
}

export class InternalServiceError extends ApiError {
  constructor() {
    super(500, 'Internal service error');
  }
}
