import { CAREMANAGER_HOME, CAREMANAGER_PWD_LOGIN, DUO_LOGIN, MEMBER_LOGIN } from '../routes';
import { BadRequest, ConflictRequest, InternalServiceError, NotFound, Unauthorized } from './errors';

type API = 'member' | 'rehoboam';

const defaultOptions: RequestInit = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
};

/**
 * Helper to throw errors on non-200-tier responses
 */
export function checkStatus(res: Response, ignoreRedirect?: boolean): void {
  if (res.status === 400) {
    throw new BadRequest();
  }
  if (res.status === 401) {
    if (typeof window !== 'undefined') {
      if (ignoreRedirect) {
        throw new Unauthorized();
      }
      const redirectBaseUrl = getRedirectBaseUrl(window.location.href);
      window?.open(`${redirectBaseUrl}?rd=${window.location.href}`, '_self');
    } else {
      throw new NotFound();
    }
  }
  if (res.status === 404) {
    throw new NotFound();
  }
  if (res.status === 409) {
    throw new ConflictRequest();
  }
  if (res.status === 500) {
    throw new InternalServiceError();
  }
}

export function getRedirectBaseUrl(href: string) {
  const isCaremanager = href.includes(CAREMANAGER_HOME);
  const isDuo = href.includes('/member') || href.includes('/impersonate');

  if (isCaremanager) {
    return CAREMANAGER_PWD_LOGIN;
  }
  if (isDuo) {
    return DUO_LOGIN;
  }
  return MEMBER_LOGIN;
}

/**
 * Make an HTTP GET request to the member API
 */
// async function getJson<T>(path: string): Promise<T>;
export async function getJson<T>(path: string, api?: API, cookie?: string, ignoreRedirect?: boolean): Promise<T> {
  const url = new URL(path, api === 'rehoboam' ? process.env.API_REHOBOAM_BASE_URL : process.env.API_BASE_URL);
  const opts: RequestInit = { ...defaultOptions, method: 'GET' };
  if (cookie) {
    opts.headers = { ...opts.headers, Cookie: cookie };
  }

  const res = await fetch(url, opts);
  checkStatus(res, ignoreRedirect);
  return res.json();
}

/**
 * Make an HTTP DELETE request to the member API
 */
export async function deleteJson<T>(path: string, emptyResponse?: true, api?: API): Promise<T | undefined> {
  const url = new URL(path, api === 'rehoboam' ? process.env.API_REHOBOAM_BASE_URL : process.env.API_BASE_URL);
  const opts: RequestInit = { ...defaultOptions, method: 'DELETE' };

  const res = await fetch(url, opts);
  checkStatus(res);

  if (emptyResponse) return;
  else return res.json();
}

/**
 * Make an HTTP POST request to the member API
 */
export async function postJson<T, U>(
  path: string,
  requestBody?: U,
  emptyResponse?: boolean,
  api?: API,
  customStatusCheck?: (res: Response) => void
): Promise<T | undefined> {
  const url = new URL(path, api === 'rehoboam' ? process.env.API_REHOBOAM_BASE_URL : process.env.API_BASE_URL);
  const opts: RequestInit = requestBody
    ? { ...defaultOptions, method: 'POST', body: JSON.stringify(requestBody) }
    : { ...defaultOptions, method: 'POST' };

  const res = await fetch(url, opts);
  if (customStatusCheck) {
    await customStatusCheck(res);
  } else {
    checkStatus(res);
  }

  if (emptyResponse) return undefined;
  return res.json();
}

/**
 * Make an HTTP POST request to the member API
 */
export async function putJson<T, U>(
  path: string,
  requestBody?: U,
  emptyResponse?: boolean,
  api?: API
): Promise<T | undefined> {
  const url = new URL(path, api === 'rehoboam' ? process.env.API_REHOBOAM_BASE_URL : process.env.API_BASE_URL);
  const opts: RequestInit = requestBody
    ? { ...defaultOptions, method: 'PUT', body: JSON.stringify(requestBody) }
    : { ...defaultOptions, method: 'PUT' };

  const res = await fetch(url, opts);
  checkStatus(res);

  if (emptyResponse) return undefined;

  return res.json();
}
