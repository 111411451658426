import classNames from 'classnames';
import React, { ReactNode, useMemo } from 'react';

import { colors, toFillColor, toGroupHoverFillColor, toStrokeColor } from '../../theme/colors';
import { ColorType } from '../../types/ui';

const Circle = ({
  className,
  stroke,
  strokeWidth = '1',
  color,
  hoverColor = color,
  size = 8,
  children = <></>,
  absolute = false,
}: {
  className?: string;
  stroke: ColorType;
  strokeWidth?: string;
  color?: ColorType | 'transparent';
  hoverColor?: ColorType | 'transparent';
  size?: number;
  children?: ReactNode;
  absolute?: boolean;
}) => {
  // Cannot modify a string class name with tailwindcss, hence these switches.
  const colorClass = useMemo(() => (color == 'transparent' ? 'fill-transparent' : toFillColor(color)), [color]);
  const strokeClass = useMemo(() => toStrokeColor(stroke), [stroke]);
  const hoverClass = useMemo(
    () => (hoverColor === 'transparent' ? 'group-hover:fill-transparent' : toGroupHoverFillColor(hoverColor)),
    [hoverColor]
  );
  const viewBox = useMemo(() => {
    const swValue = Number(strokeWidth);
    return `${-swValue / 2} ${-swValue / 2} ${40 + swValue} ${40 + swValue}`;
  }, [strokeWidth]);

  const positionClass = useMemo(() => (absolute ? 'absolute' : 'relative'), [absolute]);

  return (
    <div className={classNames('flex justify-center items-center', positionClass, className)}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox={viewBox}
        className={classNames(strokeClass)}
        stroke={colors[stroke]}
      >
        <circle
          cx="20"
          cy="20"
          r="19.5"
          strokeWidth={strokeWidth}
          className={classNames(colorClass, hoverClass)}
          fill="transparent"
        />
      </svg>
      {children}
    </div>
  );
};

export default Circle;
