import classNames from 'classnames';

import { textVariantClassName } from '../../../../theme/typography';
import { ButtonSize, ButtonVariant } from './types';

export const elevationClasses = [
  'shadow-elevation-1',
  'hover:shadow-elevation-2',
  'active:shadow-none',
  'focus:shadow-elevation-2',
];

export const VARIANT_CLASSES: Record<ButtonVariant, string[]> = {
  primary: [
    'bg-purple',
    'text-white',
    '[&>svg]:fill-white',
    'hover:bg-lightPurple',
    'hover:outline-none',
    'active:bg-darkPurple',
    'active:outline-none',
    'focus:bg-lightPurple',
    'focus:outline-offset-[-0.5px]',
    'focus:outline-2',
    'focus:outline-dashed',
    'focus:outline-linkBlue',
    ...elevationClasses,
  ],
  secondary: [
    'bg-white',
    'text-purple',
    '[&>svg]:fill-purple',
    'border-purple',
    'border-2',
    'hover:bg-purple',
    'hover:outline-none',
    'hover:text-white',
    '[&:hover>svg]:fill-white',
    'active:bg-darkPurple',
    'active:outline-none',
    '[&:active>svg]:fill-white',
    'focus:bg-purple',
    'focus:text-white',
    'focus:outline-offset-[-0.5px]',
    'focus:outline-2',
    'focus:outline-dashed',
    'focus:outline-linkBlue',
    '[&:focus>svg]:fill-white',
  ],
  magenta: [
    'bg-magenta',
    'text-white',
    '[&>svg]:fill-white',
    'hover:bg-magentaHover',
    'hover:outline-none',
    '[&:hover>svg]:fill-white',
    'active:bg-darkMagenta',
    'active:outline-none',
    'focus:bg-magentaHover',
    'focus:outline-offset-[-0.5px]',
    'focus:outline-2',
    'focus:outline-dashed',
    'focus:outline-white',
    '[&:focus>svg]:fill-white',
    ...elevationClasses,
  ],
  prompt: [
    'italic',
    'text-linkBlue',
    '[&>svg]:fill-linkBlue',
    'border-2',
    'border-linkBlue',
    'hover:bg-lightGrayTwo',
    'active:bg-lightGrayOne',
    'active:outline-none',
    'active:border-lightGrayOne',
    'focus:border-lightGrayTwo',
    'focus:bg-lightGrayTwo',
    'focus:outline-offset-[-0.5px]',
    'focus:outline-2',
    'focus:outline-dashed',
    'focus:outline-linkBlue',
  ],
  darkOpaque: [
    'bg-black25',
    'hover:bg-whiteOverlay25',
    'hover:outline-none',
    'active:bg-black25',
    'active:outline-none',
    'focus:bg-whiteOverlay25',
    'focus:outline-offset-[-0.5px]',
    'focus:outline-2',
    'focus:outline-dashed',
    'focus:outline-white',
    'text-white',
    'fill-white',
    '[&>svg]:fill-white',
  ],
};

export const COMMON_CLASSES: string[] = [
  'group',
  'flex',
  'flex-row',
  'gap-2',
  'items-center',
  'justify-center',
  'font-sans',
  'disabled:cursor-not-allowed',
  textVariantClassName('body-1-emphasis'),
];

export const SIZE_CLASSES: Record<ButtonSize, string[]> = {
  main: ['h-12', 'px-8', 'py-1', 'rounded-[6.25rem]'],
  small: ['h-8', 'px-6', 'py-1', 'rounded-[6.25rem]'],
  medium: ['h-10', 'px-4', 'py-1', 'rounded-[6.25rem]'],
};

export type ButtonClassesArgs = {
  variant: ButtonVariant;
  size: ButtonSize;
  fullWidth: boolean;
  className?: string;
};

export function buttonClasses({ variant, size, fullWidth, className }: ButtonClassesArgs): string {
  return classNames(COMMON_CLASSES, SIZE_CLASSES[size], VARIANT_CLASSES[variant], className, {
    'w-full': fullWidth,
  });
}
