import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor, toHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const InfoCircleIcon = ({ size = 24, colorOverride, hoverColorOverride, className }: IconProps) => {
  const colorClass = toFillColor(colorOverride);
  const hoverFillClassName = toHoverFillColor(hoverColorOverride);
  const groupHoverClass = toGroupHoverFillColor(hoverColorOverride);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      className={classNames(className, colorClass, hoverFillClassName, groupHoverClass, 'group flex-shrink-0')}
    >
      <mask
        id="mask0_5093_572"
        className="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5093_572)">
        <path
          className={classNames(colorClass, hoverFillClassName, groupHoverClass)}
          d="M12.5 17C12.7833 17 13.0208 16.9042 13.2125 16.7125C13.4042 16.5208 13.5 16.2833 13.5 16V12C13.5 11.7167 13.4042 11.4792 13.2125 11.2875C13.0208 11.0958 12.7833 11 12.5 11C12.2167 11 11.9792 11.0958 11.7875 11.2875C11.5958 11.4792 11.5 11.7167 11.5 12V16C11.5 16.2833 11.5958 16.5208 11.7875 16.7125C11.9792 16.9042 12.2167 17 12.5 17ZM12.5 9C12.7833 9 13.0208 8.90417 13.2125 8.7125C13.4042 8.52083 13.5 8.28333 13.5 8C13.5 7.71667 13.4042 7.47917 13.2125 7.2875C13.0208 7.09583 12.7833 7 12.5 7C12.2167 7 11.9792 7.09583 11.7875 7.2875C11.5958 7.47917 11.5 7.71667 11.5 8C11.5 8.28333 11.5958 8.52083 11.7875 8.7125C11.9792 8.90417 12.2167 9 12.5 9ZM12.5 22C11.1167 22 9.81667 21.7375 8.6 21.2125C7.38333 20.6875 6.325 19.975 5.425 19.075C4.525 18.175 3.8125 17.1167 3.2875 15.9C2.7625 14.6833 2.5 13.3833 2.5 12C2.5 10.6167 2.7625 9.31667 3.2875 8.1C3.8125 6.88333 4.525 5.825 5.425 4.925C6.325 4.025 7.38333 3.3125 8.6 2.7875C9.81667 2.2625 11.1167 2 12.5 2C13.8833 2 15.1833 2.2625 16.4 2.7875C17.6167 3.3125 18.675 4.025 19.575 4.925C20.475 5.825 21.1875 6.88333 21.7125 8.1C22.2375 9.31667 22.5 10.6167 22.5 12C22.5 13.3833 22.2375 14.6833 21.7125 15.9C21.1875 17.1167 20.475 18.175 19.575 19.075C18.675 19.975 17.6167 20.6875 16.4 21.2125C15.1833 21.7375 13.8833 22 12.5 22Z"
          fill="#172E7D"
        />
      </g>
    </svg>
  );
};

export default InfoCircleIcon;
