import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor, toGroupHoverStrokeColor, toStrokeColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const ThumbsDown = ({ size = 24, colorOverride, hoverColorOverride, className }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const hoverClass = toGroupHoverFillColor(hoverColorOverride);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className="flex-shrink-0"
    >
      <mask
        id="mask0_398_11953"
        className="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_398_11953)">
        <path
          d="M3 16C2.46667 16 2 15.8 1.6 15.4C1.2 15 1 14.5333 1 14V12C1 11.8833 1.01667 11.7583 1.05 11.625C1.08333 11.4917 1.11667 11.3667 1.15 11.25L4.15 4.2C4.3 3.86667 4.55 3.58333 4.9 3.35C5.25 3.11667 5.61667 3 6 3H17V16L11 21.95C10.75 22.2 10.4542 22.3458 10.1125 22.3875C9.77083 22.4292 9.44167 22.3667 9.125 22.2C8.80833 22.0333 8.575 21.8 8.425 21.5C8.275 21.2 8.24167 20.8917 8.325 20.575L9.45 16H3ZM15 15.15V5H6L3 12V14H12L10.65 19.5L15 15.15ZM20 3C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5V14C22 14.55 21.8042 15.0208 21.4125 15.4125C21.0208 15.8042 20.55 16 20 16H17V14H20V5H17V3H20Z"
          className={classNames(fillClass, hoverClass)}
        />
      </g>
    </svg>
  );
};

export default ThumbsDown;
