import React from 'react';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const HealthShield = ({ size = 20, colorOverride }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.77794 6.66665C7.77794 6.20641 8.15104 5.83331 8.61127 5.83331H11.3891C11.8493 5.83331 12.2224 6.20641 12.2224 6.66665V7.77776H13.3335C13.7937 7.77776 14.1668 8.15085 14.1668 8.61109V11.3889C14.1668 11.8491 13.7937 12.2222 13.3335 12.2222H12.2224V13.3333C12.2224 13.7936 11.8493 14.1666 11.3891 14.1666H8.61127C8.15104 14.1666 7.77794 13.7936 7.77794 13.3333V12.2222H6.66683C6.20659 12.2222 5.8335 11.8491 5.8335 11.3889V8.61109C5.8335 8.15085 6.20659 7.77776 6.66683 7.77776H7.77794V6.66665ZM9.44461 7.49998V8.61109C9.44461 9.07133 9.07151 9.44442 8.61127 9.44442H7.50016V10.5555H8.61127C9.07151 10.5555 9.44461 10.9286 9.44461 11.3889V12.5H10.5557V11.3889C10.5557 10.9286 10.9288 10.5555 11.3891 10.5555H12.5002V9.44442H11.3891C10.9288 9.44442 10.5557 9.07133 10.5557 8.61109V7.49998H9.44461Z"
        className={fillClass}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.45755 0.933495C9.81474 0.854118 10.185 0.854118 10.5422 0.933495L16.9273 2.35241C17.7731 2.54036 18.34 3.34502 18.2162 4.21159L16.6582 15.1178C16.6172 15.4045 16.4682 16.37 15.4747 17.24C14.4955 18.0973 12.8258 18.75 9.99988 18.75C7.17393 18.75 5.50421 18.0973 4.52509 17.24C3.53157 16.37 3.38253 15.4045 3.34159 15.1178L1.78355 4.2116C1.65976 3.34502 2.22665 2.54036 3.07242 2.35241L9.45755 0.933495ZM10.1807 2.56047C10.0616 2.53401 9.93817 2.53401 9.8191 2.56047L3.43397 3.97939L4.9915 14.8821C4.99187 14.8847 4.99226 14.8875 4.99267 14.8904C5.01214 15.0283 5.08023 15.5108 5.62302 15.986C6.19167 16.4839 7.40915 17.0833 9.99988 17.0833C12.5906 17.0833 13.8081 16.4839 14.3767 15.986C14.9195 15.5108 14.9876 15.0283 15.0071 14.8904C15.0075 14.8875 15.0079 14.8847 15.0083 14.8821L16.5658 3.97939L10.1807 2.56047Z"
        className={fillClass}
      />
    </svg>
  );
};

export default HealthShield;
