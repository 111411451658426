import classNames from 'classnames';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const CloseWithCircle = ({ className, colorOverride }: IconProps) => {
  const fillColor = toFillColor(colorOverride);
  return (
    <div className={classNames('relative flex justify-center items-center', className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        className={className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.05357 7.05242C7.37901 6.72698 7.90665 6.72698 8.23208 7.05242L9.99985 8.82019L11.7676 7.05242C12.0931 6.72698 12.6207 6.72698 12.9461 7.05242C13.2716 7.37786 13.2716 7.90549 12.9461 8.23093L11.1784 9.9987L12.9461 11.7665C13.2716 12.0919 13.2716 12.6195 12.9461 12.945C12.6207 13.2704 12.0931 13.2704 11.7676 12.945L9.99985 11.1772L8.23208 12.945C7.90665 13.2704 7.37901 13.2704 7.05357 12.945C6.72814 12.6195 6.72814 12.0919 7.05357 11.7665L8.82134 9.9987L7.05357 8.23093C6.72814 7.90549 6.72814 7.37786 7.05357 7.05242Z"
          className={classNames(fillColor)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0002 2.4987C5.85803 2.4987 2.50016 5.85656 2.50016 9.9987C2.50016 14.1408 5.85803 17.4987 10.0002 17.4987C14.1423 17.4987 17.5002 14.1408 17.5002 9.9987C17.5002 5.85656 14.1423 2.4987 10.0002 2.4987ZM0.833496 9.9987C0.833496 4.93609 4.93755 0.832031 10.0002 0.832031C15.0628 0.832031 19.1668 4.93609 19.1668 9.9987C19.1668 15.0613 15.0628 19.1654 10.0002 19.1654C4.93755 19.1654 0.833496 15.0613 0.833496 9.9987Z"
          className={classNames(fillColor)}
        />
      </svg>
    </div>
  );
};

export default CloseWithCircle;
