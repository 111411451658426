import { yupResolver } from '@hookform/resolvers/yup';
import { T } from '@transifex/react';
import classNames from 'classnames';
import React, { ForwardedRef, Ref, useCallback, useEffect, useMemo, useRef } from 'react';
import { Control, FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { Popover } from 'react-tiny-popover';
import * as Yup from 'yup';

import { Button, CheckboxWithLabel, TextLink } from '.';
import useToggle from '../../hooks/useToggle';
import { Option } from '../../types/Common';
import { DropDownIcon } from '../icons';

export type DropdownCheckboxFilterProps = {
  name: string;
  options: Option<string>[];
  label: string;
  displayCount?: boolean;
  control?: Control;
  parentRef?: HTMLElement;
};

export const DropdownCheckboxFilter = ({
  name,
  label,
  options,
  displayCount,
}: DropdownCheckboxFilterProps): JSX.Element => {
  const { setValue, register } = useFormContext();
  const { state: isOpen, setFalse: setClosed, toggle: toggleDropdown } = useToggle(false);

  const popoverContainerRef = useRef<HTMLDivElement>(null);
  const parentContainerRef = useRef<HTMLDivElement>(null);

  const checkboxState = useWatch({ name }) as string[];
  const setCheckboxState = useCallback(
    (state: string[]) => {
      setValue(name, state);
    },
    [name, setValue]
  );

  const countText = useMemo(() => {
    if (displayCount && checkboxState.length > 0) {
      return ` (${checkboxState.length})`;
    }

    return '';
  }, [checkboxState, displayCount]);

  const parentRef = useMemo(() => {
    if (!parentContainerRef.current) {
      return;
    }
    return parentContainerRef.current;
  }, []);

  return (
    <div ref={parentContainerRef}>
      <DropdownCheckboxFilterPopover
        isOpen={isOpen}
        setClosed={setClosed}
        options={options}
        setCheckboxState={setCheckboxState}
        checkboxState={checkboxState}
        ref={popoverContainerRef}
        parentRef={parentRef}
      >
        <Button
          type="mediumTeal"
          size="small"
          marginClass={['!py-2', '!px-4']}
          className={classNames({
            'bg-mediumTeal !text-white': isOpen,
          })}
          ariaLabel={`${label} dropdown`}
          onClick={toggleDropdown}
        >
          {label + countText}
          <DropDownIcon colorOverride={isOpen ? 'white' : 'mediumTeal'} className={isOpen ? 'rotate-180' : undefined} />
        </Button>
      </DropdownCheckboxFilterPopover>
      <input type="hidden" {...register(name)} />
    </div>
  );
};

export type DropdownCheckboxFilterPopoverProps = {
  options: Option<string>[];
  isOpen: boolean;
  setClosed: () => void;
  setCheckboxState: (value: string[]) => void;
  checkboxState: string[];
  children: JSX.Element;
  parentRef?: HTMLElement;
};

const DropdownCheckboxFilterPopover = React.forwardRef(
  (
    {
      options,
      isOpen,
      setClosed,
      setCheckboxState,
      checkboxState,
      children,
      parentRef,
    }: DropdownCheckboxFilterPopoverProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const popoverRef = useRef<HTMLDivElement>(null);

    return (
      <Popover
        ref={ref}
        isOpen={isOpen}
        onClickOutside={setClosed}
        positions={['bottom', 'left']}
        padding={4}
        align="start"
        parentElement={parentRef}
        content={() => (
          <DropdownCheckboxFilterPopoverContent
            ref={popoverRef}
            options={options}
            setClosed={setClosed}
            setCheckboxState={setCheckboxState}
            checkboxState={checkboxState}
          />
        )}
        containerClassName="z-10"
      >
        {children}
      </Popover>
    );
  }
);

DropdownCheckboxFilterPopover.displayName = 'DropdownCheckboxFilterPopover';

export type DropdownCheckboxFilterPopoverContentProps = {
  options: Option<string>[];
  setClosed: () => void;
  setCheckboxState: (value: string[]) => void;
  checkboxState: string[];
};

const DropdownCheckboxFilterPopoverContent = React.forwardRef(
  (props: DropdownCheckboxFilterPopoverContentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { options, checkboxState, setClosed, setCheckboxState } = props;

    const mappedOptions = useMemo(
      () =>
        options.map((option) => {
          return {
            label: option.label,
            originalValue: option.value,
            value: option.value.replace('.', '_'),
          };
        }),
      [options]
    );

    const values: Record<string, boolean> = useMemo(() => {
      return mappedOptions.reduce((accum, curr) => {
        if (checkboxState.includes(curr.originalValue)) {
          return { ...accum, [curr.value]: true };
        }
        return { ...accum, [curr.value]: false };
      }, {});
    }, [checkboxState, mappedOptions]);

    const resolver = useMemo(
      () =>
        yupResolver(
          Yup.object(
            // Ensure we have a required boolean field for each option
            mappedOptions.reduce((accum, curr) => {
              return { ...accum, [curr.value]: Yup.bool().required() };
            }, {})
          )
        ),
      [mappedOptions]
    );

    const form = useForm<Record<string, boolean>>({
      resolver,
      defaultValues: values,
    });

    const { handleSubmit } = form;

    const onCancel = useCallback(() => {
      for (const [key, value] of Object.entries(values)) {
        form.setValue(key, value);
      }

      setClosed();
    }, [setClosed, form, values]);

    const onSubmit = useMemo(() => {
      return handleSubmit((data, event) => {
        event?.stopPropagation();
        setClosed();
        const values = Object.entries(data)
          .filter(([_, value]) => value)
          .map(([key, _]) => mappedOptions.find((opt) => opt.value === key)?.originalValue as string);
        setCheckboxState(values);
      });
    }, [handleSubmit, setClosed, setCheckboxState, mappedOptions]);

    return (
      <div className="w-[335px] h-[353px] z-10" ref={ref} onSubmit={(e) => e.stopPropagation()}>
        <FormProvider {...form}>
          <form
            className="border-[1px] border-solid border-mediumTeal rounded-[4px] p-6 bg-white w-[335px] h-[353px] flex flex-col gap-8"
            onSubmit={onSubmit}
          >
            <div className="overflow-y-scroll flex flex-col gap-4 h-[220px]">
              {mappedOptions.map((option) => (
                <CheckboxWithLabel
                  key={option.value}
                  label={option.label}
                  name={option.value}
                  labelType="body1"
                  labelStyle="text-darkGrayTwo text-left"
                />
              ))}
            </div>
            <div className="flex items-center gap-6">
              <Button type="primary" submit fullWidth>
                <T _str="Apply" />
              </Button>
              <TextLink privacyLevel="public" onClick={onCancel}>
                <T _str="Cancel" />
              </TextLink>
            </div>
          </form>
        </FormProvider>
      </div>
    );
  }
);

DropdownCheckboxFilterPopoverContent.displayName = 'DropdownCheckboxFilterPopoverContent';
