import classNames from 'classnames';

import { textVariantClassName } from '../../../../theme/typography';
import { UtilityActionVariant } from './types';

export const VARIANT_CLASSES: Record<UtilityActionVariant, string[]> = {
  thumbsUp: [
    'h-10',
    'py-1',
    'px-4',
    'hover:bg-lightGrayTwo',
    'active:bg-lightGrayOne',
    'rounded-[6.25rem]',
    'focus:outline-offset-[-0.5px]',
    'focus:outline-2',
    'focus:outline-dashed',
    'focus:outline-linkBlue',
  ],
  thumbsDown: [
    'h-10',
    'py-1',
    'px-4',
    'hover:bg-lightGrayTwo',
    'active:bg-lightGrayOne',
    'rounded-[6.25rem]',
    'focus:outline-offset-[-0.5px]',
    'focus:outline-2',
    'focus:outline-dashed',
    'focus:outline-linkBlue',
  ],
  saveOnCard: [
    'h-6',
    'px-1',
    'rounded-[0.125rem]',
    'focus:outline-offset-[-0.5px]',
    'focus:outline-2',
    'focus:outline-dashed',
    'focus:outline-linkBlue',
  ],
  submit: [
    'h-12',
    'w-12',
    'p-4',
    'rounded-[0.625rem]',
    'bg-purple',
    'hover:bg-lightPurple',
    'active:bg-darkPurple',
    'focus:bg-lightPurple',
    'focus:outline-offset-[-0.5px]',
    'focus:outline-2',
    'focus:outline-dashed',
    'focus:outline-linkBlue',
  ],
  close: [
    'h-12',
    'w-12',
    'p-4',
    'rounded-[6.25rem]',
    'hover:bg-lightGrayTwo',
    'active:bg-lightGrayOne',
    'focus:bg-lightGrayTwo',
    'focus:outline-offset-[-0.5px]',
    'focus:outline-2',
    'focus:outline-dashed',
    'focus:outline-linkBlue',
  ],
};

export const COMMON_CLASSES: string[] = [
  'group',
  'flex',
  'flex-row',
  'gap-1',
  'justify-center',
  'items-center',
  'font-sans',
  textVariantClassName('body-1-emphasis'),
];

export type UtilityActionClassesArgs = {
  variant: UtilityActionVariant;
  fullWidth: boolean;
  className?: string;
};

export function buttonClasses({ variant, fullWidth, className }: UtilityActionClassesArgs): string {
  return classNames(COMMON_CLASSES, VARIANT_CLASSES[variant], className, {
    'w-full': fullWidth,
  });
}
