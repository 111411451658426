import { icons } from '../../../icons';

const MenuItemChevron = ({ expanded }: { expanded: boolean }) => {
  const ChevronIcon = icons['down'];
  const rotationAnimationClassation = `duration-300 ${expanded ? 'transform rotate-180' : ''}`;
  return (
    <div className={rotationAnimationClassation}>
      <ChevronIcon colorOverride="darkPurple" size={24} />
    </div>
  );
};

export default MenuItemChevron;
