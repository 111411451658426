import { T, useT } from '@transifex/react';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useRef } from 'react';

import { useCallContext } from '../hooks/contexts';
import { formatPhone } from '../utils/Member';
import Text from './common/Text';

type Props = {
  phoneNumber: string;
  contactName: string;
};

const HangupContact = ({ phoneNumber, contactName }: Props) => {
  const t = useT();
  const { disconnectUserFromConferenceCall } = useCallContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    buttonRef.current?.focus();
  }, []);

  const hangupNumber = useMemo(() => {
    return debounce(() => disconnectUserFromConferenceCall(phoneNumber), 250);
  }, [disconnectUserFromConferenceCall, phoneNumber]);

  return (
    <button
      aria-label={t('End Call with {contactName}', { contactName })}
      className="group px-4 py-2 flex flex-col gap-2 hover:bg-purple border-b-[1px] border-purple"
      onClick={hangupNumber}
      ref={buttonRef}
    >
      <Text privacyLevel="pii" variant="body1" color="purple" className="group-hover:text-white">
        <T _str="End Call with {contactName}" contactName={contactName} />
      </Text>
      <Text privacyLevel="pii" variant="body3" color="purple" className="group-hover:text-white">
        {formatPhone(phoneNumber)}
      </Text>
    </button>
  );
};

export default HangupContact;
