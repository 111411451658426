import classNames from 'classnames';
import Link from 'next/link';
import React, { ForwardedRef, PropsWithChildren, forwardRef, useCallback, useMemo } from 'react';

import { textVariantClassName } from '../../../theme/typography';
import { ColorType } from '../../../types/ui';
import LoadingSpinner from '../LoadingSpinner';
import { TextCase } from '../Text';

type ButtonType = 'primary' | 'secondary' | 'mediumTeal' | 'utility' | 'mediumGreen' | 'red';

type ButtonSize = 'xxsmall' | 'xsmall' | 'small' | 'large';

type MarginClass =
  | `m${string}-[${number}px]`
  | `m${string}-${number}`
  | `m${string}-auto`
  | `p${string}-[${number}px]`
  | `p${string}-${number}`
  | `!p${string}-[${number}px]`
  | `!p${string}-${number}`;

type ButtonProps = {
  rounding?: 'slight' | 'normal';
  type?: ButtonType;
  size?: ButtonSize;
  marginClass?: MarginClass[];
  fullWidth?: boolean;
  submit?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  href?: string;
  sameTab?: boolean;
  label?: string;
  className?: string;
  ariaLabel?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  active?: boolean;
  textCase?: TextCase;
};

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (
    {
      textCase = 'upper',
      rounding = 'normal',
      active = false,
      disabled = false,
      loading = false,
      type = 'primary',
      size = 'small',
      marginClass = [],
      fullWidth = false,
      submit = false,
      children,
      onClick,
      href,
      sameTab = false,
      label = typeof children === 'string' ? children : 'button',
      className: originClassName,
      ariaLabel: ariaLabelProp,
      onMouseEnter = () => {},
      onMouseLeave = () => {},
    },
    ref
  ): JSX.Element => {
    let typeClass = '';
    if (type === 'primary')
      typeClass = classNames('bg-purple border-[1.5px] border-purple', {
        'hover:bg-darkPurple': !loading,
      });
    if (type === 'secondary')
      typeClass = classNames('bg-white border-[1.5px] border-purple', { 'hover:bg-purple': !loading });
    if (type === 'mediumTeal')
      typeClass = classNames('bg-white border-[1.5px] border-mediumTeal', { 'hover:bg-mediumTeal': !loading });
    if (type === 'utility')
      typeClass = classNames('bg-white border-[1.5px] border-linkBlue', {
        'hover:bg-linkHoverBlue': !loading,
      });
    if (type === 'mediumGreen') {
      typeClass = classNames('bg-white border-[1.5px] border-purple hover:bg-green hover:border-green');
      if (active) {
        typeClass = classNames('border-[1.5px] bg-green border-green');
      }
    }
    if (type === 'red') {
      typeClass = classNames('bg-white border-[1.5px] border-purple hover:bg-lightRed hover:border-errorRedTwo');
      if (active) {
        typeClass = classNames('border-[1.5px] bg-lightRed border-errorRedTwo');
      }
    }
    if (disabled) typeClass = classNames('bg-lightGray hover:bg-lightGray border-[1.5px] border-lightGray');

    const sizeClass = useMemo(() => {
      if (!size) {
        return '';
      }
      switch (size) {
        case 'small':
          return 'py-[13px] px-[32px]';
        case 'large':
          return 'py-[20px] px-[48px]';
        case 'xsmall':
          return 'py-[12px] px-[24px]';
        case 'xxsmall':
          return 'py-[8px] px-[16px]';
        default:
          return 'py-[20px] px-[48px]';
      }
    }, [size]);

    const textClass = useMemo(() => {
      if (!size) {
        return '';
      }
      switch (size) {
        case 'xsmall':
          return textVariantClassName('button2');
        case 'xxsmall':
          return textVariantClassName('button2');
        case 'small':
          return textVariantClassName('button2');
        case 'large':
          return textVariantClassName('button1');
        default:
          return textVariantClassName('button1');
      }
    }, [size]);

    let textColorClass = '';
    if (type === 'primary') textColorClass = 'text-white hover:text-white';
    if (type === 'secondary') textColorClass = 'text-purple hover:text-white';
    if (type === 'mediumTeal') textColorClass = 'text-mediumTeal hover:text-white';
    if (type === 'utility') textColorClass = 'text-linkBlue hover:text-white';
    if (type === 'mediumGreen') textColorClass = 'text-purple hover:text-white';
    if (type === 'mediumGreen' && active) textColorClass = 'text-white';
    if (type === 'red') textColorClass = 'text-purple hover:text-errorRedTwo';
    if (type === 'red' && active) textColorClass = 'text-errorRedTwo';
    if (disabled) textColorClass = 'text-blueGray hover:text-blueGray';
    if (loading) textColorClass = 'text-white/0';

    let color: ColorType = 'purple';
    if (type === 'primary') color = 'white';
    if (type === 'secondary') color = 'purple';
    if (type === 'mediumTeal') color = 'mediumTeal';
    if (type === 'mediumGreen') color = 'purple';
    if (type === 'mediumGreen' && active) color = 'white';
    if (type === 'red') color = 'purple';
    if (type === 'red' && active) color = 'errorRedTwo';
    if (disabled) color = 'blueGray';

    const className = classNames(
      originClassName,
      typeClass,
      sizeClass,
      textClass,
      textColorClass,
      'font-sans whitespace-normal gap-1 group inline-flex shrink justify-center flex flex-row items-center gap-2',
      marginClass.join(' '),
      {
        'w-full': fullWidth,
        'w-fit': !fullWidth,
        'cursor-wait': loading,
        'cursor-not-allowed': disabled,
        'rounded-[8px]': rounding === 'slight',
        'rounded-[24px]': rounding === 'normal',
        capitalize: textCase === 'capitalize',
        'normal-case': textCase === 'normal',
        uppercase: textCase === 'upper',
        lowercase: textCase === 'lower',
      }
    );
    const ariaLabel = ariaLabelProp ?? (typeof children === 'string' ? (children as string) : 'link');

    const handleClick = useCallback(() => {
      if (onClick && !disabled && !loading) onClick();
    }, [disabled, loading, onClick]);

    return (
      <>
        {href && (
          <Link passHref href={href}>
            <a
              href={href}
              className={className}
              aria-label={ariaLabel}
              target={sameTab ? '_self' : '_blank'}
              rel={'noreferrer'}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              {children}
            </a>
          </Link>
        )}
        {!href && (
          <button
            ref={ref as ForwardedRef<HTMLButtonElement>}
            className={className}
            type={submit ? 'submit' : 'button'}
            onClick={handleClick}
            aria-label={ariaLabel}
            aria-disabled={disabled || loading}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {children}
            {loading && <LoadingSpinner className="absolute" colorOverride={color} size={24} />}
          </button>
        )}
      </>
    );
  }
);

Button.displayName = 'Button';

export default Button;
