import React from 'react';

const ImpersonationBorder = () => {
  return (
    <>
      <div className="fixed bg-magenta w-[4px] tablet:w-[8px] left-0 top-0 bottom-0 z-50" />
      <div className="fixed bg-magenta w-[4px] tablet:w-[8px] right-0 top-0 bottom-0 z-50" />
      <div className="fixed bg-magenta h-[4px] tablet:h-[8px] right-0 left-0 bottom-0 z-50" />
    </>
  );
};

export default ImpersonationBorder;
