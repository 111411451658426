import { T } from '@transifex/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

import { MEMBER_SAVED, MEMBER_SHARED } from '../../../routes';
import { Text } from '../../common';
import { DownIcon, MenuBookmarkIcon, MenuShare, UpIcon } from '../../icons';

type ResourcesMobileQuickLinkButtonProps = {
  quickLinkeIsOpen?: boolean;
  setQuickLinkIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ResourcesMobileQuickLinkButton = ({
  quickLinkeIsOpen = false,
  setQuickLinkIsOpen,
}: ResourcesMobileQuickLinkButtonProps) => {
  const router = useRouter();

  const isSavedPage = router.pathname.includes(MEMBER_SAVED);

  const desktopOverlayClass = quickLinkeIsOpen
    ? 'fixed top-0 left-0 right-0 buttom-0 bg-[rgba(21,24,31,0.5)] h-screen w-screen transform transition-all duration-200 ease-in-out -z-10'
    : '';
  const desktopOverlayAnimationClass = quickLinkeIsOpen ? 'opacity-100 z-30' : 'opacity-0';
  const mobileOverlayAnimationClass = quickLinkeIsOpen ? 'translate-y-0' : '-translate-y-10';

  return (
    <div>
      <div className="tablet:hidden flex flex-col items-stretch bg-white">
        <button
          className="flex flex-row gap-2 items-center p-4 shadow-[0px_2px_8px_0px_rgba(16,_26,_97,_0.08)]"
          onClick={() => setQuickLinkIsOpen((menuShown: boolean) => !menuShown)}
        >
          <div>
            {isSavedPage ? (
              <MenuBookmarkIcon size={24} colorOverride="magenta" active={false} />
            ) : (
              <MenuShare size={24} colorOverride="magenta" active={false} />
            )}
          </div>
          <Text variant="nav-2" color="darkGrayOne">
            {isSavedPage ? <T _str="Saved  Resources" /> : <T _str="Shared with You" />}
          </Text>
          {quickLinkeIsOpen ? (
            <UpIcon size={24} colorOverride="darkBlue" />
          ) : (
            <DownIcon size={24} colorOverride="darkBlue" />
          )}
        </button>
        <div
          className={classNames('tablet:hidden flex flex-col items-stretch bg-white z-50', mobileOverlayAnimationClass)}
        >
          {quickLinkeIsOpen && (
            <button
              className="flex flex-row gap-2 items-center p-4 mx-4"
              onClick={() => (!isSavedPage ? router.push(MEMBER_SAVED) : router.push(MEMBER_SHARED))}
            >
              <div>
                {!isSavedPage ? (
                  <MenuBookmarkIcon size={24} colorOverride="darkBlue" />
                ) : (
                  <MenuShare size={24} colorOverride="darkBlue" />
                )}
              </div>
              <Text variant="nav-2" color="darkGrayOne">
                {!isSavedPage ? <T _str="Saved  Resources" /> : <T _str="Shared with You" />}
              </Text>
            </button>
          )}
        </div>
      </div>
      <div className={classNames(desktopOverlayClass, desktopOverlayAnimationClass)} />
    </div>
  );
};

export default ResourcesMobileQuickLinkButton;
