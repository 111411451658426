export type NewTextType =
  | 'heading-1'
  | 'heading-2'
  | 'heading-3'
  | 'heading-4'
  | 'heading-5'
  | 'body-1'
  | 'body-1-emphasis'
  | 'tag';

type DeprecatedTextType =
  | 'body1'
  | 'body2'
  | 'body3'
  | 'label1'
  | 'label2'
  | 'label3'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'button1'
  | 'button2'
  | 'link'
  | 'display'
  | 't1'
  | 't2'
  | 't3'
  | 't4'
  | 't5'
  | 't6'
  | 'body-1-deprecated'
  | 'body-2'
  | 'lead'
  | 'quote-1'
  | 'quote-2'
  | 'stat'
  | 'nav-1'
  | 'nav-2'
  | 'nav-3'
  | 'nav-4'
  | 'title-1'
  | 'title-2'
  | 'title-3'
  | 'subtitle-1'
  | 'subtitle-2'
  | 'subtitle-3'
  | 'link-1'
  | 'link-2'
  | 'link-3'
  | 'overline';

export type TextType = DeprecatedTextType | NewTextType;

export const textVariantClassName = (variant?: TextType) => {
  switch (variant) {
    case 'body1':
      return 'text-lg font-bold leading-[24px] -tracking-[0.005em]';
    case 'body2':
      return 'text-base font-[500] leading-[24px]';
    case 'body3':
      return 'text-sm font-[400] leading-[20px]';
    case 'label1':
      return 'text-lg font-bold leading-[20px] uppercase';
    case 'label2':
      return 'text-sm font-semibold leading-[20px] uppercase';
    case 'label3':
      return 'text-tiny font-bold leading-[16px] uppercase';
    case 'h1':
      return 'text-4xl leading-[48px] -tracking-[-2.88%] font-bold';
    case 'h2':
      return 'text-3xl leading-[36px] -tracking-[-1.92%] font-bold';
    case 'h3':
      return 'text-2xl leading-[32px] -tracking-[-0.01em] font-bold';
    case 'h4':
      return 'text-xl font-bold leading-[28px] -tracking-[0.01em]';
    case 'button1':
      return 'text-xl font-bold leading-[22px] uppercase';
    case 'button2':
      return 'text-[15px] tracking-[0.01em] font-bold leading-[22px]';
    case 'link':
      return 'text-base font-[500] leading-[24px]';
    case 'display':
      return 'font-semibold text-[40px] leading-[44px] tablet:text-[64px] text-[64px] tablet:leading-[72px] leading-[72px]';
    case 't1':
      return 'font-semibold text-[30px] leading-[32px] -tracking-[0px] tablet:text-[48px] text-[48px] tablet:leading-[52px] leading-[52px] tablet:-tracking-[-0.2px] -tracking-[-0.2px]';
    case 't2':
      return 'font-semibold text-[25px] leading-[28px] tablet:text-[36px] text-[36px] tablet:leading-[44px] leading-[44px]';
    case 't3':
      return 'font-semibold text-[20px] leading-[24px] tablet:text-[24px] text-[24px] tablet:leading-[32px] leading-[32px]';
    case 't4':
      return 'font-semibold text-[16px] leading-[24px] tablet:text-[18px] text-[18px] tablet:leading-[28px] leading-[28px]';
    case 't5':
      return 'font-semibold text-[14px] leading-[20px] tablet:text-[16px] text-[16px] tablet:leading-[24px] leading-[24px]';
    case 't6':
      return 'font-semibold text-[12px] leading-[16px] tracking-[0.8px] uppercase';
    case 'body-1-deprecated':
      return 'font-normal text-[18px] leading-[24px] tablet:text-[18px] tablet:leading-[28px]';
    case 'body-2':
      return 'font-normal text-[16px] leading-[20px] tablet:text-[16px] text-[16px] tablet:leading-[24px] leading-[24px]';
    case 'lead':
      return 'font-normal text-[28px] leading-[36px] tablet:text-[36px] text-[36px] tablet:leading-[44px] leading-[44px]';
    case 'quote-1':
      return 'font-normal italic text-[24px] leading-[32px] tablet:text-[40px] text-[40px] tablet:leading-[56px] leading-[56px]';
    case 'quote-2':
      return 'font-normal italic text-[24px] leading-[32px] tablet:text-[30px] text-[30px] tablet:leading-[40px] leading-[40px]';
    case 'stat':
      return 'font-semibold text-[40px] leading-[48px] tablet:text-[56px] text-[56px] tablet:leading-[64px] leading-[64px]';
    case 'nav-1':
      return 'font-semibold text-[28px] leading-[32px]';
    case 'nav-2':
      return 'font-semibold text-[16px] leading-[20px] tablet:text-[20px] text-[20px] tablet:leading-[24px] leading-[24px]';
    case 'nav-3':
      return 'font-semibold text-[16px] leading-[20px]';
    case 'nav-4':
      return 'font-semibold text-[24px] leading-[32px]';
    case 'title-1':
      return 'font-semibold text-[20px] leading-[24px]';
    case 'title-2':
      return 'font-medium text-[16px] leading-[20px]';
    case 'title-3':
      return 'font-medium text-[14px] leading-[16px]';
    case 'subtitle-1':
      return 'font-normal text-[16px] leading-[20px]';
    case 'subtitle-2':
      return 'font-semibold text-[14px] leading-[20px]';
    case 'subtitle-3':
      return 'font-normal text-[14px] leading-[16px]';
    case 'link-1':
      return 'font-semibold text-[16px] leading-[24px]';
    case 'link-2':
      return 'font-semibold text-[14px] leading-[16px]';
    case 'link-3':
      return 'font-semibold text-[12px] leading-[16px]';
    case 'overline':
      return 'font-semibold text-[14px] leading-[16px] tracking-[0.8px]';
    case 'heading-1':
      return 'font-bold tablet:text-[2.5rem] tablet:leading-[3rem] text-[2rem] leading-[2.5rem] tracking-[-0.0125rem] lining-nums proportional-nums';
    case 'heading-2':
      return 'font-bold tablet:text-[2rem] tablet:leading-[2.5rem] text-[1.5rem] leading-[2rem] lining-nums proportional-nums';
    case 'heading-3':
      return 'font-bold tablet:text-[1.5rem] tablet:leading-[2rem] text-[1.25rem] leading-[1.75rem] lining-nums proportional-nums';
    case 'heading-4':
      return 'font-bold text-[1.125rem] leading-[1.625rem] lining-nums proportional-nums';
    case 'heading-5':
      return 'font-bold text-[1rem] leading-[1.5rem] lining-nums proportional-nums';
    case 'body-1':
      return 'font-normal text-[1rem] leading-[1.5rem]';
    case 'body-1-emphasis':
      return 'font-semibold text-[1rem] leading-[1.5rem]';
    case 'tag':
      return 'font-normal text-[0.875rem] leading-[1.125rem]';
    default:
      return '';
  }
};
