import partition from 'lodash/partition';
import { formatLocal } from 'phoneformat.js';

import { AlertIcon, CallIcon, CheckIcon, CloseIcon, DialPadIcon } from '../components/icons';
import { CallParticipant, CallStatus, VoiceConference } from '../types/Call';
import { cleanPhone, formatPhone } from './Member';

export const callStatusTextMap: Record<CallStatus, string> = {
  initiated: 'Dialing Member',
  answered: 'Answered',
  queued: 'Queued',
  'in-progress': 'Call Active',
  completed: 'Call Ended',
  busy: 'Line Busy',
  'no-answer': 'No Answer',
  ringing: 'Ringing Member...',
  canceled: 'Call Ended',
  failed: 'Call Failed',
};

export const callStatusIconComponentMap: Record<CallStatus, JSX.Element> = {
  initiated: <DialPadIcon size={13} colorOverride="darkGrayTwo" />,
  answered: <CallIcon size={16} colorOverride="darkGrayTwo" />,
  queued: <></>,
  'in-progress': <CheckIcon size={16} colorOverride="mediumGreen" />,
  completed: <CloseIcon size={12} colorOverride="darkGrayTwo" />,
  busy: <AlertIcon size={13} colorOverride="darkGrayTwo" />,
  'no-answer': <></>,
  ringing: <CallIcon colorOverride="darkGrayTwo" />,
  canceled: <CloseIcon size={12} colorOverride="darkGrayTwo" />,
  failed: <AlertIcon size={13} />,
};

export function getCallStatusFromParticipants(
  outboundParticipants: CallParticipant[],
  inboundParticipant?: CallParticipant
): CallStatus | undefined {
  if (outboundParticipants.length === 0 && inboundParticipant === undefined) return undefined;
  const participantStatus =
    outboundParticipants.length > 0 ? outboundParticipants[0]?.callStatus ?? [] : inboundParticipant?.callStatus;
  return participantStatus;
}

export function seperateParticipantsByDirection(participants: CallParticipant[]) {
  if (participants.length === 0) return { inboundParticipant: undefined, outboundParticipants: [] };
  const [inboundParticipants, outboundParticipants] = partition(participants, { direction: 'inbound' });
  const inboundParticipant = inboundParticipants[0];
  return { inboundParticipant, outboundParticipants };
}

export function getActiveParticipants(participants: CallParticipant[]) {
  return participants.filter((participant) => CallStatus.isActive(participant.callStatus));
}

export function getCallInfoFromConferenceByPhone(
  phoneNumber: string,
  conference: VoiceConference
): CallParticipant | undefined {
  return conference.callParticipants.find(
    (participant: CallParticipant) => cleanPhone(participant.toPhoneNumber) === cleanPhone(phoneNumber)
  );
}

export function getCallInfoFromConferenceById(
  callSid: string,
  conference?: VoiceConference
): CallParticipant | undefined {
  if (!conference) {
    return;
  }
  return conference.callParticipants.find((participant: CallParticipant) => {
    participant.twilioCallId === callSid;
  });
}

export function getNonMemberNumbers(conference?: VoiceConference, memberPhoneNumber?: string): string[] {
  if (!conference) {
    return [];
  }
  const { outboundParticipants } = seperateParticipantsByDirection(conference.callParticipants);
  return outboundParticipants
    .filter(
      (participant) =>
        CallStatus.isActive(participant.callStatus) &&
        formatPhone(participant.toPhoneNumber) !== formatPhone(memberPhoneNumber || '')
    )
    .map((secondaryParticipant) => formatPhone(secondaryParticipant.toPhoneNumber));
}
export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';

  if (phoneNumber.charAt(0) === '*') {
    return `${phoneNumber.slice(0, 3)} ${formatLocal('us', phoneNumber.slice(3))}`;
  }

  const hasSpecialChars = ['*', '#'].some((c) => phoneNumber.includes(c));
  if (hasSpecialChars) return phoneNumber;

  return formatLocal('us', phoneNumber);
};
