import classNames from 'classnames';

import { toFillColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const MenuHome = ({ size = 24, colorOverride, active = true }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const activeStandardClass = !active ? 'group-hover:invisible' : '';
  const standardClass = `${!active ? 'visible' : 'invisible'} ${activeStandardClass}`;
  const activeAlternateClass = !active ? 'group-hover:visible' : '';
  const alternateClass = `${!active ? 'invisible' : 'visible'} ${activeAlternateClass}`;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8902 5.54655C22.8016 4.38813 25.1984 4.38813 27.1098 5.54655L41.1098 14.0314C42.9041 15.1188 44 17.0645 44 19.1626V38.0005C44 41.3142 41.3137 44.0005 38 44.0005H30C28.8954 44.0005 28 43.105 28 42.0005V32.0005C28 30.8959 27.1046 30.0005 26 30.0005H22C20.8954 30.0005 20 30.8959 20 32.0005V42.0005C20 43.105 19.1046 44.0005 18 44.0005H10C6.68629 44.0005 4 41.3142 4 38.0005V19.1626C4 17.0645 5.09592 15.1188 6.89019 14.0314L20.8902 5.54655ZM25.0366 8.96734C24.3995 8.5812 23.6005 8.5812 22.9634 8.96734L8.96339 17.4522C8.36531 17.8147 8 18.4632 8 19.1626V38.0005C8 39.105 8.89543 40.0005 10 40.0005H16V32.0005C16 28.6867 18.6863 26.0005 22 26.0005H26C29.3137 26.0005 32 28.6867 32 32.0005V40.0005H38C39.1046 40.0005 40 39.105 40 38.0005V19.1626C40 18.4632 39.6347 17.8147 39.0366 17.4522L25.0366 8.96734Z"
          className={classNames(fillClass, standardClass)}
        />
        <path
          d="M27.1098 5.54655C25.1984 4.38813 22.8016 4.38813 20.8902 5.54655L6.89019 14.0314C5.09592 15.1188 4 17.0645 4 19.1626V38.0005C4 41.3142 6.68629 44.0005 10 44.0005H17C18.1046 44.0005 19 43.105 19 42.0005V32.0005C19 30.8959 19.8954 30.0005 21 30.0005H27C28.1046 30.0005 29 30.8959 29 32.0005V42.0005C29 43.105 29.8954 44.0005 31 44.0005H38C41.3137 44.0005 44 41.3142 44 38.0005V19.1626C44 17.0645 42.9041 15.1188 41.1098 14.0314L27.1098 5.54655Z"
          className={classNames(fillClass, alternateClass)}
        />
      </svg>
    </>
  );
};

export default MenuHome;
