import classNames from 'classnames';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const CalendarIcon = ({ active = true, className, size = 24, colorOverride }: IconProps) => (
  <svg
    className={classNames('flex-shrink-0', className)}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4618 4.10653H19.3849C20.4003 4.10653 21.2311 4.91736 21.2311 5.90838V20.3232C21.2311 21.3142 20.4003 22.125 19.3849 22.125H4.61569C3.6003 22.125 2.76953 21.3142 2.76953 20.3232V5.90838C2.76953 4.91736 3.6003 4.10653 4.61569 4.10653H5.53876V3.20561C5.53876 2.7101 5.95415 2.30469 6.46184 2.30469C6.96953 2.30469 7.38492 2.7101 7.38492 3.20561V4.10653H16.6157V3.20561C16.6157 2.7101 17.0311 2.30469 17.5388 2.30469C18.0465 2.30469 18.4618 2.7101 18.4618 3.20561V4.10653ZM5.53876 20.3232H18.4618C18.9695 20.3232 19.3849 19.9177 19.3849 19.4222V8.61117H4.61568V19.4222C4.61568 19.9177 5.03107 20.3232 5.53876 20.3232Z"
      className={toFillColor(colorOverride)}
      fillOpacity={active ? 1 : 0.7}
    />
  </svg>
);
