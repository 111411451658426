import classNames from 'classnames';
import Link from 'next/link';
import { useMemo } from 'react';

import { toBackgroundColor } from '../../../theme/colors';
import type { ButtonSize, ColorType, IconType } from '../../../types/ui';
import { icons } from '../../icons';

type IconButtonProps = {
  size?: ButtonSize;
  btnColor?: ColorType;
  iconSize?: number;
  icon: IconType;
  className?: string;
  color?: ColorType;
  hoverColor?: ColorType;
  onClick?: () => void;
  disabled?: boolean;
  label?: string;
  href?: string;
  sameTab?: boolean;
};

export const IconButton = ({
  color = 'darkGrayTwo',
  btnColor,
  hoverColor,
  icon,
  size = 'medium',
  className,
  iconSize: icoSize,
  onClick,
  disabled = false,
  label = '',
  href,
  sameTab,
}: IconButtonProps) => {
  const Icon = icons[icon];
  const btnBgColor = toBackgroundColor(btnColor);

  const [sizeClassName, iconSize] = useMemo(() => {
    switch (size) {
      case 'small':
        return ['w-[16px] h-[16px] max-w-[16px] max-h-[16px] min-w-[16px] min-h-[16px]', 16];
      case 'large':
        return ['w-[32px] h-[32px] max-w-[32px] max-h-[32px] min-w-[32px] min-h-[32px]', 32];
      case 'xLarge':
        return ['w-[36px] h-[36px] max-w-[36px] max-h-[36px] min-w-[36px] min-h-[36px]', 36];
      case 'xxLarge':
        return ['w-[48px] h-[48px] max-w-[48px] max-h-[48px] min-w-[48px] min-h-[48px]', 48];
      case 'medium':
      default:
        return ['w-[24px] h-[24px] max-w-[24px] max-h-[24px] min-w-[24px] min-h-[24px]', 24];
    }
  }, [size]);

  const buttonClassName = classNames(
    className,
    sizeClassName,
    'flex items-center justify-center cursor-pointer rounded-full',
    btnColor ? btnBgColor : 'bg-transparent',
    hoverColor || disabled ? '' : 'hover:bg-lightGrayOne group-hover:bg-lightGrayOne',
    disabled ? 'cursor-auto' : ''
  );

  return (
    <>
      {href && (
        <Link
          passHref
          href={href}
          className={buttonClassName}
          aria-label={label ? label : `${icon}`}
          target={href.includes('http') && !sameTab ? '_blank' : '_self'}
          rel={'noreferrer'}
        >
          <Icon
            colorOverride={color}
            {...{ hoverColorOverride: !disabled ? hoverColor : undefined }}
            size={icoSize ?? iconSize}
          />
        </Link>
      )}
      {!href && (
        <button
          type="button"
          className={buttonClassName}
          aria-label={label ? label : `${icon}`}
          onClick={() => {
            if (!disabled && onClick) onClick();
          }}
        >
          <Icon
            colorOverride={color}
            {...{ hoverColorOverride: !disabled ? hoverColor : undefined }}
            size={icoSize ?? iconSize}
          />
        </button>
      )}
    </>
  );
};
