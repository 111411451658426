import classNames from 'classnames';
import React from 'react';

import Circle from '../../icons/Circle';
import { DecorationsType } from '../Decorations';

const DecorativeCirclesSmall = ({
  decorationsType = 'activate',
  className,
}: {
  decorationsType?: DecorationsType;
  className?: string;
}) => {
  return (
    <div className={classNames('w-screen abosolute left-[0px] top-[0px]', className)}>
      {decorationsType === 'activate' && (
        <>
          <div className="absolute block top-[75px] left-[-20px]">
            <Circle
              size={94}
              color={'darkBoneTwo'}
              stroke={'darkBoneTwo'}
              className="absolute mix-blend-multiply opacity-60"
            />
          </div>
          <div className="absolute block top-[140px] left-[-23px]">
            <Circle size={45} color={'orange'} stroke={'orange'} className="absolute mix-blend-multiply opacity-60" />
          </div>
          <div className="absolute block top-[80px] left-[-75px]">
            <Circle
              strokeWidth="1.5"
              size={94}
              color={'transparent'}
              stroke={'mediumGrayOne'}
              className="absolute opacity-70"
            />
          </div>
          <div className="absolute block top-[75px] right-[-35px]">
            <Circle
              size={90}
              color="transparent"
              stroke="darkGrayTwo"
              strokeWidth="1"
              className="absolute mix-blend-multiply opacity-40"
            />
          </div>
          <div className="absolute block top-[92px] right-[-20px]">
            <Circle
              size={83}
              color={'darkBoneTwo'}
              stroke={'darkBoneTwo'}
              className="absolute mix-blend-multiply opacity-40"
            />
          </div>
          <div className="absolute block top-[105px] right-[32px]">
            <Circle size={40} color={'magenta'} stroke={'magenta'} className="absolute mix-blend-multiply opacity-50" />
          </div>
        </>
      )}
      {decorationsType === 'notFoundPage' && (
        <>
          {/* Top Left */}
          <div className="absolute block top-[64px] left-[-20px]">
            <Circle
              size={112}
              color={'darkBoneTwo'}
              stroke={'darkBoneTwo'}
              className="absolute mix-blend-multiply opacity-60"
            />
          </div>
          <div className="absolute block top-[112px] left-[-16px]">
            <Circle size={56} color={'magenta'} stroke={'magenta'} className="absolute mix-blend-multiply opacity-60" />
          </div>
          <div className="absolute block top-[64px] left-[-64px]">
            <Circle
              strokeWidth="1.5"
              size={94}
              color={'transparent'}
              stroke={'mediumGrayOne'}
              className="absolute opacity-70"
            />
          </div>
          {/* Top Right */}
          <div className="absolute block top-[96px] right-[-35px]">
            <Circle
              size={96}
              color="transparent"
              stroke="darkGrayTwo"
              strokeWidth="1"
              className="absolute mix-blend-multiply opacity-40"
            />
          </div>
          <div className="absolute block top-[40px] right-[-56px]">
            <Circle
              size={130}
              color={'darkBoneTwo'}
              stroke={'darkBoneTwo'}
              className="absolute mix-blend-multiply opacity-40"
            />
          </div>
          <div className="absolute block top-[96px] right-[32px]">
            <Circle size={64} color={'orange'} stroke={'orange'} className="absolute mix-blend-multiply opacity-50" />
          </div>
        </>
      )}
      {(decorationsType === 'home' || decorationsType === 'reverseHome') && (
        <>
          <div className="absolute block top-[60px] left-[-10px] mix-blend-multiply opacity-60 rotate-[-14.741deg]">
            <Circle size={114} color={'darkBoneTwo'} stroke={'darkBoneTwo'} />
          </div>
          <div className="absolute block top-[110px] left-[-10px] mix-blend-multiply opacity-60 rotate-[-14.741deg]">
            <Circle
              size={56}
              color={decorationsType === 'reverseHome' ? 'orange' : 'magenta'}
              stroke={decorationsType === 'reverseHome' ? 'orange' : 'magenta'}
            />
          </div>
          <div className="absolute block top-[70px] left-[-55px] opacity-70">
            <Circle strokeWidth="1.5" size={92} color={'transparent'} stroke={'mediumGrayOne'} />
          </div>
          <div className="absolute block top-[100px] right-[-20px] mix-blend-multiply opacity-40">
            <Circle size={97} color="transparent" stroke="neutralGrayThree" strokeWidth="1" />
          </div>
          <div className="absolute block top-[50px] right-[-50px] rotate-[-148.181deg] mix-blend-multiply opacity-40">
            <Circle size={130} color={'darkBoneTwo'} stroke={'darkBoneTwo'} />
          </div>
          <div className="absolute block top-[100px] right-[40px] rotate-[-148.181deg] mix-blend-multiply opacity-50">
            <Circle
              size={64}
              color={decorationsType === 'reverseHome' ? 'magenta' : 'orange'}
              stroke={decorationsType === 'reverseHome' ? 'magenta' : 'orange'}
            />
          </div>
        </>
      )}
      {decorationsType === 'recommendations' && (
        <>
          <div className="absolute block top-[52px] right-[50px] rotate-[-148.181deg] mix-blend-multiply opacity-50">
            <Circle size={86} color={'magenta'} stroke={'magenta'} />
          </div>
          <div className="absolute block top-[50px] right-[5px] mix-blend-multiply opacity-70">
            <Circle size={86} color={'darkBlue'} stroke={'darkBlue'} />
          </div>
          <div className="absolute block top-[65px] right-[-35px] rotate-[-148.181deg] mix-blend-multiply opacity-80">
            <Circle strokeWidth=".6" size={80} color={'transparent'} stroke={'darkGrayTwo'} />
          </div>
        </>
      )}
      {decorationsType === 'needs' && (
        <>
          <div className="absolute block top-[30px] left-[-10px] mix-blend-multiply opacity-60 rotate-[-14.741deg]">
            <Circle size={114} color={'darkBoneTwo'} stroke={'darkBoneTwo'} />
          </div>
          <div className="absolute block top-[120px] left-[-10px] mix-blend-multiply opacity-60 rotate-[-14.741deg]">
            <Circle size={56} color={'magenta'} stroke={'magenta'} />
          </div>
          <div className="absolute block top-[40px] left-[-55px] opacity-70">
            <Circle strokeWidth="1.5" size={92} color={'transparent'} stroke={'mediumGrayOne'} />
          </div>
          <div className="absolute block top-[70px] right-[-20px] mix-blend-multiply opacity-40">
            <Circle size={97} color="transparent" stroke="neutralGrayThree" strokeWidth="1" />
          </div>
          <div className="absolute block top-[20px] right-[-50px] rotate-[-148.181deg] mix-blend-multiply opacity-40">
            <Circle size={130} color={'darkBoneTwo'} stroke={'darkBoneTwo'} />
          </div>
          <div className="absolute block top-[70px] right-[40px] rotate-[-148.181deg] mix-blend-multiply opacity-50">
            <Circle size={64} color={'darkBlue'} stroke={'darkBlue'} />
          </div>
        </>
      )}
    </div>
  );
};

export default DecorativeCirclesSmall;
