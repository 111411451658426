import classNames from 'classnames';
import React, { PropsWithChildren, useMemo, useRef } from 'react';
import { Popover, PopoverPosition } from 'react-tiny-popover';

import Text from './Text';

type DirectionX = 'right' | 'left';
type DirectionY = 'top' | 'bottom';

type PopoverProps = {
  className?: string;
  containerClassName?: string;
  message: string | React.ReactNode;
  disabled?: boolean;
  popoverPositions?: PopoverPosition[];
  verticalAlign?: DirectionY;
  limitedWidth?: boolean;
  onClick?: () => {};
};

const Tooltip = ({
  className = '',
  containerClassName = '',
  message,
  disabled = false,
  children,
  popoverPositions = ['right'],
  verticalAlign,
  onClick,
}: PropsWithChildren<PopoverProps>) => {
  const [hover, setHover] = React.useState(false);

  const popoverContainer = useRef<HTMLDivElement>(null);

  const topPaddingShortcut = useMemo(() => {
    if (!verticalAlign) {
      return;
    }
    if (verticalAlign === 'top') {
      return 'mt-[-20px]';
    }
    return 'mt-[40px]';
  }, [verticalAlign]);

  return (
    <Popover
      containerStyle={{ zIndex: '30' }}
      ref={popoverContainer}
      isOpen={hover && !disabled}
      positions={popoverPositions}
      padding={2}
      align="start"
      content={() => (
        <>
          {message && (
            <div
              className={classNames(
                `flex flex-col border-[1px] border-solid border-mediumTeal rounded-[16px] bg-white p-4`,
                topPaddingShortcut,
                containerClassName
              )}
            >
              <Text privacyLevel="public" variant="body3" color="mediumTeal" className="whitespace-pre-line">
                {message}
              </Text>
            </div>
          )}
        </>
      )}
    >
      <div
        className={onClick ? '' : className}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onFocus={() => setHover(true)}
        onBlur={() => setHover(false)}
      >
        {onClick ? (
          <button aria-label={message?.toString()} className={className} onClick={onClick}>
            {children}
          </button>
        ) : (
          <>{children}</>
        )}
      </div>
    </Popover>
  );
};

export default Tooltip;
