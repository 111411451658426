import classNames from 'classnames';
import React from 'react';

import Circle from '../../icons/Circle';
import { DecorationsType } from '../Decorations';

const DecorativeCirclesLarge = ({
  decorationsType = 'activate',
  className,
}: {
  decorationsType?: DecorationsType;
  className?: string;
}) => {
  return (
    <div className={classNames('w-screen abosolute left-[0px] top-[0px]', className)}>
      {decorationsType === 'activate' && (
        <>
          <div className="absolute block top-[0px] left-[-68px]">
            <Circle
              size={216}
              color={'darkBoneTwo'}
              stroke={'darkBoneTwo'}
              className="absolute mix-blend-multiply opacity-60"
            />
          </div>
          <div className="absolute block top-[157px] left-[-64px]">
            <Circle
              size={104.68}
              color={'orange'}
              stroke={'orange'}
              className="absolute mix-blend-multiply opacity-60"
            />
          </div>
          <div className="absolute block top-[136px] left-[-59px]">
            <Circle
              strokeWidth="2.5"
              size={94}
              color={'transparent'}
              stroke={'mediumGrayOne'}
              className="absolute opacity-70"
            />
          </div>
          <div className="absolute block top-[-40px] right-[-70px]">
            <Circle
              size={246}
              color="transparent"
              stroke="darkGrayTwo"
              strokeWidth=".8"
              className="absolute mix-blend-multiply opacity-40"
            />
          </div>
          <div className="absolute block top-[-10px] right-[-116px]">
            <Circle
              size={271}
              color={'darkBoneTwo'}
              stroke={'darkBoneTwo'}
              className="absolute mix-blend-multiply opacity-40"
            />
          </div>
          <div className="absolute block top-[10px] right-[75px]">
            <Circle
              size={132.1}
              color={'magenta'}
              stroke={'magenta'}
              className="absolute mix-blend-multiply opacity-50"
            />
          </div>
        </>
      )}
      {decorationsType === 'notFoundPage' && (
        <>
          {/* Top Left */}
          <div className="absolute block top-[0px] left-[-16px]">
            <Circle
              size={216}
              color={'darkBoneTwo'}
              stroke={'darkBoneTwo'}
              className="absolute mix-blend-multiply opacity-60"
            />
          </div>
          <div className="absolute block top-[104px] left-[-40px]">
            <Circle
              size={104.68}
              color={'magenta'}
              stroke={'magenta'}
              className="absolute mix-blend-multiply opacity-60"
            />
          </div>
          <div className="absolute block top-[-24px] left-[-80px]">
            <Circle
              size={174}
              color={'transparent'}
              stroke={'mediumGrayOne'}
              strokeWidth="1"
              className="absolute opacity-70"
            />
          </div>
          {/* Top Right */}
          <div className="absolute block top-[-40px] right-[-70px]">
            <Circle
              size={246}
              color="transparent"
              stroke="darkGrayTwo"
              strokeWidth="0.5"
              className="absolute mix-blend-multiply opacity-40"
            />
          </div>
          <div className="absolute block top-[-10px] right-[-116px]">
            <Circle
              size={271}
              color={'darkBoneTwo'}
              stroke={'darkBoneTwo'}
              className="absolute mix-blend-multiply opacity-40"
            />
          </div>
          <div className="absolute block top-[10px] right-[75px]">
            <Circle
              size={132.1}
              color={'orange'}
              stroke={'orange'}
              className="absolute mix-blend-multiply opacity-50"
            />
          </div>
        </>
      )}
      {(decorationsType === 'home' || decorationsType === 'reverseHome') && (
        <>
          <div className="absolute block top-[20px] left-[-20px] mix-blend-multiply opacity-60 rotate-[-14.741deg]">
            <Circle size={216} color={'darkBoneTwo'} stroke={'darkBoneTwo'} />
          </div>
          <div className="absolute block top-[109px] left-[-40px] mix-blend-multiply opacity-60 rotate-[-14.741deg]">
            <Circle
              size={104.68}
              color={decorationsType === 'reverseHome' ? 'orange' : 'magenta'}
              stroke={decorationsType === 'reverseHome' ? 'orange' : 'magenta'}
            />
          </div>
          <div className="absolute block top-[10px] left-[-80px] opacity-70">
            <Circle strokeWidth=".6" size={174} color={'transparent'} stroke={'mediumGrayOne'} />
          </div>
          <div className="absolute block top-[-29px] right-[-62px] mix-blend-multiply opacity-40">
            <Circle size={246} color="transparent" stroke="neutralGrayThree" strokeWidth=".6" />
          </div>
          <div className="absolute block top-[-10px] right-[-100px] rotate-[-148.181deg] mix-blend-multiply opacity-40">
            <Circle size={271} color={'darkBoneTwo'} stroke={'darkBoneTwo'} />
          </div>
          <div className="absolute block top-[15px] right-[80px] rotate-[-148.181deg] mix-blend-multiply opacity-50">
            <Circle
              size={132.1}
              color={decorationsType === 'reverseHome' ? 'magenta' : 'orange'}
              stroke={decorationsType === 'reverseHome' ? 'magenta' : 'orange'}
            />
          </div>
        </>
      )}
      {decorationsType === 'recommendations' && (
        <>
          <div className="absolute block top-[-75px] right-[150px] rotate-[-148.181deg] mix-blend-multiply opacity-50">
            <Circle size={213} color={'magenta'} stroke={'magenta'} />
          </div>
          <div className="absolute block top-[-70px] right-[50px] mix-blend-multiply opacity-70">
            <Circle size={213} color={'darkBlue'} stroke={'darkBlue'} />
          </div>
          <div className="absolute block top-[0px] right-[25px] rotate-[-148.181deg] mix-blend-multiply opacity-80">
            <Circle strokeWidth=".6" size={143} color={'transparent'} stroke={'darkGrayTwo'} />
          </div>
        </>
      )}
      {decorationsType === 'needs' && (
        <>
          <div className="absolute block top-[0px] left-[-40px] mix-blend-multiply opacity-60 rotate-[-14.741deg]">
            <Circle size={216} color={'darkBoneTwo'} stroke={'darkBoneTwo'} />
          </div>
          <div className="absolute block top-[150px] left-[-40px] mix-blend-multiply opacity-60 rotate-[-14.741deg]">
            <Circle size={104.68} color={'magenta'} stroke={'magenta'} />
          </div>
          <div className="absolute block top-[0px] left-[-10px] opacity-50">
            <Circle strokeWidth="1.6" size={143} color={'transparent'} stroke={'mediumGrayOne'} />
          </div>
          <div className="absolute block top-[100px] right-[-100px] mix-blend-multiply opacity-50">
            <Circle size={200} color="transparent" stroke="mediumGrayOne" strokeWidth=".6" />
          </div>
          <div className="absolute block top-[-10px] right-[-100px] rotate-[-148.181deg] mix-blend-multiply opacity-40">
            <Circle size={271} color={'darkBoneTwo'} stroke={'darkBoneTwo'} />
          </div>
          <div className="absolute block top-[15px] right-[100px] rotate-[-148.181deg] mix-blend-multiply opacity-50">
            <Circle size={132.1} color={'darkBlue'} stroke={'darkBlue'} />
          </div>
        </>
      )}
    </div>
  );
};

export default DecorativeCirclesLarge;
