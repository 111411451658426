import classNames from 'classnames';
import React, { useState } from 'react';

import { Card, IconButton, Text } from '..';

type Props = {
  header: React.ReactNode;
  content: React.ReactNode;
  isExpanded?: boolean;
};

export const ExpandableRow = ({ header, content, isExpanded = false }: Props) => {
  const [expanded, setExpanded] = useState(isExpanded);

  return (
    <div>
      <Card
        direction="row"
        fullWidth
        className="items-center px-7 py-3 cursor-pointer"
        onClick={() => setExpanded((v) => !v)}
      >
        <IconButton
          className="mr-2 w-[32px] h-[32px] max-w-[32px] max-h-[32px]"
          icon={expanded ? 'down' : 'right'}
          color="magenta"
        />
        <Text privacyLevel="public" variant="h4">
          {header}
        </Text>
      </Card>
      {expanded && (
        <Card
          fullWidth
          display="block"
          className={classNames(
            'p-6 overflow-hidden transition-[max-height-TEST] duration-1000 mt-2',
            expanded ? 'max-h-[900px] overflow-visible' : 'max-h-[0px] mt-0 py-0 border-none duration-75'
          )}
        >
          {content}
        </Card>
      )}
    </div>
  );
};
