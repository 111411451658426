import classNames from 'classnames';
import React, { ForwardedRef, PropsWithChildren, forwardRef } from 'react';

import { textVariantClassName } from '../../../../theme/typography';
import LoadingSpinner from '../../LoadingSpinner';
import LinkAnchor from './LinkAnchor';
import LinkButton from './LinkButton';
import { UNDERLINE_CLASSES, VARIANT_CLASSES } from './common';
import {
  CommonProps,
  LinkAnchorProps,
  LinkButtonProps,
  LinkProps,
  LinkTextVariant,
  LinkVariant,
  UnderlineType,
} from './types';

export function isLinkButtonProps(props: LinkProps): props is LinkButtonProps {
  return !(props as unknown as LinkAnchorProps).href;
}

type RefType = HTMLButtonElement | HTMLAnchorElement;

type Link = {
  (props: LinkAnchorProps): JSX.Element;
  (props: LinkButtonProps): JSX.Element;
};

const LinkComponent = forwardRef<RefType, PropsWithChildren<LinkProps>>((passedProps, ref): JSX.Element => {
  const defaults: Required<CommonProps> = {
    underline: 'hover',
    variant: 'light',
    textVariant: 'heading-4',
    isLoading: false,
  };
  let props = { ...defaults, ...passedProps };
  const { variant, underline, textVariant, className } = props;
  const classes = classNames(
    'group',
    'flex',
    'flex-row',
    'gap-1',
    'rounded-[0.125rem]',
    'flex-wrap',
    'font-poppins',
    VARIANT_CLASSES[variant as LinkVariant],
    UNDERLINE_CLASSES[underline as UnderlineType],
    textVariantClassName(textVariant as LinkTextVariant),
    className,
    {
      'cursor-disabled': props.isLoading,
    }
  );
  props = { ...props, className: classes };
  const buttonDefaults: LinkButtonProps = {
    type: 'button',
  };

  if (isLinkButtonProps(props)) {
    props = { ...props, ...buttonDefaults };
    return (
      <div className={classNames('relative', props.className)}>
        <div className={classNames(props.isLoading ? 'invisible' : 'visible')}>
          <LinkButton {...props} ref={ref as ForwardedRef<HTMLButtonElement>} />
        </div>
        {props.isLoading && (
          <div className="absolute top-0 left-0 right-0">
            <LoadingSpinner colorOverride="linkBlue" size={24} />
          </div>
        )}
      </div>
    );
  } else {
    return <LinkAnchor {...props} ref={ref as ForwardedRef<HTMLAnchorElement>} />;
  }
});

LinkComponent.displayName = 'Link';
const Link = LinkComponent as Link;
export default Link;
