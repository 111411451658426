import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor, toGroupHoverStrokeColor, toStrokeColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const MenuNewHelp = ({ size = 24, colorOverride, hoverColorOverride, active = true }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const strokeClass = toStrokeColor(colorOverride);
  const groupHover = toGroupHoverFillColor(hoverColorOverride);
  const groupStrokeClass = toGroupHoverStrokeColor(hoverColorOverride);
  const activeStandardClass = !active ? 'group-hover:invisible' : '';
  const standardClass = `${!active ? 'visible' : 'invisible'} ${activeStandardClass}`;
  const activeAlternateClass = !active ? 'group-hover:visible' : '';
  const alternateClass = `${!active ? 'invisible' : 'visible'} ${activeAlternateClass}`;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* standard */}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 8C16.268 8 10 14.268 10 22C10 23.1046 9.10457 24 8 24C6.89543 24 6 23.1046 6 22C6 12.0589 14.0589 4 24 4C33.9411 4 42 12.0589 42 22C42 23.1046 41.1046 24 40 24C38.8954 24 38 23.1046 38 22C38 14.268 31.732 8 24 8Z"
          className={classNames(fillClass, groupHover, standardClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0239 20.4331C12.0436 19.9281 14 21.4557 14 23.5375V34.4636C14 36.5455 12.0436 38.073 10.0239 37.5681L6.54479 36.6983C3.87378 36.0306 2 33.6307 2 30.8775V27.1237C2 24.3705 3.87378 21.9706 6.54478 21.3028L10.0239 20.4331ZM10 24.5621L7.51493 25.1834C6.62459 25.406 6 26.206 6 27.1237V30.8775C6 31.7952 6.62459 32.5952 7.51493 32.8178L10 33.439V24.5621Z"
          className={classNames(fillClass, groupHover, standardClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38 24.5621V33.439L40.4851 32.8178C41.3754 32.5952 42 31.7952 42 30.8775V27.1237C42 26.206 41.3754 25.406 40.4851 25.1834L38 24.5621ZM34 23.5375C34 21.4557 35.9564 19.9281 37.9761 20.4331L41.4552 21.3028C44.1262 21.9706 46 24.3705 46 27.1237V30.8775C46 33.6307 44.1262 36.0306 41.4552 36.6983L37.9761 37.5681C35.9564 38.073 34 36.5455 34 34.4636V23.5375Z"
          className={classNames(fillClass, groupHover, standardClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38 37V36H42V37C42 40.3137 39.3137 43 36 43H29V39H36C37.1046 39 38 38.1046 38 37Z"
          className={classNames(fillClass, groupHover, standardClass)}
        />
        <path
          d="M21 40C20.4477 40 20 40.4477 20 41C20 41.5523 20.4477 42 21 42H27C27.5523 42 28 41.5523 28 41C28 40.4477 27.5523 40 27 40H21ZM16 41C16 38.2386 18.2386 36 21 36H27C29.7614 36 32 38.2386 32 41C32 43.7614 29.7614 46 27 46H21C18.2386 46 16 43.7614 16 41Z"
          className={classNames(fillClass, groupHover, standardClass)}
        />

        {/* alternate */}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 8C16.268 8 10 14.268 10 22C10 23.1046 9.10457 24 8 24C6.89543 24 6 23.1046 6 22C6 12.0589 14.0589 4 24 4C33.9411 4 42 12.0589 42 22C42 23.1046 41.1046 24 40 24C38.8954 24 38 23.1046 38 22C38 14.268 31.732 8 24 8Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0239 20.4331C12.0436 19.9281 14 21.4557 14 23.5375V34.4636C14 36.5455 12.0436 38.073 10.0239 37.5681L6.54479 36.6983C3.87378 36.0306 2 33.6307 2 30.8775V27.1237C2 24.3705 3.87378 21.9706 6.54478 21.3028L10.0239 20.4331ZM10 24.5621L7.51493 25.1834C6.62459 25.406 6 26.206 6 27.1237V30.8775C6 31.7952 6.62459 32.5952 7.51493 32.8178L10 33.439V24.5621Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38 24.5621V33.439L40.4851 32.8178C41.3754 32.5952 42 31.7952 42 30.8775V27.1237C42 26.206 41.3754 25.406 40.4851 25.1834L38 24.5621ZM34 23.5375C34 21.4557 35.9564 19.9281 37.9761 20.4331L41.4552 21.3028C44.1262 21.9706 46 24.3705 46 27.1237V30.8775C46 33.6307 44.1262 36.0306 41.4552 36.6983L37.9761 37.5681C35.9564 38.073 34 36.5455 34 34.4636V23.5375Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38 37V36H42V37C42 40.3137 39.3137 43 36 43H29V39H36C37.1046 39 38 38.1046 38 37Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 40C20.4477 40 20 40.4477 20 41C20 41.5523 20.4477 42 21 42H27C27.5523 42 28 41.5523 28 41C28 40.4477 27.5523 40 27 40H21ZM16 41C16 38.2386 18.2386 36 21 36H27C29.7614 36 32 38.2386 32 41C32 43.7614 29.7614 46 27 46H21C18.2386 46 16 43.7614 16 41Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 8C16.268 8 10 14.268 10 22C10 23.1046 9.10457 24 8 24C6.89543 24 6 23.1046 6 22C6 12.0589 14.0589 4 24 4C33.9411 4 42 12.0589 42 22C42 23.1046 41.1046 24 40 24C38.8954 24 38 23.1046 38 22C38 14.268 31.732 8 24 8Z"
          className={classNames(fillClass, strokeClass, groupHover, groupStrokeClass, alternateClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0239 20.4331C12.0436 19.9281 14 21.4557 14 23.5375V34.4636C14 36.5455 12.0436 38.073 10.0239 37.5681L6.54479 36.6983C3.87378 36.0306 2 33.6307 2 30.8775V27.1237C2 24.3705 3.87378 21.9706 6.54478 21.3028L10.0239 20.4331ZM10 24.5621L7.51493 25.1834C6.62459 25.406 6 26.206 6 27.1237V30.8775C6 31.7952 6.62459 32.5952 7.51493 32.8178L10 33.439V24.5621Z"
          className={classNames(strokeClass, groupStrokeClass, alternateClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38 24.5621V33.439L40.4851 32.8178C41.3754 32.5952 42 31.7952 42 30.8775V27.1237C42 26.206 41.3754 25.406 40.4851 25.1834L38 24.5621ZM34 23.5375C34 21.4557 35.9564 19.9281 37.9761 20.4331L41.4552 21.3028C44.1262 21.9706 46 24.3705 46 27.1237V30.8775C46 33.6307 44.1262 36.0306 41.4552 36.6983L37.9761 37.5681C35.9564 38.073 34 36.5455 34 34.4636V23.5375Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38 37V36H42V37C42 40.3137 39.3137 43 36 43H29V39H36C37.1046 39 38 38.1046 38 37Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 40C20.4477 40 20 40.4477 20 41C20 41.5523 20.4477 42 21 42H27C27.5523 42 28 41.5523 28 41C28 40.4477 27.5523 40 27 40H21ZM16 41C16 38.2386 18.2386 36 21 36H27C29.7614 36 32 38.2386 32 41C32 43.7614 29.7614 46 27 46H21C18.2386 46 16 43.7614 16 41Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          d="M6.5 25.5L10 24.5V33.5H9.5L6 31.5L6.5 25.5Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          d="M38 33V24.5L42 26V31L41.5 32.5L38.5 33.5L38 33Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          d="M6.5 25.5L10 24.5V33.5H9.5L6 31.5L6.5 25.5Z"
          className={classNames(fillClass, strokeClass, groupHover, alternateClass)}
        />
        <path
          d="M38 33V24.5L42 26V31L41.5 32.5L38.5 33.5L38 33Z"
          className={classNames(fillClass, strokeClass, groupHover, alternateClass)}
        />
        <path
          d="M20 41.5L20.5 40H28V42H20.5L20 41.5Z"
          className={classNames(fillClass, strokeClass, groupHover, alternateClass)}
        />
      </svg>
    </>
  );
};

export default MenuNewHelp;
