import { useT } from '@transifex/react';
import classNames from 'classnames';
import Image from 'next/image';
import React from 'react';

type LogoType = {
  fullWidth?: boolean;
  onClick?: () => void;
};

const Logo = ({ fullWidth = false, onClick }: LogoType) => {
  const t = useT();

  return (
    <button
      aria-label={onClick ? t('Go to member homepage') : t('Duos Logo')}
      onClick={onClick}
      className={classNames(
        'h-[36px] group',
        onClick ? 'cursor-pointer' : 'cursor-default',
        fullWidth ? 'w-full' : 'w-fit'
      )}
      tabIndex={onClick ? 0 : -1}
    >
      <Image priority alt={t('Duos Inc. Purple Logo')} src={'/images/PrimaryBrand.png'} width={165} height={36} />
    </button>
  );
};

export default Logo;
