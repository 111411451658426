import { useT } from '@transifex/react';
import classNames from 'classnames';

import { NewTextType } from '../../../../theme/typography';
import { PrivacyLevel } from '../../../../types/Privacy';
import { AlertIcon } from '../../../icons';
import { Text } from '../../new';
import AnimatedCheckIcon from './AnimatedCheckIcon';

type CheckboxProps = {
  checked?: boolean;
  label?: string | JSX.Element;
  labelType?: NewTextType;
  labelStyle?: string;
  onClick: () => void;
  privacyLevel?: PrivacyLevel;
  error?: string;
};

export const Checkbox = ({
  checked,
  label,
  labelType = 'body-1-emphasis',
  labelStyle,
  onClick,
  privacyLevel = 'pii',
  error,
}: CheckboxProps) => {
  const t = useT();
  return (
    <>
      <button
        className={classNames([
          'group',
          'py-2',
          'flex',
          'flex-row',
          'items-start',
          'gap-2',
          'cursor-pointer',
          'transition-all',
          'rounded-s-sm',
          'hover:bg-lightGrayTwo',
          'active:bg-lightGrayOne',
          'min-h-10',
          'focus:outline-offset-[-0.5px]',
          'focus:outline-2',
          'focus:outline-dashed',
          'focus:outline-linkBlue',
          'focus:outline-none',
        ])}
        onClick={onClick}
        aria-label={t('{label} radio button', { label })}
        type="button"
      >
        <AnimatedCheckIcon selected={checked} />
        {label && (
          <Text
            privacyLevel={privacyLevel}
            variant={labelType}
            color="darkGrayOne"
            className={labelStyle}
            alignment="left"
          >
            {label}
          </Text>
        )}
      </button>
      {error && (
        <div className="flex flex-row items-start gap-2">
          <AlertIcon colorOverride="errorRed" />
          <Text privacyLevel={privacyLevel} color="darkGrayOne" variant="body-1" alignment="left">
            <span dangerouslySetInnerHTML={{ __html: error }} />
          </Text>
        </div>
      )}
    </>
  );
};

export default Checkbox;
