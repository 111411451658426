import { colors } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const DownCircle = ({ size = 26, colorOverride = 'magenta' }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12" stroke={colors[colorOverride]} strokeWidth="2" />
    <path
      d="M18.0786 11.171C18.0244 11.1167 17.9601 11.0736 17.8892 11.0442C17.8183 11.0148 17.7424 10.9997 17.6656 10.9997C17.5889 10.9997 17.5129 11.0148 17.4421 11.0442C17.3712 11.0736 17.3068 11.1167 17.2526 11.171L12.9991 15.4258L8.74549 11.171C8.63596 11.0615 8.4874 11 8.3325 11C8.1776 11 8.02904 11.0615 7.91951 11.171C7.80998 11.2806 7.74844 11.4291 7.74844 11.584C7.74844 11.7389 7.80998 11.8875 7.91951 11.997L12.5861 16.6636C12.6403 16.7179 12.7046 16.761 12.7755 16.7904C12.8464 16.8198 12.9223 16.835 12.9991 16.835C13.0758 16.835 13.1518 16.8198 13.2226 16.7904C13.2935 16.761 13.3579 16.7179 13.4121 16.6636L18.0786 11.997C18.133 11.9428 18.176 11.8785 18.2055 11.8076C18.2349 11.7367 18.25 11.6608 18.25 11.584C18.25 11.5073 18.2349 11.4313 18.2055 11.3605C18.176 11.2896 18.133 11.2252 18.0786 11.171Z"
      fill={colors[colorOverride]}
      stroke={colors[colorOverride]}
    />
  </svg>
);

export default DownCircle;
