import React from 'react';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const TransportationIcon = ({ size = 20, colorOverride }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0959 2.7085C13.8054 2.7085 15.2985 3.86457 15.7265 5.51956L16.4676 8.38538C17.1919 8.51786 17.7836 8.98628 17.9814 9.70702C18.2749 10.7769 18.4236 12.2109 18.3145 13.3261C18.2251 14.2406 17.4398 14.8 16.5253 14.8899C16.4132 14.9009 16.2931 14.912 16.1648 14.923C16.1638 15.3489 16.1558 15.7149 16.1454 16.0144C16.1261 16.5741 15.7122 17.0333 15.1533 17.0687C14.9987 17.0785 14.8336 17.0849 14.6649 17.0849C14.4962 17.0849 14.3311 17.0785 14.1766 17.0687C13.6176 17.0333 13.2038 16.5741 13.1844 16.0144C13.1754 15.7531 13.1681 15.4411 13.1657 15.0829C12.2722 15.1092 11.2241 15.1255 9.99863 15.1255C8.77433 15.1255 7.72709 15.1092 6.83415 15.083C6.83178 15.4411 6.8245 15.7531 6.81547 16.0144C6.79611 16.5741 6.38226 17.0333 5.82332 17.0687C5.66876 17.0785 5.50367 17.0849 5.33496 17.0849C5.16626 17.0849 5.00117 17.0785 4.8466 17.0687C4.28766 17.0333 3.87381 16.5741 3.85445 16.0144C3.8441 15.715 3.83605 15.349 3.83506 14.9232C3.70575 14.9121 3.58485 14.901 3.47195 14.8899C2.55751 14.8 1.77212 14.2406 1.68272 13.3261C1.57369 12.2109 1.72233 10.7769 2.01588 9.70702C2.21379 8.9857 2.80634 8.51711 3.53139 8.38506L4.27247 5.51956C4.70048 3.86457 6.19358 2.7085 7.90302 2.7085H12.0959ZM7.90302 4.5835H12.0959C12.9506 4.5835 13.6972 5.16153 13.9112 5.98903L14.4529 8.0835H5.54607L6.08774 5.98903C6.30175 5.16153 7.0483 4.5835 7.90302 4.5835ZM6.24963 11.4585C6.24963 12.1489 5.68999 12.7085 4.99963 12.7085C4.30928 12.7085 3.74963 12.1489 3.74963 11.4585C3.74963 10.7681 4.30928 10.2085 4.99963 10.2085C5.68999 10.2085 6.24963 10.7681 6.24963 11.4585ZM16.2496 11.4585C16.2496 12.1489 15.69 12.7085 14.9996 12.7085C14.3093 12.7085 13.7496 12.1489 13.7496 11.4585C13.7496 10.7681 14.3093 10.2085 14.9996 10.2085C15.69 10.2085 16.2496 10.7681 16.2496 11.4585Z"
        className={fillClass}
      />
    </svg>
  );
};

export default TransportationIcon;
