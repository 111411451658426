import { ColorType } from '../types/ui';

export const colors = {
  purple: '#4F196F',
  darkPurple: '#41135C',
  lightPurple: '#612585',
  purpleGray: '#F0F1F6',
  orange: '#B64A25',
  mediumOrange: '#EEBCAA',
  lightOrange: '#FCEEE7',
  magenta: '#AC3EA4',
  lightMagenta: '#F9F0F9',
  green: '#386F19',
  mediumGreen: '#BBDCA7',
  lightGreen: '#E6F8DE',
  successGreen: '#0C8044',
  teal: '#19646F',
  mediumTeal: '#ACE4EC',
  lightTeal: '#DEF5F7',
  darkBlue: '#172E7D',
  darkBlueTint: '#1D1060',
  darkBlueBg: '#EEF2FF',
  yellow: '#E1DD8B',
  yellowTint: '#FFFEEE',
  mellowYellow: '#E1DD8B',
  ratingYellow: '#B8772E',
  starRating: '#FDCC0D',
  redTint: '#FCE8EB',
  darkRed: '#8B0000',
  darkRedTwo: '#9E2C2C',
  errorRed: '#A80523',
  errorRedTwo: '#E00000',
  errorBg: '#FFF6F6',
  lightRed: '#FFF6F6',
  darkBoneOne: '#DCDCD2',
  darkBoneTwo: '#EDE9E1',
  darkGrayOne: '#2B2A2E',
  darkGrayTwo: '#4D4A54',
  darkGrayThree: '#7C7888',
  mediumGrayOne: '#B4B4B9',
  lightGray: '#F3F3F3',
  lightGrayOne: '#D5D6D8',
  lightGrayTwo: '#EFEEF0',
  warmWhite: '#FCFAF9',
  lightGrayFour: '#F8F8F8',
  blueGray: '#C0C3C5',
  background: '#E5E5E5',
  white: '#FFFFFF',
  linkBlue: '#015F9E',
  linkHoverBlue: '#01406A',
  tabFocusBlue: '#5084AA',
  grayBlueThree: '#C0C3C5',
  googleWhite: '#EEEEEE',
  googleGray: 'rgba(0,0,0,0.54)',
  neutralGrayThree: '#7C7888',
  newLightGreen: '#EEF6F2',
  newBlueTint: '#E8F6FF',
  lightPurpleBg: '#EBE3EF',
  lightLinkBlue: '#E8F6FF',
  black25: 'rgba(0,0,0,0.25)',
  black50: 'rgba(0,0,0,0.50)',
  whiteOverlay25: 'rgba(255,255,255,0.25)',
  successGreenBg: '#CDF1D0',
  errorRedBg: '#FBD5DC',
  humanaGreen: '#78BE20',
  uhcBlue: '#002677',
  warningYellow: '#917D2F',
  warningYellowBg: '#F8F6D3',
  infoBlueBg: '#D3E2FD',
  magentaHover: '#C74CBE',
  darkMagenta: '#8E3788',
  transparent: 'transparent',
};

export const toTextColor = (color?: ColorType): string => {
  switch (color) {
    case 'purple':
      return 'text-purple';
    case 'darkPurple':
      return 'text-darkPurple';
    case 'lightPurple':
      return 'text-lightPurple';
    case 'purpleGray':
      return 'text-purpleGray';
    case 'orange':
      return 'text-orange';
    case 'mediumOrange':
      return 'text-mediumOrange';
    case 'lightOrange':
      return 'text-lightOrange';
    case 'magenta':
      return 'text-magenta';
    case 'lightMagenta':
      return 'text-lightMagenta';
    case 'mediumGreen':
      return 'text-mediumGreen';
    case 'lightGreen':
      return 'text-lightGreen';
    case 'green':
      return 'text-green';
    case 'successGreen':
      return 'text-successGreen';
    case 'teal':
      return 'text-teal';
    case 'mediumTeal':
      return 'text-mediumTeal';
    case 'lightTeal':
      return 'text-lightTeal';
    case 'darkBlue':
      return 'text-darkBlue';
    case 'darkBlueTint':
      return 'text-darkBlueTint';
    case 'darkBlueBg':
      return 'text-darkBlueBg';
    case 'mediumTeal':
      return 'text-mediumTeal';
    case 'yellow':
      return 'text-yellow';
    case 'yellowTint':
      return 'text-yellowTint';
    case 'mellowYellow':
      return 'text-mellowYellow';
    case 'ratingYellow':
      return 'text-ratingYellow';
    case 'starRating':
      return 'text-starRating';
    case 'redTint':
      return 'text-redTint';
    case 'darkRed':
      return 'text-darkRed';
    case 'darkRedTwo':
      return 'text-darkRedTwo';
    case 'errorRed':
      return 'text-errorRed';
    case 'errorRedTwo':
      return 'text-errorRedTwo';
    case 'errorBg':
      return 'text-errorBg';
    case 'lightRed':
      return 'text-lightRed';
    case 'darkBoneOne':
      return 'text-darkBoneOne';
    case 'darkBoneTwo':
      return 'text-darkBoneTwo';
    case 'darkGrayOne':
      return 'text-darkGrayOne';
    case 'darkGrayTwo':
      return 'text-darkGrayTwo';
    case 'darkGrayThree':
      return 'text-darkGrayThree';
    case 'mediumGrayOne':
      return 'text-mediumGrayOne';
    case 'lightGray':
      return 'text-lightGray';
    case 'lightGrayOne':
      return 'text-lightGrayOne';
    case 'lightGrayTwo':
      return 'text-lightGrayTwo';
    case 'warmWhite':
      return 'text-warmWhite';
    case 'lightGrayFour':
      return 'text-lightGrayFour';
    case 'blueGray':
      return 'text-blueGray';
    case 'background':
      return 'text-background';
    case 'white':
      return 'text-white';
    case 'linkBlue':
      return 'text-linkBlue';
    case 'linkHoverBlue':
      return 'text-linkHoverBlue';
    case 'tabFocusBlue':
      return 'text-tabFocusBlue';
    case 'grayBlueThree':
      return 'text-grayBlueThree';
    case 'googleWhite':
      return 'text-googleWhite';
    case 'googleGray':
      return 'text-googleGray';
    case 'neutralGrayThree':
      return 'text-neutralGrayThree';
    case 'newLightGreen':
      return 'text-newLightGreen';
    case 'newBlueTint':
      return 'text-newBlueTint';
    case 'teal':
      return 'text-teal';
    case 'lightTeal':
      return 'text-lightTeal';
    case 'lightPurpleBg':
      return 'text-lightPurpleBg';
    case 'lightLinkBlue':
      return 'text-lightLinkBlue';
    case 'black25':
      return 'text-black25';
    case 'black50':
      return 'text-black50';
    case 'whiteOverlay25':
      return 'text-whiteOverlay25';
    case 'lightMagenta':
      return 'text-lightMagenta';
    case 'lightGreen':
      return 'text-lightGreen';
    case 'successGreenBg':
      return 'text-successGreenBg';
    case 'errorRedBg':
      return 'text-errorRedBg';
    case 'humanaGreen':
      return 'text-humanaGreen';
    case 'uhcBlue':
      return 'text-uhcBlue';
    case 'warningYellow':
      return 'text-warningYellow';
    case 'warningYellowBg':
      return 'text-warningYellowBg';
    case 'infoBlueBg':
      return 'text-infoBlueBg';
    case 'magentaHover':
      return 'text-magentaHover';
    case 'darkMagenta':
      return 'text-darkMagenta';
    case 'transparent':
      return 'text-transparent';
    default:
      return '';
  }
};

/**
 *
 * @param color
 * @param isGroupHover Indicates if this func is used for generating group-hover:stroke-* classname
 * @returns TailwindCSS classname
 */
export const toFillColor = (color?: ColorType): string => {
  switch (color) {
    case 'purple':
      return 'fill-purple';
    case 'darkPurple':
      return 'fill-darkPurple';
    case 'lightPurple':
      return 'fill-lightPurple';
    case 'purpleGray':
      return 'fill-purpleGray';
    case 'orange':
      return 'fill-orange';
    case 'mediumOrange':
      return 'fill-mediumOrange';
    case 'lightOrange':
      return 'fill-lightOrange';
    case 'magenta':
      return 'fill-magenta';
    case 'lightMagenta':
      return 'fill-lightMagenta';
    case 'mediumGreen':
      return 'fill-mediumGreen';
    case 'lightGreen':
      return 'fill-lightGreen';
    case 'green':
      return 'fill-green';
    case 'successGreen':
      return 'fill-successGreen';
    case 'teal':
      return 'fill-teal';
    case 'mediumTeal':
      return 'fill-mediumTeal';
    case 'lightTeal':
      return 'fill-lightTeal';
    case 'darkBlue':
      return 'fill-darkBlue';
    case 'darkBlueTint':
      return 'fill-darkBlueTint';
    case 'darkBlueBg':
      return 'fill-darkBlueBg';
    case 'mediumTeal':
      return 'fill-mediumTeal';
    case 'yellow':
      return 'fill-yellow';
    case 'yellowTint':
      return 'fill-yellowTint';
    case 'mellowYellow':
      return 'fill-mellowYellow';
    case 'ratingYellow':
      return 'fill-ratingYellow';
    case 'starRating':
      return 'fill-starRating';
    case 'redTint':
      return 'fill-redTint';
    case 'darkRed':
      return 'fill-darkRed';
    case 'darkRedTwo':
      return 'fill-darkRedTwo';
    case 'errorRed':
      return 'fill-errorRed';
    case 'errorRedTwo':
      return 'fill-errorRedTwo';
    case 'errorBg':
      return 'fill-errorBg';
    case 'lightRed':
      return 'fill-lightRed';
    case 'darkBoneOne':
      return 'fill-darkBoneOne';
    case 'darkBoneTwo':
      return 'fill-darkBoneTwo';
    case 'darkGrayOne':
      return 'fill-darkGrayOne';
    case 'darkGrayTwo':
      return 'fill-darkGrayTwo';
    case 'darkGrayThree':
      return 'fill-darkGrayThree';
    case 'mediumGrayOne':
      return 'fill-mediumGrayOne';
    case 'lightGray':
      return 'fill-lightGray';
    case 'lightGrayOne':
      return 'fill-lightGrayOne';
    case 'lightGrayTwo':
      return 'fill-lightGrayTwo';
    case 'warmWhite':
      return 'fill-warmWhite';
    case 'lightGrayFour':
      return 'fill-lightGrayFour';
    case 'blueGray':
      return 'fill-blueGray';
    case 'background':
      return 'fill-background';
    case 'white':
      return 'fill-white';
    case 'linkBlue':
      return 'fill-linkBlue';
    case 'linkHoverBlue':
      return 'fill-linkHoverBlue';
    case 'tabFocusBlue':
      return 'fill-tabFocusBlue';
    case 'grayBlueThree':
      return 'fill-grayBlueThree';
    case 'googleWhite':
      return 'fill-googleWhite';
    case 'googleGray':
      return 'fill-googleGray';
    case 'neutralGrayThree':
      return 'fill-neutralGrayThree';
    case 'newLightGreen':
      return 'fill-newLightGreen';
    case 'newBlueTint':
      return 'fill-newBlueTint';
    case 'teal':
      return 'fill-teal';
    case 'lightTeal':
      return 'fill-lightTeal';
    case 'lightPurpleBg':
      return 'fill-lightPurpleBg';
    case 'lightLinkBlue':
      return 'fill-lightLinkBlue';
    case 'black25':
      return 'fill-black25';
    case 'black50':
      return 'fill-black50';
    case 'whiteOverlay25':
      return 'fill-whiteOverlay25';
    case 'lightMagenta':
      return 'fill-lightMagenta';
    case 'lightGreen':
      return 'fill-lightGreen';
    case 'successGreenBg':
      return 'fill-successGreenBg';
    case 'errorRedBg':
      return 'fill-errorRedBg';
    case 'humanaGreen':
      return 'fill-humanaGreen';
    case 'uhcBlue':
      return 'fill-uhcBlue';
    case 'warningYellow':
      return 'fill-warningYellow';
    case 'warningYellowBg':
      return 'fill-warningYellowBg';
    case 'infoBlueBg':
      return 'fill-infoBlueBg';
    case 'magentaHover':
      return 'fill-magentaHover';
    case 'darkMagenta':
      return 'fill-darkMagenta';
    case 'transparent':
      return 'fill-transparent';
    default:
      return '';
  }
};

/**
 *
 * @param color
 * @returns TailwindCSS classname
 */
export const toHoverText = (color?: ColorType): string => {
  switch (color) {
    case 'purple':
      return 'hover:text-purple';
    case 'darkPurple':
      return 'hover:text-darkPurple';
    case 'lightPurple':
      return 'hover:text-lightPurple';
    case 'purpleGray':
      return 'hover:text-purpleGray';
    case 'orange':
      return 'hover:text-orange';
    case 'mediumOrange':
      return 'hover:text-mediumOrange';
    case 'lightOrange':
      return 'hover:text-lightOrange';
    case 'magenta':
      return 'hover:text-magenta';
    case 'lightMagenta':
      return 'hover:text-lightMagenta';
    case 'mediumGreen':
      return 'hover:text-mediumGreen';
    case 'lightGreen':
      return 'hover:text-lightGreen';
    case 'green':
      return 'hover:text-green';
    case 'successGreen':
      return 'hover:text-successGreen';
    case 'teal':
      return 'hover:text-teal';
    case 'mediumTeal':
      return 'hover:text-mediumTeal';
    case 'lightTeal':
      return 'hover:text-lightTeal';
    case 'darkBlue':
      return 'hover:text-darkBlue';
    case 'darkBlueTint':
      return 'hover:text-darkBlueTint';
    case 'darkBlueBg':
      return 'hover:text-darkBlueBg';
    case 'mediumTeal':
      return 'hover:text-mediumTeal';
    case 'yellow':
      return 'hover:text-yellow';
    case 'yellowTint':
      return 'hover:text-yellowTint';
    case 'mellowYellow':
      return 'hover:text-mellowYellow';
    case 'ratingYellow':
      return 'hover:text-ratingYellow';
    case 'starRating':
      return 'hover:text-starRating';
    case 'redTint':
      return 'hover:text-redTint';
    case 'darkRed':
      return 'hover:text-darkRed';
    case 'darkRedTwo':
      return 'hover:text-darkRedTwo';
    case 'errorRed':
      return 'hover:text-errorRed';
    case 'errorRedTwo':
      return 'hover:text-errorRedTwo';
    case 'errorBg':
      return 'hover:text-errorBg';
    case 'lightRed':
      return 'hover:text-lightRed';
    case 'darkBoneOne':
      return 'hover:text-darkBoneOne';
    case 'darkBoneTwo':
      return 'hover:text-darkBoneTwo';
    case 'darkGrayOne':
      return 'hover:text-darkGrayOne';
    case 'darkGrayTwo':
      return 'hover:text-darkGrayTwo';
    case 'darkGrayThree':
      return 'hover:text-darkGrayThree';
    case 'mediumGrayOne':
      return 'hover:text-mediumGrayOne';
    case 'lightGray':
      return 'hover:text-lightGray';
    case 'lightGrayOne':
      return 'hover:text-lightGrayOne';
    case 'lightGrayTwo':
      return 'hover:text-lightGrayTwo';
    case 'warmWhite':
      return 'hover:text-warmWhite';
    case 'lightGrayFour':
      return 'hover:text-lightGrayFour';
    case 'blueGray':
      return 'hover:text-blueGray';
    case 'background':
      return 'hover:text-background';
    case 'white':
      return 'hover:text-white';
    case 'linkBlue':
      return 'hover:text-linkBlue';
    case 'linkHoverBlue':
      return 'hover:text-linkHoverBlue';
    case 'tabFocusBlue':
      return 'hover:text-tabFocusBlue';
    case 'grayBlueThree':
      return 'hover:text-grayBlueThree';
    case 'googleWhite':
      return 'hover:text-googleWhite';
    case 'googleGray':
      return 'hover:text-googleGray';
    case 'neutralGrayThree':
      return 'hover:text-neutralGrayThree';
    case 'newLightGreen':
      return 'hover:text-newLightGreen';
    case 'newBlueTint':
      return 'hover:text-newBlueTint';
    case 'teal':
      return 'hover:text-teal';
    case 'lightTeal':
      return 'hover:text-lightTeal';
    case 'lightPurpleBg':
      return 'hover:text-lightPurpleBg';
    case 'lightLinkBlue':
      return 'hover:text-lightLinkBlue';
    case 'black25':
      return 'hover:text-black25';
    case 'black50':
      return 'hover:text-black50';
    case 'whiteOverlay25':
      return 'hover:text-whiteOverlay25';
    case 'lightMagenta':
      return 'hover:text-lightMagenta';
    case 'lightGreen':
      return 'hover:text-lightGreen';
    case 'successGreenBg':
      return 'hover:text-successGreenBg';
    case 'errorRedBg':
      return 'hover:text-errorRedBg';
    case 'humanaGreen':
      return 'hover:text-humanaGreen';
    case 'uhcBlue':
      return 'hover:text-uhcBlue';
    case 'warningYellow':
      return 'hover:text-warningYellow';
    case 'warningYellowBg':
      return 'hover:text-warningYellowBg';
    case 'infoBlueBg':
      return 'hover:text-infoBlueBg';
    case 'magentaHover':
      return 'hover:text-magentaHover';
    case 'darkMagenta':
      return 'hover:text-darkMagenta';
    case 'transparent':
      return 'hover:text-transparent';
    default:
      return '';
  }
};

/**
 *
 * @param color
 * @returns TailwindCSS classname
 */
export const toGroupHoverText = (color?: ColorType): string => {
  switch (color) {
    case 'purple':
      return 'group-hover:text-purple';
    case 'darkPurple':
      return 'group-hover:text-darkPurple';
    case 'lightPurple':
      return 'group-hover:text-lightPurple';
    case 'purpleGray':
      return 'group-hover:text-purpleGray';
    case 'orange':
      return 'group-hover:text-orange';
    case 'mediumOrange':
      return 'group-hover:text-mediumOrange';
    case 'lightOrange':
      return 'group-hover:text-lightOrange';
    case 'magenta':
      return 'group-hover:text-magenta';
    case 'lightMagenta':
      return 'group-hover:text-lightMagenta';
    case 'mediumGreen':
      return 'group-hover:text-mediumGreen';
    case 'lightGreen':
      return 'group-hover:text-lightGreen';
    case 'green':
      return 'group-hover:text-green';
    case 'successGreen':
      return 'group-hover:text-successGreen';
    case 'teal':
      return 'group-hover:text-teal';
    case 'mediumTeal':
      return 'group-hover:text-mediumTeal';
    case 'lightTeal':
      return 'group-hover:text-lightTeal';
    case 'darkBlue':
      return 'group-hover:text-darkBlue';
    case 'darkBlueTint':
      return 'group-hover:text-darkBlueTint';
    case 'darkBlueBg':
      return 'group-hover:text-darkBlueBg';
    case 'mediumTeal':
      return 'group-hover:text-mediumTeal';
    case 'yellow':
      return 'group-hover:text-yellow';
    case 'yellowTint':
      return 'group-hover:text-yellowTint';
    case 'mellowYellow':
      return 'group-hover:text-mellowYellow';
    case 'ratingYellow':
      return 'group-hover:text-ratingYellow';
    case 'starRating':
      return 'group-hover:text-starRating';
    case 'redTint':
      return 'group-hover:text-redTint';
    case 'darkRed':
      return 'group-hover:text-darkRed';
    case 'darkRedTwo':
      return 'group-hover:text-darkRedTwo';
    case 'errorRed':
      return 'group-hover:text-errorRed';
    case 'errorRedTwo':
      return 'group-hover:text-errorRedTwo';
    case 'errorBg':
      return 'group-hover:text-errorBg';
    case 'lightRed':
      return 'group-hover:text-lightRed';
    case 'darkBoneOne':
      return 'group-hover:text-darkBoneOne';
    case 'darkBoneTwo':
      return 'group-hover:text-darkBoneTwo';
    case 'darkGrayOne':
      return 'group-hover:text-darkGrayOne';
    case 'darkGrayTwo':
      return 'group-hover:text-darkGrayTwo';
    case 'darkGrayThree':
      return 'group-hover:text-darkGrayThree';
    case 'mediumGrayOne':
      return 'group-hover:text-mediumGrayOne';
    case 'lightGray':
      return 'group-hover:text-lightGray';
    case 'lightGrayOne':
      return 'group-hover:text-lightGrayOne';
    case 'lightGrayTwo':
      return 'group-hover:text-lightGrayTwo';
    case 'warmWhite':
      return 'group-hover:text-warmWhite';
    case 'lightGrayFour':
      return 'group-hover:text-lightGrayFour';
    case 'blueGray':
      return 'group-hover:text-blueGray';
    case 'background':
      return 'group-hover:text-background';
    case 'white':
      return 'group-hover:text-white';
    case 'linkBlue':
      return 'group-hover:text-linkBlue';
    case 'linkHoverBlue':
      return 'group-hover:text-linkHoverBlue';
    case 'tabFocusBlue':
      return 'group-hover:text-tabFocusBlue';
    case 'grayBlueThree':
      return 'group-hover:text-grayBlueThree';
    case 'googleWhite':
      return 'group-hover:text-googleWhite';
    case 'googleGray':
      return 'group-hover:text-googleGray';
    case 'neutralGrayThree':
      return 'group-hover:text-neutralGrayThree';
    case 'newLightGreen':
      return 'group-hover:text-newLightGreen';
    case 'newBlueTint':
      return 'group-hover:text-newBlueTint';
    case 'teal':
      return 'group-hover:text-teal';
    case 'lightTeal':
      return 'group-hover:text-lightTeal';
    case 'lightPurpleBg':
      return 'group-hover:text-lightPurpleBg';
    case 'lightLinkBlue':
      return 'group-hover:text-lightLinkBlue';
    case 'black25':
      return 'group-hover:text-black25';
    case 'black50':
      return 'group-hover:text-black50';
    case 'whiteOverlay25':
      return 'group-hover:text-whiteOverlay25';
    case 'lightMagenta':
      return 'group-hover:text-lightMagenta';
    case 'lightGreen':
      return 'group-hover:text-lightGreen';
    case 'successGreenBg':
      return 'group-hover:text-successGreenBg';
    case 'errorRedBg':
      return 'group-hover:text-errorRedBg';
    case 'humanaGreen':
      return 'group-hover:text-humanaGreen';
    case 'uhcBlue':
      return 'group-hover:text-uhcBlue';
    case 'warningYellow':
      return 'group-hover:text-warningYellow';
    case 'warningYellowBg':
      return 'group-hover:text-warningYellowBg';
    case 'infoBlueBg':
      return 'group-hover:text-infoBlueBg';
    case 'magentaHover':
      return 'group-hover:text-magentaHover';
    case 'darkMagenta':
      return 'group-hover:text-darkMagenta';
    case 'transparent':
      return 'group-hover:text-transparent';
    default:
      return '';
  }
};

export const toHoverFillColor = (color?: ColorType): string => {
  switch (color) {
    case 'purple':
      return 'hover:fill-purple';
    case 'darkPurple':
      return 'hover:fill-darkPurple';
    case 'lightPurple':
      return 'hover:fill-lightPurple';
    case 'purpleGray':
      return 'hover:fill-purpleGray';
    case 'orange':
      return 'hover:fill-orange';
    case 'mediumOrange':
      return 'hover:fill-mediumOrange';
    case 'lightOrange':
      return 'hover:fill-lightOrange';
    case 'magenta':
      return 'hover:fill-magenta';
    case 'lightMagenta':
      return 'hover:fill-lightMagenta';
    case 'mediumGreen':
      return 'hover:fill-mediumGreen';
    case 'lightGreen':
      return 'hover:fill-lightGreen';
    case 'green':
      return 'hover:fill-green';
    case 'successGreen':
      return 'hover:fill-successGreen';
    case 'teal':
      return 'hover:fill-teal';
    case 'mediumTeal':
      return 'hover:fill-mediumTeal';
    case 'lightTeal':
      return 'hover:fill-lightTeal';
    case 'darkBlue':
      return 'hover:fill-darkBlue';
    case 'darkBlueTint':
      return 'hover:fill-darkBlueTint';
    case 'darkBlueBg':
      return 'hover:fill-darkBlueBg';
    case 'mediumTeal':
      return 'hover:fill-mediumTeal';
    case 'yellow':
      return 'hover:fill-yellow';
    case 'yellowTint':
      return 'hover:fill-yellowTint';
    case 'mellowYellow':
      return 'hover:fill-mellowYellow';
    case 'ratingYellow':
      return 'hover:fill-ratingYellow';
    case 'starRating':
      return 'hover:fill-starRating';
    case 'redTint':
      return 'hover:fill-redTint';
    case 'darkRed':
      return 'hover:fill-darkRed';
    case 'darkRedTwo':
      return 'hover:fill-darkRedTwo';
    case 'errorRed':
      return 'hover:fill-errorRed';
    case 'errorRedTwo':
      return 'hover:fill-errorRedTwo';
    case 'errorBg':
      return 'hover:fill-errorBg';
    case 'lightRed':
      return 'hover:fill-lightRed';
    case 'darkBoneOne':
      return 'hover:fill-darkBoneOne';
    case 'darkBoneTwo':
      return 'hover:fill-darkBoneTwo';
    case 'darkGrayOne':
      return 'hover:fill-darkGrayOne';
    case 'darkGrayTwo':
      return 'hover:fill-darkGrayTwo';
    case 'darkGrayThree':
      return 'hover:fill-darkGrayThree';
    case 'mediumGrayOne':
      return 'hover:fill-mediumGrayOne';
    case 'lightGray':
      return 'hover:fill-lightGray';
    case 'lightGrayOne':
      return 'hover:fill-lightGrayOne';
    case 'lightGrayTwo':
      return 'hover:fill-lightGrayTwo';
    case 'warmWhite':
      return 'hover:fill-warmWhite';
    case 'lightGrayFour':
      return 'hover:fill-lightGrayFour';
    case 'blueGray':
      return 'hover:fill-blueGray';
    case 'background':
      return 'hover:fill-background';
    case 'white':
      return 'hover:fill-white';
    case 'linkBlue':
      return 'hover:fill-linkBlue';
    case 'linkHoverBlue':
      return 'hover:fill-linkHoverBlue';
    case 'tabFocusBlue':
      return 'hover:fill-tabFocusBlue';
    case 'grayBlueThree':
      return 'hover:fill-grayBlueThree';
    case 'googleWhite':
      return 'hover:fill-googleWhite';
    case 'googleGray':
      return 'hover:fill-googleGray';
    case 'neutralGrayThree':
      return 'hover:fill-neutralGrayThree';
    case 'newLightGreen':
      return 'hover:fill-newLightGreen';
    case 'newBlueTint':
      return 'hover:fill-newBlueTint';
    case 'teal':
      return 'hover:fill-teal';
    case 'lightTeal':
      return 'hover:fill-lightTeal';
    case 'lightPurpleBg':
      return 'hover:fill-lightPurpleBg';
    case 'lightLinkBlue':
      return 'hover:fill-lightLinkBlue';
    case 'black25':
      return 'hover:fill-black25';
    case 'black50':
      return 'hover:fill-black50';
    case 'whiteOverlay25':
      return 'hover:fill-whiteOverlay25';
    case 'lightMagenta':
      return 'hover:fill-lightMagenta';
    case 'lightGreen':
      return 'hover:fill-lightGreen';
    case 'successGreenBg':
      return 'hover:fill-successGreenBg';
    case 'errorRedBg':
      return 'hover:fill-errorRedBg';
    case 'humanaGreen':
      return 'hover:fill-humanaGreen';
    case 'uhcBlue':
      return 'hover:fill-uhcBlue';
    case 'warningYellow':
      return 'hover:fill-warningYellow';
    case 'warningYellowBg':
      return 'hover:fill-warningYellowBg';
    case 'infoBlueBg':
      return 'hover:fill-infoBlueBg';
    case 'magentaHover':
      return 'hover:fill-magentaHover';
    case 'darkMagenta':
      return 'hover:fill-darkMagenta';
    case 'transparent':
      return 'hover:fill-transparent';
    default:
      return '';
  }
};

export const toGroupHoverFillColor = (color?: ColorType): string => {
  switch (color) {
    case 'purple':
      return 'group-hover:fill-purple';
    case 'darkPurple':
      return 'group-hover:fill-darkPurple';
    case 'lightPurple':
      return 'group-hover:fill-lightPurple';
    case 'purpleGray':
      return 'group-hover:fill-purpleGray';
    case 'orange':
      return 'group-hover:fill-orange';
    case 'mediumOrange':
      return 'group-hover:fill-mediumOrange';
    case 'lightOrange':
      return 'group-hover:fill-lightOrange';
    case 'magenta':
      return 'group-hover:fill-magenta';
    case 'lightMagenta':
      return 'group-hover:fill-lightMagenta';
    case 'mediumGreen':
      return 'group-hover:fill-mediumGreen';
    case 'lightGreen':
      return 'group-hover:fill-lightGreen';
    case 'green':
      return 'group-hover:fill-green';
    case 'successGreen':
      return 'group-hover:fill-successGreen';
    case 'teal':
      return 'group-hover:fill-teal';
    case 'mediumTeal':
      return 'group-hover:fill-mediumTeal';
    case 'lightTeal':
      return 'group-hover:fill-lightTeal';
    case 'darkBlue':
      return 'group-hover:fill-darkBlue';
    case 'darkBlueTint':
      return 'group-hover:fill-darkBlueTint';
    case 'darkBlueBg':
      return 'group-hover:fill-darkBlueBg';
    case 'mediumTeal':
      return 'group-hover:fill-mediumTeal';
    case 'yellow':
      return 'group-hover:fill-yellow';
    case 'yellowTint':
      return 'group-hover:fill-yellowTint';
    case 'mellowYellow':
      return 'group-hover:fill-mellowYellow';
    case 'ratingYellow':
      return 'group-hover:fill-ratingYellow';
    case 'starRating':
      return 'group-hover:fill-starRating';
    case 'redTint':
      return 'group-hover:fill-redTint';
    case 'darkRed':
      return 'group-hover:fill-darkRed';
    case 'darkRedTwo':
      return 'group-hover:fill-darkRedTwo';
    case 'errorRed':
      return 'group-hover:fill-errorRed';
    case 'errorRedTwo':
      return 'group-hover:fill-errorRedTwo';
    case 'errorBg':
      return 'group-hover:fill-errorBg';
    case 'lightRed':
      return 'group-hover:fill-lightRed';
    case 'darkBoneOne':
      return 'group-hover:fill-darkBoneOne';
    case 'darkBoneTwo':
      return 'group-hover:fill-darkBoneTwo';
    case 'darkGrayOne':
      return 'group-hover:fill-darkGrayOne';
    case 'darkGrayTwo':
      return 'group-hover:fill-darkGrayTwo';
    case 'darkGrayThree':
      return 'group-hover:fill-darkGrayThree';
    case 'mediumGrayOne':
      return 'group-hover:fill-mediumGrayOne';
    case 'lightGray':
      return 'group-hover:fill-lightGray';
    case 'lightGrayOne':
      return 'group-hover:fill-lightGrayOne';
    case 'lightGrayTwo':
      return 'group-hover:fill-lightGrayTwo';
    case 'warmWhite':
      return 'group-hover:fill-warmWhite';
    case 'lightGrayFour':
      return 'group-hover:fill-lightGrayFour';
    case 'blueGray':
      return 'group-hover:fill-blueGray';
    case 'background':
      return 'group-hover:fill-background';
    case 'white':
      return 'group-hover:fill-white';
    case 'linkBlue':
      return 'group-hover:fill-linkBlue';
    case 'linkHoverBlue':
      return 'group-hover:fill-linkHoverBlue';
    case 'tabFocusBlue':
      return 'group-hover:fill-tabFocusBlue';
    case 'grayBlueThree':
      return 'group-hover:fill-grayBlueThree';
    case 'googleWhite':
      return 'group-hover:fill-googleWhite';
    case 'googleGray':
      return 'group-hover:fill-googleGray';
    case 'neutralGrayThree':
      return 'group-hover:fill-neutralGrayThree';
    case 'newLightGreen':
      return 'group-hover:fill-newLightGreen';
    case 'newBlueTint':
      return 'group-hover:fill-newBlueTint';
    case 'teal':
      return 'group-hover:fill-teal';
    case 'lightTeal':
      return 'group-hover:fill-lightTeal';
    case 'lightPurpleBg':
      return 'group-hover:fill-lightPurpleBg';
    case 'lightLinkBlue':
      return 'group-hover:fill-lightLinkBlue';
    case 'black25':
      return 'group-hover:fill-black25';
    case 'black50':
      return 'group-hover:fill-black50';
    case 'whiteOverlay25':
      return 'group-hover:fill-whiteOverlay25';
    case 'lightMagenta':
      return 'group-hover:fill-lightMagenta';
    case 'lightGreen':
      return 'group-hover:fill-lightGreen';
    case 'successGreenBg':
      return 'group-hover:fill-successGreenBg';
    case 'errorRedBg':
      return 'group-hover:fill-errorRedBg';
    case 'humanaGreen':
      return 'group-hover:fill-humanaGreen';
    case 'uhcBlue':
      return 'group-hover:fill-uhcBlue';
    case 'warningYellow':
      return 'group-hover:fill-warningYellow';
    case 'warningYellowBg':
      return 'group-hover:fill-warningYellowBg';
    case 'infoBlueBg':
      return 'group-hover:fill-infoBlueBg';
    case 'magentaHover':
      return 'group-hover:fill-magentaHover';
    case 'darkMagenta':
      return 'group-hover:fill-darkMagenta';
    case 'transparent':
      return 'group-hover:fill-transparent';
    default:
      return '';
  }
};

export const toGroupHoverStrokeColor = (color?: ColorType): string => {
  switch (color) {
    case 'purple':
      return 'group-hover:stroke-purple';
    case 'darkPurple':
      return 'group-hover:stroke-darkPurple';
    case 'lightPurple':
      return 'group-hover:stroke-lightPurple';
    case 'purpleGray':
      return 'group-hover:stroke-purpleGray';
    case 'orange':
      return 'group-hover:stroke-orange';
    case 'mediumOrange':
      return 'group-hover:stroke-mediumOrange';
    case 'lightOrange':
      return 'group-hover:stroke-lightOrange';
    case 'magenta':
      return 'group-hover:stroke-magenta';
    case 'lightMagenta':
      return 'group-hover:stroke-lightMagenta';
    case 'mediumGreen':
      return 'group-hover:stroke-mediumGreen';
    case 'lightGreen':
      return 'group-hover:stroke-lightGreen';
    case 'green':
      return 'group-hover:stroke-green';
    case 'successGreen':
      return 'group-hover:stroke-successGreen';
    case 'teal':
      return 'group-hover:stroke-teal';
    case 'mediumTeal':
      return 'group-hover:stroke-mediumTeal';
    case 'lightTeal':
      return 'group-hover:stroke-lightTeal';
    case 'darkBlue':
      return 'group-hover:stroke-darkBlue';
    case 'darkBlueTint':
      return 'group-hover:stroke-darkBlueTint';
    case 'darkBlueBg':
      return 'group-hover:stroke-darkBlueBg';
    case 'mediumTeal':
      return 'group-hover:stroke-mediumTeal';
    case 'yellow':
      return 'group-hover:stroke-yellow';
    case 'yellowTint':
      return 'group-hover:stroke-yellowTint';
    case 'mellowYellow':
      return 'group-hover:stroke-mellowYellow';
    case 'ratingYellow':
      return 'group-hover:stroke-ratingYellow';
    case 'starRating':
      return 'group-hover:stroke-starRating';
    case 'redTint':
      return 'group-hover:stroke-redTint';
    case 'darkRed':
      return 'group-hover:stroke-darkRed';
    case 'darkRedTwo':
      return 'group-hover:stroke-darkRedTwo';
    case 'errorRed':
      return 'group-hover:stroke-errorRed';
    case 'errorRedTwo':
      return 'group-hover:stroke-errorRedTwo';
    case 'errorBg':
      return 'group-hover:stroke-errorBg';
    case 'lightRed':
      return 'group-hover:stroke-lightRed';
    case 'darkBoneOne':
      return 'group-hover:stroke-darkBoneOne';
    case 'darkBoneTwo':
      return 'group-hover:stroke-darkBoneTwo';
    case 'darkGrayOne':
      return 'group-hover:stroke-darkGrayOne';
    case 'darkGrayTwo':
      return 'group-hover:stroke-darkGrayTwo';
    case 'darkGrayThree':
      return 'group-hover:stroke-darkGrayThree';
    case 'mediumGrayOne':
      return 'group-hover:stroke-mediumGrayOne';
    case 'lightGray':
      return 'group-hover:stroke-lightGray';
    case 'lightGrayOne':
      return 'group-hover:stroke-lightGrayOne';
    case 'lightGrayTwo':
      return 'group-hover:stroke-lightGrayTwo';
    case 'warmWhite':
      return 'group-hover:stroke-warmWhite';
    case 'lightGrayFour':
      return 'group-hover:stroke-lightGrayFour';
    case 'blueGray':
      return 'group-hover:stroke-blueGray';
    case 'background':
      return 'group-hover:stroke-background';
    case 'white':
      return 'group-hover:stroke-white';
    case 'linkBlue':
      return 'group-hover:stroke-linkBlue';
    case 'linkHoverBlue':
      return 'group-hover:stroke-linkHoverBlue';
    case 'tabFocusBlue':
      return 'group-hover:stroke-tabFocusBlue';
    case 'grayBlueThree':
      return 'group-hover:stroke-grayBlueThree';
    case 'googleWhite':
      return 'group-hover:stroke-googleWhite';
    case 'googleGray':
      return 'group-hover:stroke-googleGray';
    case 'neutralGrayThree':
      return 'group-hover:stroke-neutralGrayThree';
    case 'newLightGreen':
      return 'group-hover:stroke-newLightGreen';
    case 'newBlueTint':
      return 'group-hover:stroke-newBlueTint';
    case 'teal':
      return 'group-hover:stroke-teal';
    case 'lightTeal':
      return 'group-hover:stroke-lightTeal';
    case 'lightPurpleBg':
      return 'group-hover:stroke-lightPurpleBg';
    case 'lightLinkBlue':
      return 'group-hover:stroke-lightLinkBlue';
    case 'black25':
      return 'group-hover:stroke-black25';
    case 'black50':
      return 'group-hover:stroke-black50';
    case 'whiteOverlay25':
      return 'group-hover:stroke-whiteOverlay25';
    case 'lightMagenta':
      return 'group-hover:stroke-lightMagenta';
    case 'lightGreen':
      return 'group-hover:stroke-lightGreen';
    case 'successGreenBg':
      return 'group-hover:stroke-successGreenBg';
    case 'errorRedBg':
      return 'group-hover:stroke-errorRedBg';
    case 'humanaGreen':
      return 'group-hover:stroke-humanaGreen';
    case 'uhcBlue':
      return 'group-hover:stroke-uhcBlue';
    case 'warningYellow':
      return 'group-hover:stroke-warningYellow';
    case 'warningYellowBg':
      return 'group-hover:stroke-warningYellowBg';
    case 'infoBlueBg':
      return 'group-hover:stroke-infoBlueBg';
    case 'magentaHover':
      return 'group-hover:stroke-magentaHover';
    case 'darkMagenta':
      return 'group-hover:stroke-darkMagenta';
    case 'transparent':
      return 'group-hover:stroke-transparent';
    default:
      return '';
  }
};

export const toStrokeColor = (color?: ColorType): string => {
  switch (color) {
    case 'purple':
      return 'stroke-purple';
    case 'darkPurple':
      return 'stroke-darkPurple';
    case 'lightPurple':
      return 'stroke-lightPurple';
    case 'purpleGray':
      return 'stroke-purpleGray';
    case 'orange':
      return 'stroke-orange';
    case 'mediumOrange':
      return 'stroke-mediumOrange';
    case 'lightOrange':
      return 'stroke-lightOrange';
    case 'magenta':
      return 'stroke-magenta';
    case 'lightMagenta':
      return 'stroke-lightMagenta';
    case 'mediumGreen':
      return 'stroke-mediumGreen';
    case 'lightGreen':
      return 'stroke-lightGreen';
    case 'green':
      return 'stroke-green';
    case 'successGreen':
      return 'stroke-successGreen';
    case 'teal':
      return 'stroke-teal';
    case 'mediumTeal':
      return 'stroke-mediumTeal';
    case 'lightTeal':
      return 'stroke-lightTeal';
    case 'darkBlue':
      return 'stroke-darkBlue';
    case 'darkBlueTint':
      return 'stroke-darkBlueTint';
    case 'darkBlueBg':
      return 'stroke-darkBlueBg';
    case 'mediumTeal':
      return 'stroke-mediumTeal';
    case 'yellow':
      return 'stroke-yellow';
    case 'yellowTint':
      return 'stroke-yellowTint';
    case 'mellowYellow':
      return 'stroke-mellowYellow';
    case 'ratingYellow':
      return 'stroke-ratingYellow';
    case 'starRating':
      return 'stroke-starRating';
    case 'redTint':
      return 'stroke-redTint';
    case 'darkRed':
      return 'stroke-darkRed';
    case 'darkRedTwo':
      return 'stroke-darkRedTwo';
    case 'errorRed':
      return 'stroke-errorRed';
    case 'errorRedTwo':
      return 'stroke-errorRedTwo';
    case 'errorBg':
      return 'stroke-errorBg';
    case 'lightRed':
      return 'stroke-lightRed';
    case 'darkBoneOne':
      return 'stroke-darkBoneOne';
    case 'darkBoneTwo':
      return 'stroke-darkBoneTwo';
    case 'darkGrayOne':
      return 'stroke-darkGrayOne';
    case 'darkGrayTwo':
      return 'stroke-darkGrayTwo';
    case 'darkGrayThree':
      return 'stroke-darkGrayThree';
    case 'mediumGrayOne':
      return 'stroke-mediumGrayOne';
    case 'lightGray':
      return 'stroke-lightGray';
    case 'lightGrayOne':
      return 'stroke-lightGrayOne';
    case 'lightGrayTwo':
      return 'stroke-lightGrayTwo';
    case 'warmWhite':
      return 'stroke-warmWhite';
    case 'lightGrayFour':
      return 'stroke-lightGrayFour';
    case 'blueGray':
      return 'stroke-blueGray';
    case 'background':
      return 'stroke-background';
    case 'white':
      return 'stroke-white';
    case 'linkBlue':
      return 'stroke-linkBlue';
    case 'linkHoverBlue':
      return 'stroke-linkHoverBlue';
    case 'tabFocusBlue':
      return 'stroke-tabFocusBlue';
    case 'grayBlueThree':
      return 'stroke-grayBlueThree';
    case 'googleWhite':
      return 'stroke-googleWhite';
    case 'googleGray':
      return 'stroke-googleGray';
    case 'neutralGrayThree':
      return 'stroke-neutralGrayThree';
    case 'newLightGreen':
      return 'stroke-newLightGreen';
    case 'newBlueTint':
      return 'stroke-newBlueTint';
    case 'teal':
      return 'stroke-teal';
    case 'lightTeal':
      return 'stroke-lightTeal';
    case 'lightPurpleBg':
      return 'stroke-lightPurpleBg';
    case 'lightLinkBlue':
      return 'stroke-lightLinkBlue';
    case 'black25':
      return 'stroke-black25';
    case 'black50':
      return 'stroke-black50';
    case 'whiteOverlay25':
      return 'stroke-whiteOverlay25';
    case 'lightMagenta':
      return 'stroke-lightMagenta';
    case 'lightGreen':
      return 'stroke-lightGreen';
    case 'successGreenBg':
      return 'stroke-successGreenBg';
    case 'errorRedBg':
      return 'stroke-errorRedBg';
    case 'humanaGreen':
      return 'stroke-humanaGreen';
    case 'uhcBlue':
      return 'stroke-uhcBlue';
    case 'warningYellow':
      return 'stroke-warningYellow';
    case 'warningYellowBg':
      return 'stroke-warningYellowBg';
    case 'infoBlueBg':
      return 'stroke-infoBlueBg';
    case 'magentaHover':
      return 'stroke-magentaHover';
    case 'darkMagenta':
      return 'stroke-darkMagenta';
    case 'transparent':
      return 'stroke-transparent';
    default:
      return '';
  }
};

export const toBackgroundColor = (color?: ColorType): string => {
  switch (color) {
    case 'purple':
      return 'bg-purple';
    case 'darkPurple':
      return 'bg-darkPurple';
    case 'lightPurple':
      return 'bg-lightPurple';
    case 'purpleGray':
      return 'bg-purpleGray';
    case 'orange':
      return 'bg-orange';
    case 'mediumOrange':
      return 'bg-mediumOrange';
    case 'lightOrange':
      return 'bg-lightOrange';
    case 'magenta':
      return 'bg-magenta';
    case 'lightMagenta':
      return 'bg-lightMagenta';
    case 'mediumGreen':
      return 'bg-mediumGreen';
    case 'lightGreen':
      return 'bg-lightGreen';
    case 'green':
      return 'bg-green';
    case 'successGreen':
      return 'bg-successGreen';
    case 'teal':
      return 'bg-teal';
    case 'mediumTeal':
      return 'bg-mediumTeal';
    case 'lightTeal':
      return 'bg-lightTeal';
    case 'darkBlue':
      return 'bg-darkBlue';
    case 'darkBlueTint':
      return 'bg-darkBlueTint';
    case 'darkBlueBg':
      return 'bg-darkBlueBg';
    case 'mediumTeal':
      return 'bg-mediumTeal';
    case 'yellow':
      return 'bg-yellow';
    case 'yellowTint':
      return 'bg-yellowTint';
    case 'mellowYellow':
      return 'bg-mellowYellow';
    case 'ratingYellow':
      return 'bg-ratingYellow';
    case 'starRating':
      return 'bg-starRating';
    case 'redTint':
      return 'bg-redTint';
    case 'darkRed':
      return 'bg-darkRed';
    case 'darkRedTwo':
      return 'bg-darkRedTwo';
    case 'errorRed':
      return 'bg-errorRed';
    case 'errorRedTwo':
      return 'bg-errorRedTwo';
    case 'errorBg':
      return 'bg-errorBg';
    case 'lightRed':
      return 'bg-lightRed';
    case 'darkBoneOne':
      return 'bg-darkBoneOne';
    case 'darkBoneTwo':
      return 'bg-darkBoneTwo';
    case 'darkGrayOne':
      return 'bg-darkGrayOne';
    case 'darkGrayTwo':
      return 'bg-darkGrayTwo';
    case 'darkGrayThree':
      return 'bg-darkGrayThree';
    case 'mediumGrayOne':
      return 'bg-mediumGrayOne';
    case 'lightGray':
      return 'bg-lightGray';
    case 'lightGrayOne':
      return 'bg-lightGrayOne';
    case 'lightGrayTwo':
      return 'bg-lightGrayTwo';
    case 'warmWhite':
      return 'bg-warmWhite';
    case 'lightGrayFour':
      return 'bg-lightGrayFour';
    case 'blueGray':
      return 'bg-blueGray';
    case 'background':
      return 'bg-background';
    case 'white':
      return 'bg-white';
    case 'linkBlue':
      return 'bg-linkBlue';
    case 'linkHoverBlue':
      return 'bg-linkHoverBlue';
    case 'tabFocusBlue':
      return 'bg-tabFocusBlue';
    case 'grayBlueThree':
      return 'bg-grayBlueThree';
    case 'googleWhite':
      return 'bg-googleWhite';
    case 'googleGray':
      return 'bg-googleGray';
    case 'neutralGrayThree':
      return 'bg-neutralGrayThree';
    case 'newLightGreen':
      return 'bg-newLightGreen';
    case 'newBlueTint':
      return 'bg-newBlueTint';
    case 'teal':
      return 'bg-teal';
    case 'lightTeal':
      return 'bg-lightTeal';
    case 'lightPurpleBg':
      return 'bg-lightPurpleBg';
    case 'lightLinkBlue':
      return 'bg-lightLinkBlue';
    case 'black25':
      return 'bg-black25';
    case 'black50':
      return 'bg-black50';
    case 'whiteOverlay25':
      return 'bg-whiteOverlay25';
    case 'lightMagenta':
      return 'bg-lightMagenta';
    case 'lightGreen':
      return 'bg-lightGreen';
    case 'successGreenBg':
      return 'bg-successGreenBg';
    case 'errorRedBg':
      return 'bg-errorRedBg';
    case 'humanaGreen':
      return 'bg-humanaGreen';
    case 'uhcBlue':
      return 'bg-uhcBlue';
    case 'warningYellow':
      return 'bg-warningYellow';
    case 'warningYellowBg':
      return 'bg-warningYellowBg';
    case 'infoBlueBg':
      return 'bg-infoBlueBg';
    case 'magentaHover':
      return 'bg-magentaHover';
    case 'darkMagenta':
      return 'bg-darkMagenta';
    case 'transparent':
      return 'bg-transparent';
    default:
      return '';
  }
};

export const toGroupHoverBackgroundColor = (color?: ColorType): string => {
  switch (color) {
    case 'purple':
      return 'group-hover:bg-purple';
    case 'darkPurple':
      return 'group-hover:bg-darkPurple';
    case 'lightPurple':
      return 'group-hover:bg-lightPurple';
    case 'purpleGray':
      return 'group-hover:bg-purpleGray';
    case 'orange':
      return 'group-hover:bg-orange';
    case 'mediumOrange':
      return 'group-hover:bg-mediumOrange';
    case 'lightOrange':
      return 'group-hover:bg-lightOrange';
    case 'magenta':
      return 'group-hover:bg-magenta';
    case 'lightMagenta':
      return 'group-hover:bg-lightMagenta';
    case 'mediumGreen':
      return 'group-hover:bg-mediumGreen';
    case 'lightGreen':
      return 'group-hover:bg-lightGreen';
    case 'green':
      return 'group-hover:bg-green';
    case 'successGreen':
      return 'group-hover:bg-successGreen';
    case 'teal':
      return 'group-hover:bg-teal';
    case 'mediumTeal':
      return 'group-hover:bg-mediumTeal';
    case 'lightTeal':
      return 'group-hover:bg-lightTeal';
    case 'darkBlue':
      return 'group-hover:bg-darkBlue';
    case 'darkBlueTint':
      return 'group-hover:bg-darkBlueTint';
    case 'darkBlueBg':
      return 'group-hover:bg-darkBlueBg';
    case 'mediumTeal':
      return 'group-hover:bg-mediumTeal';
    case 'yellow':
      return 'group-hover:bg-yellow';
    case 'yellowTint':
      return 'group-hover:bg-yellowTint';
    case 'mellowYellow':
      return 'group-hover:bg-mellowYellow';
    case 'ratingYellow':
      return 'group-hover:bg-ratingYellow';
    case 'starRating':
      return 'group-hover:bg-starRating';
    case 'redTint':
      return 'group-hover:bg-redTint';
    case 'darkRed':
      return 'group-hover:bg-darkRed';
    case 'darkRedTwo':
      return 'group-hover:bg-darkRedTwo';
    case 'errorRed':
      return 'group-hover:bg-errorRed';
    case 'errorRedTwo':
      return 'group-hover:bg-errorRedTwo';
    case 'errorBg':
      return 'group-hover:bg-errorBg';
    case 'lightRed':
      return 'group-hover:bg-lightRed';
    case 'darkBoneOne':
      return 'group-hover:bg-darkBoneOne';
    case 'darkBoneTwo':
      return 'group-hover:bg-darkBoneTwo';
    case 'darkGrayOne':
      return 'group-hover:bg-darkGrayOne';
    case 'darkGrayTwo':
      return 'group-hover:bg-darkGrayTwo';
    case 'darkGrayThree':
      return 'group-hover:bg-darkGrayThree';
    case 'mediumGrayOne':
      return 'group-hover:bg-mediumGrayOne';
    case 'lightGray':
      return 'group-hover:bg-lightGray';
    case 'lightGrayOne':
      return 'group-hover:bg-lightGrayOne';
    case 'lightGrayTwo':
      return 'group-hover:bg-lightGrayTwo';
    case 'warmWhite':
      return 'group-hover:bg-warmWhite';
    case 'lightGrayFour':
      return 'group-hover:bg-lightGrayFour';
    case 'blueGray':
      return 'group-hover:bg-blueGray';
    case 'background':
      return 'group-hover:bg-background';
    case 'white':
      return 'group-hover:bg-white';
    case 'linkBlue':
      return 'group-hover:bg-linkBlue';
    case 'linkHoverBlue':
      return 'group-hover:bg-linkHoverBlue';
    case 'tabFocusBlue':
      return 'group-hover:bg-tabFocusBlue';
    case 'grayBlueThree':
      return 'group-hover:bg-grayBlueThree';
    case 'googleWhite':
      return 'group-hover:bg-googleWhite';
    case 'googleGray':
      return 'group-hover:bg-googleGray';
    case 'neutralGrayThree':
      return 'group-hover:bg-neutralGrayThree';
    case 'newLightGreen':
      return 'group-hover:bg-newLightGreen';
    case 'newBlueTint':
      return 'group-hover:bg-newBlueTint';
    case 'teal':
      return 'group-hover:bg-teal';
    case 'lightTeal':
      return 'group-hover:bg-lightTeal';
    case 'lightPurpleBg':
      return 'group-hover:bg-lightPurpleBg';
    case 'lightLinkBlue':
      return 'group-hover:bg-lightLinkBlue';
    case 'black25':
      return 'group-hover:bg-black25';
    case 'black50':
      return 'group-hover:bg-black50';
    case 'whiteOverlay25':
      return 'group-hover:bg-whiteOverlay25';
    case 'lightMagenta':
      return 'group-hover:bg-lightMagenta';
    case 'lightGreen':
      return 'group-hover:bg-lightGreen';
    case 'successGreenBg':
      return 'group-hover:bg-successGreenBg';
    case 'errorRedBg':
      return 'group-hover:bg-errorRedBg';
    case 'humanaGreen':
      return 'group-hover:bg-humanaGreen';
    case 'uhcBlue':
      return 'group-hover:bg-uhcBlue';
    case 'warningYellow':
      return 'group-hover:bg-warningYellow';
    case 'warningYellowBg':
      return 'group-hover:bg-warningYellowBg';
    case 'infoBlueBg':
      return 'group-hover:bg-infoBlueBg';
    case 'magentaHover':
      return 'group-hover:bg-magentaHover';
    case 'darkMagenta':
      return 'group-hover:bg-darkMagenta';
    case 'transparent':
      return 'group-hover:bg-transparent';
    default:
      return '';
  }
};

export const toGroupActiveBackgroundColor = (color?: ColorType): string => {
  switch (color) {
    case 'purple':
      return 'group-active:bg-purple';
    case 'darkPurple':
      return 'group-active:bg-darkPurple';
    case 'lightPurple':
      return 'group-active:bg-lightPurple';
    case 'purpleGray':
      return 'group-active:bg-purpleGray';
    case 'orange':
      return 'group-active:bg-orange';
    case 'mediumOrange':
      return 'group-active:bg-mediumOrange';
    case 'lightOrange':
      return 'group-active:bg-lightOrange';
    case 'magenta':
      return 'group-active:bg-magenta';
    case 'lightMagenta':
      return 'group-active:bg-lightMagenta';
    case 'mediumGreen':
      return 'group-active:bg-mediumGreen';
    case 'lightGreen':
      return 'group-active:bg-lightGreen';
    case 'green':
      return 'group-active:bg-green';
    case 'successGreen':
      return 'group-active:bg-successGreen';
    case 'teal':
      return 'group-active:bg-teal';
    case 'mediumTeal':
      return 'group-active:bg-mediumTeal';
    case 'lightTeal':
      return 'group-active:bg-lightTeal';
    case 'darkBlue':
      return 'group-active:bg-darkBlue';
    case 'darkBlueTint':
      return 'group-active:bg-darkBlueTint';
    case 'darkBlueBg':
      return 'group-active:bg-darkBlueBg';
    case 'mediumTeal':
      return 'group-active:bg-mediumTeal';
    case 'yellow':
      return 'group-active:bg-yellow';
    case 'yellowTint':
      return 'group-active:bg-yellowTint';
    case 'mellowYellow':
      return 'group-active:bg-mellowYellow';
    case 'ratingYellow':
      return 'group-active:bg-ratingYellow';
    case 'starRating':
      return 'group-active:bg-starRating';
    case 'redTint':
      return 'group-active:bg-redTint';
    case 'darkRed':
      return 'group-active:bg-darkRed';
    case 'darkRedTwo':
      return 'group-active:bg-darkRedTwo';
    case 'errorRed':
      return 'group-active:bg-errorRed';
    case 'errorRedTwo':
      return 'group-active:bg-errorRedTwo';
    case 'errorBg':
      return 'group-active:bg-errorBg';
    case 'lightRed':
      return 'group-active:bg-lightRed';
    case 'darkBoneOne':
      return 'group-active:bg-darkBoneOne';
    case 'darkBoneTwo':
      return 'group-active:bg-darkBoneTwo';
    case 'darkGrayOne':
      return 'group-active:bg-darkGrayOne';
    case 'darkGrayTwo':
      return 'group-active:bg-darkGrayTwo';
    case 'darkGrayThree':
      return 'group-active:bg-darkGrayThree';
    case 'mediumGrayOne':
      return 'group-active:bg-mediumGrayOne';
    case 'lightGray':
      return 'group-active:bg-lightGray';
    case 'lightGrayOne':
      return 'group-active:bg-lightGrayOne';
    case 'lightGrayTwo':
      return 'group-active:bg-lightGrayTwo';
    case 'warmWhite':
      return 'group-active:bg-warmWhite';
    case 'lightGrayFour':
      return 'group-active:bg-lightGrayFour';
    case 'blueGray':
      return 'group-active:bg-blueGray';
    case 'background':
      return 'group-active:bg-background';
    case 'white':
      return 'group-active:bg-white';
    case 'linkBlue':
      return 'group-active:bg-linkBlue';
    case 'linkHoverBlue':
      return 'group-active:bg-linkHoverBlue';
    case 'tabFocusBlue':
      return 'group-active:bg-tabFocusBlue';
    case 'grayBlueThree':
      return 'group-active:bg-grayBlueThree';
    case 'googleWhite':
      return 'group-active:bg-googleWhite';
    case 'googleGray':
      return 'group-active:bg-googleGray';
    case 'neutralGrayThree':
      return 'group-active:bg-neutralGrayThree';
    case 'newLightGreen':
      return 'group-active:bg-newLightGreen';
    case 'newBlueTint':
      return 'group-active:bg-newBlueTint';
    case 'teal':
      return 'group-active:bg-teal';
    case 'lightTeal':
      return 'group-active:bg-lightTeal';
    case 'lightPurpleBg':
      return 'group-active:bg-lightPurpleBg';
    case 'lightLinkBlue':
      return 'group-active:bg-lightLinkBlue';
    case 'black25':
      return 'group-active:bg-black25';
    case 'black50':
      return 'group-active:bg-black50';
    case 'whiteOverlay25':
      return 'group-active:bg-whiteOverlay25';
    case 'lightMagenta':
      return 'group-active:bg-lightMagenta';
    case 'lightGreen':
      return 'group-active:bg-lightGreen';
    case 'successGreenBg':
      return 'group-active:bg-successGreenBg';
    case 'errorRedBg':
      return 'group-active:bg-errorRedBg';
    case 'humanaGreen':
      return 'group-active:bg-humanaGreen';
    case 'uhcBlue':
      return 'group-active:bg-uhcBlue';
    case 'warningYellow':
      return 'group-active:bg-warningYellow';
    case 'warningYellowBg':
      return 'group-active:bg-warningYellowBg';
    case 'infoBlueBg':
      return 'group-active:bg-infoBlueBg';
    case 'magentaHover':
      return 'group-active:bg-magentaHover';
    case 'darkMagenta':
      return 'group-active:bg-darkMagenta';
    case 'transparent':
      return 'group-active:bg-transparent';
    default:
      return '';
  }
};

export const toBorderColor = (color?: ColorType): string => {
  switch (color) {
    case 'purple':
      return 'border-purple';
    case 'darkPurple':
      return 'border-darkPurple';
    case 'lightPurple':
      return 'border-lightPurple';
    case 'purpleGray':
      return 'border-purpleGray';
    case 'orange':
      return 'border-orange';
    case 'mediumOrange':
      return 'border-mediumOrange';
    case 'lightOrange':
      return 'border-lightOrange';
    case 'magenta':
      return 'border-magenta';
    case 'lightMagenta':
      return 'border-lightMagenta';
    case 'mediumGreen':
      return 'border-mediumGreen';
    case 'lightGreen':
      return 'border-lightGreen';
    case 'green':
      return 'border-green';
    case 'successGreen':
      return 'border-successGreen';
    case 'teal':
      return 'border-teal';
    case 'mediumTeal':
      return 'border-mediumTeal';
    case 'lightTeal':
      return 'border-lightTeal';
    case 'darkBlue':
      return 'border-darkBlue';
    case 'darkBlueTint':
      return 'border-darkBlueTint';
    case 'darkBlueBg':
      return 'border-darkBlueBg';
    case 'mediumTeal':
      return 'border-mediumTeal';
    case 'yellow':
      return 'border-yellow';
    case 'yellowTint':
      return 'border-yellowTint';
    case 'mellowYellow':
      return 'border-mellowYellow';
    case 'ratingYellow':
      return 'border-ratingYellow';
    case 'starRating':
      return 'border-starRating';
    case 'redTint':
      return 'border-redTint';
    case 'darkRed':
      return 'border-darkRed';
    case 'darkRedTwo':
      return 'border-darkRedTwo';
    case 'errorRed':
      return 'border-errorRed';
    case 'errorRedTwo':
      return 'border-errorRedTwo';
    case 'errorBg':
      return 'border-errorBg';
    case 'lightRed':
      return 'border-lightRed';
    case 'darkBoneOne':
      return 'border-darkBoneOne';
    case 'darkBoneTwo':
      return 'border-darkBoneTwo';
    case 'darkGrayOne':
      return 'border-darkGrayOne';
    case 'darkGrayTwo':
      return 'border-darkGrayTwo';
    case 'darkGrayThree':
      return 'border-darkGrayThree';
    case 'mediumGrayOne':
      return 'border-mediumGrayOne';
    case 'lightGray':
      return 'border-lightGray';
    case 'lightGrayOne':
      return 'border-lightGrayOne';
    case 'lightGrayTwo':
      return 'border-lightGrayTwo';
    case 'warmWhite':
      return 'border-warmWhite';
    case 'lightGrayFour':
      return 'border-lightGrayFour';
    case 'blueGray':
      return 'border-blueGray';
    case 'background':
      return 'border-background';
    case 'white':
      return 'border-white';
    case 'linkBlue':
      return 'border-linkBlue';
    case 'linkHoverBlue':
      return 'border-linkHoverBlue';
    case 'tabFocusBlue':
      return 'border-tabFocusBlue';
    case 'grayBlueThree':
      return 'border-grayBlueThree';
    case 'googleWhite':
      return 'border-googleWhite';
    case 'googleGray':
      return 'border-googleGray';
    case 'neutralGrayThree':
      return 'border-neutralGrayThree';
    case 'newLightGreen':
      return 'border-newLightGreen';
    case 'newBlueTint':
      return 'border-newBlueTint';
    case 'teal':
      return 'border-teal';
    case 'lightTeal':
      return 'border-lightTeal';
    case 'lightPurpleBg':
      return 'border-lightPurpleBg';
    case 'lightLinkBlue':
      return 'border-lightLinkBlue';
    case 'black25':
      return 'border-black25';
    case 'black50':
      return 'border-black50';
    case 'whiteOverlay25':
      return 'border-whiteOverlay25';
    case 'lightMagenta':
      return 'border-lightMagenta';
    case 'lightGreen':
      return 'border-lightGreen';
    case 'successGreenBg':
      return 'border-successGreenBg';
    case 'errorRedBg':
      return 'border-errorRedBg';
    case 'humanaGreen':
      return 'border-humanaGreen';
    case 'uhcBlue':
      return 'border-uhcBlue';
    case 'warningYellow':
      return 'border-warningYellow';
    case 'warningYellowBg':
      return 'border-warningYellowBg';
    case 'infoBlueBg':
      return 'border-infoBlueBg';
    case 'magentaHover':
      return 'border-magentaHover';
    case 'darkMagenta':
      return 'border-darkMagenta';
    case 'transparent':
      return 'border-transparent';
    default:
      return '';
  }
};
