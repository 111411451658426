import React, { PropsWithChildren } from 'react';

import { TextLink } from '../..';
import { TextType } from '../../../../theme/typography';
import { ColorType } from '../../../../types/ui';

type FooterLinkProps = {
  href: string;
  color?: ColorType;
  variant?: TextType;
  underline?: boolean;
  sameTab?: boolean;
};

const FooterLink = ({
  href,
  variant = 'nav-3',
  color = 'darkGrayTwo',
  underline,
  children,
  sameTab,
}: PropsWithChildren<FooterLinkProps>) => {
  return (
    <TextLink
      sameTab={sameTab}
      link={href}
      variant={variant}
      color={color}
      hoverColor={color}
      invertUnderline={!underline}
    >
      {children}
    </TextLink>
  );
};

export default FooterLink;
