import classNames from 'classnames';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const LibraryIcon = ({ size = 24, colorOverride, active, className }: IconProps) => (
  <svg className={classNames('flex-shrink-0', className)} width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M2.90882 17.5999H20V0H2.86165C2.10344 0.0023687 1.37699 0.29608 0.840851 0.817022C0.304715 1.33796 0.00243778 2.04383 0 2.78055V18.9863H0.000518943C0.000518943 18.9933 0 19.0001 0 19.0071C0 20.6296 1.31047 22 2.86165 22H20V20.5332H2.86165C2.15396 20.5332 1.50943 19.8058 1.50943 19.007C1.50943 18.2443 2.15024 17.5999 2.90882 17.5999ZM15.0943 1.48192V9.4026L12.8113 7.45516L10.566 9.39119V1.48192H15.0943ZM9.0566 1.46666V11.3666H10.5491L12.8168 9.4114L15.1087 11.3666H16.6038V1.46666H18.4906V16.1332H4.53208L4.5283 1.46666H9.0566ZM2.86165 1.46666H3.01887L3.02245 16.1332H2.90863C2.41782 16.1333 1.93554 16.2579 1.50943 16.4945V2.79201C1.50828 2.44215 1.65 2.10612 1.9035 1.85765C2.157 1.60919 2.50158 1.46857 2.86165 1.46666V1.46666Z"
      className={toFillColor(colorOverride)}
      fillOpacity={active ? 1 : 0.7}
    />
  </svg>
);
