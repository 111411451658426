import moment from 'moment';
import { useRouter } from 'next/router';
import React, { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from 'react';

import {
  useConversation,
  useConversations,
  useCreateConversation,
  useSendMessage,
  useUserSession,
} from '../../../../hooks/api';
import { Conversation, Message, MessageContent } from '../../../../types/Conversation';
import { sortByCreationTime } from '../../../../utils/sort';

export const ChatContext = createContext<{
  isError: boolean;
  isLoading: boolean;
  lastMessage: Message | undefined;
  messagesWithoutLoadingOnes: Message[] | undefined;
  onSendMessage: (text: string) => void;
  onSendSelectedResponse: (label: string, value: string) => void;
  showChat: boolean;
  showLoader: boolean;
  showDots: boolean;
  isFinishedCallback: () => void;
}>({
  isError: false,
  isLoading: false,
  lastMessage: undefined,
  messagesWithoutLoadingOnes: undefined,
  onSendMessage: () => {},
  onSendSelectedResponse: () => {},
  showChat: false,
  showLoader: false,
  showDots: false,
  isFinishedCallback: () => {},
});

const messagesThatShouldLoad = ['display_benefits', 'display_community_resources', 'display_benefits_and_resources'];

export function ChatContextWrapper({ children }: PropsWithChildren<{}>) {
  const router = useRouter();
  const { message } = router.query;

  const [currentConversationId, setCurrentConversationId] = useState<string>();
  const [showChat, setShowChat] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showDots, setShowDots] = useState(false);
  const { data: userSession, isError: sessionError, isLoading: sessionLoading } = useUserSession();
  const {
    data: conversations,
    isError: conversationsError,
    isLoading: conversationsLoading,
    isSuccess: conversationsLoaded,
  } = useConversations({ accountId: userSession?.sub });
  const conversationsInLastHour: Conversation[] | undefined = conversations
    ? sortByCreationTime(
        conversations.filter((conversation: Conversation) =>
          moment(conversation.creationTime).isAfter(moment().add(-1, 'hours'))
        )
      )
    : undefined;
  const {
    data: createdConversation,
    mutateAsync: createConversationMutation,
    isIdle: haveNotCreatedConversation,
  } = useCreateConversation();
  const {
    data: currentConversation,
    isLoading: conversationLoading,
    isError: conversationError,
  } = useConversation({
    conversationId: currentConversationId,
  });
  const {
    isSuccess: sendMessageIsSuccessful,
    data: sendMessageResponse,
    mutate: sendMessage,
    isError: messageError,
    isLoading: messageLoading,
  } = useSendMessage();

  const lastMessage = useMemo(
    () => (currentConversation?.messages ? currentConversation?.messages.slice(-1)[0] : undefined),
    [currentConversation?.messages]
  );
  const chatbotMessages = useMemo(
    () =>
      currentConversation?.messages ? currentConversation.messages.filter((message: Message) => message.isChatbot) : [],
    [currentConversation?.messages]
  );
  const lastChatbotMessage = useMemo(
    () => (chatbotMessages.length > 0 ? chatbotMessages.slice(-1)[0] : undefined),
    [chatbotMessages]
  );

  const scrollToBottom = useCallback(() => {
    const desktopElement = document?.getElementById('desktop-chat-scroll-to');
    desktopElement?.scrollIntoView({ behavior: 'smooth' });
    const mobileElement = document?.getElementById('mobile-chat-scroll-to');
    mobileElement?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (showLoader) {
      const timeout = setTimeout(() => {
        setShowLoader(false);
      }, 4000);

      return () => clearTimeout(timeout);
    }
  }, [scrollToBottom, showLoader]);

  const onSendMessage = useCallback(
    (text: string, conversationId?: string, displayFtue?: boolean) => {
      if (conversationId || currentConversationId) {
        scrollToBottom();
        setShowChat(false);
        setShowDots(true);
        sendMessage({
          conversationId: conversationId ? conversationId : currentConversationId!,
          message: { type: 'text', text } as MessageContent,
          displayFtue,
        });
      }
    },
    [currentConversationId, scrollToBottom, sendMessage]
  );

  const onSendSelectedResponse = useCallback(
    (label: string, value: string) => {
      scrollToBottom();
      setShowDots(true);
      sendMessage({
        conversationId: currentConversation?.id ?? '',
        message: { type: 'select_option', selectedOption: { label, value } } as MessageContent,
      });
    },
    [currentConversation?.id, scrollToBottom, sendMessage]
  );

  const isError = sessionError || conversationsError || conversationError || messageError;
  const isLoading = conversationLoading || messageLoading;

  useEffect(() => {
    if (
      sendMessageResponse &&
      messagesThatShouldLoad.includes(sendMessageResponse?.messages?.slice(-2)?.[0]?.message?.type)
    ) {
      setShowDots(false);
      setShowLoader(true);
    }
  }, [sendMessageResponse]);

  useEffect(() => {
    if (lastChatbotMessage?.message.forceSelection) {
      setShowChat(false);
    } else if (!showDots && !showLoader) {
      setShowChat(true);
    }
  }, [lastChatbotMessage, lastChatbotMessage?.message.forceSelection, showDots, showLoader]);

  const messagesWithoutLoadingOnes = useMemo(
    () =>
      showLoader && messagesThatShouldLoad.includes(currentConversation?.messages?.slice(-2)?.[0]?.message?.type ?? '')
        ? currentConversation?.messages?.slice(0, currentConversation?.messages?.length - 2)
        : currentConversation?.messages,
    [currentConversation?.messages, showLoader]
  );

  const sendMessageAndClearParams = useCallback(async () => {
    if (message) {
      const conversation = await createConversationMutation({ flowName: 'member_chatflow' });
      setCurrentConversationId(conversation?.id);
      onSendMessage(message.toString(), conversation?.id, false);
      router.replace('/app/chat', undefined, { shallow: true });
    }
    // This is necessary to avoid infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, router]);

  const startAndSetConversation = useCallback(async () => {
    const conversation = await createConversationMutation({ flowName: 'member_chatflow' });
    setCurrentConversationId(conversation?.id);
  }, [createConversationMutation]);

  useEffect(() => {
    if (router.isReady && message) {
      // We've created a new conversation to use
      sendMessageAndClearParams();
    }
    // Necessary for this to only occur once. TODO: move this to serverside props.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, sendMessageAndClearParams]);

  useEffect(() => {
    if (
      router.isReady &&
      message === undefined &&
      conversationsLoaded &&
      currentConversationId === undefined &&
      createdConversation === undefined &&
      haveNotCreatedConversation
    ) {
      if (conversationsInLastHour && conversationsInLastHour.length > 0) {
        // Use the latest existing conversation
        setCurrentConversationId(conversationsInLastHour[0]?.id);
      } else {
        // New conversation when none exist
        startAndSetConversation();
      }
    }
  }, [
    conversationsInLastHour,
    conversationsLoaded,
    createdConversation,
    haveNotCreatedConversation,
    currentConversationId,
    message,
    router.isReady,
    startAndSetConversation,
  ]);

  useEffect(() => {
    if (!isLoading || !showLoader) {
      const interval = setTimeout(() => {
        scrollToBottom();
      }, 500);

      return () => clearTimeout(interval);
    }
  }, [isLoading, scrollToBottom, showLoader]);

  useEffect(() => {
    if (!isLoading) {
      setShowDots(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (sendMessageIsSuccessful) {
      setShowDots(false);
    }
  }, [sendMessageIsSuccessful]);

  const isFinishedCallback = useCallback(() => {
    setShowLoader(false);
  }, []);

  const value = useMemo(() => {
    return {
      isError,
      isLoading,
      lastMessage,
      messagesWithoutLoadingOnes,
      onSendMessage,
      onSendSelectedResponse,
      showChat,
      showLoader,
      showDots,
      isFinishedCallback,
    };
  }, [
    isError,
    isLoading,
    lastMessage,
    messagesWithoutLoadingOnes,
    onSendMessage,
    onSendSelectedResponse,
    showChat,
    showLoader,
    showDots,
    isFinishedCallback,
  ]);

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}

export default ChatContextWrapper;
