import { HealthBenefit, Resource } from './Resources';

export enum Readiness {
  NotReadyForChange = 'notReadyForChange',
  ThinkingAboutChange = 'thinkingAboutChange',
  ReadyForChange = 'readyForChange',
}

export const ReadinessLabels: Record<Readiness, string> = {
  notReadyForChange: 'Not Ready For Change',
  thinkingAboutChange: 'Thinking About Change',
  readyForChange: 'Ready For Change',
};

export const ReadinessPriority: Record<Readiness, number> = {
  readyForChange: 1,
  thinkingAboutChange: 2,
  notReadyForChange: 3,
};

export enum InternalMemberNeed {
  Transportation = 'Transportation',
  FinancialAssistance = 'Financial Assistance',
  Food = 'Food',
  Housing = 'Housing',
  FallPrevention = 'Fall Prevention',
  SocialEngagement = 'Social Engagement',
  AppropriateCoverage = 'Appropriate Coverage',
  ConditionSupport = 'Condition Support',
  MedicationSupport = 'Medication Support',
  AccessToCare = 'Access to Care',
  CaregiverSupport = 'Caregiver Support',
  Other = 'Other',
}

export const InternalMemberNeeds = [
  InternalMemberNeed.Transportation,
  InternalMemberNeed.FinancialAssistance,
  InternalMemberNeed.Food,
  InternalMemberNeed.Housing,
  InternalMemberNeed.FallPrevention,
  InternalMemberNeed.SocialEngagement,
  InternalMemberNeed.AppropriateCoverage,
  InternalMemberNeed.ConditionSupport,
  InternalMemberNeed.MedicationSupport,
  InternalMemberNeed.AccessToCare,
  InternalMemberNeed.CaregiverSupport,
  InternalMemberNeed.Other,
];

export enum ExternalMemberNeed {
  HealthcareAndHealthInsurance = 'Health',
  Transportation = 'Transportation',
  Food = 'Food',
  HousingAndHelpAroundTheHouse = 'Home & Care',
  FinancialAssistance = 'Money',
  SocialEnrichmentEducationAndFitness = 'Recreation',
}

export const ExternalMemberNeeds = [
  ExternalMemberNeed.HealthcareAndHealthInsurance,
  ExternalMemberNeed.Transportation,
  ExternalMemberNeed.Food,
  ExternalMemberNeed.HousingAndHelpAroundTheHouse,
  ExternalMemberNeed.FinancialAssistance,
  ExternalMemberNeed.SocialEnrichmentEducationAndFitness,
];

export enum HealthEventTags {
  PostDischarge = 'post-discharge',
  ChronicConditionDiagnosis = 'chronic condition diagnosis',
}

export type ActionItemStatus = 'open' | 'completed';

export type ActionItemType = 'recommended' | 'custom';

export type SupportedCompletionReason = 'fulfilled' | 'memberPrefersNoAction' | 'noLongerRelevant' | 'other';

export type CompletionReason =
  | 'achievedResearchTeam'
  | 'achievedReferredToBenefit'
  | 'achievedResolvedByMember'
  | 'unresolvedMemberPrefersNoAction'
  | 'unresolvedUnsuccessfulAttempts'
  | SupportedCompletionReason;

export const SupportedCompletionReasonOptions: Record<SupportedCompletionReason, string> = {
  fulfilled: 'Fulfilled',
  memberPrefersNoAction: 'Member prefers no action',
  noLongerRelevant: 'No longer relevant',
  other: 'Other',
};

export type ActionItem = {
  id: string;
  todoListId: string;
  text: string;
  memberNeeds: InternalMemberNeed[];
  readinessStatus?: Readiness;
  status: ActionItemStatus;
  type: ActionItemType;
  priority: number;
  toolkitUrl: string;
  dedupKey: string;
  serviceRequestSuggestions: string[];
  linkedServiceRequests: [];
  creationTime: string;
  lastModifiedTime: string;
  surveyInstanceId: string;
  comments?: string;
  closedAt?: string;
  completionReason?: CompletionReason;
  completionExplanation?: string;
  discussed?: boolean;
  benefits: HealthBenefit[];
  source: ActionItemSource;
  customActionUrl?: string;
  customActionButtonText?: string;
  communityResources: Resource[];
};

export type ActionItemSource =
  | 'consultation'
  | 'campaignTool'
  | 'benefitsData'
  | 'eligibilityFile'
  | 'userCreated'
  | 'welcomeCallGuidance'
  | 'noRecommendations'
  | 'allRecommendationsComplete'
  | 'lostCoverage'
  | 'unengaged'
  | 'csatSurvey';

export type ActionItemPayload = {
  text?: string;
  memberNeeds?: InternalMemberNeed[];
  readinessStatus?: Readiness | undefined;
  status?: ActionItemStatus;
  completionReason?: SupportedCompletionReason;
  completionExplanation?: string;
};

export type ActionItemInteraction = {
  id: string;
  todoItemId: string;
  type: InteractionType;
  commentId?: string;
  user?: {
    id: string;
    displayName: string;
  };
  creationTime: string;
};

export type InteractionType =
  | 'commentAdded'
  | 'readyForChange'
  | 'notReadyForChange'
  | 'thinkingAboutChange'
  | 'discussTapped'
  | 'serviceRequestCreated'
  | 'itemAdded'
  | 'itemClosed';

export type ActivityEntry = {
  id: string;
  displayName?: string;
  creationTime: string;
  userId?: string;
  type: InteractionType;
  itemId: string;
  text: string;
  lastEditTime?: string;
};

export const MapActionItemTextToSearch: Record<string, string> = {
  'Find financial resources that can help with food assistance': 'Food',
  'Check on available food assistance that accommodates dietary restrictions': 'Dietary Restrictions',
  'Explore available options for affordable housing': 'Housing',
  'Understand the reason for recent hospitalization and put resources in place where necessary': 'Post-Discharge',
  'Understand what prevents access to transportation': 'Transportation',
  'Find out what prevents access to transportation to the doctor or pharmacy': 'Transportation',
  'Find out what gets in the way of taking medications': 'Medications',
  'Understand what causes loss of balance and recommend support strategies to correct': 'Fall',
  'Understand if there are financial assistance needs and implement support resources': 'Money',
  'Understand what kind of support is needed for CHF condition': 'Chronic Condition Diagnosis',
  'Assess what kind of support is needed for diabetes condition': 'Chronic Condition Diagnosis',
  'Figure out what kind of support is needed for hypertension condition': 'Chronic Condition Diagnosis',
  'Understand what kind of support is needed for cancer condition': 'Chronic Condition Diagnosis',
  'Assess what kind of support is needed for UTI condition': 'Chronic Condition Diagnosis',
  'Explore available options for caregiver support': 'Caregiver Support',
  "Consider if it's time to choose a new primary care doctor": 'PCP',
  'Figure out if any care resources are needed': 'Health',
  'Consider how to incorporate different social opportunities': 'Social',
  'Consider a referral for Medicare consultant services': 'Medicare Consultant',
  'Create an Advance Directive': 'Advance Directive',
};
