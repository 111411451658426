import { colors } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const CreditCard = ({ size = 18, colorOverride = 'purple' }: IconProps) => {
  return (
    <svg width={size} height={(size * 7) / 9} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.125 9.5C11.9592 9.5 11.8003 9.56585 11.6831 9.68306C11.5658 9.80027 11.5 9.95924 11.5 10.125C11.5 10.2908 11.5658 10.4497 11.6831 10.5669C11.8003 10.6842 11.9592 10.75 12.125 10.75H14.625C14.7908 10.75 14.9497 10.6842 15.0669 10.5669C15.1842 10.4497 15.25 10.2908 15.25 10.125C15.25 9.95924 15.1842 9.80027 15.0669 9.68306C14.9497 9.56585 14.7908 9.5 14.625 9.5H12.125ZM0.25 3.875C0.25 3.0462 0.57924 2.25134 1.16529 1.66529C1.75134 1.07924 2.5462 0.75 3.375 0.75H14.625C15.4538 0.75 16.2487 1.07924 16.8347 1.66529C17.4208 2.25134 17.75 3.0462 17.75 3.875V10.125C17.75 10.9538 17.4208 11.7487 16.8347 12.3347C16.2487 12.9208 15.4538 13.25 14.625 13.25H3.375C2.5462 13.25 1.75134 12.9208 1.16529 12.3347C0.57924 11.7487 0.25 10.9538 0.25 10.125V3.875ZM16.5 4.5V3.875C16.5 3.37772 16.3025 2.90081 15.9508 2.54917C15.5992 2.19754 15.1223 2 14.625 2H3.375C2.87772 2 2.40081 2.19754 2.04917 2.54917C1.69754 2.90081 1.5 3.37772 1.5 3.875V4.5H16.5ZM1.5 5.75V10.125C1.5 10.6223 1.69754 11.0992 2.04917 11.4508C2.40081 11.8025 2.87772 12 3.375 12H14.625C15.1223 12 15.5992 11.8025 15.9508 11.4508C16.3025 11.0992 16.5 10.6223 16.5 10.125V5.75H1.5Z"
        fill={colors[colorOverride]}
      />
    </svg>
  );
};

export default CreditCard;
