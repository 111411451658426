import { T, useT } from '@transifex/react';
import { Call } from '@twilio/voice-sdk';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import React, { Dispatch, SetStateAction, createRef, useCallback, useEffect, useMemo } from 'react';

import { DialpadState } from '../../../contexts/CallContext';
import { VoiceConference } from '../../../types/Call';
import { formatPhoneNumber, getActiveParticipants } from '../../../utils/Call';
import { cleanPhone } from '../../../utils/Member';
import { AddButtonWithText, CirclePhoneIcon, DialPadIcon } from '../../icons';
import { IconButton } from '../IconButton';
import Text from '../Text';
import { TextInput } from '../TextInput';
import TextLink from '../TextLink';
import NumberButton from './NumberButton';

type DialpadProps = {
  primaryCall?: Call;
  dialpadNumber: string;
  setDialpadNumber: Dispatch<SetStateAction<string>>;
  dialpadState: DialpadState;
  setDialpadState: Dispatch<SetStateAction<DialpadState>>;
  connectCall: (phoneNumber: string) => void;
  addToConference: (phoneNumber: string) => void;
  conference?: VoiceConference;
};

const Dialpad = ({
  primaryCall,
  dialpadNumber,
  setDialpadNumber,
  dialpadState,
  setDialpadState,
  connectCall,
  addToConference,
  conference,
}: DialpadProps) => {
  const t = useT();
  const onClick = useCallback(
    (value: string) => {
      setDialpadNumber((currentPhoneNumber) => currentPhoneNumber + value);
      if (primaryCall) primaryCall.sendDigits(value);
    },
    [primaryCall, setDialpadNumber]
  );

  const buttonRef = createRef<HTMLButtonElement>();

  const minimized = useMemo(() => dialpadState === 'minimized', [dialpadState]);

  const toggleMinimized = useCallback(
    () => setDialpadState((currentState) => (currentState === 'minimized' ? 'open' : 'minimized')),
    [setDialpadState]
  );

  const handleClose = useCallback(() => {
    setDialpadNumber('');
    setDialpadState('close');
  }, [setDialpadNumber, setDialpadState]);

  useEffect(() => {
    if (dialpadState === 'open') buttonRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedPhoneNumber = useMemo(() => formatPhoneNumber(dialpadNumber), [dialpadNumber]);

  const isConferenceAtMaxParticipants = useMemo(() => {
    if (!conference || conference.callParticipants.length < 1) {
      return false;
    }
    const activeParticipants = getActiveParticipants(conference.callParticipants);
    return activeParticipants.length >= 3;
  }, [conference]);

  const handleNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDialpadNumber(e.target.value);
    },
    [setDialpadNumber]
  );

  return (
    <div
      className={classNames(
        primaryCall ? 'bottom-[100px]' : 'bottom-0',
        'fixed right-[24px] bg-lightGrayFour border-mediumTeal border-2 rounded-t-lg flex flex-col gap-2 items-stretch z-30',
        minimized ? 'px-[24px] py-[16px]' : 'px-[32px] py-[24px]'
      )}
    >
      <div className={'flex flex-1 flex-row align-center min-w-[264px] justify-between gap-2'}>
        <IconButton
          label={t('Minimize Dialpad')}
          icon={minimized ? 'up' : 'down'}
          size="large"
          onClick={toggleMinimized}
        />
        <div className="flex flex-1 flex-row h-[32px] items-center gap-1">
          {minimized && (
            <>
              <Text privacyLevel="public" variant="label2">
                <T _str="Dialpad" />
              </Text>
              <DialPadIcon size={20} colorOverride="darkGrayOne" />
            </>
          )}
        </div>
        <IconButton label={t('Close Dialpad')} icon="close" size="large" onClick={handleClose} />
      </div>
      {!minimized && (
        <>
          <div className="flex flex-row justify-center min-h-[32px]">
            <TextInput
              name="phoneNumber"
              variant="h3"
              textColor="magenta"
              inputClassName="border-none text-center bg-transparent !font-medium normal-case break-words"
              value={primaryCall ? dialpadNumber : formattedPhoneNumber}
              onChange={handleNumberChange}
            />
          </div>
          <div className="flex flex-row justify-center">
            <TextLink privacyLevel="public" variant="label2" onClick={() => setDialpadNumber('')}>
              <T _str="CLEAR" />
            </TextLink>
          </div>
          <div className="flex flex-row justify-center">
            <NumberButton innerRef={buttonRef} value="1" onClick={onClick} className="pt-[10px] !justify-start" />
            <NumberButton value="2" altValue="ABC" onClick={onClick} />
            <NumberButton value="3" altValue="DEF" onClick={onClick} />
          </div>
          <div className="flex flex-row justify-center">
            <NumberButton value="4" altValue="GHI" onClick={onClick} />
            <NumberButton value="5" altValue="JKL" onClick={onClick} />
            <NumberButton value="6" altValue="MNO" onClick={onClick} />
          </div>
          <div className="flex flex-row justify-center">
            <NumberButton value="7" altValue="PQRS" onClick={onClick} />
            <NumberButton value="8" altValue="TUV" onClick={onClick} />
            <NumberButton value="9" altValue="WXYZ" onClick={onClick} />
          </div>
          <div className="flex flex-row justify-center">
            <NumberButton value="*" onClick={onClick} />
            <NumberButton value="0" altValue="+" onClick={onClick} />
            <NumberButton value="#" onClick={onClick} />
          </div>
          {!primaryCall ? (
            <div className="flex flex-row justify-center">
              <button
                aria-label={t('Call {phoneNumber}', { phoneNumber: cleanPhone(dialpadNumber) })}
                onClick={debounce(() => {
                  setDialpadState('close');
                  connectCall(dialpadNumber);
                  setDialpadNumber('');
                }, 250)}
              >
                <CirclePhoneIcon />
              </button>
            </div>
          ) : (
            !isConferenceAtMaxParticipants && (
              <div className="flex flex-row justify-center">
                <button
                  aria-label={t('Call {phoneNumber}', { phoneNumber: cleanPhone(dialpadNumber) })}
                  onClick={debounce(() => {
                    setDialpadState('close');
                    addToConference(dialpadNumber);
                    setDialpadNumber('');
                  }, 250)}
                >
                  <AddButtonWithText />
                </button>
              </div>
            )
          )}
        </>
      )}
    </div>
  );
};

export default Dialpad;
