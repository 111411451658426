import { yupResolver } from '@hookform/resolvers/yup';
import { T, useT } from '@transifex/react';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { DeepPartial, FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Button, Modal } from '.';

type DuosFormProps<T extends FieldValues> = {
  defaultValues: DeepPartial<T>;
  schema: Yup.ObjectSchema<any>;
  children: (methods: UseFormReturn<T>) => JSX.Element;
};

export const DuosForm = <T extends FieldValues>({ defaultValues, schema, children }: DuosFormProps<T>) => {
  const t = useT();
  const router = useRouter();

  const [targetUrl, setTargetUrl] = useState('');
  const [forceRouting, setForceRouting] = useState(false);

  const handleCloseConfirmModal = () => setTargetUrl('');

  const formResult = useForm<T>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const confirmationMessage = 'Changes you made may not be saved.';
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
    };
    const beforeRouteHandler = (url: string) => {
      if (router.asPath !== url && !forceRouting) {
        setTargetUrl(url);

        router.events.emit('routeChangeError');
        // tslint:disable-next-line: no-string-throw
        throw `Route change to "${url}" was aborted (this error can be safely ignored).`;
      }
    };

    if (formResult.formState.isDirty) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
      router.events.on('routeChangeStart', beforeRouteHandler);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      router.events.off('routeChangeStart', beforeRouteHandler);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      router.events.off('routeChangeStart', beforeRouteHandler);
    };
  }, [formResult.formState.isDirty, forceRouting, router.asPath, router.events]);

  const handleContinueEditing = useCallback(() => {
    setTargetUrl('');
  }, []);

  const handleDiscardChange = useCallback(() => {
    setForceRouting(true);
    router.push(targetUrl);
  }, [router, targetUrl]);

  return (
    <>
      {children(formResult)}
      <Modal
        open={!!targetUrl}
        setClose={handleCloseConfirmModal}
        title={t("You haven't saved your changes.\nAre you sure you want to leave this page?")}
        titleClassName="whitespace-pre-wrap"
      >
        <div className="mt-2 flex flex-col items-center gap-6">
          <Button label={t('Continue Editing')} onClick={handleContinueEditing}>
            <T _str="Continue Editing" />
          </Button>
          <Button label={t('Continue with Unsaved Changes')} onClick={handleDiscardChange}>
            <T _str="Continue with Unsaved Changes" />
          </Button>
        </div>
      </Modal>
    </>
  );
};
