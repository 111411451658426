import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const MenuHamburger = ({ size = 24, colorOverride, hoverColorOverride, active = true }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const groupHoverClass = active ? toGroupHoverFillColor(hoverColorOverride) : '';
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 6.375C0 5.61561 0.716344 5 1.6 5H30.4C31.2837 5 32 5.61561 32 6.375C32 7.13439 31.2837 7.75 30.4 7.75H1.6C0.716344 7.75 0 7.13439 0 6.375Z"
          className={classNames(fillClass, groupHoverClass)}
        />
        <path
          d="M0 16C0 15.2406 0.716344 14.625 1.6 14.625H30.4C31.2837 14.625 32 15.2406 32 16C32 16.7594 31.2837 17.375 30.4 17.375H1.6C0.716344 17.375 0 16.7594 0 16Z"
          className={classNames(fillClass, groupHoverClass)}
        />
        <path
          d="M1.6 24.25C0.716344 24.25 0 24.8656 0 25.625C0 26.3844 0.716344 27 1.6 27H30.4C31.2837 27 32 26.3844 32 25.625C32 24.8656 31.2837 24.25 30.4 24.25H1.6Z"
          className={classNames(fillClass, groupHoverClass)}
        />
      </svg>
    </>
  );
};

export default MenuHamburger;
