/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { T, useT } from '@transifex/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Text } from '../..';
import { useLogout } from '../../../../hooks/api';
import { useAuthenticatedContext } from '../../../../hooks/contexts';
import {
  MEMBER_ACTIVATE_SURVEY,
  MEMBER_CHAT,
  MEMBER_HELP,
  MEMBER_HOME,
  MEMBER_LOGIN,
  MEMBER_RECOMMENDATIONS,
  MEMBER_RESOLVED_RECOMMENDATIONS,
  MEMBER_SAVED,
} from '../../../../routes';
import { IconType } from '../../../../types/ui';
import { CloseIcon } from '../../../icons';
import Logo from '../../../icons/Logo';
import ImpersonationBanner from '../../ImpersonationBanner';
import MenuItem from './MenuItem';

export type MenuType = 'loggedIn' | 'loggedOut';

type MenuItem = {
  label: string;
  icon?: IconType;
  showIcon?: boolean;
  route?: string;
  onClick?: () => void;
  subMenu?: MenuItem[];
};

type MainMenuProps = {
  type?: MenuType;
  open: boolean;
  setClose: () => void;
  currentLocale?: string;
  switchLocale?: () => void;
};

const MainMenu = ({ type = 'loggedIn', open, setClose, currentLocale, switchLocale }: MainMenuProps) => {
  const t = useT();

  const desktopOverlayClass =
    'fixed top-0 left-0 right-0 buttom-0 bg-[rgba(21,24,31,0.5)] h-screen w-screen transform transition-all duration-200 ease-in-out overflow-y-auto tablet:overflow-y-hidden';
  const desktopOverlayAnimationClass = open
    ? 'tablet:translate-x-0 opacity-100 z-50'
    : 'tablet:-translate-x-2 opacity-0 -z-10';
  const mobileOverlayAnimationClass = open ? 'translate-y-0' : '-translate-y-2';
  const headerClass = 'flex flex-col justify-start items-stretch bg-white h-[72px]';
  const desktopSideBarClass = 'tablet:w-[400px] tablet:h-screen';
  const menuContentClass = 'flex-1 flex flex-row items-stretch h-screen';
  const mobileTopBarClass = 'flex flex-col justify-start items-stretch tablet:bg-white w-screen';
  const { mutateAsync: logMemberOut } = useLogout();
  const router = useRouter();
  const [submenuClicked, setSubmenuClicked] = useState(false);
  const handleMenuItemClick = useCallback(() => {
    if (submenuClicked) {
      setClose();
    }
    setSubmenuClicked(false);
  }, [setClose, submenuClicked]);

  const handleLogout = useCallback(async () => {
    try {
      await logMemberOut();
    } catch (e) {}
    router.push(`${MEMBER_LOGIN}?logout=true`);
  }, [logMemberOut, router]);

  useEffect(() => {
    if (open) {
      document.body.className = 'overflow-hidden h-screen';
    } else {
      document.body.className = '';
    }
  });

  const menuItems: {
    label: string;
    icon?: IconType;
    route?: string;
    onClick?: () => void;
    subMenuItems?: { label: string; route: string }[];
  }[] = useMemo(() => {
    return type === 'loggedIn'
      ? [
          { label: t('Home'), icon: 'menuHome', route: MEMBER_HOME },
          { label: t('Digital Duo'), icon: 'digitalDuo', route: MEMBER_CHAT },
          { label: t('Saved Resources'), icon: 'menuBookmark', route: MEMBER_SAVED },
          {
            label: t('Recommended'),
            icon: 'menuRecs',
            subMenuItems: [
              { label: t('All Recommendations'), route: MEMBER_RECOMMENDATIONS },
              { label: t('Resolved'), route: MEMBER_RESOLVED_RECOMMENDATIONS },
            ],
          },
          { label: t('Help'), icon: 'menuNewHelp', route: MEMBER_HELP },
          { label: t('Log Out'), icon: 'menuLogout', onClick: handleLogout },
        ]
      : [
          { label: t('Sign Up'), route: `${MEMBER_ACTIVATE_SURVEY}` },
          { label: t('Log In'), icon: 'menuLogin', route: MEMBER_LOGIN },
          {
            label: currentLocale === 'en' ? 'ESP' : 'EN',
            icon: 'globe',
            onClick: () => {
              if (switchLocale) {
                switchLocale();
                setClose();
              }
            },
          },
        ];
  }, [handleLogout, t, type]);
  const isModulesFeatureEnabled = process.env.NEXT_PUBLIC_FF_MODULE_HOME_PAGE === 'true';
  const menuItemSection = useMemo(() => {
    const currentRoute = router.asPath;
    // Filter out the 'Recommended' menu item if the feature flag is enabled
    const filteredMenuItems = isModulesFeatureEnabled
      ? menuItems.filter((item) => item.label !== t('Recommended'))
      : menuItems;

    return filteredMenuItems.map(({ label, icon, route, onClick, subMenuItems }) => {
      // Menu section should be active if any of its sub menu page is active
      const subMenuRoutes = subMenuItems?.map((subItem) => subItem.route) ?? [];
      const sectionRoutes = [route, ...subMenuRoutes].filter((route) => !!route);
      const selected = sectionRoutes.includes(currentRoute);

      let onClickFunc = onClick;
      if (route) onClickFunc = onClick ? onClick : () => router.push(route);

      const renderedSubMenuItems = subMenuItems?.map(({ label, route }) => ({
        label,
        route,
        selected: currentRoute === route,
      }));

      return (
        <MenuItem
          key={label}
          label={label}
          icon={icon}
          onClick={() => {
            setSubmenuClicked(!!subMenuItems);
            onClickFunc && onClickFunc();
          }}
          selected={selected}
          subMenuItems={renderedSubMenuItems}
        />
      );
    });
  }, [menuItems, router, isModulesFeatureEnabled, t]);

  const { userSession } = useAuthenticatedContext();

  const isImpersonating = userSession?.impersonator !== undefined;

  return (
    <div
      className={classNames(desktopOverlayClass, desktopOverlayAnimationClass, mobileOverlayAnimationClass)}
      aria-label={t('Click backdrop to close Menu')}
      onClick={handleMenuItemClick}
    >
      {isImpersonating && <ImpersonationBanner userSession={userSession} />}
      <div className={classNames(headerClass)}>
        <div className="h-[72px] flex flex-row justify-between items-center desktop:px-[184px] px-[24px] py-[16px] border-b-[1px] border-mediumGrayOne">
          <div className="w-[80px] justify-start">
            <button
              aria-label={t('Close Main Menu')}
              className="justify-self-start group flex flex-col items-center"
              onClick={setClose}
            >
              <CloseIcon size={32} colorOverride="purple" hoverColorOverride="magenta" />
              <Text variant="subtitle-2" color="purple" className="group-hover:text-magenta">
                <T _str="Close" />
              </Text>
            </button>
          </div>
          <Logo />
          <div className="w-[80px] justify-end"></div>
        </div>
      </div>
      <div className={classNames(menuContentClass)}>
        <div className={classNames(desktopSideBarClass, mobileTopBarClass)}>
          {menuItemSection}
          <div className="flex-1 opacity-0" aria-label={t('Click backdrop to close Menu')} onClick={setClose} />
        </div>
        <div
          className="flex-1 invisible tablet:visible"
          aria-label={t('Click backdrop to close Menu')}
          onClick={setClose}
        />
      </div>
    </div>
  );
};

export default MainMenu;
