import { IconProps } from '../../types/ui';

export const DuosIcon = ({ size = 24 }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M4.63174 4.63003C5.71279 3.54877 5.71279 1.88026 4.63174 0.808313C3.55069 -0.263633 1.88252 -0.272954 0.810787 0.808313C-0.270262 1.88958 -0.270262 3.55809 0.810787 4.63003C1.89184 5.70198 3.55069 5.7113 4.63174 4.63003Z"
        fill="#AC3EA4"
      />
      <path
        d="M23.1962 19.3665C22.1151 18.2852 20.447 18.2852 19.3752 19.3665C18.2942 20.4478 18.2942 22.1163 19.3752 23.1882C20.4563 24.2695 22.1245 24.2695 23.1962 23.1882C24.2679 22.1163 24.2679 20.4478 23.1962 19.3665Z"
        fill="#4F196F"
      />
      <path
        d="M6.96017 17.9887C7.49137 17.6811 7.86415 17.1871 8.02258 16.5999C8.15305 16.0965 8.15305 15.5372 7.92006 15.1085C7.38886 14.2416 7.09064 13.1883 7.09064 12.0511C7.09064 9.19878 9.11295 6.79389 11.9833 6.79389C13.3346 6.79389 14.4902 7.33452 15.3476 8.2014C15.4874 7.35316 16.0745 6.58882 16.8853 6.11344C17.547 5.73126 18.3019 5.61009 19.0008 5.71262C17.2581 3.87633 14.7605 2.73914 11.974 2.73914C6.4942 2.73914 2.47754 6.92439 2.47754 12.5078C2.47754 14.4653 3.11126 16.1804 4.15503 17.5786C4.79807 18.3523 6.02823 18.5294 6.96017 17.9887Z"
        fill="#AC3EA4"
      />
      <path
        d="M21.5088 11.9753C21.5088 10.4186 21.1174 8.96449 20.4278 7.68748C19.8034 6.65282 18.4241 6.35454 17.371 6.96042C16.4298 7.50105 15.9638 8.62893 16.3273 9.60766C16.7 10.372 16.9051 11.2296 16.9051 12.1337C16.9051 15.0047 14.9107 17.4189 12.059 17.4189C10.8847 17.4189 9.86892 17.0367 9.05813 16.4029C9.03949 16.552 9.01153 16.7105 8.96494 16.8596C8.74127 17.6985 8.20075 18.4069 7.44588 18.8357C6.93331 19.134 6.37415 19.2738 5.8243 19.2738C5.81498 19.2738 5.81498 19.2738 5.80566 19.2738C7.56703 20.672 9.85028 21.4643 12.2081 21.4643C17.371 21.455 21.5088 17.3164 21.5088 11.9753Z"
        fill="#4F196F"
      />
    </svg>
  );
};
