import classNames from 'classnames';

import { toFillColor, toStrokeColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const SharedIcon = ({ size = 16, colorOverride, strokeColorOverride, className }: IconProps) => {
  const fillClassName = toFillColor(colorOverride);
  const strokeClassName = toStrokeColor(strokeColorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" className={classNames('group', className)}>
      <path
        d="M5.33333 8.66536C3.12419 8.66536 1.33333 10.4562 1.33333 12.6654V13.332C1.33333 13.7002 1.03486 13.9987 0.666667 13.9987C0.298477 13.9987 0 13.7002 0 13.332V12.6654C0 9.71985 2.38781 7.33203 5.33333 7.33203C8.27885 7.33203 10.6667 9.71985 10.6667 12.6654V13.332C10.6667 13.7002 10.3682 13.9987 10 13.9987C9.63181 13.9987 9.33333 13.7002 9.33333 13.332V12.6654C9.33333 10.4562 7.54247 8.66536 5.33333 8.66536Z"
        strokeWidth="0.5"
        className={classNames(fillClassName, strokeClassName)}
      />
      <path
        d="M12 6.66536C10.5272 6.66536 9.33333 7.85927 9.33333 9.33203H8C8 7.12289 9.79086 5.33203 12 5.33203C14.2091 5.33203 16 7.12289 16 9.33203V9.66536C16 10.0336 15.7015 10.332 15.3333 10.332C14.9651 10.332 14.6667 10.0336 14.6667 9.66536V9.33203C14.6667 7.85927 13.4728 6.66536 12 6.66536Z"
        className={classNames(fillClassName)}
      />
      <path
        d="M5.33333 3.33333C4.22876 3.33333 3.33333 4.22876 3.33333 5.33333C3.33333 6.4379 4.22876 7.33333 5.33333 7.33333C6.4379 7.33333 7.33333 6.4379 7.33333 5.33333C7.33333 4.22876 6.4379 3.33333 5.33333 3.33333ZM2 5.33333C2 3.49238 3.49238 2 5.33333 2C7.17428 2 8.66667 3.49238 8.66667 5.33333C8.66667 7.17428 7.17428 8.66667 5.33333 8.66667C3.49238 8.66667 2 7.17428 2 5.33333Z"
        strokeWidth="0.5"
        className={classNames(fillClassName, strokeClassName)}
      />
      <path
        d="M11.9997 2.66536C11.2633 2.66536 10.6663 3.26232 10.6663 3.9987C10.6663 4.73508 11.2633 5.33203 11.9997 5.33203C12.7361 5.33203 13.333 4.73508 13.333 3.9987C13.333 3.26232 12.7361 2.66536 11.9997 2.66536ZM9.33301 3.9987C9.33301 2.52594 10.5269 1.33203 11.9997 1.33203C13.4724 1.33203 14.6663 2.52594 14.6663 3.9987C14.6663 5.47146 13.4724 6.66536 11.9997 6.66536C10.5269 6.66536 9.33301 5.47146 9.33301 3.9987Z"
        strokeWidth="0.5"
        className={classNames(fillClassName, strokeClassName)}
      />
    </svg>
  );
};

export default SharedIcon;
