import { useT } from '@transifex/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

import { Text } from '../..';

type SubMenuItemProps = {
  label: string;
  route: string;
  isSelected?: boolean;
};

const SubMenuItem = ({ label, route, isSelected = false }: SubMenuItemProps) => {
  const t = useT();
  const router = useRouter();
  return (
    <button
      aria-label={t('Navigate to {label} page', { label })}
      className={classNames(
        'group h-[64px] flex flex-row justify-start items-start px-10 py-3 gap-1',
        isSelected ? 'bg-lightGrayFour' : 'bg-white'
      )}
      onClick={() => router.push(route)}
    >
      <Text
        variant="nav-4"
        color={isSelected ? 'magenta' : 'darkGrayOne'}
        className={isSelected ? '' : 'group-hover:text-magenta hover:no-underline'}
      >
        {label}
      </Text>
    </button>
  );
};

export default SubMenuItem;
