import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor, toGroupHoverStrokeColor, toStrokeColor } from '../../theme/colors';
import { ColorType, IconProps } from '../../types/ui';

const PhoneWithRingIcon = ({
  size = 64,
  colorOverride = 'purple',
  hoverColorOverride = 'white',
  className,
  fillColorOverride = 'white',
  hoverFillColorOverride = 'purple',
}: IconProps & { fillColorOverride?: ColorType; hoverFillColorOverride?: ColorType }) => {
  const colorClass = toFillColor(fillColorOverride);
  const strokeClass = toStrokeColor(colorOverride);
  const groupHoverClass = toGroupHoverStrokeColor(hoverColorOverride);
  const groupHoverFillClass = toGroupHoverFillColor(hoverFillColorOverride);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, colorClass, groupHoverClass, groupHoverFillClass, 'group')}
    >
      <path
        d="M15.125 2.625C18.875 2.625 21.875 5.625 21.875 9.375M15.125 6.375C16.625 6.375 18.125 7.875 18.125 9.375M3.125 2.625C3.125 15.375 9.125 21.375 21.875 21.375V15.375L16.625 13.875L15.125 16.125C12.125 16.125 8.375 12.375 8.375 9.375L10.625 7.875L9.125 2.625H3.125Z"
        className={classNames(colorClass, strokeClass, groupHoverClass, groupHoverFillClass)}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneWithRingIcon;
