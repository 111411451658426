import React from 'react';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const PencilIcon = ({ size = 20, colorOverride }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
      <path
        className={fillClass}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6715 0.297691L19.7023 5.32852C20.0992 5.72544 20.0992 6.36898 19.7023 6.7659L7.06359 19.4046C6.68237 19.7858 6.16533 20 5.62621 20H1.01638C0.455049 20 0 19.5449 0 18.9836V14.3738C0 13.8347 0.214164 13.3176 0.595382 12.9364L13.2341 0.297691C13.631 -0.0992304 14.2746 -0.0992303 14.6715 0.297691ZM16.1089 7.48459L17.5462 6.04721L13.9528 2.45376L12.5154 3.89114L16.1089 7.48459ZM5.58384 17.9672L2.03276 14.4162L2.03276 17.9672H5.58384Z"
        fill="#AC3EA4"
      />
    </svg>
  );
};

export default PencilIcon;
