import { Call } from '@twilio/voice-sdk';

import { RegistrationResponse } from '../hooks/api';
import {
  Account,
  AccountSearchPayload,
  CreateMemberAccountRequest,
  SearchAccountResponse,
  UpdateAssignmentPayload,
  UpdateAssignmentResponse,
} from '../types/Account';
import { AnalyticsEvent } from '../types/Analytics';
import {
  AWVFeedbackRequestBody,
  AWVResource,
  AWVResourcesSearchParams,
  MemberAttestationCreate,
  MemberAttestationUpdate,
} from '../types/AnnualWellness';
import { PasswordLoginParams, ResetPasswordLoginParams, SetPasswordLoginParams } from '../types/Auth';
import { SubmitAuthChallengeParams } from '../types/AuthChallenge';
import { CalendarEvent } from '../types/CalendarEvent';
import { CallLogResponse, CallRecording, LegacyCallResponse, VoiceConference } from '../types/Call';
import { Caregiver } from '../types/Caregiver';
import { GetClientsResponse } from '../types/Client';
import { ClientConfig, ClientConfigV2 } from '../types/ClientConfig';
import { SubmitClientErrorBody } from '../types/ClientError';
import { PaginatedResults, PaginationParam } from '../types/Common';
import { Conversation, MessageContent } from '../types/Conversation';
import {
  ConfirmEligibilityDOBParams,
  ConfirmEligibilityHIDParams,
  EligibilityRecordSummary,
} from '../types/EligibilityRecord';
import { CreateAccountParams } from '../types/ExternalMemberAccount';
import { FetchPromptsParams, FetchPromptsResponse, LogPromptParams, UserPrompt } from '../types/Ftue';
import { IncentiveFaq } from '../types/Incentive';
import { MagicLinkLoginParams } from '../types/MagicLink';
import { CreateMarketingTrackingEventRequest } from '../types/Marketing';
import { IMemberTag, ManagedMember, Member, MemberJourneyStatus } from '../types/Member';
import { Module, ModuleContentResponse } from '../types/Module';
import { MemberNeedResolutionState, MemberNeedState, MemberNeedView, UpdateMemberNeed } from '../types/Need';
import { PaginatedPlans, PlanSearchParams } from '../types/Plan';
import { ResourcesReferralResponse, TrackingRequest, UpdateResourceReferralRequest } from '../types/Referral';
import { ExternalMemberRegistrationRequest } from '../types/Register';
import { Relationship } from '../types/Relationship';
import {
  BenefitWithPlan,
  DuoCaremanagerResourceFeedbackRequest,
  EmailMemberResourceInfoRequestBase,
  EmailResourceInfoRequest,
  MemberResources,
  NeedAssociatedResourceSearch,
  Resource,
  ResourceFeedbackRequest,
  ResourceSearchRequest,
  SaveRatingRequest,
  SaveUserResourceRequestBase,
  SavedUserResourceMetadata,
} from '../types/Resources';
import {
  CreateServiceRequestBody,
  FinalMileServiceRequest,
  GetServiceRequestParams,
  PromoteServiceRequestBody,
} from '../types/ServiceRequest';
import { UserSessionResponse } from '../types/UserSession';
import { checkStatus, deleteJson, getJson, postJson, putJson } from './common';
import { BadRequest, NotFound } from './errors';

export function getEligibilityRecordSummary(eligibilityRecordId: string): Promise<EligibilityRecordSummary> {
  return getJson(`/v1/rest/eligibility/${eligibilityRecordId}`);
}

export function getEligibilityRecordSummaryByShortCode(shortCode: string): Promise<EligibilityRecordSummary> {
  return getJson(`/v1/rest/eligibility/code/${shortCode}`);
}

export function createExtMemberAccount(params: CreateAccountParams): Promise<void> {
  return postJson('/v1/rest/account', params, true);
}
export function confirmEligibilityDOB(params: ConfirmEligibilityDOBParams): Promise<void> {
  return postJson('/v1/rest/register', params, true);
}
export function confirmEligibilityHealthId(params: ConfirmEligibilityHIDParams): Promise<void> {
  return postJson('/v1/rest/register', params, true);
}

/**
 * Submit a request to login via magic link
 */
export function magicLinkLogin(params: MagicLinkLoginParams): Promise<void> {
  return postJson('/v1/auth/magicLink', params, true);
}

/**
 * Submit a request to login with password
 */
export function passwordLogin(params: PasswordLoginParams): Promise<void> {
  return postJson('/v1/auth/login', params, true);
}

/**
 * Submit a request to update password
 */
export function setPasswordLogin(params: SetPasswordLoginParams): Promise<void> {
  return postJson('/v1/auth/setPasswordLogin', params, true);
}

/**
 * Submit a request to reset password and get token
 */
export function resetPasswordLogin(params: ResetPasswordLoginParams): Promise<void> {
  return postJson('/v1/auth/resetPasswordLogin', params, true);
}

export function logout(): Promise<void> {
  return postJson('/v1/auth/logout', {}, true);
}

/**
 * Answer an authentication challenge as part of login
 */
export function submitAuthChallenge(params: SubmitAuthChallengeParams): Promise<undefined> {
  return postJson('/v1/auth/challenge', params, true);
}

export function submitActivateAccount(code: string): Promise<undefined> {
  return postJson(`/v1/auth/activate/${code}`, {}, true);
}

export function getUserSession(): Promise<UserSessionResponse> {
  return getJson('/v1/admin/member/session', 'member', undefined, true);
}

export function getUserSessionWithCookie(cookie: string): Promise<UserSessionResponse> {
  return getJson('/v1/admin/member/session', 'member', cookie);
}

export function getUserEvents({ userId }: { userId: string }): Promise<any> {
  return getJson(`/v1/admin/member/events?userId=${userId}`);
}

export function getMemberById(memberId: string): Promise<Member> {
  return getJson(`/v1/admin/members/${memberId}`);
}

export function getCaregiversById(memberId: string): Promise<Relationship[]> {
  return getJson(`/v1/admin/members/${memberId}/caregivers`);
}

export function getMembersById(memberId: string): Promise<Relationship[]> {
  return getJson(`/v1/admin/members/${memberId}/members`);
}

export function getMemberByAccount(): Promise<Member> {
  return getJson(`/v1/admin/member/current`);
}

export function getCaregiverById(caregiverId: string): Promise<Caregiver> {
  return getJson(`/v1/admin/caregivers/${caregiverId}`);
}

export function getClients(): Promise<GetClientsResponse | undefined> {
  return postJson<GetClientsResponse, { formType: string }>('/v1/admin/member/resource/client/form/search', {
    formType: 'search',
  });
}

export function getMembers({ limit, offset }: PaginationParam): Promise<{ data: Member[] }> {
  return getJson<{ data: Member[] }>(`/v1/admin/members?limit=${limit}&offset=${offset}`);
}

export function getMemberCards({ limit, offset }: PaginationParam): Promise<{ data: Member[] }> {
  return getJson<{ data: Member[] }>(`/v1/admin/members/cards?limit=${limit}&offset=${offset}`);
}

export function getCurrentUserAssignedMembers(
  memberJourneyStatusList: MemberJourneyStatus[]
): Promise<{ data: ManagedMember[] }> {
  return getJson<{ data: ManagedMember[] }>(
    `/v1/admin/users/current/assignedMembers?memberJourneyStatusList=${memberJourneyStatusList}`
  );
}

export function getDefaultTags(): Promise<IMemberTag[]> {
  return getJson<IMemberTag[]>(`/v1/admin/tags`);
}

export function addMemberTags(userId: string, tagNames: string[]): Promise<IMemberTag[] | undefined> {
  return postJson<IMemberTag[], string[]>(`/v1/admin/members/${userId}/tags`, tagNames);
}

export function deleteMemberTag(userId: string, tagName: string): Promise<void> {
  return deleteJson<void>(`/v1/admin/members/${userId}/tags/${tagName}`);
}

export function generateVoiceToken(): Promise<{ token: string }> {
  return getJson<{ token: string }>('/v1/admin/twilio/voiceToken');
}

export function updateMember(userId: string, payload: Partial<Member>): Promise<Member | undefined> {
  return putJson<Member, Partial<Member>>(`/v1/admin/members/${userId}`, payload);
}

export function getWelcomeCall(userId: string): Promise<CalendarEvent | null> {
  return getJson<CalendarEvent>(`/v1/admin/calendarEvent/event/welcomeCallEvent/${userId}`).catch((error) => {
    if (!(error instanceof NotFound)) {
      throw error;
    } else {
      return null;
    }
  });
}

export function getConference(twilioCallId: string): Promise<VoiceConference> {
  return getJson<any>(`/v1/admin/twilio/call/${twilioCallId}/conference`);
}

// This call is only used to remove a single user from a conference call.
export function removeFromConferenceCall(twilioCallId: string): Promise<Call | undefined> {
  return postJson<Call, any>(`/v1/admin/twilio/call/${twilioCallId}/hangup`);
}

export function joinConferenceCall(twilioConferenceId: string, phoneNumber: string): Promise<string | undefined> {
  return postJson<string, any>(`/v1/admin/twilio/conference/${twilioConferenceId}/join`, {
    toPhoneNumber: phoneNumber,
  });
}

export function getLegacyCallsForMember({
  memberId,
  limit = 10,
  offset = 0,
}: {
  memberId: string;
  limit?: number;
  offset?: number;
}): Promise<LegacyCallResponse> {
  return getJson<LegacyCallResponse>(`/v1/admin/twilio/calls/log/legacy/${memberId}?limit=${limit}&offset=${offset}`);
}

export function getCallsForMember({
  memberId,
  limit = 10,
  offset = 0,
}: {
  memberId: string;
  limit?: number;
  offset?: number;
}): Promise<CallLogResponse> {
  return getJson<CallLogResponse>(`/v1/admin/twilio/calls/log/${memberId}?limit=${limit}&offset=${offset}`);
}

export function getCallRecording({ recordingId }: { recordingId: string }): Promise<CallRecording | null> {
  return getJson<CallRecording>(`
  /v1/admin/twilio/recording/${recordingId}/mediaUrl`).catch((error) => {
    if (!(error instanceof NotFound)) {
      throw error;
    } else {
      return null;
    }
  });
}

export function searchAccounts(payload: AccountSearchPayload): Promise<SearchAccountResponse | undefined> {
  return postJson<SearchAccountResponse, AccountSearchPayload>(`/v1/admin/account/search`, { ...payload });
}

export function updateDuoAssigned(payload: UpdateAssignmentPayload): Promise<UpdateAssignmentResponse | undefined> {
  return postJson<UpdateAssignmentResponse, UpdateAssignmentPayload>(`/v1/admin/memberAssignments`, { ...payload });
}

export function getClientConfig(): Promise<ClientConfig> {
  return getJson<ClientConfig>(`/v1/external/config`);
}

export function createReferralByTrackingInfo({
  payload,
}: {
  payload: TrackingRequest;
}): Promise<ResourcesReferralResponse | undefined> {
  return postJson<ResourcesReferralResponse, TrackingRequest>(`/v1/admin/member/resources/referral`, { ...payload });
}

export function updateResourceReferralStatus(
  referralId: string,
  payload: UpdateResourceReferralRequest
): Promise<void> {
  return putJson<void, UpdateResourceReferralRequest>(
    `/v1/admin/member/resources/referral/${referralId}/status`,
    payload,
    true
  );
}

// Returns the Attestation UUID
export function createMemberAttestation(payload: MemberAttestationCreate): Promise<string | undefined> {
  return postJson<string, MemberAttestationCreate>(`/v1/rest/attestation`, payload);
}

export function updateMemberAttestation(
  attestationId: string,
  payload: MemberAttestationUpdate
): Promise<string | undefined> {
  return putJson<string, MemberAttestationUpdate>(`/v1/rest/attestation/${attestationId}`, payload);
}

export function getAWVResources({ resourceType, memberId }: AWVResourcesSearchParams): Promise<AWVResource[]> {
  const params = new URLSearchParams();
  memberId && params.append('memberId', memberId);
  resourceType && params.append('resourceType', resourceType);
  return getJson<AWVResource[]>(`/v1/rest/awvResources?${params.toString()}`);
}

export function createAWVFeedback(payload: AWVFeedbackRequestBody): Promise<string | undefined> {
  return postJson<string, AWVFeedbackRequestBody>(`/v1/rest/awvFeedback`, payload);
}

export function searchResources(payload: ResourceSearchRequest): Promise<MemberResources | undefined> {
  return postJson<MemberResources, ResourceSearchRequest>(`/v1/admin/resources/search`, payload);
}

export function searchResourcesCurrentUser(
  payload: NeedAssociatedResourceSearch
): Promise<MemberResources | undefined> {
  return postJson<MemberResources, NeedAssociatedResourceSearch>(`/v1/rest/resources/search`, payload);
}

export function emailResourceInfo({ payload }: { payload: EmailResourceInfoRequest }): Promise<string | undefined> {
  return postJson<string, EmailResourceInfoRequest>(`/v1/admin/member/resources/emailInfo`, payload);
}

export function emailCurrentMemberResourceInfo(
  payload: EmailMemberResourceInfoRequestBase
): Promise<string | undefined> {
  return postJson<string, EmailMemberResourceInfoRequestBase>(
    `/v1/admin/users/current/resources/emailInfo`,
    payload,
    true
  );
}

export function getMemberAccount(memberId: string): Promise<Account | undefined> {
  return getJson<Account>(`/v1/admin/account/member/${memberId}`);
}
export function createMemberAccount(payload: CreateMemberAccountRequest): Promise<Account | undefined> {
  return postJson<Account, CreateMemberAccountRequest>(`/v1/admin/account`, payload);
}

export function getPlansByZipcode({ zipcode }: PlanSearchParams): Promise<PaginatedPlans> {
  return getJson<PaginatedPlans>(`/v1/admin/member/plans/${zipcode}`);
}

export type CreateConversationPayload = { flowName: 'member_chatflow' };

export function createConversation(payload: CreateConversationPayload): Promise<Conversation | undefined> {
  return postJson<Conversation, CreateConversationPayload>('/v1/admin/conversations', payload);
}

export type SendMessagePayload = { message: MessageContent };

export function sendMessage({
  conversationId,
  payload,
  displayFtue,
}: {
  conversationId: string;
  payload: SendMessagePayload;
  displayFtue?: boolean;
}): Promise<Conversation | undefined> {
  const params = new URLSearchParams();
  if (displayFtue !== undefined) params.append('displayFtue', displayFtue.toString());
  return postJson<Conversation, SendMessagePayload>(
    `/v1/admin/conversations/${conversationId}/message?${params.toString()}`,
    payload
  );
}

export function getConversations({ accountId }: { accountId?: string }): Promise<Conversation[]> {
  return getJson<Conversation[]>(`/v1/admin/conversations?accountId=${accountId}`);
}

export function getConversation({ conversationId }: { conversationId?: string }): Promise<Conversation> {
  return getJson<Conversation>(`/v1/admin/conversations/${conversationId}`);
}

export function getSavedUserResources(): Promise<MemberResources | undefined> {
  return getJson<MemberResources>('/v1/admin/users/current/savedResources');
}

export function getSavedMemberResourcesAdmin(memberId: string): Promise<MemberResources | undefined> {
  return getJson<MemberResources>(`/v1/admin/members/${memberId}/savedResources`);
}

export function getSharedResources(): Promise<MemberResources | undefined> {
  return getJson<MemberResources>('/v1/admin/users/current/sharedResources');
}

export function submitResourceRating(payload: SaveRatingRequest): Promise<void> {
  return postJson(`/v1/rest/members/current/ratedResources`, payload, true);
}

export function submitResourceRatingAdmin(memberId: string, payload: SaveRatingRequest): Promise<void> {
  return postJson(`/v1/admin/members/${memberId}/ratedResources`, payload, true);
}

export function saveUserResource(payload: SaveUserResourceRequestBase): Promise<SavedUserResourceMetadata | undefined> {
  return postJson<SavedUserResourceMetadata, SaveUserResourceRequestBase>(
    '/v1/admin/users/current/savedResources',
    payload
  );
}

export function saveUserResourceAdmin(
  memberId: string,
  payload: SaveUserResourceRequestBase
): Promise<SavedUserResourceMetadata | undefined> {
  return postJson<SavedUserResourceMetadata, SaveUserResourceRequestBase>(
    `/v1/admin/members/${memberId}/savedResources`,
    payload
  );
}

export function deleteSavedUserResource(savedResourceId: string): Promise<void> {
  return deleteJson<void>(`/v1/admin/users/current/savedResources/${savedResourceId}`, true);
}

export function deleteSavedUserResourceAdmin(memberId: string, savedResourceId: string): Promise<void> {
  return deleteJson<void>(`/v1/admin/members/${memberId}/savedResources/${savedResourceId}`, true);
}

export function createResourceFeedbackForCurrentMember(payload: ResourceFeedbackRequest): Promise<void> {
  return postJson<void, ResourceFeedbackRequest>('/v1/admin/users/current/resources/feedback', payload, true);
}

export function createResourceFeedback(payload: DuoCaremanagerResourceFeedbackRequest): Promise<void> {
  return postJson<void, DuoCaremanagerResourceFeedbackRequest>(`/v1/admin/resources/feedback`, payload, true);
}

export function getResourceReferrals(): Promise<any> {
  return getJson('/v1/admin/member/resources/referrals');
}

export function registerMemberExternal(
  payload: ExternalMemberRegistrationRequest
): Promise<RegistrationResponse | undefined> {
  return postJson<RegistrationResponse, ExternalMemberRegistrationRequest>(
    '/v1/rest/register',
    payload,
    false,
    'member',
    async (res) => {
      if (res.status !== 400) {
        checkStatus(res);
        return;
      }
      const result = await res.json();
      throw new BadRequest(result);
    }
  );
}

export function createMarketingEvent(payload: CreateMarketingTrackingEventRequest): Promise<void> {
  return postJson<void, CreateMarketingTrackingEventRequest>('/v1/rest/marketingEvent', payload, true);
}

export function createAuthdAnalyticsEvents(payload: AnalyticsEvent[]): Promise<void> {
  let fullstorySessionId = window.FS?.getCurrentSession?.();
  payload = payload.map((e) => {
    return { ...e, fullstorySessionId: fullstorySessionId };
  });
  return postJson<void, AnalyticsEvent[]>('/v1/admin/analyticsEvents', payload, true);
}

export function createUnauthdAnalyticsEvents(payload: AnalyticsEvent[]): Promise<void> {
  let fullstorySessionId = window.FS?.getCurrentSession?.();
  payload = payload.map((e) => {
    return { ...e, fullstorySessionId: fullstorySessionId };
  });
  return postJson<void, AnalyticsEvent[]>('/v1/rest/analyticsEvents', payload, true);
}

export function getHasAccountForRecord(eligibilityRecordId: string): Promise<boolean> {
  return getJson(`/v1/rest/eligibility/${eligibilityRecordId}/active`);
}

export type GetMemberNeedsOpts = {
  memberId?: string;
  limit?: number;
  offset?: number;
  state?: MemberNeedState;
  resolutionStatus?: MemberNeedResolutionState;
};

export function getMemberNeeds({
  limit,
  offset,
  state,
  resolutionStatus,
}: GetMemberNeedsOpts): Promise<PaginatedResults<MemberNeedView>> {
  const queryParams = new URLSearchParams();

  limit && queryParams.append('limit', limit.toString());
  offset && queryParams.append('offset', offset.toString());
  state && queryParams.append('state', state);
  resolutionStatus && queryParams.append('resolutionStatus', resolutionStatus);

  return getJson(`/v1/rest/members/current/memberNeeds?${queryParams.toString()}`);
}

export function getMemberNeed({ memberNeedId }: { memberNeedId: string }): Promise<MemberNeedView | undefined> {
  return getJson(`/v1/rest/members/current/memberNeeds/${memberNeedId}`);
}

export function updateMemberNeed({ payload }: { payload: UpdateMemberNeed }): Promise<MemberNeedView | undefined> {
  return putJson(`/v1/rest/members/current/memberNeeds/${payload.id}`, { ...payload, id: undefined });
}

export function updateExternalMemberNeed({ payload }: { payload: UpdateMemberNeed }): Promise<string | undefined> {
  return putJson(`/v1/rest/memberNeeds/${payload.id}`, { ...payload, id: undefined });
}

export function getAccountClientConfig(): Promise<ClientConfigV2> {
  return getJson('/v1/admin/account/configs');
}

export function impersonateAccount(accountId: string): Promise<Account | undefined> {
  return postJson(`/v1/admin/account/${accountId}/impersonate`, {}, false);
}

export function unimpersonateAccount(): Promise<void> {
  return postJson(`/v1/rest/account/unimpersonate`, {}, true);
}

export function getMemberIncentiveFaqsById(incentiveId: string): Promise<IncentiveFaq[]> {
  return getJson(`/v1/rest/members/current/incentives/${incentiveId}/faqs`);
}

export function adminGetMemberIncentiveFaqsById(id: string): Promise<IncentiveFaq[]> {
  return getJson(`/v1/admin/incentives/${id}/faqs`);
}

export function getMemberNeedsAdmin({
  memberId,
  limit,
  offset,
  state,
  resolutionStatus,
}: GetMemberNeedsOpts): Promise<PaginatedResults<MemberNeedView>> {
  const queryParams = new URLSearchParams();

  limit && queryParams.append('limit', limit.toString());
  offset && queryParams.append('offset', offset.toString());
  state && queryParams.append('state', state);
  resolutionStatus && queryParams.append('resolutionStatus', resolutionStatus);
  return getJson(`/v1/admin/members/${memberId}/memberNeeds?${queryParams.toString()}`);
}

export function getMemberNeedAdmin({
  memberNeedId,
}: {
  memberId: string;
  memberNeedId: string;
}): Promise<MemberNeedView | undefined> {
  return getJson(`/v1/admin/memberNeeds/${memberNeedId}`);
}

export function getCurrentMemberModules(): Promise<Module[]> {
  return getJson('/v1/rest/members/current/modules');
}

export function updateMemberNeedAdmin({ payload }: { payload: UpdateMemberNeed }): Promise<MemberNeedView | undefined> {
  return putJson(`/v1/admin/memberNeeds/${payload.id}`, { ...payload, id: undefined });
}

export function getCurrentModulesAdmin({ memberId }: { memberId: string }): Promise<Module[]> {
  return getJson(`/v1/admin/members/${memberId}/modules`);
}

export function getCurrentMemberModuleContent(moduleId: string): Promise<ModuleContentResponse> {
  return getJson(`/v1/rest/members/current/modules/${moduleId}/content`);
}

export function getMemberModuleContent(moduleId: string, memberId: string): Promise<ModuleContentResponse> {
  return getJson(`/v1/admin/members/${memberId}/modules/${moduleId}/content`);
}

export function getMemberResourceById(memberId: string, resourceId: string): Promise<Resource> {
  return getJson(`/v1/admin/members/${memberId}/resources/${resourceId}`);
}

export function getCurrentMemberResourceById(resourceId: string): Promise<Resource> {
  return getJson(`/v1/rest/members/current/resources/${resourceId}`);
}

export function getMemberBenefitByName(memberId: String, benefitName: string): Promise<BenefitWithPlan> {
  const encodedBenefitName = encodeURIComponent(benefitName);
  return getJson(`/v1/admin/members/${memberId}/benefits/${encodedBenefitName}`);
}

export function getCurrentMemberBenefitByName(benefitName: string): Promise<BenefitWithPlan> {
  const encodedBenefitName = encodeURIComponent(benefitName);
  return getJson(`/v1/rest/members/current/benefits/${encodedBenefitName}`);
}

export function fetchPrompts({
  userId,
  includeExpired,
  promptType,
}: FetchPromptsParams): Promise<FetchPromptsResponse> {
  const params = new URLSearchParams();
  if (includeExpired !== undefined) {
    params.append('includeExpired', String(includeExpired));
  }
  if (promptType) {
    params.append('promptType', promptType);
  }
  return getJson(`/v1/admin/members/${userId}/prompts?${params.toString()}`);
}

export function logPrompt({ userId, promptType }: LogPromptParams): Promise<void> {
  return postJson<void, { promptType: string }>(`/v1/admin/members/${userId}/prompt`, { promptType });
}

export function getServiceRequests({
  memberId,
  finalMileId,
  limit,
  offset,
}: GetServiceRequestParams): Promise<PaginatedResults<FinalMileServiceRequest>> {
  const params = new URLSearchParams();
  if (memberId) params.append('memberId', memberId);
  if (finalMileId) params.append('finalMileId', finalMileId);
  if (limit) params.append('limit', limit.toString());
  if (offset) params.append('offset', offset.toString());
  return getJson(`/v1/admin/member/finalMiles/serviceRequests?${params.toString()}`);
}

export function createServiceRequest({
  finalMileId,
  payload,
}: {
  finalMileId: string;
  payload: CreateServiceRequestBody;
}): Promise<void> {
  return postJson<void, CreateServiceRequestBody>(
    `/v1/admin/member/finalMiles/${finalMileId}/serviceRequests`,
    payload
  );
}

export function promoteServiceRequest({
  serviceRequestId,
  payload,
}: {
  serviceRequestId: string;
  payload: PromoteServiceRequestBody;
}): Promise<void> {
  return postJson<void, PromoteServiceRequestBody>(
    `/v1/admin/member/finalMiles/serviceRequests/${serviceRequestId}/promote`,
    payload
  );
}

export function submitClientError({ payload }: { payload: SubmitClientErrorBody }): Promise<void> {
  return postJson<void, SubmitClientErrorBody>(`/v1/rest/clientError`, payload);
}
