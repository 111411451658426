import { T } from '@transifex/react';
import React from 'react';

import { Text } from '../..';
import FooterLink from './FooterLink';

const FooterTerms = () => {
  return (
    <div className="flex tablet:flex-row flex-col tablet:items-center items-center gap-3 pt-8">
      <Text variant="subtitle-3" color="darkGrayOne">
        <T _str="© DUOS {year} All Rights Reserved" year={new Date().getFullYear()} />{' '}
      </Text>
      <div className="flex flex-row gap-3">
        <FooterLink href="https://www.getduos.com/privacy-policy" variant="link-2" color="darkGrayOne" underline>
          <T _str="Privacy Policy" />
        </FooterLink>
        <FooterLink href="https://www.getduos.com/terms-of-service" variant="link-2" color="darkGrayOne" underline>
          <T _str="Terms of Service" />
        </FooterLink>
      </div>
    </div>
  );
};

export default FooterTerms;
