import { useT } from '@transifex/react';
import classNames from 'classnames';
import React from 'react';

import { Text } from '..';
import { Option } from '../../../types/Common';
import { PrivacyLevel } from '../../../types/Privacy';

export type DropdownOptionProps<T extends string> = Option<T> & {
  onChange: (value: T) => void;
  privacyLevel?: PrivacyLevel;
};

function DropdownOption<T extends string>({ value, label, onChange, privacyLevel = 'pii' }: DropdownOptionProps<T>) {
  const t = useT();
  return (
    <button
      className={classNames(
        value[0],
        'p-3 cursor-pointer',
        'hover:bg-lightGrayTwo active:bg-lightLinkBlue rounded-[8px]'
      )}
      onClick={() => onChange(value)}
      type="button"
      aria-label={t('{label} option', { label })}
    >
      <div className="flex flex-row justify-start w-full">
        <Text privacyLevel={privacyLevel} tabIndex={-1} variant="body-1" color="darkGrayOne" alignment="left">
          {label}
        </Text>
      </div>
    </button>
  );
}

export default DropdownOption;
