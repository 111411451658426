import classNames from 'classnames';
import Link from 'next/link';
import React, { PropsWithChildren, forwardRef } from 'react';

import { buttonClasses } from './common';
import { AllLinkButtonProps } from './types';

const LinkButton = forwardRef<HTMLAnchorElement, PropsWithChildren<AllLinkButtonProps>>((props, ref): JSX.Element => {
  const { variant, size, fullWidth, children, href, className, ...rest } = props;
  const classes = classNames(buttonClasses({ variant, size, fullWidth, className }), 'inline-flex', 'flex-row');

  return (
    <Link passHref {...rest} ref={ref} className={classes} href={href}>
      {children}
    </Link>
  );
});

LinkButton.displayName = 'LinkButton';

export default LinkButton;
