import React, { PropsWithChildren, forwardRef } from 'react';

import { buttonClasses } from './common';
import { AllRegularButtonProps } from './types';

const RegularButton = forwardRef<HTMLButtonElement, PropsWithChildren<AllRegularButtonProps>>(
  (props, ref): JSX.Element => {
    const { variant, size, fullWidth, children, className, ...rest } = props;
    const classes = buttonClasses({ size, variant, className, fullWidth });
    return (
      <button {...rest} ref={ref} className={classes}>
        {children}
      </button>
    );
  }
);

RegularButton.displayName = 'RegularButton';

export default RegularButton;
