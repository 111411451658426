import { useT } from '@transifex/react';
import classNames from 'classnames';

import { NewTextType } from '../../../../theme/typography';
import { PrivacyLevel } from '../../../../types/Privacy';
import { Text } from '../../new';
import AnimatedRadioIcon from './AnimatedRadioIcon';

type RadioProps = {
  checked?: boolean;
  label?: string | JSX.Element;
  labelType?: NewTextType;
  labelStyle?: string;
  onClick: () => void;
  privacyLevel?: PrivacyLevel;
};

export const RadioButton = ({
  checked,
  label,
  labelType = 'body-1-emphasis',
  labelStyle,
  onClick,
  privacyLevel = 'pii',
}: RadioProps) => {
  const t = useT();
  return (
    <button
      className={classNames([
        'group',
        'flex',
        'items-center',
        'gap-2',
        'cursor-pointer',
        'transition-all',
        'rounded-s-sm',
        'hover:bg-lightGrayTwo',
        'active:bg-lightGrayOne',
        'h-10',
        {
          'focus:outline-offset-[-0.5px]': !checked,
          'focus:outline-2': !checked,
          'focus:outline-dashed': !checked,
          'focus:outline-linkBlue': !checked,
          'focus:outline-none': checked,
        },
      ])}
      onClick={onClick}
      aria-label={t('{label} radio button', { label })}
      type="button"
    >
      <AnimatedRadioIcon selected={checked} />
      {label && (
        <Text
          privacyLevel={privacyLevel}
          variant={labelType}
          color="darkGrayOne"
          className={labelStyle}
          alignment="left"
        >
          {label}
        </Text>
      )}
    </button>
  );
};

export default RadioButton;
