import React from 'react';

export const replaceStringWithComponent = (
  text: string,
  keyword: string,
  component: React.ReactNode
): React.ReactNode[] => {
  let preIdx = 0;
  let idx = text.indexOf(keyword);
  const result: React.ReactNode[] = [];

  while (idx > -1) {
    result.push(text.slice(preIdx, idx));
    result.push(component);

    preIdx = idx + keyword.length;
    idx = text.slice(preIdx).indexOf(keyword);
  }

  if (idx === -1) {
    result.push(text.slice(preIdx));
  }

  return result;
};

export const replaceStringsWithComponents = (text: string, components: Record<string, React.ReactNode>) => {
  let result: React.ReactNode[] = [];
  let source: React.ReactNode[] = [text];
  const keywords = Object.keys(components);

  for (const keyword of keywords) {
    result = [];
    source.forEach(
      (element) =>
        (result = result.concat(
          typeof element === 'string' ? replaceStringWithComponent(element, keyword, components[keyword]) : [element]
        ))
    );

    source = result;
  }

  return (
    <>
      {source.map((element, i) => (
        <React.Fragment key={i}>{element}</React.Fragment>
      ))}
    </>
  );
};
