import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export default function Container(props: PropsWithChildren<{ className?: string }>): JSX.Element {
  const wideDesktop = classNames('wideDesktop:max-w-[1200px]', 'wideDesktop:mx-auto');
  const desktop = classNames('desktop:max-w-[calc(100%-80px)]', 'desktop:mx-[40px]');
  const mobile = classNames('max-w-[calc(100%-40px)]', 'mx-[20px]');

  return <div className={classNames('w-full', wideDesktop, desktop, mobile, props.className)}>{props.children}</div>;
}
