import { IconButton } from '../..';

const FooterSocialLinks = () => {
  return (
    <div className="flex flex-no-wrap mt-[24px]">
      <IconButton
        size="medium"
        color="magenta"
        hoverColor="darkPurple"
        href="https://www.facebook.com/getduos"
        icon="facebook"
        label="Visit the DUOS Facebook page"
      />
      <IconButton
        size="medium"
        color="magenta"
        hoverColor="darkPurple"
        href="https://www.linkedin.com/company/duosliving"
        icon="linkedIn"
        label="Visit the DUOS LinkedIn page"
        className="mx-[18px]"
      />
      <IconButton
        size="medium"
        color="magenta"
        hoverColor="darkPurple"
        href="https://www.instagram.com/duos/"
        icon="instagram"
        label="Visit the DUOS Instagram page"
      />
    </div>
  );
};

export default FooterSocialLinks;
