import classNames from 'classnames';

import { toFillColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const MenuLogin = ({ size = 24, colorOverride, active = true }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const activeStandardClass = active ? 'group-hover:invisible' : '';
  const standardClass = `${active ? 'visible' : 'invisible'} ${activeStandardClass}`;
  const activeAlternateClass = active ? 'group-hover:visible' : '';
  const alternateClass = `${active ? 'invisible' : 'visible'} ${activeAlternateClass}`;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M39.7891 40C39.7891 41.1046 38.8936 42 37.7891 42L9.78906 42C8.68449 42 7.78906 41.1046 7.78906 40V38C7.78906 36.8954 6.89363 36 5.78906 36C4.68449 36 3.78906 36.8954 3.78906 38V40C3.78906 43.3137 6.47536 46 9.78906 46L37.7891 46C41.1028 46 43.7891 43.3137 43.7891 40L43.7891 10C43.7891 6.68629 41.1028 4 37.7891 4L9.78906 4C6.47535 4 3.78906 6.68629 3.78906 10V12C3.78906 13.1046 4.68449 14 5.78906 14C6.89363 14 7.78906 13.1046 7.78906 12V10C7.78906 8.89543 8.68449 8 9.78906 8L37.7891 8C38.8936 8 39.7891 8.89543 39.7891 10L39.7891 40Z"
          className={classNames(fillClass, standardClass)}
        />
        <path
          d="M23.2033 22.5858C23.9843 23.3668 23.9843 24.6332 23.2033 25.4142L17.2033 31.4142C16.4222 32.1953 15.1559 32.1953 14.3748 31.4142C13.5938 30.6332 13.5938 29.3668 14.3748 28.5858L16.9606 26H5.78906C4.68449 26 3.78906 25.1046 3.78906 24C3.78906 22.8954 4.68449 22 5.78906 22H16.9606L14.3748 19.4142C13.5938 18.6332 13.5938 17.3668 14.3748 16.5858C15.1559 15.8047 16.4222 15.8047 17.2033 16.5858L23.2033 22.5858Z"
          className={classNames(fillClass, standardClass)}
        />
        <path
          d="M7.97896 10.014C7.98283 8.90941 8.88139 8.01712 9.98595 8.02099L37.9858 8.11907C39.0903 8.12294 39.9826 9.0215 39.9788 10.1261L39.9718 12.126C39.9679 13.2306 40.8602 14.1292 41.9647 14.133C43.0693 14.1369 43.9679 13.2446 43.9717 12.1401L43.9787 10.1401C43.9903 6.82638 41.3135 4.1307 37.9998 4.11909L9.99996 4.02102C6.68627 4.00941 3.99059 6.68628 3.97898 9.99996L3.8739 39.9998C3.86229 43.3135 6.53916 46.0092 9.85285 46.0208L37.8527 46.1188C41.1664 46.1304 43.862 43.4536 43.8737 40.1399L43.8807 38.1399C43.8845 37.0353 42.9922 36.1368 41.8877 36.1329C40.7831 36.129 39.8846 37.0213 39.8807 38.1259L39.8737 40.1259C39.8698 41.2304 38.9712 42.1227 37.8667 42.1189L9.86686 42.0208C8.7623 42.0169 7.87001 41.1184 7.87388 40.0138L7.97896 10.014Z"
          className={classNames(fillClass, alternateClass)}
        />
        <path
          d="M40 40C40 41.1046 39.1046 42 38 42L10 42C8.89543 42 8 41.1046 8 40V38C8 36.8954 7.10457 36 6 36C4.89543 36 4 36.8954 4 38V40C4 43.3137 6.68629 46 10 46L38 46C41.3137 46 44 43.3137 44 40L44 10C44 6.68629 41.3137 4 38 4L10 4C6.68629 4 4 6.68629 4 10V12C4 13.1046 4.89543 14 6 14C7.10457 14 8 13.1046 8 12V10C8 8.89543 8.89543 8 10 8L38 8C39.1046 8 40 8.89543 40 10L40 40Z"
          className={classNames(fillClass, alternateClass)}
        />
        <rect
          x="41"
          y="44"
          width="34"
          height="36"
          transform="rotate(-180 41 44)"
          className={classNames(fillClass, alternateClass)}
        />
        <path
          d="M23.2033 22.5858C23.9843 23.3668 23.9843 24.6332 23.2033 25.4142L17.2033 31.4142C16.4222 32.1953 15.1559 32.1953 14.3748 31.4142C13.5938 30.6332 13.5938 29.3668 14.3748 28.5858L16.9606 26H5.78906C4.68449 26 3.78906 25.1046 3.78906 24C3.78906 22.8954 4.68449 22 5.78906 22H16.9606L14.3748 19.4142C13.5938 18.6332 13.5938 17.3668 14.3748 16.5858C15.1559 15.8047 16.4222 15.8047 17.2033 16.5858L23.2033 22.5858Z"
          className={classNames('fill-white', alternateClass)}
        />
      </svg>
    </>
  );
};

export default MenuLogin;
