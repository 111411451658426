import React, { ReactElement } from 'react';

import Text from './Text';

type Props = {
  control: ReactElement<any, any>;
  label: React.ReactNode;
};

const FormControlLabel = React.forwardRef<HTMLLabelElement, Props>(({ control, label }, ref) => {
  const labelText =
    typeof label !== 'string' ? (
      label
    ) : (
      <Text component="span" privacyLevel="pii" tabIndex={-1} variant={'body1'} className={'text-darkGrayTwo'}>
        {label}
      </Text>
    );

  return (
    <label className="inline-flex items-center cursor-pointer gap-2 align-middle" ref={ref}>
      {control}
      {labelText}
    </label>
  );
});

FormControlLabel.displayName = 'FormControlLabel';

export default FormControlLabel;
