import { useT } from '@transifex/react';
import React, { PropsWithChildren, createContext, useCallback, useMemo, useState } from 'react';

import { ActionItemForm } from '../components/forms/ActionItem';
import {
  useAddNewItem,
  useComments,
  useEditComment,
  useInteractions,
  useUpdateTodoItem,
  useUserSession,
} from '../hooks/api';
import { ActionItem, ActionItemInteraction } from '../types/ActionItem';
import { Comment, EditCommentPayload } from '../types/Comments';
import { UserSession } from '../types/UserSession';

export type ActionFormType = 'edit' | 'new';

type ActionItemContextProps = {
  userId: string;
  itemId?: string;
  scrollRef?: React.RefObject<HTMLDivElement> | null;
};

export const ActionItemContext = createContext<{
  currentUser: UserSession | null;
  selectedActionItem: ActionItem | null;
  newItemModalOpen: ActionFormType | null;
  setEditItemModal: (formType: ActionFormType | null, actionItem: ActionItem | null) => void;
  addNewActionItem: (newItem: ActionItemForm) => void;
  updateActionItem: (itemId: string, newItem: ActionItemForm) => void;
  alertMessage: string;
  comments: Comment[];
  interactions: ActionItemInteraction[];
  isLoading: boolean;
  editComment: (currentComment: EditCommentPayload) => void;
}>({
  currentUser: null,
  selectedActionItem: null,
  newItemModalOpen: null,
  setEditItemModal: () => {},
  addNewActionItem: () => {},
  updateActionItem: () => {},
  alertMessage: '',
  comments: [],
  interactions: [],
  isLoading: true,
  editComment: () => {},
});

export function ActionItemContextWrapper({ userId, itemId, children }: PropsWithChildren<ActionItemContextProps>) {
  const t = useT();

  const [newItemModalOpen, setNewItemModalOpen] = useState<ActionFormType | null>(null);
  const [selectedActionItem, setSelectedActionItem] = useState<ActionItem | null>(null);
  const [alertMessage, setAlertMessage] = useState('');

  const { data: userData } = useUserSession();
  const { data: interactionData, isLoading: interactionIsLoading } = useInteractions({ itemId: itemId });
  const { data: commentsData, isLoading: commentsIsLoading } = useComments({
    objectId: itemId,
    objectType: 'todoItem',
  });
  const editCommentMutation = useEditComment();

  const editComment = useCallback(
    (currentComment: EditCommentPayload) => {
      editCommentMutation.mutate({ payload: { ...currentComment } });
    },
    [editCommentMutation]
  );

  const currentUser = useMemo(() => userData ?? null, [userData]);

  const comments = useMemo(() => commentsData ?? [], [commentsData]);
  const interactions = useMemo(() => interactionData ?? [], [interactionData]);
  const isLoading = useMemo(() => interactionIsLoading || commentsIsLoading, [interactionIsLoading, commentsIsLoading]);

  const setEditItemModal = useCallback((formType: ActionFormType | null, actionItem: ActionItem | null) => {
    setNewItemModalOpen(formType);
    setSelectedActionItem(actionItem);
  }, []);

  const newItemAlert = useCallback(
    (newItem: ActionItem) => {
      setAlertMessage(t('A new action item was successfully added.'));
      setSelectedActionItem(newItem);
    },
    [t]
  );

  const addItemMutation = useAddNewItem({
    memberId: userId,
    onSuccess: newItemAlert,
  });

  const addNewActionItem = useCallback(
    (newItem: ActionItemForm) => {
      addItemMutation.mutate({
        memberId: userId,
        payload: {
          text: newItem.title,
          memberNeeds: newItem.memberNeed ? [newItem.memberNeed] : [],
          readinessStatus: newItem.readiness,
        },
      });
      setNewItemModalOpen(null);
    },
    [addItemMutation, userId]
  );

  const updateItemAlert = useCallback(
    (updatedItem: ActionItem) => {
      setAlertMessage(t('An action item was successfully updated.'));
      setSelectedActionItem(updatedItem);
    },
    [t]
  );

  const updateItemMutation = useUpdateTodoItem({
    memberId: userId,
    onSuccess: updateItemAlert,
  });

  const updateActionItem = useCallback(
    (itemId: string, newItem: ActionItemForm) => {
      updateItemMutation.mutate({
        itemId,
        payload: {
          text: newItem.title,
          memberNeeds: newItem.memberNeed ? [newItem.memberNeed] : undefined,
          readinessStatus: newItem.readinessStatus ?? newItem.readiness,
          status: newItem?.status ?? 'open',
          completionReason: newItem.completionReason,
          completionExplanation: newItem.completionExplanation,
        },
      });
      setNewItemModalOpen(null);
    },
    [updateItemMutation]
  );

  const value = useMemo(() => {
    return {
      currentUser,
      selectedActionItem,
      newItemModalOpen,
      setEditItemModal,
      addNewActionItem,
      updateActionItem,
      alertMessage,
      comments,
      interactions,
      isLoading,
      editComment,
    };
  }, [
    currentUser,
    selectedActionItem,
    newItemModalOpen,
    setEditItemModal,
    addNewActionItem,
    updateActionItem,
    alertMessage,
    comments,
    interactions,
    isLoading,
    editComment,
  ]);

  return <ActionItemContext.Provider value={value}>{children}</ActionItemContext.Provider>;
}

export default ActionItemContextWrapper;
