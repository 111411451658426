import { MutableRefObject, Ref, RefCallback } from 'react';
import { ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';

type MutableRef<T> = RefCallback<T> | MutableRefObject<T | null> | null;

export function combineRefs<T>(...refs: MutableRef<T>[]): RefCallback<T> {
  return (instance: T | null) => {
    for (const ref of refs) {
      if (ref instanceof Function) {
        ref(instance);
      } else if (ref) {
        ref.current = instance;
      }
    }
  };
}

/**
 * Merge a forwarded ref into a field managed by a react-hook-form Controller
 * so that the forwarded ref can reference the field as well as the form.
 * @param field The field values to be updated (passed by a Controller's render prop)
 * @param forwardedRef The forwarded ref to be merged in
 * @returns A new set of ControllerRenderProps
 */
export function mergeForwardedRef<
  TRef = any,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  field: ControllerRenderProps<TFieldValues, TName>,
  forwardedRef: MutableRef<TRef>
): ControllerRenderProps<TFieldValues, TName> {
  const { ref: fieldRef, ...rest } = field;
  const ref = combineRefs(fieldRef, forwardedRef);
  return { ...rest, ref };
}
