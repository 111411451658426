import React from 'react';

import { colors } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const MagnifyPlusIcon = ({ className, size = 64, colorOverride = 'purple' }: IconProps) => {
  return (
    <svg
      width={size}
      height={size + 1}
      viewBox="0 0 64 65"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.0273 6.03516C22.8693 6.03516 18.8046 7.27399 15.3473 9.59501C11.8901 11.916 9.19544 15.215 7.60423 19.0747C6.01302 22.9344 5.59668 27.1815 6.40787 31.279C7.21907 35.3764 9.22135 39.1402 12.1615 42.0943C15.1017 45.0484 18.8477 47.0601 22.9258 47.8752C27.004 48.6902 31.2311 48.2719 35.0726 46.6732C38.9141 45.0744 42.1975 42.367 44.5076 38.8934C46.8177 35.4197 48.0507 31.3358 48.0507 27.1581C48.0443 21.5579 45.8273 16.189 41.886 12.229C37.9447 8.26911 32.6011 6.04159 27.0273 6.03516ZM27.0273 45.3675C23.4428 45.3675 19.9388 44.2996 16.9584 42.2987C13.978 40.2978 11.655 37.4539 10.2833 34.1265C8.91154 30.7992 8.55263 27.1379 9.25193 23.6056C9.95124 20.0733 11.6773 16.8287 14.212 14.2821C16.7466 11.7355 19.9759 10.0012 23.4916 9.29855C27.0072 8.59594 30.6513 8.95655 33.9629 10.3348C37.2746 11.713 40.1051 14.047 42.0965 17.0415C44.088 20.036 45.1509 23.5566 45.1509 27.1581C45.1455 31.9859 43.2343 36.6144 39.8367 40.0281C36.439 43.4419 31.8323 45.3621 27.0273 45.3675Z"
        fill={colors[colorOverride]}
      />
      <path
        d="M36.2379 21.9985H32.1615V17.9021C32.1615 17.5157 32.0088 17.1452 31.7369 16.872C31.465 16.5988 31.0962 16.4453 30.7117 16.4453H23.3433C22.9588 16.4453 22.59 16.5988 22.3181 16.872C22.0462 17.1452 21.8934 17.5157 21.8934 17.9021V21.9985H17.8171C17.4325 21.9985 17.0638 22.1519 16.7919 22.4251C16.5199 22.6983 16.3672 23.0689 16.3672 23.4552V30.8584C16.3672 31.2448 16.5199 31.6153 16.7919 31.8885C17.0638 32.1617 17.4325 32.3152 17.8171 32.3152H21.8934V36.4109C21.8933 36.6022 21.9307 36.7917 22.0036 36.9685C22.0764 37.1453 22.1832 37.3059 22.3178 37.4412C22.4525 37.5765 22.6124 37.6838 22.7883 37.757C22.9643 37.8301 23.1529 37.8677 23.3433 37.8676H30.7117C30.9021 37.8678 31.0907 37.8302 31.2667 37.757C31.4426 37.6838 31.6025 37.5765 31.7372 37.4412C31.8718 37.3059 31.9786 37.1453 32.0515 36.9685C32.1243 36.7917 32.1617 36.6022 32.1615 36.4109V32.3152H36.2379C36.6224 32.3152 36.9912 32.1617 37.2631 31.8885C37.535 31.6153 37.6878 31.2448 37.6878 30.8584V23.4552C37.6878 23.0689 37.535 22.6983 37.2631 22.4251C36.9912 22.1519 36.6224 21.9985 36.2379 21.9985ZM34.788 29.4017H30.7117C30.3271 29.4017 29.9583 29.5552 29.6864 29.8284C29.4145 30.1016 29.2618 30.4721 29.2618 30.8584V34.9541H24.7932V30.8584C24.7932 30.4721 24.6405 30.1016 24.3686 29.8284C24.0966 29.5552 23.7279 29.4017 23.3433 29.4017H19.267V24.912H23.3433C23.7279 24.912 24.0966 24.7585 24.3686 24.4853C24.6405 24.2121 24.7932 23.8416 24.7932 23.4552V19.3588H29.2618V23.4552C29.2618 23.8416 29.4145 24.2121 29.6864 24.4853C29.9583 24.7585 30.3271 24.912 30.7117 24.912H34.788V29.4017Z"
        fill={colors[colorOverride]}
      />
      <path
        d="M63.1516 55.2108L54.9506 46.9711C54.6825 46.6993 54.3633 46.4838 54.0115 46.337C53.6598 46.1902 53.2826 46.1151 52.9017 46.116H52.899C52.518 46.115 52.1406 46.1899 51.7887 46.3364C51.4368 46.483 51.1173 46.6982 50.8487 46.9696L49.8236 47.9996L46.9631 45.1255C51.6496 39.8801 54.1134 33.004 53.8301 25.9608C53.5467 18.9177 50.5386 12.2638 45.4459 7.41555C40.3533 2.56728 33.5784 -0.0924696 26.5634 0.00245625C19.5484 0.0973821 12.8473 2.93949 7.88645 7.9238C2.92564 12.9081 0.0969231 19.641 0.00244467 26.6892C-0.0920337 33.7375 2.55518 40.5444 7.3806 45.6612C12.206 50.7779 18.8285 53.8003 25.8385 54.085C32.8484 54.3697 39.6921 51.8942 44.9128 47.1855L47.7733 50.0596L46.7483 51.0895C46.4789 51.3599 46.2652 51.6811 46.1194 52.0345C45.9737 52.388 45.8986 52.7668 45.8986 53.1494C45.8986 53.532 45.9737 53.9109 46.1194 54.2643C46.2652 54.6178 46.4789 54.9389 46.7483 55.2093L54.9506 63.4506C55.4947 63.9963 56.232 64.3027 57.0008 64.3027C57.7695 64.3027 58.5069 63.9963 59.051 63.4506L63.1516 59.3306C63.6949 58.7841 64 58.0432 64 57.2707C64 56.4982 63.6949 55.7574 63.1516 55.2108ZM27.0289 51.1934C22.2973 51.1934 17.672 49.7836 13.7379 47.1425C9.80376 44.5013 6.73747 40.7473 4.92678 36.3553C3.11609 31.9632 2.64234 27.1302 3.56542 22.4676C4.4885 17.805 6.76696 13.5221 10.1127 10.1606C13.4584 6.799 17.7211 4.50975 22.3617 3.5823C27.0024 2.65485 31.8125 3.13085 36.1839 4.95011C40.5553 6.76938 44.2916 9.85019 46.9203 13.803C49.549 17.7557 50.9521 22.4029 50.9521 27.1569C50.9447 33.5295 48.4219 39.639 43.937 44.1451C39.4521 48.6512 33.3715 51.186 27.0289 51.1934ZM57.0009 61.3906L48.7984 53.1495L52.899 49.0295L61.1027 57.2693L57.0009 61.3906Z"
        fill={colors[colorOverride]}
      />
    </svg>
  );
};
