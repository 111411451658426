import { colors } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const HomeIcon = ({ active, className, size = 24, colorOverride = 'white' }: IconProps) => {
  return (
    <svg
      className={className}
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 24 24"
      fill={colors[colorOverride]}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.13478 20.8437V17.7859C9.13478 17.0054 9.77217 16.3726 10.5584 16.3726H13.4326C13.8102 16.3726 14.1723 16.5215 14.4393 16.7866C14.7063 17.0516 14.8563 17.4111 14.8563 17.7859V20.8437C14.8539 21.1682 14.9821 21.4802 15.2124 21.7105C15.4427 21.9408 15.7561 22.0703 16.0829 22.0703H18.0438C18.9596 22.0727 19.8388 21.7132 20.4872 21.0711C21.1356 20.4291 21.5 19.5573 21.5 18.6481V9.93717C21.5 9.20277 21.1721 8.50615 20.6046 8.03498L13.934 2.74618C12.7737 1.81887 11.1111 1.84881 9.98539 2.81729L3.46701 8.03498C2.87274 8.49226 2.51755 9.19095 2.5 9.93717V18.6392C2.5 20.5342 4.04738 22.0703 5.95617 22.0703H7.87229C8.55123 22.0703 9.103 21.5265 9.10792 20.8525L9.13478 20.8437Z"
        fillOpacity={active ? 1 : 0.7}
      />
    </svg>
  );
};
