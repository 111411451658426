import NextLink from 'next/link';
import React, { PropsWithChildren, forwardRef, useCallback } from 'react';

import { AllLinkAnchorProps } from './types';

const LinkAnchor = forwardRef<HTMLAnchorElement, PropsWithChildren<AllLinkAnchorProps>>((props, ref): JSX.Element => {
  const { variant, textVariant, children, href, beforeNav, className, isLoading, ...rest } = props;

  const onClick = useCallback(() => {
    if (beforeNav) return beforeNav();
  }, [beforeNav]);

  return (
    <NextLink passHref {...rest} ref={ref} className={className} href={href} onClick={onClick}>
      {children}
    </NextLink>
  );
});

LinkAnchor.displayName = 'LinkAnchor';
export default LinkAnchor;
