import { colors } from '../../theme/colors';
import { IconProps } from '../../types/ui';

function Diamond({ size = 20, colorOverride = 'teal' }: IconProps) {
  return (
    <svg width={size} height={(size * 10) / 9} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.14829 0.5L1.16504 5.28125L0.790039 5.72675L1.16504 6.21875L9.41504 16.7188L10.0008 17.4688L10.5865 16.7188L18.8365 6.21875L19.2115 5.72675L18.8365 5.28125L14.8525 0.5H5.14829ZM5.85179 2H8.59379L6.57779 5H3.34379L5.85179 2ZM11.4063 2H14.1483L16.6563 5H13.4215L11.4063 2ZM10 2.633L11.5938 5H8.40629L10 2.633ZM3.27329 6.5H6.43754L8.21879 12.8045L3.27329 6.5ZM7.98479 6.5H11.992L10 13.4847L7.98479 6.5ZM13.5625 6.5H16.7268L11.7813 12.7812L13.5625 6.5Z"
        fill={colors[colorOverride]}
      />
    </svg>
  );
}

export default Diamond;
