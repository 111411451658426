import classNames from 'classnames';
import React, { Ref } from 'react';

import Text from '../Text';

type NumberButtonProps = {
  innerRef?: Ref<HTMLButtonElement>;
  className?: string;
  value: string;
  altValue?: string;
  onClick: (value: string) => void;
};

const NumberButton = ({ innerRef, value, altValue, onClick, className }: NumberButtonProps) => {
  return (
    <button
      ref={innerRef}
      className={classNames(
        'group h-[72px] w-[72px] bg-white m-2 border-purple border-2 rounded-full flex flex-col items-center justify-center hover:bg-purple',
        className
      )}
      onClick={() => onClick(value)}
      aria-label={value}
    >
      <Text privacyLevel="public" variant="h3" color="purple" fontWeight="medium" className="group-hover:text-white">
        {value}
      </Text>
      {altValue && (
        <Text privacyLevel="public" variant="body3" color="purple" className="group-hover:text-white">
          {altValue}
        </Text>
      )}
    </button>
  );
};

export default NumberButton;
