import { T, useT } from '@transifex/react';
import React, { useCallback, useEffect, useState } from 'react';

import { Card, IconButton } from '..';
import { Option } from '../../../types/Common';
import { useFilters } from '../../../utils/filter';
import { icons } from '../../icons';
import Search from '../../icons/Search';
import { DropdownCheckboxFilter } from '../DropdownCheckboxFilter';
import Text from '../Text';
import { TextInput } from '../TextInput';

export type SearchBarProps = {
  title: string;
  subtitle: string;
  filters?: SearchFilter[];
  onSearchChange: ({ query, filter }: SearchParams) => void;
};

export type SearchParams = {
  query: string;
  filter: Record<string, string | string[]>;
};

export type SearchFilter = {
  options: Option<string>[];
  selected: string[];
  label: string;
  key: string;
};

export const SearchBar = ({ title, subtitle, filters, onSearchChange }: SearchBarProps) => {
  const t = useT();
  const [showFilters, setShowFilters] = useState(false);
  const { filterValue, setFilterValue } = useFilters();
  const [query, setQuery] = useState('');
  const handleToggleFilters = () => setShowFilters((v) => !v);

  const AccordionIcon = icons[showFilters ? 'down-circle' : 'right-circle'];

  const onQueryChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  }, []);

  const onQueryClear = useCallback(() => {
    setQuery('');
  }, []);

  useEffect(() => onSearchChange({ query, filter: filterValue }), [filterValue, onSearchChange, query]);

  return (
    <Card display="flex" direction="col" className="grid grid-full grid-gaps col-span-full [&>*]:grid-px py-8">
      <Text privacyLevel="public" variant="h4" color="darkGrayOne" className="col-span-full">
        {title}
      </Text>
      <Text privacyLevel="public" variant="body2" color="darkGrayOne" className="col-span-full -mt-2">
        {subtitle}
      </Text>
      <div className="flex items-center w-full col-span-full">
        <TextInput
          fill
          name="query"
          value={query}
          onChange={onQueryChange}
          startAdornment={<Search />}
          endAdorment={
            <IconButton label={t('Clear keyword search')} color="purple" icon="close" onClick={onQueryClear} />
          }
        />
      </div>
      {filters && filters.length > 0 && (
        <>
          <div className="divider bg-lightGrayOne h-[1px] w-full mt-4 mb-2" />
          <button
            className="flex items-center gap-2"
            aria-label={showFilters ? t('Hide Filters') : t('Show Filters')}
            tabIndex={0}
            onClick={handleToggleFilters}
          >
            <AccordionIcon colorOverride="darkGrayThree" />
            <Text privacyLevel="public" variant="body1" color="darkGrayOne">
              <T _str="Filters" />
            </Text>
          </button>

          {showFilters && (
            <div className="flex items-center gap-4 mt-4">
              <Text privacyLevel="public" variant="label2" className="-mr-2">
                <T _str="Filter By:" />
              </Text>
              {filters.map((filter) => (
                <DropdownCheckboxFilter
                  key={filter.key}
                  name={filter.key}
                  options={filter.options}
                  label={filter.label}
                />
              ))}
            </div>
          )}
        </>
      )}
    </Card>
  );
};
