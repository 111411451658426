import React from 'react';

const Bell = () => {
  return (
    <svg
      className="mr-[16px] fill-darkGrayOne"
      width="22"
      height="27"
      viewBox="0 0 22 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.8335 26.5C8.59317 26.5 6.771 24.6778 6.771 22.4375C6.771 21.989 7.13499 21.625 7.5835 21.625C8.032 21.625 8.396 21.989 8.396 22.4375C8.396 23.782 9.48898 24.875 10.8335 24.875C12.1778 24.875 13.271 23.782 13.271 22.4375C13.271 21.989 13.635 21.625 14.0835 21.625C14.532 21.625 14.896 21.989 14.896 22.4375C14.896 24.6778 13.0738 26.5 10.8335 26.5Z" />
      <path d="M19.771 23.25H1.89597C0.850388 23.25 0 22.3996 0 21.3542C0 20.7994 0.241608 20.2741 0.663132 19.9133C2.31075 18.5212 3.25 16.4977 3.25 14.3538V11.3333C3.25 7.15175 6.65175 3.75 10.8335 3.75C15.015 3.75 18.4167 7.15175 18.4167 11.3333V14.3538C18.4167 16.4977 19.356 18.5212 20.9929 19.9058C21.4251 20.2741 21.6667 20.7994 21.6667 21.3542C21.6667 22.3996 20.8163 23.25 19.771 23.25ZM10.8335 5.375C7.54756 5.375 4.875 8.04756 4.875 11.3333V14.3538C4.875 16.9763 3.72568 19.4529 1.7226 21.1461C1.68471 21.1787 1.625 21.2459 1.625 21.3542C1.625 21.5014 1.74858 21.625 1.89597 21.625H19.771C19.9182 21.625 20.0417 21.5014 20.0417 21.3542C20.0417 21.2459 19.9822 21.1787 19.9463 21.1483C17.9411 19.4529 16.7917 16.9763 16.7917 14.3538V11.3333C16.7917 8.04756 14.1192 5.375 10.8335 5.375Z" />
      <path d="M10.8335 5.375C10.385 5.375 10.021 5.011 10.021 4.5625V1.3125C10.021 0.863998 10.385 0.5 10.8335 0.5C11.282 0.5 11.646 0.863998 11.646 1.3125V4.5625C11.646 5.011 11.282 5.375 10.8335 5.375Z" />
    </svg>
  );
};

export default Bell;
