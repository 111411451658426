import { colors, toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

export const BackArrow = ({ size = 32, colorOverride = 'purple' }: IconProps) => {
  const colorClass = toFillColor(colorOverride);
  return (
    <svg width={size} height={size * 0.9} viewBox="0 0 32 29" fill="none" className={colorClass}>
      <path d="M30 12.9986H6.28L13.54 4.2786C13.8795 3.87016 14.0428 3.3436 13.994 2.81475C13.9453 2.2859 13.6884 1.79808 13.28 1.4586C12.8716 1.11912 12.345 0.955797 11.8162 1.00456C11.2873 1.05332 10.7995 1.31017 10.46 1.7186L0.46 13.7186C0.392721 13.814 0.332559 13.9143 0.28 14.0186C0.28 14.1186 0.28 14.1786 0.14 14.2786C0.0493473 14.5079 0.00188224 14.752 0 14.9986C0.00188224 15.2452 0.0493473 15.4893 0.14 15.7186C0.14 15.8186 0.14 15.8786 0.28 15.9786C0.332559 16.0829 0.392721 16.1832 0.46 16.2786L10.46 28.2786C10.648 28.5044 10.8835 28.6859 11.1497 28.8104C11.4159 28.9348 11.7062 28.9991 12 28.9986C12.4673 28.9995 12.9202 28.8368 13.28 28.5386C13.4825 28.3707 13.6499 28.1645 13.7726 27.9318C13.8953 27.6991 13.9709 27.4445 13.9951 27.1825C14.0192 26.9206 13.9915 26.6564 13.9134 26.4052C13.8353 26.154 13.7084 25.9207 13.54 25.7186L6.28 16.9986H30C30.5304 16.9986 31.0391 16.7879 31.4142 16.4128C31.7893 16.0377 32 15.529 32 14.9986C32 14.4682 31.7893 13.9595 31.4142 13.5844C31.0391 13.2093 30.5304 12.9986 30 12.9986Z" />
    </svg>
  );
};
