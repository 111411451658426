import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor, toHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const MenuPageIcon = ({ size = 52, colorOverride = 'white', hoverColorOverride = 'lightGrayOne' }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const hoverClass = toGroupHoverFillColor(hoverColorOverride);
  const combinedFillClass = classNames(fillClass, hoverClass);
  return (
    <svg
      width={size}
      height={size}
      className="group"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7.50195C3 6.67353 3.67157 6.00195 4.5 6.00195H43.5C44.3284 6.00195 45 6.67353 45 7.50195C45 8.33038 44.3284 9.00195 43.5 9.00195H4.5C3.67157 9.00195 3 8.33038 3 7.50195Z"
        className={combinedFillClass}
      />
      <path
        d="M3 16.502C3 15.6735 3.67157 15.002 4.5 15.002H43.5C44.3284 15.002 45 15.6735 45 16.502C45 17.3304 44.3284 18.002 43.5 18.002H4.5C3.67157 18.002 3 17.3304 3 16.502Z"
        className={combinedFillClass}
      />
      <path
        d="M4.5 24.002C3.67157 24.002 3 24.6735 3 25.502C3 26.3304 3.67157 27.002 4.5 27.002H43.5C44.3284 27.002 45 26.3304 45 25.502C45 24.6735 44.3284 24.002 43.5 24.002H4.5Z"
        className={combinedFillClass}
      />
      <path
        d="M13.382 34.014C14.334 34.014 15.0993 34.308 15.678 34.896C16.266 35.4747 16.56 36.2867 16.56 37.332V41.882H14.6V37.598C14.6 36.9913 14.446 36.5293 14.138 36.212C13.83 35.8853 13.41 35.722 12.878 35.722C12.346 35.722 11.9213 35.8853 11.604 36.212C11.296 36.5293 11.142 36.9913 11.142 37.598V41.882H9.182V37.598C9.182 36.9913 9.028 36.5293 8.72 36.212C8.412 35.8853 7.992 35.722 7.46 35.722C6.91867 35.722 6.48933 35.8853 6.172 36.212C5.864 36.5293 5.71 36.9913 5.71 37.598V41.882H3.75V34.126H5.71V35.064C5.962 34.7373 6.284 34.4807 6.676 34.294C7.07733 34.1073 7.516 34.014 7.992 34.014C8.59867 34.014 9.14 34.1447 9.616 34.406C10.092 34.658 10.4607 35.022 10.722 35.498C10.974 35.05 11.338 34.6907 11.814 34.42C12.2993 34.1493 12.822 34.014 13.382 34.014Z"
        className={combinedFillClass}
      />
      <path
        d="M25.6299 37.836C25.6299 38.116 25.6113 38.368 25.5739 38.592H19.9039C19.9506 39.152 20.1466 39.5907 20.4919 39.908C20.8373 40.2253 21.2619 40.384 21.7659 40.384C22.4939 40.384 23.0119 40.0713 23.3199 39.446H25.4339C25.2099 40.1927 24.7806 40.8087 24.1459 41.294C23.5113 41.77 22.7319 42.008 21.8079 42.008C21.0613 42.008 20.3893 41.8447 19.7919 41.518C19.2039 41.182 18.7419 40.7107 18.4059 40.104C18.0793 39.4973 17.9159 38.7973 17.9159 38.004C17.9159 37.2013 18.0793 36.4967 18.4059 35.89C18.7326 35.2833 19.1899 34.8167 19.7779 34.49C20.3659 34.1633 21.0426 34 21.8079 34C22.5453 34 23.2033 34.1587 23.7819 34.476C24.3699 34.7933 24.8226 35.246 25.1399 35.834C25.4666 36.4127 25.6299 37.08 25.6299 37.836ZM23.5999 37.276C23.5906 36.772 23.4086 36.3707 23.0539 36.072C22.6993 35.764 22.2653 35.61 21.7519 35.61C21.2666 35.61 20.8559 35.7593 20.5199 36.058C20.1933 36.3473 19.9926 36.7533 19.9179 37.276H23.5999Z"
        className={combinedFillClass}
      />
      <path
        d="M31.3585 34.014C32.2825 34.014 33.0292 34.308 33.5985 34.896C34.1679 35.4747 34.4525 36.2867 34.4525 37.332V41.882H32.4925V37.598C32.4925 36.982 32.3385 36.5107 32.0305 36.184C31.7225 35.848 31.3025 35.68 30.7705 35.68C30.2292 35.68 29.7999 35.848 29.4825 36.184C29.1745 36.5107 29.0205 36.982 29.0205 37.598V41.882H27.0605V34.126H29.0205V35.092C29.2819 34.756 29.6132 34.4947 30.0145 34.308C30.4252 34.112 30.8732 34.014 31.3585 34.014Z"
        className={combinedFillClass}
      />
      <path
        d="M43.6384 34.126V41.882H41.6644V40.902C41.4124 41.238 41.0811 41.504 40.6704 41.7C40.2691 41.8867 39.8304 41.98 39.3544 41.98C38.7477 41.98 38.2111 41.854 37.7444 41.602C37.2777 41.3407 36.9091 40.9627 36.6384 40.468C36.3771 39.964 36.2464 39.3667 36.2464 38.676V34.126H38.2064V38.396C38.2064 39.012 38.3604 39.488 38.6684 39.824C38.9764 40.1507 39.3964 40.314 39.9284 40.314C40.4697 40.314 40.8944 40.1507 41.2024 39.824C41.5104 39.488 41.6644 39.012 41.6644 38.396V34.126H43.6384Z"
        className={combinedFillClass}
      />
    </svg>
  );
};

export default MenuPageIcon;
