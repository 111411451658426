export const InsuranceIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.75 5C7.23348 5 6 6.23348 6 7.75C6 9.26652 7.23348 10.5 8.75 10.5C10.2665 10.5 11.5 9.26652 11.5 7.75C11.5 6.23348 10.2665 5 8.75 5Z"
      fill="#4D4A54"
    />
    <path
      d="M9.11966 6.33343C9.11966 6.14766 8.972 6 8.78623 6C8.60046 6 8.4528 6.14766 8.4528 6.33343V7.4528H7.33343C7.14766 7.4528 7 7.60046 7 7.78623C7 7.972 7.14766 8.11966 7.33343 8.11966H8.4528V9.23903C8.4528 9.42479 8.60046 9.57246 8.78623 9.57246C8.972 9.57246 9.11966 9.42479 9.11966 9.23903V8.11966H10.239C10.4248 8.11966 10.5725 7.972 10.5725 7.78623C10.5725 7.60046 10.4248 7.4528 10.239 7.4528H9.11966V6.33343Z"
      fill="white"
    />
    <path
      d="M16.469 14.3553C16.469 14.3553 16.469 14.1388 16.469 14.0191V4.63812C16.469 4.03484 15.9809 3.54668 15.3776 3.54668H12.6743V1.32236C12.6743 1.14276 12.5315 1 12.3519 1H5.11707C4.93746 1 4.7947 1.14276 4.7947 1.32236V3.55131H2.09144C1.49275 3.55131 1 4.03948 1 4.64275V14.0167C1 14.1364 1 14.3553 1 14.3553V14.4989C1 14.4989 1 14.5198 1 14.6994C1 14.879 1.17029 15 1.3499 15H16.1206C16.3002 15 16.469 14.8788 16.469 14.6992C16.469 14.5242 16.469 14.3553 16.469 14.3553ZM5.43931 1.64465H12.0338V3.5512H5.43931V1.64465ZM15.8241 13.9132C15.8241 14.1573 15.626 14.3553 15.3774 14.3553H2.09117C1.84709 14.3553 1.64448 14.1573 1.64448 13.9132V12.0941H15.8195V13.9132H15.8241ZM15.8241 11.4494H1.64903V4.63806C1.64903 4.39398 1.84705 4.19136 2.09572 4.19136H15.3819C15.626 4.19136 15.8286 4.38939 15.8286 4.63806V11.4494H15.8241Z"
      fill="#4D4A54"
      stroke="#4D4A54"
      strokeWidth="0.5"
    />
  </svg>
);
