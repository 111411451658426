import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';

const ConfettiAnimation = dynamic(() => import('../animation/ConfettiAnimation'), { ssr: false });

const Confetti = ({ duration = 5000, onClose }: { duration?: number; onClose?: () => void }) => {
  useEffect(() => {
    const confettiTimer = setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, duration);

    return () => clearTimeout(confettiTimer);
  }, [duration, onClose]);

  return (
    <>
      <div className="flex flex-col w-screen">
        <div className="absolute top-[-200px] z-1000 tablet:top-0 left-0 w-full pointer-events-none">
          <ConfettiAnimation />
        </div>
      </div>
    </>
  );
};

export default Confetti;
