import React from 'react';

const Welcome = () => (
  <svg width="51" height="39" viewBox="0 0 51 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.3003 -8.43642e-07C28.6425 -3.77778e-07 20 8.73198 20 19.5C20 30.268 28.6425 39 39.3003 39L51 39L51 0.00534685L39.3003 0.00534736L39.3003 -8.43642e-07Z"
      fill="#172E7D"
    />
    <g>
      <path
        d="M26 19.5C26 12.5964 20.1797 7 13 7C5.8203 7 -8.48157e-07 12.5964 -5.46393e-07 19.5C-2.44629e-07 26.4035 5.8203 32 13 32C20.1797 32 26 26.4035 26 19.5Z"
        fill="#2EBEF1"
      />
    </g>
  </svg>
);

export default Welcome;
