import classNames from 'classnames';
import React from 'react';

import { toFillColor, toStrokeColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const MainNav = ({ size = 20, colorOverride }: IconProps) => {
  const fillColor = toFillColor(colorOverride);
  const strokeColor = toStrokeColor(colorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 20V4H4V20H20Z" className={classNames(fillColor, strokeColor)} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4ZM4 20V4H20V20H4Z"
        className={classNames(fillColor)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7C6 6.44772 6.44772 6 7 6L17 6C17.5523 6 18 6.44772 18 7C18 7.55229 17.5523 8 17 8L7 8C6.44772 8 6 7.55228 6 7Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12C6 11.4477 6.44772 11 7 11L17 11C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13L7 13C6.44772 13 6 12.5523 6 12Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 17C6 16.4477 6.44772 16 7 16H13C13.5523 16 14 16.4477 14 17C14 17.5523 13.5523 18 13 18H7C6.44772 18 6 17.5523 6 17Z"
        fill="white"
      />
    </svg>
  );
};
export default MainNav;
