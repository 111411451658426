import { toFillColor, toStrokeColor } from '../../theme/colors';
import { ColorType } from '../../types/ui';

type Props = {
  fillColorOverride?: ColorType;
  outlineColorOverride?: ColorType;
  size?: number;
};

export const PersonCheckCircle = ({ size = 20, fillColorOverride, outlineColorOverride }: Props) => {
  const fill = fillColorOverride ? toFillColor(fillColorOverride) : 'fill-white';
  const outline = outlineColorOverride ? toStrokeColor(outlineColorOverride) : 'stroke-lightGrayTwo';

  return (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.5" y="1.5" width="97" height="97" rx="48.5" className={fill} />
      <rect x="1.5" y="1.5" width="97" height="97" rx="48.5" strokeWidth="3" className={outline} />
      <path
        d="M62.822 60.5121C63.0183 61.5991 62.1049 62.502 61.0003 62.502C58.2038 62.502 52.7589 62.502 46.5 62.502C40.2411 62.502 34.7962 62.502 31.9997 62.502C30.8951 62.502 29.9817 61.5991 30.178 60.5121C31.3539 53.9997 38.2136 49.002 46.5 49.002C54.7864 49.002 61.6461 53.9997 62.822 60.5121Z"
        fill="#AC3EA4"
      />
      <path
        d="M46.0666 47.2048C48.7211 47.2045 50.7694 45.1562 50.7582 42.5132C50.747 39.8701 48.7106 37.8108 46.0676 37.8225C43.4131 37.8228 41.3648 39.8711 41.376 42.5141C41.3871 45.1572 43.4121 47.2051 46.0666 47.2048Z"
        fill="#AC3EA4"
      />
      <path
        d="M60.75 43.902L68.2 36.452C68.5 36.152 68.85 36.002 69.25 36.002C69.65 36.002 70 36.152 70.3 36.452C70.6 36.752 70.75 37.1103 70.75 37.527C70.75 37.9436 70.6 38.302 70.3 38.602L61.8 47.102C61.5 47.402 61.15 47.552 60.75 47.552C60.35 47.552 60 47.402 59.7 47.102L55.45 42.852C55.15 42.552 55 42.1936 55 41.777C55 41.3603 55.15 41.002 55.45 40.702C55.75 40.402 56.1 40.252 56.5 40.252C56.9 40.252 57.25 40.402 57.55 40.702L60.75 43.902Z"
        fill="#4F196F"
      />
    </svg>
  );
};
