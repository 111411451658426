import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { useMember, useUserSession } from '../../hooks/api';
import { getHelpUrl } from '../../utils/const';
import { HelpIcon } from '../icons';

export const HelpCenterLink = ({ isIcon = false, label = 'Help Center' }: { isIcon?: boolean; label?: string }) => {
  const router = useRouter();
  const { userId } = router.query;
  const { data: member } = useMember({ memberId: userId?.toString() ?? undefined });
  const { data: userSession } = useUserSession();
  return (
    <Link passHref href={getHelpUrl({ member, userSession })}>
      <a
        target="_blank"
        rel="noreferrer"
        className="fs-unmask text-linkBlue cursor-pointer underline"
        href={getHelpUrl({ member, userSession })}
      >
        {isIcon ? <HelpIcon size={32} colorOverride="purple" hoverColorOverride="lightGrayOne" /> : label}
      </a>
    </Link>
  );
};
