import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { Text } from '.';
import AnimatedRadioIcon from './RadioButton/AnimatedRadioIcon';

type RadioButtonCardProps = {
  label: string;
  selected?: boolean;
  onClick: () => void;
};

const RadioButtonCard = ({ label, selected = false, onClick, children }: PropsWithChildren<RadioButtonCardProps>) => {
  return (
    <div
      className={classNames([
        'max-w-[350px]',
        'flex',
        'flex-col',
        'items-stretch',
        'rounded-lg',
        'border',
        {
          'border-purple': selected,
          'bg-white': selected,
          'border-darkGrayOne': !selected,
        },
      ])}
    >
      <button
        className={classNames([
          {
            'cursor-default': selected,
            'focus:border-transparent': !selected,
            'focus:outline-offset-[-0.5px]': !selected,
            'focus:outline-2': !selected,
            'focus:outline-dashed': !selected,
            'focus:outline-linkBlue': !selected,
            'focus:outline-none': selected,
            'hover:border-purple': !selected,
          },
          'group',
          'rounded-lg',
          'self-stretch',
          'flex',
          'flex-row',
          'items-stretch',
          'gap-2',
          'py-6 px-4',
        ])}
        type="button"
        onClick={!selected ? onClick : undefined}
      >
        <AnimatedRadioIcon selected={selected} className="-z-10 group-hover:z-10" />
        <Text variant="body-1-emphasis" alignment="left">
          {label}
        </Text>
      </button>
      <div
        className={classNames([
          'w-full',
          'flex',
          'flex-col',
          'items-stretch',
          'gap-4',
          'transform',
          'transition-all',
          'ease-in-out',
          'duration-500',
          'px-4',
          selected
            ? 'pb-6 mt-[-8px] visible max-h-[calc(200vh)] translate-y-0 opacity-100'
            : 'mt-0 invisible max-h-0 -translate-y-5 opacity-0',
        ])}
      >
        {children}
      </div>
    </div>
  );
};

export default RadioButtonCard;
