import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

function Globe({ size = 24, colorOverride, hoverColorOverride, className }: IconProps) {
  const colorClass = toFillColor(colorOverride);
  const hoverFillClassName = toGroupHoverFillColor(hoverColorOverride);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className={classNames(className, colorClass, hoverFillClassName)}
    >
      <path
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
        className={classNames(hoverFillClassName)}
      />
      <path
        d="M12.3953 1.25053C12.8351 0.916523 13.4625 1.00232 13.7965 1.44216L13.0001 2.04693C13.7965 1.44216 13.7962 1.44185 13.7965 1.44216L13.7975 1.44348L13.7987 1.44517L13.8021 1.44965L13.812 1.46297C13.8201 1.47379 13.8308 1.48848 13.8442 1.50697C13.8709 1.54396 13.908 1.5962 13.9539 1.66324C14.0458 1.79728 14.1731 1.99068 14.324 2.23971C14.6256 2.73744 15.0226 3.4595 15.4182 4.37596C16.2086 6.20715 17.0001 8.8306 17.0001 11.9976C17.0001 15.1645 16.2086 17.788 15.4182 19.6191C15.0226 20.5356 14.6256 21.2577 14.324 21.7554C14.1731 22.0044 14.0458 22.1978 13.9539 22.3319C13.908 22.3989 13.8709 22.4511 13.8442 22.4881C13.8308 22.5066 13.8201 22.5213 13.812 22.5321L13.8021 22.5455L13.7987 22.5499L13.7975 22.5516C13.7972 22.5519 13.7965 22.5529 13.0001 21.9482L13.7965 22.5529C13.4625 22.9928 12.8351 23.0786 12.3953 22.7446C11.956 22.411 11.8699 21.7848 12.2024 21.345C12.2024 21.345 12.2024 21.345 12.2024 21.345L12.2032 21.3441C12.203 21.3442 12.2033 21.3439 12.2032 21.3441L12.2053 21.3412L12.2233 21.3166C12.2405 21.2927 12.2679 21.2543 12.304 21.2015C12.3762 21.0961 12.4832 20.934 12.6136 20.7189C12.8745 20.2884 13.2275 19.6478 13.5819 18.8266C14.2916 17.1825 15.0001 14.8306 15.0001 11.9976C15.0001 9.16451 14.2916 6.81265 13.5819 5.16852C13.2275 4.34732 12.8745 3.70674 12.6136 3.27622C12.4832 3.06113 12.3762 2.89901 12.304 2.79356C12.2679 2.74085 12.2405 2.70236 12.2233 2.67855L12.2053 2.65392L12.2033 2.65117C11.8697 2.21136 11.9556 1.5844 12.3953 1.25053Z"
        className={classNames(hoverFillClassName)}
      />
      <path
        d="M11.6047 22.7456C11.1649 23.0796 10.5375 22.9938 10.2035 22.5539L10.9999 21.9492C10.2035 22.5539 10.2038 22.5542 10.2035 22.5539L10.2025 22.5526L10.2013 22.5509L10.1979 22.5464L10.188 22.5331C10.1799 22.5223 10.1692 22.5076 10.1558 22.4891C10.1291 22.4521 10.092 22.3999 10.0461 22.3329C9.95423 22.1988 9.82688 22.0054 9.67597 21.7564C9.37436 21.2587 8.97737 20.5366 8.58182 19.6201C7.79145 17.7889 6.99995 15.1655 6.99995 11.9985C6.99995 8.83159 7.79145 6.20813 8.58182 4.37695C8.97738 3.46049 9.37436 2.73842 9.67597 2.2407C9.82688 1.99166 9.95423 1.79827 10.0461 1.66423C10.092 1.59719 10.1291 1.54494 10.1558 1.50796C10.1692 1.48946 10.1799 1.47478 10.188 1.46396L10.1979 1.45063L10.2013 1.44616L10.2025 1.44447C10.2028 1.44415 10.2035 1.44315 10.9999 2.04791L10.2035 1.44315C10.5375 1.00331 11.1649 0.917509 11.6047 1.25151C12.044 1.58511 12.1301 2.21131 11.7976 2.65107C11.7976 2.65105 11.7976 2.65108 11.7976 2.65107L11.7968 2.65204C11.797 2.65186 11.7967 2.65222 11.7968 2.65204L11.7947 2.65491L11.7767 2.67953C11.7595 2.70335 11.7321 2.74184 11.696 2.79455C11.6238 2.89999 11.5168 3.06211 11.3864 3.2772C11.1255 3.70772 10.7725 4.34831 10.4181 5.16951C9.70845 6.81363 8.99995 9.16549 8.99995 11.9985C8.99995 14.8316 9.70844 17.1834 10.4181 18.8276C10.7725 19.6488 11.1255 20.2894 11.3864 20.7199C11.5168 20.935 11.6238 21.0971 11.696 21.2025C11.7321 21.2552 11.7595 21.2937 11.7767 21.3175L11.7947 21.3422L11.7967 21.3449C12.1303 21.7847 12.0444 22.4117 11.6047 22.7456Z"
        className={classNames(hoverFillClassName)}
      />
      <path
        d="M1.62891 15.5C1.62891 14.9477 2.07662 14.5 2.62891 14.5H21.3697C21.922 14.5 22.3697 14.9477 22.3697 15.5C22.3697 16.0523 21.922 16.5 21.3697 16.5H2.62891C2.07662 16.5 1.62891 16.0523 1.62891 15.5Z"
        className={classNames(hoverFillClassName)}
      />
      <path
        d="M1.62891 8.5C1.62891 7.94772 2.07662 7.5 2.62891 7.5H21.3697C21.922 7.5 22.3697 7.94772 22.3697 8.5C22.3697 9.05228 21.922 9.5 21.3697 9.5H2.62891C2.07662 9.5 1.62891 9.05228 1.62891 8.5Z"
        className={classNames(hoverFillClassName)}
      />
    </svg>
  );
}

export default Globe;
