import React from 'react';

import { colors } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const Call = ({ size = 18, colorOverride = 'purple', className }: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1875 1.1875C14.3125 1.1875 16.8125 3.6875 16.8125 6.8125M11.1875 4.3125C12.4375 4.3125 13.6875 5.5625 13.6875 6.8125M1.1875 1.1875C1.1875 11.8125 6.1875 16.8125 16.8125 16.8125V11.8125L12.4375 10.5625L11.1875 12.4375C8.6875 12.4375 5.5625 9.3125 5.5625 6.8125L7.4375 5.5625L6.1875 1.1875H1.1875Z"
        stroke={colors[colorOverride]}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Call;
