import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import 'react-h5-audio-player/lib/styles.css';

import '../../styles/globals.css';
import '../../styles/globals.css';
import { getRedirectBaseUrl } from '../api/common';
import { submitClientError } from '../api/member';
import ErrorPage from '../components/app/ErrorPage';
import { LoadingSpinner } from '../components/common';
import Dialpad from '../components/common/Dialpad';
import ImpersonationBorder from '../components/common/ImpersonationBorder';
import { Link, Text } from '../components/common/new';
import { CallBanner } from '../components/member/dashboard/CallBanner';
import AlertContextWrapper from '../contexts/AlertContext';
import AuthenticatedContextWrapper from '../contexts/AuthenticatedContext';
import CallContextWrapper from '../contexts/CallContext';
import { useGetCurrentMember, useUserSession } from '../hooks/api';
import { useAlertContext, useAuthenticatedContext, useCallContext } from '../hooks/contexts';
import {
  CAREMANAGER_HOME,
  CAREMANAGER_PWD_LOGIN,
  DUO_LOGIN,
  MEMBER_LOGIN,
  caremanagerRoutes,
  unauthenticatedRoutes,
} from '../routes';
import { DuosRole } from '../types/Account';
import { setClientSideTranslations } from '../utils/i18n';
import queryClient from '../utils/queryClient';

declare global {
  interface Window {
    FS: {
      identify: (
        uid: string,
        userVars: { role: string; displayName: string; client?: string; isDemoRecord?: boolean }
      ) => void;
      getCurrentSession: () => string | undefined;
    };
  }
}

const MyApp: React.FunctionComponent<AppProps> = (props) => {
  setClientSideTranslations({ locale: props.pageProps.locale, translations: props.pageProps.translations });
  const router = useRouter();

  const onError = useCallback(
    (error: Error) =>
      submitClientError({ payload: { errorMessage: error.message, pathname: router.pathname } }).catch((reason) =>
        console.warn(`Unable to submit error message: ${reason}`)
      ),
    [router.pathname]
  );

  const isUnathenticatedRoute = useMemo(
    () =>
      unauthenticatedRoutes.includes(router.pathname) ||
      unauthenticatedRoutes.filter((route) => router.pathname.includes(route)).length > 0,
    [router.pathname]
  );

  useEffect(() => {
    router.events.on('routeChangeStart', (url, { shallow }) => {
      window.scrollTo(0, 0);
    });
  }, [router.events]);

  const { Component, pageProps } = props;

  const DevelopmentAlertBanner: React.FunctionComponent = () =>
    process.env.APP_ENV === 'dev' ? (
      <div className="min-h-[72px] desktop:min-h-[40.5px] h-fw-screen z-1000 bg-yellowTint flex flex-col items-stretch justify-center px-4">
        <Text variant="body-1" display="inline">
          This is the development environment. For client demo purposes, please use the{' '}
          <Link href="https://app.getduos.com" className="!inline" textVariant="body-1-emphasis">
            production environment
          </Link>
          .
        </Text>
      </div>
    ) : (
      <></>
    );

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={ErrorPage} onError={onError}>
        <Hydrate state={pageProps.dehydratedState}>
          <Head>
            <link rel="icon" href="/favicon.svg" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
          </Head>
          <DevelopmentAlertBanner />
          <AuthenticatedContextWrapper>
            {!isUnathenticatedRoute && <Authenticated {...props} />}
            {isUnathenticatedRoute && <Component {...pageProps} />}
          </AuthenticatedContextWrapper>
        </Hydrate>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

// Prevents call context from being loaded for unauthenticated users
const Authenticated: React.FunctionComponent<AppProps> = (props) => {
  const router = useRouter();
  const { data: userSession, isError } = useUserSession();
  const { data: member, status: loadMemberStatus } = useGetCurrentMember({
    enabled: userSession?.role === DuosRole.Member,
  });

  const { setUserSession } = useAuthenticatedContext();

  useEffect(() => {
    if (userSession && loadMemberStatus !== 'loading') {
      window.FS?.identify?.(userSession.sub, {
        role: userSession.role,
        displayName: userSession.sub,
        client: loadMemberStatus === 'success' ? member?.clientName : undefined,
        isDemoRecord: loadMemberStatus === 'success' ? member?.isDemoRecord : undefined,
      });
      setUserSession(userSession);
    }
  }, [member, userSession, loadMemberStatus, setUserSession]);

  const isCaremanagerResource = caremanagerRoutes.includes(router.pathname);
  const isCaremanager = userSession?.role === DuosRole.Caremanager;

  if (isError) {
    if (userSession && userSession.role) {
      if (isCaremanager) router.push(CAREMANAGER_PWD_LOGIN);
      if ([DuosRole.Dev, DuosRole.Duo, DuosRole.Ops, DuosRole.Product, DuosRole.Support].includes(userSession.role))
        router.push(DUO_LOGIN);
      else router.push(MEMBER_LOGIN);
    } else {
      // Use the same logic as base 401 check when role isn't known.
      const redirectURL = getRedirectBaseUrl(router.pathname);
      router.push(redirectURL);
    }
  } else {
    if (isCaremanager && !isCaremanagerResource) router.push(CAREMANAGER_HOME);
  }

  return (
    <>
      {userSession && (
        <AlertContextWrapper>
          <CallContextWrapper>
            {userSession.impersonator && <ImpersonationBorder />}
            <CallEnabled {...props} />
          </CallContextWrapper>
        </AlertContextWrapper>
      )}
      {!userSession && <LoadingSpinner colorOverride="purple" className="my-8" />}
    </>
  );
};

const CallEnabled: React.FunctionComponent<AppProps> = (props) => {
  const { Component, pageProps } = props;
  const {
    primaryCall,
    dialpadState,
    setDialpadState,
    dialpadNumber,
    setDialpadNumber,
    connectCall,
    addToConference,
    conference,
    callError,
  } = useCallContext();
  const { pushAlert } = useAlertContext();
  useEffect(() => {
    if (callError) pushAlert(callError, { ttl: 2500, error: true });
  }, [callError, pushAlert]);
  const callOverlayClassName = 'fixed bg-white left-0 right-0 bottom-0 z-30';
  return (
    <>
      <Component {...pageProps} />
      {primaryCall && <CallBanner className={callOverlayClassName} />}
      {dialpadState !== 'close' && (
        <Dialpad
          primaryCall={primaryCall}
          dialpadNumber={dialpadNumber}
          setDialpadNumber={setDialpadNumber}
          dialpadState={dialpadState}
          setDialpadState={setDialpadState}
          connectCall={connectCall}
          addToConference={addToConference}
          conference={conference}
        />
      )}
    </>
  );
};
export default MyApp;
