import classNames from 'classnames';
import React from 'react';

import { toFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const Social = ({ size = 20, colorOverride }: IconProps) => {
  const fillColor = toFillColor(colorOverride);
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.52004 4.93166C2.6301 3.60415 3.5216 2.58469 4.84003 2.39454C6.23979 2.19266 8.49419 2 12.0007 2C15.5071 2 17.7615 2.19266 19.1613 2.39454C20.4797 2.58469 21.3712 3.60414 21.4813 4.93166C21.5799 6.12139 21.6673 7.90717 21.6673 10.5C21.6673 13.0928 21.5799 14.8786 21.4813 16.0683C21.3712 17.3959 20.4797 18.4153 19.1613 18.6055C17.8065 18.8008 15.6511 18.9876 12.3347 18.9994L8.93769 21.717C7.95555 22.5027 6.50065 21.8035 6.50065 20.5457V18.7941C5.84652 18.7365 5.29761 18.6715 4.84003 18.6055C3.5216 18.4153 2.6301 17.3959 2.52004 16.0683C2.4214 14.8786 2.33398 13.0928 2.33398 10.5C2.33398 7.90717 2.4214 6.12139 2.52004 4.93166ZM6.90283 7.89344C6.90283 7.17062 7.4153 6.60763 8.13766 6.58194C8.89064 6.55517 10.0926 6.5267 12.0028 6.50733C14.0158 6.48691 15.2422 6.51205 15.9856 6.54703C16.6662 6.57905 17.1028 7.0982 17.1028 7.77955C17.1028 8.51618 16.6077 9.08047 15.8716 9.10934C15.1189 9.13886 13.9162 9.164 12.0028 9.164C9.99048 9.164 8.76422 9.1362 8.02077 9.10472C7.35256 9.07644 6.90283 8.56224 6.90283 7.89344ZM6.90283 13.3316C6.90283 12.6248 7.37312 12.0674 8.07924 12.0356C8.5382 12.0149 9.16816 11.9996 10.0282 11.9996C10.8883 11.9996 11.5182 12.0149 11.9772 12.0356C12.6833 12.0674 13.1536 12.6248 13.1536 13.3316C13.1536 14.0384 12.6833 14.5958 11.9772 14.6276C11.5182 14.6484 10.8883 14.6636 10.0282 14.6636C9.16816 14.6636 8.5382 14.6484 8.07924 14.6276C7.37312 14.5958 6.90283 14.0384 6.90283 13.3316Z"
        className={classNames(fillColor)}
      />
    </svg>
  );
};
export default Social;
