import React, { ForwardedRef, PropsWithChildren, forwardRef } from 'react';

import LinkButton from './LinkButton';
import RegularButton from './RegularButton';
import { ButtonProps, CommonProps, LinkButtonProps, RegularButtonProps } from './types';

export function isRegularButtonProps(props: ButtonProps): props is RegularButtonProps {
  return !(props as unknown as LinkButtonProps).href;
}

const defaults: Required<CommonProps> = {
  variant: 'primary',
  size: 'small',
  fullWidth: false,
};

const buttonDefaults: RegularButtonProps = {
  type: 'button',
};

type RefType = HTMLButtonElement | HTMLAnchorElement;

type Button = {
  (props: LinkButtonProps): JSX.Element;
  (props: RegularButtonProps): JSX.Element;
};

const ButtonComponent = forwardRef<RefType, PropsWithChildren<ButtonProps>>((passedProps, ref): JSX.Element => {
  let props = { ...defaults, ...passedProps };

  if (isRegularButtonProps(props)) {
    props = { ...buttonDefaults, ...props };
    return <RegularButton {...props} ref={ref as ForwardedRef<HTMLButtonElement>} />;
  } else {
    return <LinkButton {...props} ref={ref as ForwardedRef<HTMLAnchorElement>} />;
  }
});

ButtonComponent.displayName = 'Button';
const Button = ButtonComponent as Button;
export default Button;
