import { useT } from '@transifex/react';
import { PropsWithChildren, useCallback, useState } from 'react';

import { ColorType } from '../../../../types/ui';
import { AlertIcon } from '../../../icons';
import CheckCircle from '../../../icons/CheckCircle';
import InfoCircleOutlineIcon from '../../../icons/InfoCircleOutline';
import { IconButton } from '../../IconButton';
import Card, { CardBackgroundColor } from '../Card';

type AlertType = 'warning' | 'error' | 'info' | 'message';

type AlertBannerProps = {
  type: AlertType;
  canClose?: boolean;
  onClose?: () => void;
};

type AlertClasses = {
  backgroundColor: CardBackgroundColor;
  iconColor: ColorType;
  showCloseBtnColor: ColorType;
};

const AlertBanner = ({ type, canClose = false, onClose, children }: PropsWithChildren<AlertBannerProps>) => {
  const t = useT();

  const [isOpen, setIsOpen] = useState(true);
  const setClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  }, [onClose]);

  const AlertClasses: Record<AlertType, AlertClasses> = {
    warning: {
      backgroundColor: 'yellowTint',
      iconColor: 'darkGrayOne',
      showCloseBtnColor: 'lightGrayTwo',
    },
    error: {
      backgroundColor: 'errorRedBg',
      iconColor: 'errorRed',
      showCloseBtnColor: 'lightGrayTwo',
    },
    info: {
      backgroundColor: 'lightGreen',
      iconColor: 'successGreen',
      showCloseBtnColor: 'transparent',
    },
    message: {
      backgroundColor: 'lightLinkBlue',
      iconColor: 'mediumTeal',
      showCloseBtnColor: 'transparent',
    },
  };

  if (!isOpen) return <></>;
  return (
    <Card backgroundColor={AlertClasses[type].backgroundColor} rounding="slight">
      <div className="flex flex-row items-start gap-2 p-2">
        {type === 'message' && <AlertIcon size={24} colorOverride={AlertClasses[type].iconColor} />}
        {type === 'warning' && <InfoCircleOutlineIcon size={24} colorOverride={AlertClasses[type].iconColor} />}
        {type === 'error' && <AlertIcon size={24} colorOverride={AlertClasses[type].iconColor} />}
        {type === 'info' && <CheckCircle size={24} colorOverride={AlertClasses[type].iconColor} />}

        {children}
        {canClose && (
          <IconButton
            label={t('Close')}
            icon="close"
            color="linkBlue"
            hoverColor="linkHoverBlue"
            size="xLarge"
            onClick={setClose}
            btnColor={AlertClasses[type].showCloseBtnColor}
            iconSize={20}
          />
        )}
      </div>
    </Card>
  );
};

export default AlertBanner;
