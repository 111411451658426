import { toStrokeColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const HealthIcon = ({ size = 16, colorOverride = 'magenta' }: IconProps) => {
  const strokeClass = toStrokeColor(colorOverride);

  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        className={strokeClass}
        d="M12.2 1.8C12.2 1.58783 12.1157 1.38434 11.9657 1.23431C11.8157 1.08429 11.6122 1 11.4 1H6.6C6.38783 1 6.18434 1.08429 6.03432 1.23431C5.88429 1.38434 5.8 1.58783 5.8 1.8V5.8H1.8C1.58783 5.8 1.38434 5.88429 1.23431 6.03432C1.08429 6.18434 1 6.38783 1 6.6V11.4C1 11.6122 1.08429 11.8157 1.23431 11.9657C1.38434 12.1157 1.58783 12.2 1.8 12.2H5.8V16.2C5.8 16.4122 5.88429 16.6157 6.03432 16.7657C6.18434 16.9157 6.38783 17 6.6 17H11.4C11.6122 17 11.8157 16.9157 11.9657 16.7657C12.1157 16.6157 12.2 16.4122 12.2 16.2V12.2H16.2C16.4122 12.2 16.6157 12.1157 16.7657 11.9657C16.9157 11.8157 17 11.6122 17 11.4V6.6C17 6.38783 16.9157 6.18434 16.7657 6.03432C16.6157 5.88429 16.4122 5.8 16.2 5.8H12.2V1.8Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HealthIcon;
