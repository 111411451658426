import classNames from 'classnames';
import React from 'react';

import { toFillColor, toGroupHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const LoadingSpinner = ({
  size = 24,
  colorOverride = 'white',
  hoverColorOverride,
  className = '',
}: IconProps): JSX.Element => {
  const fillColor = toFillColor(colorOverride);
  const groupHoverFillColor = toGroupHoverFillColor(hoverColorOverride);
  return (
    <svg
      className={classNames('m-auto group', className)}
      width={size}
      height={size}
      viewBox="25 25 50 50"
      enableBackground="new 0 0 0 0"
      xmlSpace="preserve"
    >
      <path
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        className={classNames(fillColor, groupHoverFillColor)}
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};

export default LoadingSpinner;
