import classNames from 'classnames';
import React from 'react';

import { toTextColor } from '../../theme/colors';
import { ColorType } from '../../types/ui';

const HorizontalBar = ({
  color = 'lightGrayTwo',
  className = '',
  full = true,
}: {
  color?: ColorType;
  className?: string;
  full?: boolean;
}) => {
  const colorClass = toTextColor(color);
  return (
    <hr className={classNames(colorClass, 'text-opacity-50 border-[1.33px] rounded', className, { 'w-full': full })} />
  );
};

export default HorizontalBar;
