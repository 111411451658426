import { memo, useCallback } from 'react';

import { useCallContext } from '../../../hooks/contexts';

type Props = {
  number: string;
};

export const PhoneNumberLink = memo(({ number }: Props) => {
  const { setDialpadState, setDialpadNumber, primaryCall } = useCallContext();

  const handlePhoneNumberClick = useCallback(() => {
    setDialpadNumber(number);
    setDialpadState('open');
  }, [setDialpadNumber, setDialpadState, number]);

  return (
    <button
      type="button"
      className="text-linkBlue hover:text-linkHoverBlue cursor-pointer underline"
      onClick={handlePhoneNumberClick}
      disabled={primaryCall !== undefined}
    >
      {number}
    </button>
  );
});

PhoneNumberLink.displayName = 'PhoneNumberLink';
