export type LayoutType = 'new' | 'chat';

export type LayoutSections = 'margin' | 'children' | 'desktop' | 'mobile' | 'tablet';

export const SECTION_CLASSES: Record<LayoutType, Record<LayoutSections, string[]>> = {
  chat: {
    margin: ['tablet:pt-[40px]', 'tablet:pb-[40px]'],
    children: ['flex', 'flex-col', 'tablet:grid', 'tablet:gap-y-6'],
    desktop: ['desktop:grid-cols-[repeat(12,1fr)]', 'desktop:gap-x-[24px]'],
    tablet: ['tablet:grid-cols-[repeat(12,1fr)]', 'tablet:gap-x-[24px]'],
    mobile: ['grid-cols-[repeat(4,1fr)]', 'min-w-[88px]', 'gap-x-[16px]'],
  },
  new: {
    margin: ['pt-[32px]', 'pb-[24px]'],
    children: ['grid', 'gap-y-6'],
    desktop: ['desktop:grid-cols-[repeat(12,1fr)]', 'desktop:gap-x-[24px]'],
    tablet: ['tablet:grid-cols-[repeat(12,1fr)]', 'tablet:gap-x-[24px]'],
    mobile: ['grid-cols-[repeat(4,1fr)]', 'min-w-[88px]', 'gap-x-[16px]'],
  },
};

export const TYPE_CLASSES: Record<LayoutType, string[]> = {
  chat: [
    ...SECTION_CLASSES.chat.children,
    ...SECTION_CLASSES.chat.margin,
    ...SECTION_CLASSES.chat.desktop,
    ...SECTION_CLASSES.chat.tablet,
    ...SECTION_CLASSES.chat.mobile,
  ],
  new: [
    ...SECTION_CLASSES.new.children,
    ...SECTION_CLASSES.new.margin,
    ...SECTION_CLASSES.new.desktop,
    ...SECTION_CLASSES.new.tablet,
    ...SECTION_CLASSES.new.mobile,
  ],
};

export const TYPE_CLASSES_WITHOUT_MARGIN: Record<LayoutType, string[]> = {
  chat: [
    ...SECTION_CLASSES.chat.children,
    ...SECTION_CLASSES.chat.desktop,
    ...SECTION_CLASSES.chat.tablet,
    ...SECTION_CLASSES.chat.mobile,
  ],
  new: [
    ...SECTION_CLASSES.new.children,
    ...SECTION_CLASSES.new.desktop,
    ...SECTION_CLASSES.new.tablet,
    ...SECTION_CLASSES.new.mobile,
  ],
};

export const COMMON_CLASSES: string[] = ['grow', 'w-full'];

export const PAGE_CLASSES: string[] = [
  'relative',
  'min-h-[calc(100svh)]',
  'w-screen',
  'flex',
  'flex-col',
  'bg-warmWhite',
];

export const GRID_CHILDREN_SECTION_CLASSES: string[] = [
  'min-h-[calc(100svh-396px)]',
  'w-full',
  'flex',
  'flex-col',
  'justify-start',
  'items-stretch',
  'z-10',
];
