import classNames from 'classnames';
import React from 'react';

import { IconProps } from '../../types/ui';

function CheckmarkWithRibbon({ size = 24, colorOverride = 'white', className }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, 'group')}
    >
      <mask
        id="mask0_1638_5602"
        className="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1638_5602)">
        <path
          d="M10.95 12.7L9.50001 11.275C9.31668 11.0917 9.08751 11 8.81251 11C8.53751 11 8.30001 11.1 8.10001 11.3C7.91668 11.4833 7.82501 11.7167 7.82501 12C7.82501 12.2833 7.91668 12.5167 8.10001 12.7L10.25 14.85C10.45 15.05 10.6833 15.15 10.95 15.15C11.2167 15.15 11.45 15.05 11.65 14.85L15.9 10.6C16.1 10.4 16.1958 10.1667 16.1875 9.9C16.1792 9.63333 16.0833 9.4 15.9 9.2C15.7 9 15.4625 8.89583 15.1875 8.8875C14.9125 8.87916 14.675 8.975 14.475 9.175L10.95 12.7ZM8.15001 21.75L6.70001 19.3L3.95001 18.7C3.70001 18.65 3.50001 18.5208 3.35001 18.3125C3.20001 18.1042 3.14168 17.875 3.17501 17.625L3.45001 14.8L1.57501 12.65C1.40835 12.4667 1.32501 12.25 1.32501 12C1.32501 11.75 1.40835 11.5333 1.57501 11.35L3.45001 9.2L3.17501 6.375C3.14168 6.125 3.20001 5.89583 3.35001 5.6875C3.50001 5.47916 3.70001 5.35 3.95001 5.3L6.70001 4.7L8.15001 2.25C8.28335 2.03333 8.46668 1.8875 8.70001 1.8125C8.93335 1.7375 9.16668 1.75 9.40001 1.85L12 2.95L14.6 1.85C14.8333 1.75 15.0667 1.7375 15.3 1.8125C15.5333 1.8875 15.7167 2.03333 15.85 2.25L17.3 4.7L20.05 5.3C20.3 5.35 20.5 5.47916 20.65 5.6875C20.8 5.89583 20.8583 6.125 20.825 6.375L20.55 9.2L22.425 11.35C22.5917 11.5333 22.675 11.75 22.675 12C22.675 12.25 22.5917 12.4667 22.425 12.65L20.55 14.8L20.825 17.625C20.8583 17.875 20.8 18.1042 20.65 18.3125C20.5 18.5208 20.3 18.65 20.05 18.7L17.3 19.3L15.85 21.75C15.7167 21.9667 15.5333 22.1125 15.3 22.1875C15.0667 22.2625 14.8333 22.25 14.6 22.15L12 21.05L9.40001 22.15C9.16668 22.25 8.93335 22.2625 8.70001 22.1875C8.46668 22.1125 8.28335 21.9667 8.15001 21.75ZM9.45001 19.95L12 18.85L14.6 19.95L16 17.55L18.75 16.9L18.5 14.1L20.35 12L18.5 9.85L18.75 7.05L16 6.45L14.55 4.05L12 5.15L9.40001 4.05L8.00001 6.45L5.25001 7.05L5.50001 9.85L3.65001 12L5.50001 14.1L5.25001 16.95L8.00001 17.55L9.45001 19.95Z"
          fill={colorOverride}
        />
      </g>
    </svg>
  );
}

export default CheckmarkWithRibbon;
