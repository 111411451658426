import { Dispatch, PropsWithChildren, SetStateAction, createContext, useMemo, useState } from 'react';

import { UserSessionResponse } from '../types/UserSession';

export const AuthenticatedContext = createContext<{
  userSession: UserSessionResponse | undefined;
  setUserSession: Dispatch<SetStateAction<UserSessionResponse | undefined>>;
}>({
  userSession: undefined,
  setUserSession: () => {},
});

type AuthenticatedContextProps = {};

function AuthenticatedContextWrapper({ children }: PropsWithChildren<AuthenticatedContextProps>) {
  const [userSession, setUserSession] = useState<UserSessionResponse>();
  const value = useMemo(
    () => ({
      userSession: userSession,
      setUserSession: setUserSession,
    }),
    [userSession]
  );

  return <AuthenticatedContext.Provider value={value}>{children}</AuthenticatedContext.Provider>;
}

export default AuthenticatedContextWrapper;
