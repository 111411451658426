import { T } from '@transifex/react';
import classNames from 'classnames';
import React from 'react';

import { Button } from '.';
import { BookmarkFilledIcon, BookmarkOutlineIcon } from '../../icons';

const SaveButton = ({ isSaved = false, onClick }: { isSaved?: boolean; onClick: () => void }) => {
  return (
    <Button variant="darkOpaque" size="medium" onClick={onClick}>
      <BookmarkFilledIcon
        colorOverride="linkBlue"
        className={classNames(isSaved ? 'block' : 'hidden group-hover:block')}
      />
      <BookmarkOutlineIcon className={classNames(isSaved ? 'hidden' : 'block group-hover:hidden')} />
      {isSaved ? <T _str="Saved" /> : <T _str="Save" />}
    </Button>
  );
};

export default SaveButton;
