import { T } from '@transifex/react';
import React, { ReactNode } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { TextType } from '../../../theme/typography';
import { PrivacyLevel } from '../../../types/Privacy';
import { ColorType } from '../../../types/ui';
import { Text, TextInput } from '../../common';
import type { InputType } from '../../common/TextInput';
import { ErrorStyle } from '../../common/TextInput/TextInput';
import FormLabel from './FormLabel';

type FormTextField<T extends FieldValues> = {
  id?: string;
  name?: Path<T>;
  control?: Control<T, any>;
  error?: string;
  placeholder?: string;
  label: string | ReactNode;
  labelVariant?: TextType;
  labelColor?: ColorType;
  subLabel?: string | ReactNode;
  subLabelVariant?: TextType;
  subLabelColor?: ColorType;
  max?: number;
  rows?: number;
  disabled?: boolean;
  inputType?: InputType;
  privacyLevel?: PrivacyLevel;
  startAdornment?: JSX.Element;
  endAdorment?: JSX.Element;
  errorStyle?: ErrorStyle;
  increment?: (value: number) => void;
  decrement?: (value: number) => void;
  textColor?: ColorType;
};
const FormTextField = <T extends FieldValues>({
  id,
  name,
  control,
  label,
  labelVariant = 'body-1-emphasis',
  labelColor = 'darkGrayOne',
  subLabel = '',
  subLabelVariant = 'body-1',
  subLabelColor = 'darkGrayOne',
  error,
  placeholder,
  max,
  disabled,
  inputType,
  rows,
  privacyLevel = 'pii',
  startAdornment,
  endAdorment,
  increment,
  decrement,
  textColor,
}: FormTextField<T>) => {
  return (
    <>
      {name && (
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            const fieldLength = (field?.value && field.value.length) || 0;
            return (
              <div className="flex flex-col items-stretch gap-2">
                {typeof label === 'string' ? (
                  <FormLabel variant={labelVariant} color={labelColor}>
                    {label}{' '}
                    {typeof subLabel === 'string' && subLabel !== '' && (
                      <FormLabel variant={subLabelVariant} color={subLabelColor}>
                        ({subLabel})
                      </FormLabel>
                    )}
                  </FormLabel>
                ) : (
                  label
                )}
                <TextInput
                  textColor={textColor}
                  id={id}
                  privacyLevel={privacyLevel}
                  placeholder={placeholder ?? ''}
                  disabled={disabled}
                  error={error}
                  type={inputType}
                  rows={rows}
                  startAdornment={startAdornment}
                  endAdorment={endAdorment}
                  {...field}
                  increment={increment}
                  decrement={decrement}
                />
                {max && (
                  <Text privacyLevel="public" className="self-end" variant="body2" color="darkGrayOne">
                    <T _str="{fieldLength} / {max} characters" fieldLength={fieldLength} max={max} />
                  </Text>
                )}
              </div>
            );
          }}
        />
      )}
    </>
  );
};

export default FormTextField;
