import classNames from 'classnames';
import React, { useMemo } from 'react';

import { toFillColor, toGroupHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

function Share({ size = 24, className, colorOverride, hoverColorOverride }: IconProps) {
  const fillClass = useMemo(() => toFillColor(colorOverride), [colorOverride]);
  const hoverFillClass = useMemo(() => toGroupHoverFillColor(hoverColorOverride), [hoverColorOverride]);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('group flex-shrink-0', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4.55228 12 5 12.4477 5 13V19C5 19.5523 5.44772 20 6 20H18C18.5523 20 19 19.5523 19 19V13C19 12.4477 19.4477 12 20 12C20.5523 12 21 12.4477 21 13V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V13C3 12.4477 3.44772 12 4 12Z"
        className={classNames(fillClass, hoverFillClass)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7071 2.29289C12.3166 1.90237 11.6834 1.90237 11.2929 2.29289L7.79289 5.79289C7.40237 6.18342 7.40237 6.81658 7.79289 7.20711C8.18342 7.59763 8.81658 7.59763 9.20711 7.20711L11 5.41421V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V5.41421L14.7929 7.20711C15.1834 7.59763 15.8166 7.59763 16.2071 7.20711C16.5976 6.81658 16.5976 6.18342 16.2071 5.79289L12.7071 2.29289Z"
        className={classNames(fillClass, hoverFillClass)}
      />
    </svg>
  );
}

export default Share;
