import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

const EnvelopWithCheckIcon = ({ size = 24, colorOverride, hoverColorOverride, className }: IconProps) => {
  const fillClassName = toFillColor(colorOverride);
  const hoverFillClassName = toGroupHoverFillColor(hoverColorOverride);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className)}
    >
      <path
        d="M12.2916 14.9793C12.1805 14.9793 12.0763 14.9618 11.9791 14.9268C11.8819 14.8923 11.7916 14.8334 11.7083 14.7501L9.33329 12.3751C9.18051 12.2223 9.10413 12.0279 9.10413 11.7918C9.10413 11.5556 9.18051 11.3612 9.33329 11.2084C9.48607 11.0556 9.68052 10.9793 9.91663 10.9793C10.1527 10.9793 10.3472 11.0556 10.5 11.2084L12.2916 13.0001L16.4166 8.87509C16.5694 8.72231 16.7638 8.64592 17 8.64592C17.2361 8.64592 17.4305 8.72231 17.5833 8.87509C17.7361 9.02786 17.8125 9.22231 17.8125 9.45842C17.8125 9.69453 17.7361 9.88898 17.5833 10.0418L12.875 14.7501C12.7916 14.8334 12.7013 14.8923 12.6041 14.9268C12.5069 14.9618 12.4027 14.9793 12.2916 14.9793ZM2.33329 2.00009L8.99996 6.16675L15.6666 2.00009H2.33329ZM2.33329 13.6668C1.87496 13.6668 1.48274 13.5037 1.15663 13.1776C0.829959 12.8509 0.666626 12.4584 0.666626 12.0001V2.00009C0.666626 1.54175 0.829959 1.14953 1.15663 0.82342C1.48274 0.496753 1.87496 0.33342 2.33329 0.33342H15.6666C16.125 0.33342 16.5175 0.496753 16.8441 0.82342C17.1702 1.14953 17.3333 1.54175 17.3333 2.00009V5.62509L16.5 6.45842L15.6666 7.29175V3.66675L9.43746 7.56259C9.36801 7.60425 9.29496 7.63536 9.21829 7.65592C9.14218 7.67703 9.0694 7.68759 8.99996 7.68759C8.93051 7.68759 8.85774 7.67703 8.78163 7.65592C8.70496 7.63536 8.6319 7.60425 8.56246 7.56259L2.33329 3.66675V12.0001H6.62496L8.29163 13.6668H2.33329Z"
        className={classNames(fillClassName, hoverFillClassName)}
      />
    </svg>
  );
};

export default EnvelopWithCheckIcon;
