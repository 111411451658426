import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor, toGroupHoverStrokeColor, toStrokeColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const MenuShare = ({ size = 24, colorOverride, hoverColorOverride, active = true }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const strokeClass = toStrokeColor(colorOverride);
  const groupHover = toGroupHoverFillColor(hoverColorOverride);
  const groupStrokeClass = toGroupHoverStrokeColor(hoverColorOverride);
  const activeStandardClass = active ? 'group-hover:invisible' : '';
  const standardClass = `${active ? 'visible' : 'invisible'} ${activeStandardClass}`;
  const activeAlternateClass = active ? 'group-hover:visible' : '';
  const alternateClass = `${active ? 'invisible' : 'visible'} ${activeAlternateClass}`;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* standard */}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5832 7.5C20.0724 7.5 19.5832 7.70418 19.2239 8.06647L19.0646 8.22695C18.783 8.51077 18.3997 8.67041 17.9999 8.67041C17.6 8.67041 17.2167 8.51078 16.9351 8.22695L16.776 8.06663C16.0267 7.3115 14.8063 7.3115 14.057 8.06663C13.3143 8.81509 13.3143 10.0242 14.057 10.7726L18.0126 14.759C18.8416 13.962 19.6342 13.1437 20.4465 12.3048C20.934 11.8014 21.4287 11.2906 21.9427 10.7726C22.2996 10.4132 22.5 9.92651 22.5 9.41963C22.5 8.91279 22.2998 8.4264 21.9429 8.06684C21.5836 7.70458 21.094 7.5 20.5832 7.5ZM18.0008 5.23324C18.77 4.75815 19.6628 4.5 20.5832 4.5C21.895 4.5 23.1506 5.02436 24.0724 5.95375C24.9879 6.87616 25.5 8.12246 25.5 9.41963C25.5 10.7169 24.9878 11.9633 24.0722 12.8857M18.0008 5.23324C16.1003 4.05655 13.5716 4.29665 11.9275 5.95354C10.0242 7.87167 10.0242 10.9676 11.9275 12.8857L16.3045 17.2968C17.2078 18.2071 18.6804 18.2362 19.6203 17.369L19.6284 17.3615C20.6694 16.3869 21.6866 15.3369 22.6713 14.3205C23.1461 13.8305 23.6135 13.348 24.0722 12.8857M18.9055 5.95354L17.9998 6.85231L17.0941 5.95363M18.9055 5.95354L17.9998 6.85231L18.9055 5.95354Z"
          className={classNames(fillClass, groupHover, standardClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27 15.75C27 13.6789 28.6789 12 30.75 12C32.8211 12 34.5 13.6789 34.5 15.75V23.6272C34.5 24.2637 34.2471 24.8742 33.7971 25.3243L28.0607 31.0607C27.4749 31.6464 26.5251 31.6464 25.9393 31.0607C25.3536 30.4749 25.3536 29.5251 25.9393 28.9393L31.5 23.3787V15.75C31.5 15.3358 31.1642 15 30.75 15C30.3358 15 30 15.3358 30 15.75V22.5C30 23.3284 29.3284 24 28.5 24C27.6716 24 27 23.3284 27 22.5V15.75Z"
          className={classNames(fillClass, groupHover, standardClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.9104 20.7185C24.28 19.3489 26.3724 19.0094 28.1048 19.8756L28.7692 20.2078C29.5235 20.5849 30 21.3559 30 22.1993C30 22.7898 29.7654 23.3561 29.3479 23.7737L28.0607 25.0609C27.4749 25.6467 26.5251 25.6467 25.9393 25.0609C25.3536 24.4751 25.3536 23.5254 25.9393 22.9396L26.4381 22.4408C25.9398 22.3227 25.4051 22.4664 25.0317 22.8399L23.6893 24.1822C23.408 24.4635 23.25 24.845 23.25 25.2429V30.0002C23.25 30.8287 22.5784 31.5002 21.75 31.5002C20.9216 31.5002 20.25 30.8287 20.25 30.0002V25.2429C20.25 24.0494 20.7241 22.9048 21.568 22.0609L22.9104 20.7185Z"
          className={classNames(fillClass, groupHover, standardClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.25 15C4.83579 15 4.5 15.3358 4.5 15.75V23.3787L10.0607 28.9393C10.6464 29.5251 10.6464 30.4749 10.0607 31.0607C9.47487 31.6464 8.52513 31.6464 7.93934 31.0607L2.20294 25.3243C1.75286 24.8742 1.5 24.2637 1.5 23.6272V15.75C1.5 13.6789 3.17893 12 5.25 12C7.32107 12 9 13.6789 9 15.75V22.5C9 23.3284 8.32843 24 7.5 24C6.67157 24 6 23.3284 6 22.5V15.75C6 15.3358 5.66421 15 5.25 15Z"
          className={classNames(fillClass, groupHover, standardClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.56189 22.4408C10.0602 22.3227 10.5949 22.4664 10.9683 22.8399L12.3107 24.1822C12.592 24.4635 12.75 24.845 12.75 25.2429V30.0002C12.75 30.8287 13.4216 31.5002 14.25 31.5002C15.0784 31.5002 15.75 30.8287 15.75 30.0002V25.2429C15.75 24.0494 15.2759 22.9048 14.432 22.0609L13.0896 20.7185C11.72 19.3489 9.62763 19.0094 7.89518 19.8756L7.23082 20.2078C6.47649 20.5849 6 21.3559 6 22.1993C6 22.7898 6.23458 23.3561 6.65215 23.7737L7.93934 25.0609C8.52513 25.6467 9.47487 25.6467 10.0607 25.0609C10.6464 24.4751 10.6464 23.5254 10.0607 22.9396L9.56189 22.4408Z"
          className={classNames(fillClass, groupHover, standardClass)}
        />
        <path
          d="M18 6.75L17.25 6H17.625H18H18.75L18 6.75Z"
          className={classNames(fillClass, strokeClass, groupHover, groupStrokeClass, standardClass)}
        />

        {/* alternate */}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5832 7.5C20.0724 7.5 19.5832 7.70418 19.2239 8.06647L19.0646 8.22695C18.783 8.51077 18.3997 8.67041 17.9999 8.67041C17.6 8.67041 17.2167 8.51078 16.9351 8.22695L16.776 8.06663C16.0267 7.3115 14.8063 7.3115 14.057 8.06663C13.3143 8.81509 13.3143 10.0242 14.057 10.7726L18.0126 14.759C18.8416 13.962 19.6342 13.1437 20.4465 12.3048C20.934 11.8014 21.4287 11.2906 21.9427 10.7726C22.2996 10.4132 22.5 9.92651 22.5 9.41963C22.5 8.91279 22.2998 8.4264 21.9429 8.06684C21.5836 7.70458 21.094 7.5 20.5832 7.5ZM18.0008 5.23324C18.77 4.75815 19.6628 4.5 20.5832 4.5C21.895 4.5 23.1506 5.02436 24.0724 5.95375C24.9879 6.87616 25.5 8.12246 25.5 9.41963C25.5 10.7169 24.9878 11.9633 24.0722 12.8857M18.0008 5.23324C16.1003 4.05655 13.5716 4.29665 11.9275 5.95354C10.0242 7.87167 10.0242 10.9676 11.9275 12.8857L16.3045 17.2968C17.2078 18.2071 18.6804 18.2362 19.6203 17.369L19.6284 17.3615C20.6694 16.3869 21.6866 15.3369 22.6713 14.3205C23.1461 13.8305 23.6135 13.348 24.0722 12.8857M18.9055 5.95354L17.9998 6.85231L17.0941 5.95363M18.9055 5.95354L17.9998 6.85231L18.9055 5.95354Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27 15.75C27 13.6789 28.6789 12 30.75 12C32.8211 12 34.5 13.6789 34.5 15.75V23.6272C34.5 24.2637 34.2471 24.8742 33.7971 25.3243L28.0607 31.0607C27.4749 31.6464 26.5251 31.6464 25.9393 31.0607C25.3536 30.4749 25.3536 29.5251 25.9393 28.9393L31.5 23.3787V15.75C31.5 15.3358 31.1642 15 30.75 15C30.3358 15 30 15.3358 30 15.75V22.5C30 23.3284 29.3284 24 28.5 24C27.6716 24 27 23.3284 27 22.5V15.75Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.9104 20.7185C24.28 19.3489 26.3724 19.0094 28.1048 19.8756L28.7692 20.2078C29.5235 20.5849 30 21.3559 30 22.1993C30 22.7898 29.7654 23.3561 29.3479 23.7737L28.0607 25.0609C27.4749 25.6467 26.5251 25.6467 25.9393 25.0609C25.3536 24.4751 25.3536 23.5254 25.9393 22.9396L26.4381 22.4408C25.9398 22.3227 25.4051 22.4664 25.0317 22.8399L23.6893 24.1822C23.408 24.4635 23.25 24.845 23.25 25.2429V30.0002C23.25 30.8287 22.5784 31.5002 21.75 31.5002C20.9216 31.5002 20.25 30.8287 20.25 30.0002V25.2429C20.25 24.0494 20.7241 22.9048 21.568 22.0609L22.9104 20.7185Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.25 15C4.83579 15 4.5 15.3358 4.5 15.75V23.3787L10.0607 28.9393C10.6464 29.5251 10.6464 30.4749 10.0607 31.0607C9.47487 31.6464 8.52513 31.6464 7.93934 31.0607L2.20294 25.3243C1.75286 24.8742 1.5 24.2637 1.5 23.6272V15.75C1.5 13.6789 3.17893 12 5.25 12C7.32107 12 9 13.6789 9 15.75V22.5C9 23.3284 8.32843 24 7.5 24C6.67157 24 6 23.3284 6 22.5V15.75C6 15.3358 5.66421 15 5.25 15Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.56189 22.4408C10.0602 22.3227 10.5949 22.4664 10.9683 22.8399L12.3107 24.1822C12.592 24.4635 12.75 24.845 12.75 25.2429V30.0002C12.75 30.8287 13.4216 31.5002 14.25 31.5002C15.0784 31.5002 15.75 30.8287 15.75 30.0002V25.2429C15.75 24.0494 15.2759 22.9048 14.432 22.0609L13.0896 20.7185C11.72 19.3489 9.62763 19.0094 7.89518 19.8756L7.23082 20.2078C6.47649 20.5849 6 21.3559 6 22.1993C6 22.7898 6.23458 23.3561 6.65215 23.7737L7.93934 25.0609C8.52513 25.6467 9.47487 25.6467 10.0607 25.0609C10.6464 24.4751 10.6464 23.5254 10.0607 22.9396L9.56189 22.4408Z"
          className={classNames(fillClass, groupHover, alternateClass)}
        />
        <path
          d="M17.625 8.625L16.5 7.875L16.125 7.5H15.75H15.375H14.25L13.875 7.875L13.5 8.25V9V10.125L18 14.625L22.5 10.125V9L22.125 8.25L21.75 7.875L21 7.5H20.25L19.875 7.875L17.625 8.625Z"
          className={classNames(fillClass, strokeClass, groupHover, groupStrokeClass, alternateClass)}
        />
        <path
          d="M18 6.75L17.25 6H17.625H18H18.75L18 6.75Z"
          className={classNames(strokeClass, groupStrokeClass, alternateClass)}
        />
      </svg>
    </>
  );
};

export default MenuShare;
