import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

import { toTextColor } from '../../../theme/colors';
import { TextType, textVariantClassName } from '../../../theme/typography';
import { ColorType } from '../../../types/ui';
import { Alignment } from '../Text';

type Props = {
  text: string;
  variant: TextType;
  color: ColorType;
  alignment?: Alignment;
};

const MarkdownText = ({ text, variant, color, alignment = 'left' }: Props) => {
  const DEFAULT_MARKDOWN_CLASSNAMES = [
    'markdown-images',
    'markdown-bulleted-list',
    'markdown-ordered-list',
    'markdown-links',
  ];
  const DISPLAY_CLASSNAMES = ['flex', 'flex-col', 'gap-2'];

  const variantClassNames = textVariantClassName(variant);
  const colorClassNames = toTextColor(color);
  const alignmentClassName =
    alignment === 'left'
      ? 'text-left'
      : alignment === 'center'
      ? 'text-center'
      : alignment === 'right'
      ? 'text-right'
      : '';

  return (
    <ReactMarkdown
      className={classNames(
        DEFAULT_MARKDOWN_CLASSNAMES,
        DISPLAY_CLASSNAMES,
        variantClassNames,
        colorClassNames,
        alignmentClassName,
        'font-sans'
      )}
      linkTarget="_blank"
    >
      {text}
    </ReactMarkdown>
  );
};

export default MarkdownText;
