import classNames from 'classnames';

import { toFillColor, toGroupHoverFillColor, toGroupHoverStrokeColor, toStrokeColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const ThumbsUp = ({ size = 24, colorOverride, hoverColorOverride, className }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const hoverClass = toGroupHoverFillColor(hoverColorOverride);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className="flex-shrink-0"
    >
      <mask
        id="mask0_389_11335"
        className="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_389_11335)">
        <path
          d="M21 8.0001C21.5333 8.0001 22 8.2001 22.4 8.6001C22.8 9.0001 23 9.46676 23 10.0001V12.0001C23 12.1168 22.9833 12.2418 22.95 12.3751C22.9167 12.5084 22.8833 12.6334 22.85 12.7501L19.85 19.8001C19.7 20.1334 19.45 20.4168 19.1 20.6501C18.75 20.8834 18.3833 21.0001 18 21.0001H7V8.0001L13 2.0501C13.25 1.8001 13.5458 1.65426 13.8875 1.6126C14.2292 1.57093 14.5583 1.63343 14.875 1.8001C15.1917 1.96676 15.425 2.2001 15.575 2.5001C15.725 2.8001 15.7583 3.10843 15.675 3.4251L14.55 8.0001H21ZM9 8.8501V19.0001H18L21 12.0001V10.0001H12L13.35 4.5001L9 8.8501ZM4 21.0001C3.45 21.0001 2.97917 20.8043 2.5875 20.4126C2.19583 20.0209 2 19.5501 2 19.0001V10.0001C2 9.4501 2.19583 8.97926 2.5875 8.5876C2.97917 8.19593 3.45 8.0001 4 8.0001H7V10.0001H4V19.0001H7V21.0001H4Z"
          className={classNames(fillClass, hoverClass)}
        />
      </g>
    </svg>
  );
};

export default ThumbsUp;
