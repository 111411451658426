import { LinkVariant, UnderlineType } from './types';

export const VARIANT_CLASSES: Record<LinkVariant, string[]> = {
  light: [
    'text-linkBlue',
    'hover:text-linkHoverBlue',
    'group-hover:text-linkHoverBlue',
    'visited:text-linkBlue',
    'visited:hover:text-linkHoverBlue',
    'visited:group-hover:text-linkHoverBlue',
    '[&>svg]:fill-linkBlue',
    '[&:hover>svg]:fill-linkHoverBlue',
    'focus:outline-offset-[-0.5px]',
    'focus:outline-2',
    'focus:outline-dashed',
    'focus:outline-linkBlue',
  ],
  cancel: [
    'text-errorRed',
    'hover:text-errorRed',
    'group-hover:text-errorRed',
    'visited:text-errorRed',
    'visited:hover:text-errorRed',
    'visited:group-hover:text-errorRed',
    '[&>svg]:fill-linkBlue',
    '[&:hover>svg]:fill-errorRed',
    'focus:outline-offset-[-0.5px]',
    'focus:outline-2',
    'focus:outline-dashed',
    'focus:outline-errorRed',
  ],
  gray: [
    'text-darkGrayOne',
    'hover:text-linkHoverBlue',
    'group-hover:text-linkHoverBlue',
    'visited:text-darkGrayOne',
    'visited:hover:text-linkHoverBlue',
    'visited:group-hover:text-linkHoverBlue',
    '[&>svg]:fill-darkGrayOne',
    '[&:hover>svg]:fill-linkHoverBlue',
    'focus:outline-offset-[-0.5px]',
    'focus:outline-2',
    'focus:outline-dashed',
    'focus:outline-darkGrayOne',
  ],
  dark: [
    'text-white',
    'hover:text-white',
    'group-hover:text-white',
    'visited:text-white',
    'visited:hover:text-white',
    'visited:group-hover:text-white',
    '[&>svg]:fill-white',
    '[&:hover>svg]:fill-white',
    'focus:outline-offset-[-0.5px]',
    'focus:outline-2',
    'focus:outline-dashed',
    'focus:outline-white',
  ],
};

export const UNDERLINE_CLASSES: Record<UnderlineType, string[]> = {
  always: ['underline', 'hover:underline', 'group-hover:underline'],
  hover: ['hover:underline', 'group-hover:underline'],
  none: [],
};
