import classNames from 'classnames';

import { toFillColor } from '../../../theme/colors';
import { IconProps } from '../../../types/ui';

const MenuLogout = ({ size = 24, colorOverride, active = true }: IconProps) => {
  const fillClass = toFillColor(colorOverride);
  const activeStandardClass = !active ? 'group-hover:invisible' : '';
  const standardClass = `${!active ? 'visible' : 'invisible'} ${activeStandardClass}`;
  const activeAlternateClass = !active ? 'group-hover:visible' : '';
  const alternateClass = `${!active ? 'invisible' : 'visible'} ${activeAlternateClass}`;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.21094 8C8.21094 6.89543 9.10637 6 10.2109 6H38.2109C39.3155 6 40.2109 6.89543 40.2109 8V10C40.2109 11.1046 41.1064 12 42.2109 12C43.3155 12 44.2109 11.1046 44.2109 10V8C44.2109 4.68629 41.5246 2 38.2109 2H10.2109C6.89723 2 4.21094 4.68629 4.21094 8V38C4.21094 41.3137 6.89723 44 10.2109 44H38.2109C41.5246 44 44.2109 41.3137 44.2109 38V36C44.2109 34.8954 43.3155 34 42.2109 34C41.1064 34 40.2109 34.8954 40.2109 36V38C40.2109 39.1046 39.3155 40 38.2109 40H10.2109C9.10637 40 8.21094 39.1046 8.21094 38V8Z"
          className={classNames(fillClass, standardClass)}
        />
        <path
          d="M24.7967 25.4142C24.0157 24.6332 24.0157 23.3668 24.7967 22.5858L30.7967 16.5858C31.5778 15.8047 32.8441 15.8047 33.6252 16.5858C34.4062 17.3668 34.4062 18.6332 33.6252 19.4142L31.0394 22H42.2109C43.3155 22 44.2109 22.8954 44.2109 24C44.2109 25.1046 43.3155 26 42.2109 26H31.0394L33.6252 28.5858C34.4062 29.3668 34.4062 30.6332 33.6252 31.4142C32.8441 32.1953 31.5778 32.1953 30.7967 31.4142L24.7967 25.4142Z"
          className={classNames(fillClass, standardClass)}
        />
        <path
          d="M40.021 37.986C40.0172 39.0906 39.1186 39.9829 38.014 39.979L10.0142 39.8809C8.90966 39.8771 8.01737 38.9785 8.02124 37.8739L8.02824 35.874C8.03211 34.7694 7.13982 33.8708 6.03526 33.867C4.9307 33.8631 4.03214 34.7554 4.02827 35.8599L4.02126 37.8599C4.00965 41.1736 6.68652 43.8693 10.0002 43.8809L38 43.979C41.3137 43.9906 44.0094 41.3137 44.021 38L44.1261 8.00022C44.1377 4.68653 41.4608 1.99085 38.1472 1.97924L10.1473 1.88117C6.83363 1.86956 4.13795 4.54642 4.12634 7.86011L4.11934 9.8601C4.11547 10.9647 5.00776 11.8632 6.11232 11.8671C7.21688 11.871 8.11544 10.9787 8.11931 9.87411L8.12632 7.87412C8.13019 6.76956 9.02875 5.87727 10.1333 5.88114L38.1331 5.97922C39.2377 5.98309 40.13 6.88165 40.1261 7.98621L40.021 37.986Z"
          className={classNames(fillClass, alternateClass)}
        />
        <path
          d="M8 8C8 6.89543 8.89543 6 10 6H38C39.1046 6 40 6.89543 40 8V10C40 11.1046 40.8954 12 42 12C43.1046 12 44 11.1046 44 10V8C44 4.68629 41.3137 2 38 2H10C6.68629 2 4 4.68629 4 8V38C4 41.3137 6.68629 44 10 44H38C41.3137 44 44 41.3137 44 38V36C44 34.8954 43.1046 34 42 34C40.8954 34 40 34.8954 40 36V38C40 39.1046 39.1046 40 38 40H10C8.89543 40 8 39.1046 8 38V8Z"
          className={classNames(fillClass, alternateClass)}
        />
        <rect x="7" y="4" width="34" height="36" className={classNames(fillClass, alternateClass)} />
        <path
          d="M24.7967 25.4142C24.0157 24.6332 24.0157 23.3668 24.7967 22.5858L30.7967 16.5858C31.5778 15.8047 32.8441 15.8047 33.6252 16.5858C34.4062 17.3668 34.4062 18.6332 33.6252 19.4142L31.0394 22H42.2109C43.3155 22 44.2109 22.8954 44.2109 24C44.2109 25.1046 43.3155 26 42.2109 26H31.0394L33.6252 28.5858C34.4062 29.3668 34.4062 30.6332 33.6252 31.4142C32.8441 32.1953 31.5778 32.1953 30.7967 31.4142L24.7967 25.4142Z"
          className={classNames('fill-white', alternateClass)}
        />
      </svg>
    </>
  );
};

export default MenuLogout;
