import { formatLocal } from 'phoneformat.js';

import { Location } from '../types/User';

export const getInitials = (name: string) => {
  const names = name.split(' ');
  let result = names[0];
  if (names.length > 1) result = `${names[0]?.charAt(0) || ''}${names[1]?.charAt(0) || ''}`;
  else if (names[0].length > 1) result = names[0].slice(0, 2);
  return result;
};

export const generateText = (value: string | object) => {
  if (typeof value === 'string') return value;
  if (typeof value === 'object') {
    return Object.values(value).join('\n');
  }

  return '';
};

/**
 * @returns Either a phone number string with format XXXXXXXXXX or an empty string.
 */
export const cleanPhone = (input: string): string => {
  const digits = input.match(/\d/g);
  // Strip country code if US.
  if (digits && digits.length > 10 && digits[0] === '1') {
    return digits.slice(1).join('');
  }
  if (digits && digits.length > 0) {
    return digits.join('');
  }
  return '';
};

export const formatPhone = (phone: string) => {
  if (phone === '') return '';
  if (phone.charAt(0) === '*') {
    return `${phone.slice(0, 3)} ${formatLocal('us', phone.slice(3))}`;
  }

  const hasSpecialChars = ['*', '#'].some((c) => phone.includes(c));
  if (hasSpecialChars) return phone;

  const countryCodeStrippedPhone = cleanPhone(phone);

  if (!countryCodeStrippedPhone) return phone;

  const formattedPhone = `(${countryCodeStrippedPhone.slice(0, 3)}) ${countryCodeStrippedPhone.slice(
    3,
    6
  )}-${countryCodeStrippedPhone.slice(6, 15)}`;
  return formattedPhone;
};

export const formatLocation = (location: Location) => {
  const { address, cityName, regionName, zipCode } = location;
  const localDetails = [];
  const regionalDetails = [];
  if (address) localDetails.push(address);
  if (cityName) localDetails.push(cityName);
  if (regionName) regionalDetails.push(regionName);
  if (zipCode) regionalDetails.push(zipCode);
  if (localDetails.length > 0 && regionalDetails.length > 0)
    return [localDetails.join(' '), regionalDetails.join(' ')].join(', ');
  if (localDetails.length > 0) return localDetails.join(' ');
  if (regionalDetails.length > 0) return regionalDetails.join(' ');
  return undefined;
};
