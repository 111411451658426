import classNames from 'classnames';
import React from 'react';

import { toFillColor, toGroupHoverFillColor } from '../../theme/colors';
import { IconProps } from '../../types/ui';

function NavigationIcon({ size = 24, colorOverride, hoverColorOverride }: IconProps) {
  const fillClassName = toFillColor(colorOverride);
  const hoverFillClassName = toGroupHoverFillColor(hoverColorOverride);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="flex-shrink-0"
    >
      <mask
        id="mask0_3076_6888"
        className="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width={size} height={size} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3076_6888)">
        <path
          d="M16 8.4L7.09999 17.3C6.91665 17.4833 6.68332 17.575 6.39999 17.575C6.11665 17.575 5.88332 17.4833 5.69999 17.3C5.51665 17.1167 5.42499 16.8833 5.42499 16.6C5.42499 16.3167 5.51665 16.0833 5.69999 15.9L14.6 7H6.99999C6.71665 7 6.47915 6.90417 6.28749 6.7125C6.09582 6.52083 5.99999 6.28333 5.99999 6C5.99999 5.71667 6.09582 5.47917 6.28749 5.2875C6.47915 5.09583 6.71665 5 6.99999 5H17C17.2833 5 17.5208 5.09583 17.7125 5.2875C17.9042 5.47917 18 5.71667 18 6V16C18 16.2833 17.9042 16.5208 17.7125 16.7125C17.5208 16.9042 17.2833 17 17 17C16.7167 17 16.4792 16.9042 16.2875 16.7125C16.0958 16.5208 16 16.2833 16 16V8.4Z"
          className={classNames(fillClassName, hoverFillClassName)}
        />
      </g>
    </svg>
  );
}
export default NavigationIcon;
