import { useT } from '@transifex/react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';

import { Text } from '../..';
import { IconType } from '../../../../types/ui';
import DynamicIcon from '../../MultiSelect/DynamicIcon';
import MenuItemChevron from './MenuItemChevron';
import SubMenuItem from './SubMenuItem';

type MenuItemProps = {
  label?: string;
  icon?: IconType;
  selected?: boolean;
  onClick?: () => void;
  subMenuItems?: { label: string; route: string; selected: boolean }[];
};

const MenuItem = ({ label, icon = 'menuHome', selected = false, onClick, subMenuItems }: MenuItemProps) => {
  const t = useT();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(selected);
  const [isActive, setIsActive] = useState(selected);

  const subMenuActive = subMenuItems?.find((menuItem) => menuItem.selected) ?? false;
  const isLabelActive = !selected && subMenuActive;

  const handleSubMenuToggle = useCallback(() => {
    setIsActive(!isSubMenuOpen);
    setIsSubMenuOpen((prevIsSubMenuOpen) => !prevIsSubMenuOpen);
    if (isSubMenuOpen && onClick) {
      onClick();
    }
  }, [isSubMenuOpen, onClick, setIsSubMenuOpen]);

  return (
    <>
      <button
        aria-label={t('Navigate to {label} page', { label })}
        className={classNames(
          'group h-[84px] flex flex-row justify-between items-center px-5 py-6 gap-3 hover:shadow-[inset_0px_-2px_rgba(172,62,164,1)]',
          selected
            ? 'bg-lightGrayFour shadow-[inset_0px_-2px_rgba(172,62,164,1)]'
            : 'bg-white border-t-[1px] border-mediumGrayOne'
        )}
        onClick={() => {
          if (subMenuItems) {
            handleSubMenuToggle();
          } else if (onClick) {
            onClick();
          }
        }}
      >
        <div className="flex flex-row items-center">
          {icon && <DynamicIcon size={48} colorOverride="magenta" active={isActive} icon={icon} />}
          <Text
            variant="nav-1"
            color={isLabelActive ? 'magenta' : 'darkGrayOne'}
            className={classNames(selected ? '' : 'group-hover:text-magenta', 'ml-[8px]')}
          >
            {label}
          </Text>
        </div>
        {subMenuItems && <MenuItemChevron expanded={isSubMenuOpen} />}
      </button>
      {isSubMenuOpen && subMenuItems && (
        <>
          {subMenuItems.map((subMenuItem, index) => (
            <SubMenuItem
              key={index}
              label={subMenuItem.label}
              route={subMenuItem.route}
              isSelected={subMenuItem.selected}
            />
          ))}
        </>
      )}
    </>
  );
};

export default MenuItem;
