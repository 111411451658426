import React, { PropsWithChildren, forwardRef } from 'react';

import Close from './Close';
import SaveOnCard from './SaveOnCard';
import Submit from './Submit';
import ThumbsDown from './ThumbsDown';
import ThumbsUp from './ThumbsUp';
import { buttonClasses } from './common';
import { AllRegularButtonProps } from './types';

const UtilityButton = forwardRef<HTMLButtonElement, PropsWithChildren<AllRegularButtonProps>>(
  (props, ref): JSX.Element => {
    const { variant, fullWidth, isActive, children, className, ...rest } = props;
    const classes = buttonClasses({ variant, className, fullWidth });
    return (
      <button {...rest} ref={ref} className={classes}>
        {variant === 'thumbsUp' && <ThumbsUp />}
        {variant === 'thumbsDown' && <ThumbsDown />}
        {variant === 'saveOnCard' && <SaveOnCard saved={isActive} />}
        {variant === 'submit' && <Submit />}
        {variant === 'close' && <Close />}
      </button>
    );
  }
);

UtilityButton.displayName = 'UtilityButton';

export default UtilityButton;
