import classNames from 'classnames';
import React, { useMemo } from 'react';

import { toFillColor, toGroupHoverFillColor, toGroupHoverStrokeColor, toStrokeColor } from '../../theme/colors';
import { ColorType, IconProps } from '../../types/ui';

type CheckCircleProps = IconProps & {
  checkColorOverride?: ColorType;
  circleBorderOverride?: ColorType;
  checked?: boolean;
};

function CheckCircle({
  size = 24,
  className = '',
  colorOverride,
  checkColorOverride,
  circleBorderOverride,
  hoverColorOverride,
  hoverStrokeColorOverride,
  checked,
}: CheckCircleProps) {
  const fillClass = useMemo(() => toFillColor(colorOverride), [colorOverride]);
  const strokeClass = useMemo(() => toStrokeColor(circleBorderOverride), [circleBorderOverride]);
  const checkMarkClass = useMemo(() => toFillColor(checkColorOverride), [checkColorOverride]);
  const hoverFillClass = useMemo(() => toGroupHoverFillColor(hoverColorOverride), [hoverColorOverride]);
  const hoverStrokeClass = useMemo(() => toGroupHoverStrokeColor(hoverStrokeColorOverride), [hoverStrokeColorOverride]);

  return (
    <div className={classNames('flex-shrink-0 relative flex justify-center items-center', className)}>
      <svg width={size} height={size} viewBox="0 0 26 26" fill="none" className={className}>
        <circle
          cx="13"
          cy="13"
          r="12"
          className={classNames(strokeClass, fillClass, hoverFillClass, hoverStrokeClass)}
          strokeWidth="2"
        />
        <path
          d="M19.0235 8.52733C19.2547 8.74591 19.3896 9.04735 19.3986 9.36539C19.4076 9.68343 19.2899 9.99203 19.0715 10.2233L12.2715 17.4233C12.1613 17.5398 12.0288 17.633 11.882 17.6975C11.7351 17.7619 11.5769 17.7962 11.4165 17.7985C11.2562 17.8007 11.097 17.7708 10.9484 17.7105C10.7999 17.6502 10.6649 17.5607 10.5515 17.4473L6.95146 13.8473C6.73949 13.6199 6.6241 13.319 6.62958 13.0081C6.63507 12.6972 6.76101 12.4006 6.98087 12.1807C7.20073 11.9609 7.49734 11.8349 7.80823 11.8295C8.11911 11.824 8.41998 11.9394 8.64746 12.1513L11.3755 14.8777L17.3275 8.57533C17.546 8.34413 17.8475 8.20919 18.1655 8.20019C18.4836 8.19119 18.7922 8.30886 19.0235 8.52733Z"
          className={checkMarkClass}
        />
      </svg>
    </div>
  );
}

export default CheckCircle;
