import * as Yup from 'yup';

export const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneNumberRegExp = /(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}/gm;
export const airtablePhoneLinkRegExp = /http:\/\/tel:(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}/gm;

export const isValidEmail = (email: string) => email.toLowerCase().match(emailRegExp);

export const emailValidator = ({ isRequired }: { isRequired: boolean }) => {
  const base = isRequired ? Yup.string().required('Email addresss is required.') : Yup.string();
  return base.test('format', 'Invalid email address.', (val) => !val || !!isValidEmail(val));
};
