import React, { PropsWithChildren, forwardRef } from 'react';

import { AllLinkButtonProps } from './types';

const LinkButton = forwardRef<HTMLButtonElement, PropsWithChildren<AllLinkButtonProps>>((props, ref): JSX.Element => {
  const { variant, textVariant, children, href, className, isLoading, ...rest } = props;
  return (
    <button {...rest} ref={ref} className={className}>
      {children}
    </button>
  );
});

LinkButton.displayName = 'LinkButton';
export default LinkButton;
