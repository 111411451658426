import { useT } from '@transifex/react';
import classNames from 'classnames';
import Image from 'next/image';

import Text from './Text';

type DuosLoadingImageProps = {
  text?: string | React.ReactNode;
  className?: string;
  size?: number;
};

const DuosLoadingImage = ({ text, className, size }: DuosLoadingImageProps) => {
  const t = useT();
  return (
    <div className={classNames('flex flex-col items-center text-center h-screen gap-6 mt-[240px]', className)}>
      <Image priority alt={t('Duos Inc. Purple Logo')} src={'/images/PrimaryBrand.png'} width={165} height={36} />
      {text && (
        <Text privacyLevel="public" color="purple" variant="body-1-deprecated">
          {text}
        </Text>
      )}
    </div>
  );
};

export default DuosLoadingImage;
