import { PropsWithChildren, ReactNode, createContext, useCallback, useMemo, useState } from 'react';

type AlertType = 'info' | 'error';

type AlertOpts = {
  ttl?: number;
  style?: string;
  error?: boolean;
};

type PushAlert = (message: ReactNode, opts?: AlertOpts) => void;
type ClearAlert = () => void;

export const AlertContext = createContext<{
  alertMessage?: ReactNode;
  alertStyle?: string;
  pushAlert: PushAlert;
  clearAlert: ClearAlert;
  alertType?: AlertType;
}>({
  alertMessage: undefined,
  alertStyle: undefined,
  pushAlert: () => {},
  clearAlert: () => {},
  alertType: 'info',
});

type AlertContextProps = {};

type AlertState = {
  message?: ReactNode;
  style?: string;
  timeout?: NodeJS.Timeout;
  type?: AlertType;
};

function AlertContextWrapper({ children }: PropsWithChildren<AlertContextProps>) {
  const [alertState, setAlertState] = useState<AlertState>({});

  const clearAlert = useCallback(() => {
    setAlertState((existing) => {
      if (existing.timeout) {
        clearTimeout(existing.timeout);
      }

      return {};
    });
  }, []);

  const pushAlert = useCallback(
    (message: ReactNode, opts?: AlertOpts) => {
      setAlertState((existing) => {
        if (existing.timeout) {
          clearTimeout(existing.timeout);
        }

        const timeout = opts?.ttl
          ? setTimeout(() => {
              clearAlert();
            }, opts?.ttl)
          : undefined;

        return {
          message,
          timeout,
          style: opts?.style,
          type: opts?.error ? 'error' : 'info',
        };
      });
    },
    [clearAlert]
  );

  const value = useMemo(
    () => ({
      alertMessage: alertState.message,
      alertStyle: alertState.style,
      pushAlert,
      clearAlert,
      alertType: alertState.type,
    }),
    [alertState.message, alertState.style, pushAlert, clearAlert, alertState.type]
  );

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
}

export default AlertContextWrapper;
